import React from 'react';

import styles from './index.module.css';
import { useIntl } from 'react-intl';

type SummaryInfoProps = {
  options: {
    title: string;
    value: React.ReactNode;
  }[];
};

export const SummaryInfo = ({ options }: SummaryInfoProps) => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <span className={styles.value}>
        {intl.formatMessage({
          id: 'sidebar.operation.check_data',
          defaultMessage: 'Check the data',
        })}
      </span>
      {options.map(({ title, value }) => (
        <div className={styles.row}>
          <span className={styles.title}>{title}</span>
          {typeof value === 'string' ? (
            <span className={styles.value}>{value}</span>
          ) : (
            value
          )}
        </div>
      ))}
    </div>
  );
};
