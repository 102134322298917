/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';
import { observer } from 'mobx-react-lite';
import Table from 'components/core/table';
import Badge from 'components/core/badges/badge';
import { UserModel, UserVerificationStatus } from 'models/UserModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { useIntl } from 'react-intl';
import { cutId } from 'utils/strings';
import { useIntlUserVerificationStatuses } from 'hooks/intl/user-verification-statuses';
import { useIntlUserApproved } from 'hooks/intl/user-approved';
import type { TableProps } from 'antd';
import { GroupDto } from 'api/auth';

type Props = {
  collection: CollectionWithPages<UserModel, {}>;
  onUserClick?: (user: UserModel) => void;
};

const UsersTable = observer(({ collection, onUserClick }: Props) => {
  const intl = useIntl();
  const { verificationStatusToIntlText } = useIntlUserVerificationStatuses();
  const { approvedBooleanToIntlText } = useIntlUserApproved();

  const verificationStatusToBadgeType = (status: UserVerificationStatus) => {
    switch (status) {
      case UserVerificationStatus.GREEN:
        return 'success';

      case UserVerificationStatus.PENDING:
      case UserVerificationStatus.PENDINGL2F:
        return 'warning';

      case UserVerificationStatus.REJECT:
      case UserVerificationStatus.RETRY:
        return 'danger';

      case UserVerificationStatus.INIT:
      case UserVerificationStatus.UNDEFINED:
      default:
        return 'neutral';
    }
  };

  const columns: TableProps<UserModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.users.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{cutId(id)}</span>,
      width: 60,
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span>{name}</span>,
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.phone',
        defaultMessage: 'Phone',
      }),
      dataIndex: 'phone',
      key: 'phone',
      render: (phone: string) => <span>{phone}</span>,
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.email',
        defaultMessage: 'Email',
      }),
      dataIndex: 'email',
      key: 'email',
      render: (email: string) => <span>{email}</span>,
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'approved',
      key: 'approved',
      render: (approved: boolean) => (
        <span>{approvedBooleanToIntlText(approved)}</span>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.kycKyb',
        defaultMessage: 'KYC/KYB',
      }),
      dataIndex: 'verificationStatus',
      key: 'verificationStatus',
      render: (verificationStatus: UserVerificationStatus) => (
        <Badge
          text={verificationStatusToIntlText(verificationStatus)}
          type={verificationStatusToBadgeType(verificationStatus)}
        />
      ),
      width: 130,
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.groups',
        defaultMessage: 'Groups',
      }),
      dataIndex: 'groups',
      key: 'groups',
      render: (groups: GroupDto[]) => (
        <div className='flex flex-wrap gap-2'>
          {groups?.map(({ name }) => (
            <span
              className={`rounded-md p-1 border border-base-80 border-solid`}
            >
              {name}
            </span>
          ))}
        </div>
      ),
      width: 130,
    },
  ];

  return (
    <>
      <Table<UserModel, {}>
        collection={collection}
        columns={columns}
        onRowClick={onUserClick}
      />
    </>
  );
});

export default UsersTable;
