import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { StepWithdraw } from './step-withdraw';
import { StepSummary } from './step-summary';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import { WithdrawType } from 'api/wallet';

type WithdrawPanelProps = {
  accountId?: string;
  handleSubmit: () => void;
};

export type FormValues = {
  amount: string;
  fromAccount: string;
  currency: string;
  note: string;
  type: WithdrawType;
  // BankTransferWithdraw
  iban?: string;
  bicOrSwiftCode?: string;
  // CryptoWithdraw
  to?: string;
  blockchain?: string;

  // CounterParty
  counterPartyId?: string;
};

export type Step = 'withdraw' | 'summary';

export const WithdrawPanel = observer(
  ({ accountId, handleSubmit }: WithdrawPanelProps) => {
    const intl = useIntl();
    const [step, setStep] = useState<Step>('withdraw');

    const { layoutStore } = useBaseStores();

    useEffect(() => {
      layoutStore.setSidebarTitle(
        intl.formatMessage({
          id: `sidebar.operation.${step}`,
          defaultMessage: step[0].toUpperCase() + step.slice(1),
        })
      );
    }, [step, layoutStore, intl]);

    const form = useForm<FormValues>({
      mode: 'onChange',
      defaultValues: {
        fromAccount: accountId,
        note: '',
        amount: '0',
        type: WithdrawType.COUNTERPARTY,
      },
    });

    return (
      <FormProvider {...form}>
        {step === 'withdraw' && <StepWithdraw setStep={setStep} />}
        {step === 'summary' && (
          <StepSummary setStep={setStep} handleSubmit={handleSubmit} />
        )}
      </FormProvider>
    );
  }
);
