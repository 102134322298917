/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable, runInAction } from 'mobx';
import { AdminCompanyModel } from 'models/CompanyModel';
import { FilterModel } from 'models/filter/FilterModel';
import { AdminGroupsRequestModel } from 'models/request/AdminGroupsRequestModel';
import { AdminListRequestModel } from 'models/request/AdminListRequestModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { readStrFromQueryParams } from 'utils/queryParams';

export class CompaniesPageStore {
  constructor(private authApi: AuthApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _partnerId = 0;

  private _companies = new CollectionWithPages<
    AdminCompanyModel,
    AdminListRequestModel
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.authApi.admin.adminListCompanyInfo({
          ...params,
        },
        {
          headers: { PartnerId: this._partnerId },
        },
      );

      const items = result.data.data.map((x) => new AdminCompanyModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminGroupsRequestModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.search
      );

      if (searchStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.search,
          value: {
            search: searchStr,
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  get companies() {
    return this._companies;
  }

  async init(props: {
    partnerId: number;
    filter: FilterModel<AdminGroupsRequestModel>[];
    page: number;
  }) {
    const { partnerId, filter, page } = props;

    runInAction(() => {
      this._partnerId = partnerId;
    });

    await this._companies.fetchData(filter, page);
  }
}
