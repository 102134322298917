/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Api as Auth } from 'api/auth';
import { Api as Currency } from 'api/currency';
import { Api as Wallet } from 'api/wallet';
import { Api as Approver } from 'api/approver';
import { Api as Acquiring } from 'api/acquiring';
import { Api as CardHolder } from 'api/card-holder';
import { Api as Loans } from 'api/loans';

export class AuthApi extends Auth<string | undefined> {}
export class WalletApi extends Wallet<string | undefined> {}
export class ApproverApi extends Approver<string | undefined> {}
export class CurrencyApi extends Currency<string | undefined> {}
export class AcquiringApi extends Acquiring<string | undefined> {}
export class CardHolderApi extends CardHolder<string | undefined> {}
export class LoansApi extends Loans<string | undefined> {}

export interface ApiWithSecurityData {
  setSecurityData: (data: string | null | undefined) => void;
}
