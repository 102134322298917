/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AdminGroup } from 'api/auth';

export { VerificationStatus as UserVerificationStatus } from 'api/auth';

export class AdminGroupModel {
  constructor(dto: AdminGroup) {
    this.id = dto.id?.toString() ?? '';
    this.description = dto.description;
    this.name = dto.name;
    this.partner = dto.partner;
    this.userScopes = dto.userScopes;
    this.system = dto.system;
  }

  id: string;
  description?: string;
  name?: string;
  partner: string;
  userScopes?: string[];
  system?: boolean;
}
