/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { UserSession } from 'api/auth';

export class UserSessionModel {
  constructor(dto: UserSession) {
    this.id = dto.jti;
    this.createDate = dto.createDate;
    this.expireDate = dto.expireDate;
    this.ip = dto.ip;
    this.jti = dto.jti;
    this.userAgent = dto.userAgent;
  }

  id: string;
  createDate: string;
  expireDate: string;
  ip: string;
  jti: string;
  userAgent: string;
}
