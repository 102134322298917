/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { AdminWalletAccountModel } from 'models/WalletAccount';
import { TableProps } from 'antd';
import Table from '../core/table';

type Props = {
  collection: CollectionWithPages<AdminWalletAccountModel, {}>;
  onRowClick: (item: AdminWalletAccountModel) => void;
  renderBalanceCell: (accountId: string) => React.ReactNode;
};

const AccountsTable = observer(
  ({ collection, onRowClick, renderBalanceCell }: Props) => {
    const intl = useIntl();

    const columns: TableProps<AdminWalletAccountModel>['columns'] = [
      {
        title: intl.formatMessage({
          id: 'table.accounts.col.id',
          defaultMessage: 'Id',
        }),
        dataIndex: 'id',
        key: 'id',
        render: (accountId: string) => <span>{accountId}</span>,
        width: 20,
      },
      {
        title: intl.formatMessage({
          id: 'table.accounts.col.type',
          defaultMessage: 'Type',
        }),
        dataIndex: 'type',
        key: 'type',
        render: (type) => <span>{type}</span>,
        width: 30,
      },
      {
        title: intl.formatMessage({
          id: 'table.accounts.col.balance',
          defaultMessage: 'Balance',
        }),
        dataIndex: 'partnerId',
        key: 'partnerId',
        render: (_, { accountId }) =>
          accountId ? renderBalanceCell(accountId) : <span>-</span>,
        width: 120,
      },
      {
        title: intl.formatMessage({
          id: 'table.accounts.col.userId',
          defaultMessage: 'User ID',
        }),
        dataIndex: 'accountId',
        key: 'accountId',
        render: (_, { users }) => (
          <span>{users && users.length > 0 ? users[0].userId : ''}</span>
        ),
        width: 30,
      },
    ];

    return (
      <Table<AdminWalletAccountModel, {}>
        collection={collection}
        columns={columns}
        onRowClick={onRowClick}
      />
    );
  }
);

export default AccountsTable;
