/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import BackButton from 'components/elements/back-button';
import Tabs from 'components/core/tabs';
import LoanDetailsManagementTab from './tabs/management';

import { ROUTES } from 'routes';
import { observer } from 'mobx-react-lite';
import {
  ProviderLoanDetailsPageStore,
  useLoanDetailsPageStore,
} from './provider/StoreProvider';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import LoanDetailsBasicInfo from './basic-info';

const LoanDetailsPageWithProvider = observer(() => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const loanDetailsPageStore = useLoanDetailsPageStore();

  const loanId = useMemo(
    () => loanDetailsPageStore.detailsLoan?.id ?? '',
    [loanDetailsPageStore.detailsLoan?.id]
  );

  const tabsData = useMemo(
    () => [
      {
        id: 'management',
        text: intl.formatMessage({
          id: 'page.loan_details.tabs.management',
          defaultMessage: 'Management',
        }),
        navLink: ROUTES.loanDetails.management.getFullPath(loanId),
      },
    ],
    [intl, loanId]
  );

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.loan_details.title',
        defaultMessage: 'Loan',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  if (!loanDetailsPageStore.detailsLoan) {
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <BackButton fallbackUrl={ROUTES.loans.list.fullPath} />
        <Tabs data={tabsData} />
      </div>

      <div className={styles.body}>
        <div className={styles.basic_info}>
          <LoanDetailsBasicInfo />
        </div>

        <div className={styles.tab_container}>
          <Routes>
            <Route element={<LoanDetailsManagementTab />} index />

            <Route
              path='*'
              element={
                <Navigate
                  to={ROUTES.loanDetails.management.getFullPath(loanId)}
                  replace
                />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
});

const LoanDetailsPage = () => {
  const { loanId } = useParams();

  if (!loanId) {
    return null;
  }

  return (
    <ProviderLoanDetailsPageStore loanId={loanId}>
      <LoanDetailsPageWithProvider />
    </ProviderLoanDetailsPageStore>
  );
};

export default LoanDetailsPage;
