/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useLayoutEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { GroupsDetailsPageStore } from '../store/GroupsDetailsPageStore';

const storeContext = createContext<GroupsDetailsPageStore>(
  {} as GroupsDetailsPageStore
);

type Props = {
  groupId?: number;
  children?: React.ReactNode;
};
export const ProvideGroupsDetailsPageStore = observer(
  ({ children, groupId }: Props) => {
    const { authApi, partnerStore } = useBaseStores();

    const store = useLocalObservable(() => new GroupsDetailsPageStore(authApi));

    useLayoutEffect(() => {
      if (!partnerStore.currentPartner) {
        return;
      }

      store.init({
        partnerId: partnerStore.currentPartner?.id || 0,
        groupId,
      });
    }, [groupId, store, partnerStore.currentPartner]);

    return (
      <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
  }
);

export const useGroupsDetailsPageStore = () => {
  return useContext(storeContext);
};
