/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  AdminSubscriptionDetailsDto,
  RecurrentInvoiceInterval,
} from 'api/auth';
import { parseBigNumberZero } from 'utils/bignumber';
import BigNumber from 'bignumber.js';

export class SubscriptionsDetailsModel {
  constructor(dto: AdminSubscriptionDetailsDto) {
    this.id = dto.id ?? '';
    this.amount = parseBigNumberZero(dto.amount);
    this.currency = dto.currency;
    this.initialPaymentAmount = parseBigNumberZero(dto.initialPaymentAmount);

    this.description = dto.description;
    this.name = dto.name;
    this.partnerId = dto.partnerId ?? 0;
    this.partnerName = dto.partnerName ?? '';
    this.groupIds = dto.groupIds;
    this.subscriptionInterval = dto.subscriptionInterval;
  }

  id?: string;
  amount: BigNumber;
  currency: string;
  initialPaymentAmount: BigNumber;
  description: string;
  groupIds: number[];

  name: string;
  partnerId?: number;
  partnerName?: string;
  subscriptionInterval: RecurrentInvoiceInterval;
}
