import FormSelect from 'components/form/form-select';
import { observer } from 'mobx-react-lite';
import { useOperationStore } from '../provider/OperationStoreProvider';
import { useIntl } from 'react-intl';
import { OperationType } from 'api/wallet';

export const OperationSelect = observer(() => {
  const { type, changeType } = useOperationStore();
  const intl = useIntl();

  const options = Object.values(OperationType).map((item) => ({
    id: item,
    label: intl.formatMessage({
      id: `sidebar.operation.${type}`,
      defaultMessage: item[0] + item.slice(1).toLowerCase(),
    }),
    value: item,
  }));

  return (
    <FormSelect
      label={intl.formatMessage({
        id: 'sidebar.create_offer.payment_period',
        defaultMessage: 'Payment period',
      })}
      darkMode={true}
      variant='filled'
      options={options}
      onChange={(value) => changeType(value?.value as OperationType)}
      value={options.find((item) => item.value === type)}
    />
  );
});
