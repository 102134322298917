/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useIntlUserVerificationStatuses } from 'hooks/intl/user-verification-statuses';
import { observer } from 'mobx-react-lite';
import { KycVerificationModel } from 'models/KycVerificationModel';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { cutId } from 'utils/strings';
import Table from 'components/core/table';
import type { TableProps } from 'antd';
import { UserVerificationStatus } from '../../models/UserModel';

type Props = {
  collection: CollectionWithPages<KycVerificationModel, {}>;
};

const KycVerificationsTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { verificationStatusToIntlText } = useIntlUserVerificationStatuses();

  const columns: TableProps<KycVerificationModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.kyc_verfications.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{cutId(id)}</span>,
      width: 60,
    },
    {
      title: intl.formatMessage({
        id: 'table.kyc_verfications.col.type',
        defaultMessage: 'Type',
      }),
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => <span>{type}</span>,
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.kyc_verfications.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status: UserVerificationStatus) => (
        <span>{status ? verificationStatusToIntlText(status) : ''}</span>
      ),
      width: 20,
    },
  ];

  return (
    <Table<KycVerificationModel, {}>
      collection={collection}
      columns={columns}
    />
  );
});

export default KycVerificationsTable;
