/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowsRightIcon } from 'assets/images/icons/arrow-right.svg';

export type ButtonVariants = 'right';

type ButtonProps = {
  variant?: ButtonVariants;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const ButtonIcon = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, ...buttonProps }, ref) => {
    return (
      <button
        {...buttonProps}
        className={classNames(styles.button, className)}
        ref={ref}
      >
        <ArrowsRightIcon />
      </button>
    );
  }
);

export default ButtonIcon;
