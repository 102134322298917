import { Individual } from 'api/auth';
import styles from './index.module.css';
import { useIntl } from 'react-intl';
import Button from 'components/core/button';
import { useUploadDocumentKyb } from '../modal-upload-document';
import { observer } from 'mobx-react-lite';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import { useEffect, useState } from 'react';
import { KybVerificationFileModel } from 'models/KybVerificationModel';
import { cutId } from 'utils/strings';
import { useDeleteDocumentKyb } from '../modal-delete-document';

type ExpandedRowProps = {
  individual: Individual;
};

export const ExpandedRow = observer(({ individual }: ExpandedRowProps) => {
  const intl = useIntl();
  const { showUploadModal } = useUploadDocumentKyb();
  const { showDeleteModal } = useDeleteDocumentKyb();
  const userDetailsPageStore = useUserDetailsPageStore();
  const [files, setFiles] = useState<KybVerificationFileModel[]>([]);

  const loadDocuments = async () => {
    try {
      const result = await userDetailsPageStore.fetchVerificationDocuments(
        individual.verificationIds || []
      );
      setFiles(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadDocuments();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <span className={styles.title}>
          {intl.formatMessage({
            id: 'page.user_details.kyb.documents',
            defaultMessage: 'Documents',
          })}
        </span>

        {!!individual.verificationIds?.length ? (
          <Button
            className={styles.btn}
            variant='primary'
            size='small'
            onClick={() =>
              showUploadModal(
                individual.verificationIds?.[0] || '',
                loadDocuments
              )
            }
          >
            {intl.formatMessage({
              id: 'page.user_details.kyb.add_documetn',
              defaultMessage: 'Add document',
            })}
          </Button>
        ) : (
          <Button
            className={styles.btn}
            variant='primary'
            size='small'
            onClick={() =>
              userDetailsPageStore.createIndividualVerification(individual.id!)
            }
          >
            {intl.formatMessage({
              id: 'page.user_details.kyb.create_verefication',
              defaultMessage: 'Create verification',
            })}
          </Button>
        )}
      </div>
      {!!files.length ? (
        <>
          <div className={styles['grid-row']}>
            <span className={styles['table-title']}>
              {intl.formatMessage({
                id: 'table.kyb_verifications.col.id',
                defaultMessage: 'Id',
              })}
            </span>
            <span className={styles['table-title']}>
              {intl.formatMessage({
                id: 'table.kyb_verifications.col.filename',
                defaultMessage: 'File name',
              })}
            </span>
            <span className={styles['table-title']}>
              {intl.formatMessage({
                id: 'table.kyb_verifications.col.action',
                defaultMessage: 'Action',
              })}
            </span>
          </div>
          {files.map((file) => (
            <div className={styles['grid-row']}>
              <span>{cutId(file.id)}</span>
              <span>{file.filename}</span>
              <div className={styles.btns}>
                <Button
                  className={styles.btn}
                  variant='primary'
                  size='small'
                  onClick={() => userDetailsPageStore.loadFile(file)}
                >
                  {intl.formatMessage({
                    id: 'page.user_details.kyb.load',
                    defaultMessage: 'Load',
                  })}
                </Button>
                <Button
                  className={styles.btn}
                  variant='negative'
                  size='small'
                  onClick={() => showDeleteModal(file, loadDocuments)}
                >
                  {intl.formatMessage({
                    id: 'page.user_details.kyb.delete',
                    defaultMessage: 'Delete',
                  })}
                </Button>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className={styles.empty}>No data</div>
      )}
    </div>
  );
});
