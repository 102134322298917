/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { observer } from 'mobx-react-lite';

const DashboardSection = observer(
  ({
    title,
    additionalInfo,
    children,
  }: {
    title?: string;
    additionalInfo?: string;
    children: React.ReactNode;
  }) => {
    return (
      <div className={styles.section}>
        <div className={styles.header}>
          {!!title && <h3>{title}</h3>}

          {!!additionalInfo && (
            <span className={styles.additional_info}>{additionalInfo}</span>
          )}
        </div>

        {children}
      </div>
    );
  }
);

export default DashboardSection;
