import React, { useEffect, useMemo } from 'react';
import { CardInfo } from '../../сard-info';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormValues } from '../';
import { useOperationStore } from '../../provider/OperationStoreProvider';
import { debounce } from 'lodash';
import { WithdrawType } from 'api/wallet';

export const TotalInfo = observer(() => {
  const intl = useIntl();
  const store = useOperationStore();
  const { currencyStore } = useBaseStores();
  const { control } = useFormContext<FormValues>();
  const values = useWatch({
    control,
  });
  const loadBalances = useMemo(
    () => debounce(store.accountBalances.fetch, 500),
    [store.accountBalances]
  );

  useEffect(() => {
    if (values.fromAccount) loadBalances(values.fromAccount);
  }, [loadBalances, values.fromAccount]);

  useEffect(() => {
    const data = {
      currency: values.currency,
      amount: values.amount,
      type: values.type,
      fromAccount: values.fromAccount,
    };

    let request = null;

    if (!Number(values.amount)) return;
    if (
      values.bicOrSwiftCode &&
      values.iban &&
      values.type === WithdrawType.BANK_TRANSFER
    ) {
      request = {
        ...data,
        iban: values.iban,
        bicOrSwiftCode: values.bicOrSwiftCode,
      };
    }

    if (values.to && values.type === WithdrawType.CRYPTO) {
      request = {
        ...data,
        to: values.to,
      };
    }

    if (values.counterPartyId && values.type === WithdrawType.COUNTERPARTY) {
      request = {
        ...data,
        counterPartyId: values.counterPartyId,
      };
    }

    if (!request) return;

    store.withdraw.feeData.sync(
      request as any,
      {
        dryRun: true,
      },
      {
        headers: { PartnerId: store.partnerId },
      }
    );
  }, [values, store]);

  return (
    <CardInfo
      options={[
        {
          value: `$${
            currencyStore.currenciesMap
              .get(values.currency!)
              ?.eurPrice?.toString() || ''
          }`,
          title: intl.formatMessage({
            id: 'sidebar.operation.spot_price',
            defaultMessage: `${currencyStore.currenciesMap.get(values.currency!)?.name} Spot price`,
          }),
        },
        {
          value:
            currencyStore
              .getEuroValue(values.currency, Number(values.amount || 0))
              ?.toString() || '',
          title: intl.formatMessage({
            id: 'sidebar.operation.amount',
            defaultMessage: 'Total value',
          }),
        },
      ]}
    />
  );
});
