/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { WalletApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import BigNumber from 'bignumber.js';
import { makeAutoObservable } from 'mobx';
import { AdminWalletAccountModel } from 'models/WalletAccount';
import { FilterModel } from 'models/filter/FilterModel';
import { AdminListRequestModel } from 'models/request/AdminListRequestModel';
import { CurrencyStore } from 'stores/CurrencyStore';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { readStrFromQueryParams } from 'utils/queryParams';

export class AccountsPageStore {
  constructor(
    private walletApi: WalletApi,
    private currencyStore: CurrencyStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _accounts = new CollectionWithPages<
    AdminWalletAccountModel,
    AdminListRequestModel
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.walletApi.admin.adminListAccount({
        ...params,
        sort: 'createdAt,id',
      });

      const items = result.data.data.map((x) => new AdminWalletAccountModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminListRequestModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.search
      );

      if (searchStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.search,
          value: {
            search: searchStr,
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  get accounts() {
    return this._accounts;
  }

  fetchAccountBalance = async (accountId: string) => {
    try {
      const result = await this.walletApi.admin.adminGetAccountBalance(
        accountId
      );

      return result.data.reduce((acc, item) => {
        return (
          acc +
          Number(
            this.currencyStore.exchangeCurrencies({
              from: item.currency,
              to: 'USD',
              amount: BigNumber(item.balance),
            })?.targetAmount || 0
          )
        );
      }, 0);
    } catch (error) {
      return null;
    }
  };

  async init(props: {
    filter: FilterModel<AdminListRequestModel>[];
    page: number;
  }) {
    const { filter, page } = props;

    await this._accounts.fetchData(filter, page);
  }
}
