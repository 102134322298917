/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Button from 'components/core/button';

import { useIntl } from 'react-intl';
import { ReactComponent as FiltersSvg } from 'assets/images/icons/filters.svg';

type Props = {
  activeCount: number;
  onClick?: () => void;
};

const FilterButton = ({ activeCount, onClick }: Props) => {
  const intl = useIntl();

  return (
    <Button className={styles.main} variant='secondary' onClick={onClick}>
      <span>
        {intl.formatMessage({
          id: 'button.filters',
          defaultMessage: 'Filters',
        })}
      </span>

      <FiltersSvg />

      {activeCount > 0 && (
        <span className={styles.active_label}>{activeCount}</span>
      )}
    </Button>
  );
};

export default FilterButton;
