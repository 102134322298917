/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React, { useMemo, useState } from 'react';
import Button from 'components/core/button';
import FormListSingleSelect from 'components/form/form-list-single-select';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { SelectItemModel } from 'models/form/SelectItemModel';
import { SubscriptionsUserModel } from 'models/SubscriptionsUserModel';
import { AdminSubscriptionModel } from 'models/request/AdminSubscriptionModel';
import { RecurrentInvoiceStatus } from 'api/auth';
import SearchField from 'components/core/search-field';

const SUBSCRIPTION_STATUSES: RecurrentInvoiceStatus[] = [
  RecurrentInvoiceStatus.INIT,
  RecurrentInvoiceStatus.TRIAL,
  RecurrentInvoiceStatus.ACTIVE,
  RecurrentInvoiceStatus.PAYMENT_FAILED,
  RecurrentInvoiceStatus.PAYMENT_REJECTED,
  RecurrentInvoiceStatus.CANCELLED,
  RecurrentInvoiceStatus.DEACTIVATING,
];

const selectOptionsSubscriptions = SUBSCRIPTION_STATUSES.map((status) => ({
  id: status.toLocaleUpperCase(),
  label: status,
  value: status,
}));

type Props = {
  collection: CollectionWithPages<
    SubscriptionsUserModel,
    AdminSubscriptionModel
  >;
};

const SidebarSubscriptionsFilter = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { partnerStore, layoutStore } = useBaseStores();
  const { setFilterQueryParams } = useSetQueryParams();

  const selectOptionsPartners = useMemo(
    () =>
      partnerStore.availablePartners.map((x) => ({
        id: x.id?.toString() ?? '',
        label: x.name ?? '',
        value: x.name ?? '',
      })),
    [partnerStore.availablePartners]
  );

  const [selectStatus, setSelectStatus] = useState<
    SelectItemModel<string> | undefined
  >(selectOptionsSubscriptions.find((x) => x.id === collection.filter.status));

  const [selectPartner, setSelectPartner] = useState<
    SelectItemModel<string> | undefined
  >(
    selectOptionsPartners.find(
      (x) => x.id === collection.filter.partnerId?.toString()
    )
  );

  const [userId, setUserId] = useState<string>(collection.filter.userId || '');

  const onChangeSelectStatus = (val: SelectItemModel<string> | undefined) => {
    setSelectStatus(val);
  };

  const onChangeSelectPartner = (val: SelectItemModel<string> | undefined) => {
    setSelectPartner(val);
  };

  const onChangeUserId = (val: string | '') => {
    setUserId(val);
  };

  const onApply = useCallback(() => {
    setFilterQueryParams([
      { id: URL_SEARCH_PARAMS.page, value: '1' },

      {
        id: URL_SEARCH_PARAMS.status,
        value: selectStatus ? selectStatus.id : undefined,
      },

      {
        id: URL_SEARCH_PARAMS.partnerId,
        value: selectPartner ? selectPartner.id : undefined,
      },

      {
        id: URL_SEARCH_PARAMS.userId,
        value: userId,
      },
    ]);

    layoutStore.toggleSidebar(false);
  }, [layoutStore, setFilterQueryParams, selectStatus, userId, selectPartner]);

  return (
    <React.Fragment>
      <h5>
        {intl.formatMessage({
          id: 'sidebar.filters.subscriptionsUserIdTitle',
          defaultMessage: 'User Id',
        })}
      </h5>
      <SearchField
        className={'mb-4'}
        placeholder={intl.formatMessage({
          id: 'sidebar.filters.subscriptionsUserId',
          defaultMessage: 'Search by User ID',
        })}
        value={userId}
        onChange={onChangeUserId}
      />

      <FormListSingleSelect
        title={intl.formatMessage({
          id: 'sidebar.filters.subscriptionsStatus',
          defaultMessage: 'Subscriptions status',
        })}
        options={selectOptionsSubscriptions}
        selected={selectStatus}
        onSelect={onChangeSelectStatus}
        collapse={true}
      />

      <FormListSingleSelect
        title={intl.formatMessage({
          id: 'sidebar.filters.subscriptionsPartner',
          defaultMessage: 'Subscriptions Partner',
        })}
        options={selectOptionsPartners}
        selected={selectPartner}
        onSelect={onChangeSelectPartner}
        collapse={true}
      />

      <Button className={styles.apply_btn} variant='primary' onClick={onApply}>
        {intl.formatMessage({
          id: 'sidebar.filters.apply',
          defaultMessage: 'Apply',
        })}
      </Button>
    </React.Fragment>
  );
});

export const useSidebarSubscriptionsFilter = ({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.filters',
        defaultMessage: 'Filters',
      }),
      <SidebarSubscriptionsFilter collection={collection} />
    );
  }, [collection, intl, layoutStore]);

  return {
    showSubscriptionsFiltersSidebar: showSidebar,
  };
};
