/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  ConfirmDepositRequest,
  ConfirmRequest,
  ConfirmWithdrawalRequest,
} from 'api/approver';

export { VerificationStatus as UserVerificationStatus } from 'api/auth';

export class TransactionApproverModel {
  constructor(
    dto: ConfirmRequest | ConfirmDepositRequest | ConfirmWithdrawalRequest
  ) {
    this.id = dto.id ?? '';
    this.type = dto.requestType;
    this.createdAt = dto.createdAt;

    if ('status' in dto) this.status = dto.status;
    if ('currency' in dto) this.symbol = dto.currency;

    if ('amount' in dto) this.amount = dto.amount;
    // if ('deposit' in dto) this.amount = dto.deposit;
    if ('withdraw' in dto) {
      this.amount = dto.withdraw?.amount;
      this.status = dto.withdraw?.status;
      this.symbol = dto.withdraw?.currency;
      this.type = dto.withdraw?.currency;
    }

    if ('deposit' in dto) {
      this.deposit = dto.deposit;
    }
  }

  id: string;
  createdAt?: string;
  status?: string;
  type?: string;
  amount?: string;
  symbol?: string;
  deposit?: string;
}

export interface DepositData {
  type: string;
  id: string;
  status: string;
  toAccount: string;
  currency: string;
  amount: string;
  fee: string;
  feeCurrency: string;
  feeAccount: string;
  blockchain: string;
  token: string;
  externalId: string;
  meta: {
    fromAddress: string;
    toAddress: string;
    tokenAddress: string;
    blockNumber: number | null;
    txHash: string;
  };
  createdAt: number;
  updatedAt: number;
}
