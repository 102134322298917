/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback, useMemo } from 'react';
import joi from 'joi';
import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import styles from './index.module.css';
import Button from 'components/core/button';
import { AdminCurrency, FeeConfig } from 'api/currency';
import { Controller, useForm } from 'react-hook-form';
import FormField from 'components/form/form-field';
import { useExchangePairsPageStore } from '../provider/ExchangePairsProvider';
import { ExchangePairsStore } from '../store/ExchangePairsStore';
import { joiResolver } from '@hookform/resolvers/joi';
import { useIntlValidation } from 'hooks/intl/validation';
import { useSearchParams } from 'react-router-dom';
import { readStrFromQueryParams } from 'utils/queryParams';

type PairInfo = {
  from: AdminCurrency;
  to: AdminCurrency;
  spread: string;
  fee: FeeConfig | undefined;
};

type FormValues = {
  spread: string;
} & FeeConfig;

type Props = {
  pairInfo: PairInfo;
  store: ExchangePairsStore;
};

const UpdatePairModal = observer(({ pairInfo, store }: Props) => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();
  const { validationOptions } = useIntlValidation();

  const [queryParams] = useSearchParams();
  const group = readStrFromQueryParams(queryParams, 'group');
  const partner = readStrFromQueryParams(queryParams, 'partner');

  const formScheme = useMemo(
    () =>
      joi.object<FormValues>({
        spread: joi.number().allow('').allow(null),
        feePercent: joi.number().allow('').allow(null).optional(),
        feeFixed: joi.number().allow('').allow(null).optional(),
        feeMin: joi.number().allow('').allow(null).optional(),
        feeMax: joi.number().allow('').allow(null).optional(),
      }),
    []
  );

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: joiResolver(formScheme, validationOptions),
    defaultValues: {
      spread: pairInfo.spread,
      ...pairInfo.fee,
    },
  });

  const handleUpdate = async ({ spread, ...fee }: FormValues) => {
    await store.updateExchangePair({
      fee,
      spread,
      fromCurrencySlug: pairInfo.from.slug,
      toCurrencySlug: pairInfo.to.slug,
      partner: partner!,
      group: group!,
    });
    layoutStore.toggleModal(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputs}>
        <Controller
          control={control}
          name='spread'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              required
              label={intl.formatMessage({
                id: 'sidebar.exchange.spread',
                defaultMessage: 'Spread',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.exchange.spread.placeholder',
                defaultMessage: 'Enter spread...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name='feePercent'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.exchange.fee_percent',
                defaultMessage: 'Percent fee',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.exchange.fee_percent.placeholder',
                defaultMessage: 'Enter percent fee...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name='feeFixed'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.exchange.fee_fixed',
                defaultMessage: 'Fixed fee',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.exchange.fee_fixed.placeholder',
                defaultMessage: 'Enter fixed fee...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name='feeMin'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.exchange.fee_min',
                defaultMessage: 'Min fee',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.exchange.fee_fixed.placeholder',
                defaultMessage: 'Enter min fee...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name='feeMax'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.exchange.fee_max',
                defaultMessage: 'Max fee',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.exchange.fee_max.placeholder',
                defaultMessage: 'Enter max fee...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>
      <div className={styles.btns}>
        <Button
          className={styles.btn}
          variant='primary'
          disabled={store.loading}
          onClick={handleSubmit(handleUpdate, (er: any) => console.log(er))}
        >
          {intl.formatMessage({
            id: 'page.pair_configuration.update.save',
            defaultMessage: 'Save',
          })}
        </Button>
        <Button
          className={styles.btn}
          variant='secondary'
          onClick={() => layoutStore.toggleModal(false)}
        >
          {intl.formatMessage({
            id: 'page.pair_configuration.update.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
      </div>
    </div>
  );
});

export const useUpdatePairModal = () => {
  const { layoutStore } = useBaseStores();
  const store = useExchangePairsPageStore();

  const showUpdatePairModal = useCallback(
    (pairInfo: PairInfo, onSuccess: () => void) => {
      layoutStore.toggleModal(
        true,
        `Update ${pairInfo.from.shortName.toUpperCase()}/${pairInfo.to.shortName.toUpperCase()} `,
        <UpdatePairModal pairInfo={pairInfo} store={store} />
      );
    },
    [layoutStore, store]
  );

  return {
    showUpdatePairModal,
  };
};
