/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import InputField from '../input-field';

import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';

type Props = {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  className?: string;
};

const DEBOUNCE_MS = 500;

const SearchField = (props: Props) => {
  const { value, onChange, className, placeholder } = props;

  const [inputVal, setInputVal] = useState(value);

  const debouncedChange = useMemo(
    () => debounce(onChange, DEBOUNCE_MS),
    [onChange]
  );

  const onInputValChanged = useCallback(
    (val: string) => {
      setInputVal(val);
      debouncedChange(val);
    },
    [debouncedChange]
  );

  useEffect(() => {
    setInputVal(value);
  }, [value]);

  return (
    <InputField
      className={className}
      value={inputVal}
      onChange={onInputValChanged}
      placeholder={placeholder}
      icon={<FaMagnifyingGlass />}
    />
  );
};

export default SearchField;
