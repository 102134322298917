/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import Button from 'components/core/button';
import { observer } from 'mobx-react-lite';
import { KybVerificationFileModel } from 'models/KybVerificationModel';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { cutId } from 'utils/strings';
import { TableProps } from 'antd';
import Table from '../core/table';

type Props = {
  collection: CollectionWithPages<KybVerificationFileModel, {}>;
  loadFile: (file: KybVerificationFileModel) => void;
  deleteFile: (file: KybVerificationFileModel) => void;
};

const kybFilesTable = observer(
  ({ collection, loadFile, deleteFile }: Props) => {
    const intl = useIntl();

    const loadFileTrigger = useCallback(
      (file: KybVerificationFileModel) => {
        loadFile(file);
      },
      [loadFile]
    );

    const columns: TableProps<KybVerificationFileModel>['columns'] = [
      {
        title: intl.formatMessage({
          id: 'table.kyb_verifications.col.id',
          defaultMessage: 'Id',
        }),
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => <span>{cutId(id)}</span>,
        width: 40,
      },
      {
        title: intl.formatMessage({
          id: 'table.kyb_verifications.col.filename',
          defaultMessage: 'File name',
        }),
        dataIndex: 'filename',
        key: 'filename',
        render: (filename: string) => <span>{filename}</span>,
        width: 70,
      },
      {
        title: intl.formatMessage({
          id: 'table.kyb_verifications.col.action',
          defaultMessage: 'Action',
        }),
        dataIndex: 'type',
        render: (_, record) => (
          <div className='flex gap-2'>
            <Button
              variant='primary'
              className='w-full h-8'
              size='small'
              onClick={() => loadFileTrigger(record)}
            >
              {intl.formatMessage({
                id: 'table.kyb_verifications.col.load',
                defaultMessage: 'Load',
              })}
            </Button>
            <Button
              variant='negative'
              className='w-full h-8'
              size='small'
              onClick={() => deleteFile(record)}
            >
              {intl.formatMessage({
                id: 'table.kyb_verifications.col.delete',
                defaultMessage: 'Delete',
              })}
            </Button>
          </div>
        ),
        width: 10,
      },
    ];

    return (
      <Table<KybVerificationFileModel, {}>
        collection={collection}
        columns={columns}
      />
    );
  }
);

export default kybFilesTable;
