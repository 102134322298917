/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { UsersPageStore } from '../store/UsersPageStore';

const storeContext = createContext<UsersPageStore>({} as UsersPageStore);

type Props = {
  children?: React.ReactNode;
};
export const ProvideUsersPageStore = observer(({ children }: Props) => {
  const { authApi, partnerStore } = useBaseStores();

  const store = useLocalObservable(() => new UsersPageStore(authApi));

  const { page, filter } = useParseCollectionQueryParams({
    collection: store.users,
  });

  useEffect(() => {
    if (!partnerStore.currentPartner) {
      return;
    }

    store.init({
      partnerId: partnerStore.currentPartner.id ?? 0,
      filter: filter,
      page: page,
    });
  }, [filter, page, store, partnerStore.currentPartner]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useUsersPageStore = () => {
  return useContext(storeContext);
};
