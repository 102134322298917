/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import TransactionsFilter from './tx-filter';

import { observer } from 'mobx-react-lite';
import {
  ProvideTransactionsPageStore,
  useTransactionsPageStore,
} from './provider/StoreProvider';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import TransactionsApproverTable from 'components/tables/transactions-approver-table';
import { TransactionApproverModel } from 'models/TransactionsApproverModel';
import { useSidebarTransactionReview } from 'components/sidebars/sidebar-transaction-review';

const TransactionsPageWithProvider = observer(() => {
  const intl = useIntl();
  const transactionsPageStore = useTransactionsPageStore();
  const { showTransactionReviewSidebar } = useSidebarTransactionReview();

  const totalText = useMemo(
    () =>
      transactionsPageStore.transactions.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.approver.total',
              defaultMessage: 'Total approver {transactions}',
            },
            {
              transactions: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.approver.total.count',
                      defaultMessage: '{count} transactions',
                    },
                    { count: transactionsPageStore.transactions.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, transactionsPageStore.transactions.totalItems]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.approver.title',
        defaultMessage: 'Transactions approver',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalText]);

  const onTransactionClick = useCallback(
    (transaction: TransactionApproverModel) => {
      showTransactionReviewSidebar(transaction);
    },
    [showTransactionReviewSidebar]
  );

  return (
    <div className={styles.main}>
      <TransactionsFilter collectData={transactionsPageStore.transactions} />
      <TransactionsApproverTable
        collection={transactionsPageStore.transactions}
        onTransactionClick={onTransactionClick}
      />
    </div>
  );
});

const TransactionsAllPage = () => {
  return (
    <ProvideTransactionsPageStore>
      <TransactionsPageWithProvider />
    </ProvideTransactionsPageStore>
  );
};

export default TransactionsAllPage;
