/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';

export type Tab = {
  id: string;
  text: string;
  navLink?: string;
  end?: boolean;
};

const TabNavLink = ({ tab }: { tab: Required<Tab> }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        classNames(styles.link, {
          [styles.active]: isActive,
        })
      }
      to={tab.navLink}
      replace
      end={tab.end ?? true}
    >
      <span>{tab.text}</span>
    </NavLink>
  );
};

type ButtonProps = {
  tab: Tab;
  activeTabId?: string;
  onTabChanged?: (tab: Tab) => void;
};
const TabButton = ({ tab, activeTabId, onTabChanged }: ButtonProps) => {
  return (
    <button
      className={classNames(styles.link, {
        [styles.active]: tab.id === activeTabId,
      })}
      onClick={() => {
        if (tab.id === activeTabId) {
          return;
        }

        onTabChanged?.(tab);
      }}
    >
      {tab.text}
    </button>
  );
};

type Props = {
  data: Tab[];
  className?: string;
  activeTabId?: string;
  onTabChanged?: (tab: Tab) => void;
};
const Tabs = ({ data, className, activeTabId, onTabChanged }: Props) => {
  return (
    <div className={classNames(styles.container, className)}>
      {data.map((tab) =>
        tab.navLink != null ? (
          <TabNavLink key={tab.id} tab={tab as Required<Tab>} />
        ) : (
          <TabButton
            key={tab.id}
            tab={tab}
            activeTabId={activeTabId}
            onTabChanged={onTabChanged}
          />
        )
      )}
    </div>
  );
};

export default Tabs;
