/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type BaseTwoFactorAuthenticator = BaseBaseTwoFactorAuthenticator &
  (
    | BaseBaseTwoFactorAuthenticatorAuthenticatorTypeMapping<'oob', OobTwoFactorAuthenticator>
    | BaseBaseTwoFactorAuthenticatorAuthenticatorTypeMapping<'otp', TotpTwoFactorAuthenticator>
    | BaseBaseTwoFactorAuthenticatorAuthenticatorTypeMapping<'u2f', U2FTwoFactorAuthenticator>
  );

/** Base authentication request */
export type BaseAuthRequest = BaseBaseAuthRequest &
  (
    | BaseBaseAuthRequestGrantTypeMapping<'client_credentials', AuthClientCredentialsRequest>
    | BaseBaseAuthRequestGrantTypeMapping<'mfa-oob', AuthOobRequest>
    | BaseBaseAuthRequestGrantTypeMapping<'mfa-otp', AuthOtpRequest>
    | BaseBaseAuthRequestGrantTypeMapping<'mfa-u2f', AuthU2FRequest>
    | BaseBaseAuthRequestGrantTypeMapping<'password', AuthPasswordRequest>
    | BaseBaseAuthRequestGrantTypeMapping<'refresh_token', AuthRefreshTokenRequest>
  );

/** invoice payment info */
export type BaseInvoicePaymentDto = BaseBaseInvoicePaymentDto &
  (
    | BaseBaseInvoicePaymentDtoTypeMapping<'CHECKOUT', CheckoutInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'EXTERNAL', ExternalInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'INWIZO', InwizoInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'ITEZ', ItezInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'PLATFORM', PlatformInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'STRIPE', StripeInvoicePaymentDto>
  );

/** Object for merchant, invoice info */
export type BaseInvoiceDto = BaseBaseInvoiceDto &
  (
    | BaseBaseInvoiceDtoTypeMapping<'ORIGINAL', OriginalInvoiceDto>
    | BaseBaseInvoiceDtoTypeMapping<'RECURRENT', RecurrentInvoiceDto>
  );

export type BaseAdminUserVerification = BaseBaseAdminUserVerification &
  (
    | BaseBaseAdminUserVerificationTypeMapping<'KYB_LAYER2', AdminKybLayerTwoVerification>
    | BaseBaseAdminUserVerificationTypeMapping<'KYB_MANUAL', AdminKybManualUserVerification>
    | BaseBaseAdminUserVerificationTypeMapping<'OFFLINE', AdminOfflineUserVerification>
    | BaseBaseAdminUserVerificationTypeMapping<'PAYSPOT_OFFLINE', AdminPaySpotOfflineUserVerification>
    | BaseBaseAdminUserVerificationTypeMapping<'SUMSUB', AdminSumSubVerification>
  );

export type BaseUserVerification = BaseBaseUserVerification &
  (
    | BaseBaseUserVerificationTypeMapping<'KYB_LAYER2', KybLayer2UserVerification>
    | BaseBaseUserVerificationTypeMapping<'KYB_MANUAL', KybManualUserVerification>
    | BaseBaseUserVerificationTypeMapping<'KYC_LAYER2', KycLayer2UserVerification>
    | BaseBaseUserVerificationTypeMapping<'OFFLINE', OffileUserVerification>
    | BaseBaseUserVerificationTypeMapping<'PAYSPOT_OFFLINE', PaySpotOfflineUserVerification>
    | BaseBaseUserVerificationTypeMapping<'SUMSUB', SumSubUserVerification>
  );

export interface AdminGroup {
  /** Group description */
  description?: string;
  /** @format int32 */
  id?: number;
  /** Group name */
  name: string;
  /** Partner name */
  partner: string;
  /** System group */
  system?: boolean;
  /** User scopes */
  userScopes?: string[];
}

export interface AdminKybLayerTwoVerification {
  docs?: VerificationDocument[];
  /** @format uuid */
  id?: string;
  requiredDocSetType?: DocSetType[];
  status?: VerificationStatus;
  /**
   * Verification type
   * @example "SUMSUB"
   */
  type: string;
}

export interface AdminKybManualUserVerification {
  docs?: VerificationDocument[];
  /** @format uuid */
  id?: string;
  requiredDocType?: IdDocType[];
  status?: VerificationStatus;
  /**
   * Verification type
   * @example "SUMSUB"
   */
  type: string;
}

export interface AdminOfflineUserVerification {
  docs?: VerificationDocument[];
  /** @format uuid */
  id?: string;
  requiredDocSetType?: DocSetType[];
  status?: VerificationStatus;
  /**
   * Verification type
   * @example "SUMSUB"
   */
  type: string;
}

export interface AdminPaySpotOfflineUserVerification {
  docs?: VerificationDocument[];
  /** @format uuid */
  id?: string;
  requiredDocSetType?: DocSetType[];
  status?: VerificationStatus;
  /**
   * Verification type
   * @example "SUMSUB"
   */
  type: string;
}

export interface AdminSubscriptionDetailsDto {
  /** subscription's payment amount */
  amount: number;
  /** subscription's payment currency */
  currency: string;
  /** subscription's description */
  description: string;
  /** Container of groups with roles, available after subscription's payment */
  groupIds: number[];
  /**
   * subscription's id
   * @format uuid
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  /**
   * Amount of first payment
   * @example 0
   */
  initialPaymentAmount: number;
  /** subscription's name */
  name: string;
  /**
   * id of the partner
   * @format int32
   */
  partnerId?: number;
  /** name of the partner, linked to subscription */
  partnerName?: string;
  /** subscription's payment interval */
  subscriptionInterval: RecurrentInvoiceInterval;
  /** subscription's trial availability */
  trialAvailable: boolean;
}

export interface AdminSubscriptionDto {
  /** subscription's payment amount */
  amount: number;
  /** subscription's payment currency */
  currency: string;
  /** is subscription deleted */
  deleted: boolean;
  /**
   * subscription's id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  /**
   * subscription's initial payment amount
   * @example 0
   */
  initialPaymentAmount: number;
  /** subscription's statuses */
  status: RecurrentInvoiceStatus;
  subscriptionDetails?: AdminSubscriptionDetailsDto;
  /**
   * subscription's owner id
   * @format uuid
   */
  userId: string;
}

export interface AdminSubscriptionInitDto {
  /**
   * Id of subscription form (subscription details)
   * @format uuid
   */
  subscriptionDetailsId: string;
  /** User Id */
  userId: string;
}

export interface AdminSumSubVerification {
  docs?: VerificationDocument[];
  externalId?: string;
  /** @format uuid */
  id?: string;
  requiredDocSetType?: DocSetType[];
  status?: VerificationStatus;
  /**
   * Verification type
   * @example "SUMSUB"
   */
  type: string;
}

export interface AdminUser {
  addresses?: UserAddress[];
  /**
   * [Deprecated] Use group KYC
   * @deprecated
   */
  appoved?: boolean;
  /** @format uuid */
  companyInfoId?: string;
  companyName?: string;
  email?: string;
  emails?: UserEmailAddressDto[];
  externalPartnerId?: string;
  firstName?: string;
  groups?: GroupDto[];
  id: string;
  lastName?: string;
  partner: string;
  /** @format int64 */
  partnerId?: number;
  phone?: string;
  /** User address */
  residentialAddress?: UserAddress;
  status: UserStatusDto;
  userProfile?: AdminUserProfile;
  userType: UserType;
  verificationStatus?: VerificationStatus;
  verifications?: (
    | KybLayer2UserVerification
    | KybManualUserVerification
    | KycLayer2UserVerification
    | OffileUserVerification
    | PaySpotOfflineUserVerification
    | SumSubUserVerification
  )[];
}

export interface AdminUserProfile {
  userAvatarUrl: string;
  userTitle: string;
}

export interface AdminUserScope {
  description?: string;
  /** @format int32 */
  id?: number;
  slug?: string;
}

export interface ApiError {
  description: string;
  key?: string;
}

export interface ApiErrorResponse {
  errors?: ApiError[];
  message: string;
  status: HttpStatus;
  traceId: string;
}

export interface ApplicantAddresses {
  buildingName?: string;
  buildingNumber?: string;
  country?: string;
  flatNumber?: string;
  postCode?: string;
  state?: string;
  street?: string;
  subStreet?: string;
  town?: string;
}

export interface ApplicantDoc {
  country?: string;
  idDocType?: string;
  imageIds?: string[];
}

export interface ApplicantInfo {
  addresses?: ApplicantAddresses[];
  country?: string;
  countryOfBirth?: string;
  dob?: string;
  firstName?: string;
  firstNameEn?: string;
  gender?: string;
  idDocs?: JsonNode[];
  lastName?: string;
  lastNameEn?: string;
  legalName?: string;
  middleName?: string;
  middleNameEn?: string;
  nationality?: string;
  placeOfBirth?: string;
  placeOfBirthEn?: string;
  stateOfBirth?: string;
  tin?: string;
}

export interface ApplicantRequiredIdDocsStatus {
  IDENTITY?: ApplicantDoc;
  PROOF_OF_RESIDENCE?: ApplicantDoc;
  SELFIE?: ApplicantDoc;
}

/** Authentication request with client credentials */
export type AuthClientCredentialsRequest = BaseBaseAuthRequest & {
  client_id: string;
  client_secret: string;
};

/** Authentication request with OOB code (SMS, email) */
export type AuthOobRequest = BaseBaseAuthRequest & {
  binding_code: string;
  client_id: string;
  mfaToken: string;
  oobCode?: string;
};

/** Authentication request with TOTP code */
export type AuthOtpRequest = BaseBaseAuthRequest & {
  client_id: string;
  mfaToken: string;
  otp: string;
};

/** Authentication request with username and password */
export type AuthPasswordRequest = BaseBaseAuthRequest & {
  client_id: string;
  password?: string;
  username: string;
};

/** Authentication request with refresh token */
export type AuthRefreshTokenRequest = BaseBaseAuthRequest & {
  refresh_token: string;
};

/** Authentication request with U2F MFA */
export type AuthU2FRequest = BaseBaseAuthRequest & {
  challenge: string;
  client_id: string;
  mfaToken: string;
};

export interface AuthenticationResponse {
  access_token?: string;
  error?: string;
  error_description?: string;
  /** @format int64 */
  expires_in?: number;
  mfa_token?: string;
  refresh_token?: string;
  scope?: string;
  token_type?: string;
  user_id?: string;
}

export type CheckoutInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  /** checkout session ret value */
  session?: JsonNode;
};

export interface CompanyInfo {
  companyName: string;
  controlScheme?: string;
  /**
   * @minLength 3
   * @maxLength 3
   */
  country?: string;
  email?: string;
  /** @format uuid */
  id?: string;
  incorporatedOn?: string;
  individuals: Individual[];
  legalAddress?: string;
  meta?: {
    assetAllocationToCrypto?: string;
    /** @pattern ^\[([A-Z]{2},)*([A-Z]{2})?]$ */
    businessJurisdictions: string;
    companyEmail?: string;
    companyPhoneNumber?: string;
    companyWebsite?: string;
    controlExemptionReason?: string;
    cryptoInvestmentPlans?: string;
    descriptionOfBusinessNature: string;
    /** @pattern ^\[([A-Z]+,)*([A-Z]+)?]$ */
    engageInActivities: string;
    entityType: string;
    entityTypeDescription?: string;
    /** @pattern ^\d{4}-\d{2}-\d{2}$ */
    establishedOn: string;
    frequencyOfCryptoTransactions?: string;
    frequencyOfTransactions?: string;
    /** @pattern ^\[([A-Z]{2},)*([A-Z]{2})?]$ */
    fundsSendReceiveJurisdictions: string;
    initialDepositSource?: string;
    investmentExperienceCrypto?: string;
    investmentStrategyCrypto?: string;
    monthlyCryptoDeposits: string;
    monthlyCryptoInvestmentDeposit: string;
    monthlyCryptoInvestmentWithdrawal: string;
    monthlyCryptoWithdrawals: string;
    monthlyDeposit: string;
    monthlyInvestmentDeposit: string;
    monthlyInvestmentWithdrawal: string;
    monthlyWithdrawals: string;
    naics?: string;
    naicsDescription?: string;
    newYorkOffice?: string;
    ongoingDepositSource?: string;
    physicalCity?: string;
    /**
     * @minLength 2
     * @maxLength 2
     */
    physicalCountryCode: string;
    physicalState?: string;
    /**
     * @minLength 0
     * @maxLength 35
     */
    physicalStreetAddress: string;
    physicalZipCode: string;
    primaryBusiness?: string;
    primarySourceOfFunds: string;
    primarySourceOfFundsDescription?: string;
    registeredCity?: string;
    /**
     * @minLength 2
     * @maxLength 2
     */
    registeredCountryCode: string;
    registeredState?: string;
    /**
     * @minLength 0
     * @maxLength 35
     */
    registeredStreetAddress: string;
    registeredZipCode: string;
    regulatedStatus: string;
    regulationAgency?: string;
    regulationAgencyRegulationNumber?: string;
    stateOfIncorporation?: string;
    totalAssets?: string;
    totalInvestableAssets?: string;
    tradesPerMonth?: string;
    usdValueOfCrypto: string;
    usdValueOfFiat: string;
    vendorsAndCounterparties?: string;
    [key: string]: any;
  };
  phone?: string;
  postalAddress?: string;
  registrationLocation?: string;
  registrationNumber: string;
  taxId?: string;
  type?: string;
  verifications: string[];
  website?: string;
}

export interface DeviceDto {
  deviceId: string;
  registrationToken: string;
}

/** Redeem code */
export interface DiscountRedeem {
  code: string;
  /** @format date-time */
  created_date?: string;
  description: string;
  discount_amount?: number;
  discount_currency?: string;
  discount_percent?: number;
  /** @uniqueItems true */
  discount_redeems: DiscountRedeem[];
  /** @format uuid */
  id: string;
  /** @format date-time */
  last_modified_date?: string;
  name: string;
  /** @format int64 */
  redeemLimit?: number;
}

export enum DocSetType {
  PHONE_VERIFICATION = 'PHONE_VERIFICATION',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  APPLICANT_DATA = 'APPLICANT_DATA',
  IDENTITY = 'IDENTITY',
  IDENTITY2 = 'IDENTITY2',
  IDENTITY3 = 'IDENTITY3',
  IDENTITY4 = 'IDENTITY4',
  PROOF_OF_RESIDENCE = 'PROOF_OF_RESIDENCE',
  PROOFOFRESIDENCE2 = 'PROOF_OF_RESIDENCE2',
  SELFIE = 'SELFIE',
  SELFIE2 = 'SELFIE2',
}

export interface DocumentUploadRequest {
  subType?: IdDocSubType;
  type: IdDocType;
}

export interface EmailConfirmation {
  code: string;
}

export type ExternalInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'>;

export interface GroupDto {
  name: string;
  partner: string;
}

export enum HttpStatus {
  Value100CONTINUE = '100 CONTINUE',
  Value101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  Value102PROCESSING = '102 PROCESSING',
  Value103EARLYHINTS = '103 EARLY_HINTS',
  Value103CHECKPOINT = '103 CHECKPOINT',
  Value200OK = '200 OK',
  Value201CREATED = '201 CREATED',
  Value202ACCEPTED = '202 ACCEPTED',
  Value203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  Value204NOCONTENT = '204 NO_CONTENT',
  Value205RESETCONTENT = '205 RESET_CONTENT',
  Value206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  Value207MULTISTATUS = '207 MULTI_STATUS',
  Value208ALREADYREPORTED = '208 ALREADY_REPORTED',
  Value226IMUSED = '226 IM_USED',
  Value300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  Value301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  Value302FOUND = '302 FOUND',
  Value302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  Value303SEEOTHER = '303 SEE_OTHER',
  Value304NOTMODIFIED = '304 NOT_MODIFIED',
  Value305USEPROXY = '305 USE_PROXY',
  Value307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  Value308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  Value400BADREQUEST = '400 BAD_REQUEST',
  Value401UNAUTHORIZED = '401 UNAUTHORIZED',
  Value402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  Value403FORBIDDEN = '403 FORBIDDEN',
  Value404NOTFOUND = '404 NOT_FOUND',
  Value405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  Value406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  Value407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  Value408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  Value409CONFLICT = '409 CONFLICT',
  Value410GONE = '410 GONE',
  Value411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  Value412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  Value413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  Value413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  Value414URITOOLONG = '414 URI_TOO_LONG',
  Value414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  Value415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  Value416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  Value417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  Value418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  Value419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  Value420METHODFAILURE = '420 METHOD_FAILURE',
  Value421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  Value422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  Value423LOCKED = '423 LOCKED',
  Value424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  Value425TOOEARLY = '425 TOO_EARLY',
  Value426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  Value428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  Value429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  Value431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  Value451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  Value500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  Value501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  Value502BADGATEWAY = '502 BAD_GATEWAY',
  Value503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  Value504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  Value505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  Value506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  Value507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  Value508LOOPDETECTED = '508 LOOP_DETECTED',
  Value509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  Value510NOTEXTENDED = '510 NOT_EXTENDED',
  Value511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

export enum IdDocSubType {
  FRONT_SIDE = 'FRONT_SIDE',
  BACK_SIDE = 'BACK_SIDE',
  DIRECTORS_REGISTRY = 'DIRECTORS_REGISTRY',
  STATE_REGISTRY = 'STATE_REGISTRY',
  INCUMBENCY_CERT = 'INCUMBENCY_CERT',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  TRUST_AGREEMENT = 'TRUST_AGREEMENT',
  INFORMATION_STATEMENT = 'INFORMATION_STATEMENT',
  INCORPORATION_CERT = 'INCORPORATION_CERT',
  INCORPORATION_ARTICLES = 'INCORPORATION_ARTICLES',
  SHAREHOLDER_REGISTRY = 'SHAREHOLDER_REGISTRY',
  GOOD_STANDING_CERT = 'GOOD_STANDING_CERT',
  OTHER = 'OTHER',
}

export enum IdDocType {
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
  DRIVERS = 'DRIVERS',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  UTILITY_BILL = 'UTILITY_BILL',
  SELFIE = 'SELFIE',
  VIDEO_SELFIE = 'VIDEO_SELFIE',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  ID_DOC_PHOTO = 'ID_DOC_PHOTO',
  AGREEMENT = 'AGREEMENT',
  CONTRACT = 'CONTRACT',
  DRIVERS_TRANSLATION = 'DRIVERS_TRANSLATION',
  INVESTOR_DOC = 'INVESTOR_DOC',
  VEHICLE_REGISTRATION_CERTIFICATE = 'VEHICLE_REGISTRATION_CERTIFICATE',
  INCOME_SOURCE = 'INCOME_SOURCE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  BANK_CARD = 'BANK_CARD',
  COVID_VACCINATION_FORM = 'COVID_VACCINATION_FORM',
  OTHER = 'OTHER',
  COMPANY_DOC = 'COMPANY_DOC',
  TRANSPARENCY_REGISTRY_EXTRACT = 'TRANSPARENCY_REGISTRY_EXTRACT',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
}

export interface Individual {
  /** @pattern ^[1-2][09]\d{2}-(0[0-9]|1[0-2])-[0-3][0-9]$ */
  dateOfBirth?: string;
  emailAddress?: string;
  firstName: string;
  /** @format uuid */
  id?: string;
  individualType?: IndividualType;
  lastName: string;
  /** User address */
  mailingAddress?: UserAddress;
  middleName?: string;
  nationality?: string;
  passportNumber?: string;
  /** @format int64 */
  percentageOwnership?: number;
  taxReferenceNumber?: string;
  telephoneNumber?: string;
  title?: string;
  usResidencyStatus?: UsResidencyStatus;
  /** @uniqueItems true */
  verificationIds?: string[];
}

export interface IndividualAccountContactAddress {
  items?: IndividualAccountDetailsContactAddress;
}

export interface IndividualAccountDetails {
  items?: IndividualAccountDetailsItems;
}

export interface IndividualAccountDetailsContactAddress {
  address?: QuestionnairesItem;
  sameAsResidenceAddre?: QuestionnairesItem;
}

export interface IndividualAccountDetailsItems {
  areYouAPep?: QuestionnairesItem;
  areYouAUsPerson?: QuestionnairesItem;
  purposeOfAccou_ng4xy?: QuestionnairesItem;
  sourceOfIncome?: QuestionnairesItem;
}

export interface IndividualAccountDetailsVolumes {
  monthlyTurnoverOfInc?: QuestionnairesItem;
  monthlyTurnoverOfOut?: QuestionnairesItem;
  numberOfIncomingPaym?: QuestionnairesItem;
  numberOfOutgoingPaym?: QuestionnairesItem;
}

export interface IndividualAccountVolumes {
  items?: IndividualAccountDetailsVolumes;
}

export interface IndividualApplicantDataResponse {
  /** checkout session ret value */
  agreement?: JsonNode;
  applicantPlatform?: string;
  /** checkout session ret value */
  applicantRiskLevel?: JsonNode;
  clientId?: string;
  createdAt?: string;
  email?: string;
  externalUserId?: string;
  fixedInfo?: ApplicantInfo;
  id?: string;
  info?: ApplicantInfo;
  inspectionId?: string;
  key?: string;
  lang?: string;
  questionnaires?: IndividualQuestionnaires[];
  /** checkout session ret value */
  requiredIdDocs?: JsonNode;
  /** checkout session ret value */
  review?: JsonNode;
  type?: string;
}

export interface IndividualQuestionnaires {
  id?: string;
  sections?: IndividualQuestionnairesSections;
}

export interface IndividualQuestionnairesSections {
  accountDetails?: IndividualAccountDetails;
  contactAddress?: IndividualAccountContactAddress;
  volumes?: IndividualAccountVolumes;
}

export enum IndividualType {
  INDIVIDUAL = 'INDIVIDUAL',
  OFFICER = 'OFFICER',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  CONTROL_PERSON = 'CONTROL_PERSON',
  AUTHORIZED_PERSON = 'AUTHORIZED_PERSON',
}

/** Object for merchant, with info about new invoice */
export interface InvoiceMerchantResponse {
  desc: string;
  id?: string;
  payUrl?: string;
  /** @format int32 */
  result: number;
  transactionId?: string;
}

/** Status of current invoice different for sub types */
export enum InvoiceStatus {
  INIT = 'INIT',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
}

export type InwizoInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  someData?: string;
};

export type ItezInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  someData?: string;
};

/** checkout session ret value */
export type JsonNode = object;

export type KybLayer2UserVerification = UtilRequiredKeys<BaseBaseUserVerification, 'type'> & {
  applicationDocumentErrors?: KycLayer2ApplicationDocumentError[];
  applicationErrors?: string[];
  applicationValidationErrors?: KycLayer2ApplicationValidationError[];
  docs?: VerificationDocument[];
  individualErrors?: KycLayer2IndividualError[];
};

export type KybManualUserVerification = UtilRequiredKeys<BaseBaseUserVerification, 'type'> & {
  docs?: VerificationDocument[];
  requiredDocSubType?: IdDocSubType[];
  requiredDocType?: IdDocType[];
};

export interface KycLayer2ApplicationDocumentError {
  description?: string;
  document?: string;
  id: string;
  status?: string;
}

export interface KycLayer2ApplicationValidationError {
  description: string;
  field_name: string;
  field_status: string;
}

export interface KycLayer2IndividualError {
  id: string;
  individual_document_errors?: KycLayer2ApplicationDocumentError[];
  individual_validation_errors?: KycLayer2ApplicationValidationError[];
}

export type KycLayer2UserVerification = UtilRequiredKeys<BaseBaseUserVerification, 'type'> & {
  applicationDocumentErrors?: KycLayer2ApplicationDocumentError[];
  applicationErrors?: string[];
  applicationValidationErrors?: KycLayer2ApplicationValidationError[];
  docs?: VerificationDocument[];
};

export interface Layer2EventDto {
  /** checkout session ret value */
  event_data: JsonNode;
  /** @format date-time */
  event_date: string;
  /** @format uuid */
  event_id: string;
  event_type: string;
}

export interface Limit {
  currency?: string;
  /**
   * Day limit in currency
   * @format number
   * @example "9.87"
   */
  dayLimit?: string;
  /**
   * Global limit in currency
   * @format number
   * @example "9.87"
   */
  globalLimit?: string;
  group?: string;
  /** @format int32 */
  groupPriority?: number;
  /**
   * Month limit in currency
   * @format number
   * @example "9.87"
   */
  monthLimit?: string;
  type: LimitType;
  /**
   * Year limit in currency
   * @format number
   * @example "9.87"
   */
  yearLimit?: string;
}

export enum LimitType {
  FEE_LIMIT = 'FEE_LIMIT',
  WITHDRAW_LIMIT = 'WITHDRAW_LIMIT',
  DEPOSIT_LIMIT = 'DEPOSIT_LIMIT',
}

export enum NoteStatusType {
  ACTIVE = 'ACTIVE',
  ON_HOLD = 'ON_HOLD',
  COMPLETED = 'COMPLETED',
  DROPPED = 'DROPPED',
}

export type OffileUserVerification = UtilRequiredKeys<BaseBaseUserVerification, 'type'> & {
  docs?: VerificationDocument[];
  requiredDocSetType?: DocSetType[];
};

export type OobTwoFactorAuthenticator = UtilRequiredKeys<
  BaseBaseTwoFactorAuthenticator,
  'active' | 'authenticator_type' | 'id'
> & {
  name?: string;
  oob_channel: string;
};

/** List of linked invoices for payment. */
export type OriginalInvoiceDto = UtilRequiredKeys<BaseBaseInvoiceDto, 'amount' | 'currency' | 'type'> & {
  /** Base invoice amount, which shows that discount was applied */
  AmountBeforeDiscount?: number;
  /** Discount code, that was applied to this invoice */
  discountCode?: string;
  /** List of payments */
  payments?: (
    | CheckoutInvoicePaymentDto
    | ExternalInvoicePaymentDto
    | InwizoInvoicePaymentDto
    | ItezInvoicePaymentDto
    | PlatformInvoicePaymentDto
    | StripeInvoicePaymentDto
  )[];
  /** Status of current invoice different for sub types */
  status?: InvoiceStatus;
};

export interface PageableResponseAdminGroup {
  /** @format int32 */
  currentPage: number;
  data: AdminGroup[];
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseAdminSubscriptionDetailsDto {
  /** @format int32 */
  currentPage: number;
  data: AdminSubscriptionDetailsDto[];
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseAdminSubscriptionDto {
  /** @format int32 */
  currentPage: number;
  data: AdminSubscriptionDto[];
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseAdminUser {
  /** @format int32 */
  currentPage: number;
  data: AdminUser[];
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseAdminUserScope {
  /** @format int32 */
  currentPage: number;
  data: AdminUserScope[];
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseCompanyInfo {
  /** @format int32 */
  currentPage: number;
  data: CompanyInfo[];
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseDiscountRedeem {
  /** @format int32 */
  currentPage: number;
  data: DiscountRedeem[];
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseTermsAndConditionsDto {
  /** @format int32 */
  currentPage: number;
  data: TermsAndConditionsDto[];
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseUserSession {
  /** @format int32 */
  currentPage: number;
  data: UserSession[];
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PartnerDTO {
  clientId?: string;
  clientSecret?: string;
  /** @format int32 */
  id?: number;
  name?: string;
  token?: string;
}

export type PaySpotOfflineUserVerification = UtilRequiredKeys<BaseBaseUserVerification, 'type'> & {
  docs?: VerificationDocument[];
  requiredDocSetType?: DocSetType[];
};

/** payment status */
export enum PaymentStatus {
  INIT = 'INIT',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITHOUT_FEE = 'COMPLETED_WITHOUT_FEE',
  FAILED = 'FAILED',
}

export type PlatformInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  /** internal account for payment */
  accountId?: string;
};

export interface QuestionnairesItem {
  value?: string;
  values?: string[];
}

export type RecurrentInvoiceDto = UtilRequiredKeys<BaseBaseInvoiceDto, 'amount' | 'currency' | 'type'> & {
  /**
   * Amount of first payment for this recurrent invoice
   * @example 0
   */
  initialPaymentAmount: number;
  /** List of linked invoices for payment. */
  linkedInvoices: OriginalInvoiceDto[];
  /**
   * Date of the next payment for this recurrent invoice
   * @format int64
   */
  nextPaymentDate?: number;
  /** subscription's payment interval */
  recurrentInvoiceInterval: RecurrentInvoiceInterval;
  /** subscription's statuses */
  status?: RecurrentInvoiceStatus;
};

/** subscription's payment interval */
export enum RecurrentInvoiceInterval {
  DAYS30 = 'DAYS30',
  DAYS365 = 'DAYS365',
}

/** subscription's statuses */
export enum RecurrentInvoiceStatus {
  INIT = 'INIT',
  TRIAL = 'TRIAL',
  ACTIVE = 'ACTIVE',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  CANCELLED = 'CANCELLED',
  DEACTIVATING = 'DEACTIVATING',
}

export type StripeInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  someData?: string;
};

export interface SubscriptionDetailsDto {
  /** subscription's payment amount */
  amount: number;
  /** subscription's payment currency */
  currency: string;
  /** subscription's description */
  description: string;
  /**
   * subscription's id
   * @format uuid
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  /** subscription's initial payment amount */
  initialPaymentAmount: number;
  /** subscription's name */
  name: string;
  /** subscription's payment interval */
  subscriptionInterval: RecurrentInvoiceInterval;
  /** subscription's trial availability */
  trialAvailable: boolean;
}

export interface SubscriptionEntityDto {
  /** @format date-time */
  createdAt?: string;
  /**
   * subscription's id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  invoice?: SubscriptionInvoiceDto;
  /** @format date-time */
  lastModifiedDate?: string;
  /** subscription's statuses */
  status: RecurrentInvoiceStatus;
  subscriptionDetails?: SubscriptionDetailsDto;
}

export interface SubscriptionInitDto {
  /** Internal account for payment */
  accountId?: string;
  /**
   * Id of subscription form (subscription details)
   * @format uuid
   */
  subscriptionDetailsId: string;
}

export interface SubscriptionInvoiceDto {
  /** subscription's payment amount */
  amount: number;
  /** subscription's payment currency */
  currency: string;
  /**
   * subscription's id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  /**
   * Date of the next payment
   * @format date-time
   */
  nextPaymentDate: string;
  /** subscription's statuses */
  status: RecurrentInvoiceStatus;
}

export type SumSubUserVerification = UtilRequiredKeys<BaseBaseUserVerification, 'type'> & {
  docs?: VerificationDocument[];
  requiredDocSetType?: DocSetType[];
  sumSubToken?: string;
};

export interface TermsAndConditionsDto {
  signed: boolean;
  termsAndConditions?: string;
  /** @format int32 */
  version: number;
}

export type TotpTwoFactorAuthenticator = UtilRequiredKeys<
  BaseBaseTwoFactorAuthenticator,
  'active' | 'authenticator_type' | 'id'
>;

export interface TwoFactorAuthenticationChallengeRequest {
  authenticator_id: string;
  challenge_type: TwoFactorType;
  client_id?: string;
  client_secret?: string;
  mfa_token: string;
}

export interface TwoFactorAuthenticationChallengeResponse {
  binding_method?: string;
  challenge?: string;
  challenge_type?: string;
  oob_code?: string;
}

export interface TwoFactorAuthenticationCreateRequest {
  type: TwoFactorType;
}

export interface TwoFactorAuthenticationResponse {
  active: boolean;
  authenticator_type: TwoFactorType;
  barcodeUri?: string;
  /** checkout session ret value */
  challenge?: JsonNode;
  oobCode?: string;
  /** @format uuid */
  twoFactorId: string;
}

export enum TwoFactorType {
  Otp = 'otp',
  U2F = 'u2f',
  Oob = 'oob',
}

export type U2FTwoFactorAuthenticator = UtilRequiredKeys<
  BaseBaseTwoFactorAuthenticator,
  'active' | 'authenticator_type' | 'id'
>;

export type Unit = object;

export enum UsResidencyStatus {
  US_CITIZEN = 'US_CITIZEN',
  RESIDENT_ALIEN = 'RESIDENT_ALIEN',
  NON_RESIDENT_ALIEN = 'NON_RESIDENT_ALIEN',
}

/** User address */
export interface UserAddress {
  /**
   * (Optional) Building name
   * @minLength 0
   * @maxLength 35
   * @example "Buckingham Palace"
   */
  buildingName?: string;
  /**
   * (Optional) Building number
   * @example "10"
   */
  buildingNumber?: string;
  confirmed?: boolean;
  /**
   * Country Alpha-3 code
   * @minLength 3
   * @maxLength 3
   * @example "GBR"
   */
  country?: string;
  /**
   * (Optional) Flat number
   * @example "1"
   */
  flatNumber?: string;
  /** @format uuid */
  id?: string;
  /**
   * Post code
   * @example "SW1A 1AA"
   */
  postCode?: string;
  /**
   * State
   * @example "MA"
   */
  state?: string;
  /**
   * Street
   * @minLength 0
   * @maxLength 35
   * @example "Downing Street"
   */
  street?: string;
  /**
   * Sub street (second line of postal address)
   * @minLength 0
   * @maxLength 35
   * @example "10"
   */
  subStreet?: string;
  /**
   * Town
   * @example "London"
   */
  town?: string;
}

export interface UserCreate {
  companyInfo?: CompanyInfo;
  /**
   * Date of birth
   * @format date-time
   */
  dob?: string;
  /** Email address */
  email?: string;
  /** Email address confirm code */
  emailConfirmCode?: string;
  /** First name */
  firstName?: string;
  /** Last name */
  lastName: string;
  /** Password */
  password?: string;
  /** Phone number */
  phone?: string;
  /** Phone confirm code */
  phoneConfirmCode?: string;
  userType: UserType;
}

export interface UserEmailAddressDto {
  /** If email confirmed by code */
  confirmed?: boolean;
  /**
   * Email
   * @format email
   */
  email: string;
  /** @format uuid */
  id?: string;
}

export interface UserInfo {
  addresses: UserAddress[];
  /**
   * [Deprecated] Use group KYC
   * @deprecated
   */
  approved: boolean;
  companyName?: string;
  emails: UserEmailAddressDto[];
  firstName?: string;
  lastName?: string;
  personalFeeLimit?: Limit;
  phone?: string;
  primaryEmail?: UserEmailAddressDto;
  /** User address */
  residentialAddress?: UserAddress;
  userScopes: string[];
  userType: UserType;
}

export interface UserLimits {
  depositLimit?: Limit;
  feeLimit?: Limit;
  withdrawLimit?: Limit;
}

export interface UserNote {
  /** @format date-time */
  createdAt?: string;
  createdBy?: string;
  /** @format uuid */
  id: string;
  note: string;
  status: NoteStatusType;
  /** @format date-time */
  updatedAt?: string;
  updatedBy?: string;
}

export interface UserNoteCreate {
  note: string;
}

export interface UserNoteUpdate {
  note: string;
}

export interface UserPhoneNumberCreate {
  /** Phone number */
  confirmed: boolean;
  /** Phone number */
  phone: string;
}

export interface UserPhoneNumberUpdate {
  /** Confirmation code */
  code?: string;
  /** Phone number */
  phone: string;
}

export interface UserPrimaryEmailUpdate {
  /** Confirmation code */
  code?: string;
  /**
   * New primary email
   * @format email
   */
  email: string;
}

export interface UserResetPassword {
  newPassword: string;
  signedPayload: string;
  username: string;
}

export interface UserResetPasswordRequest {
  code?: string;
  username: string;
}

export interface UserSession {
  /** @format date-time */
  createDate: string;
  deleted: boolean;
  /** @format date-time */
  expireDate: string;
  ip: string;
  jti: string;
  /** @format date-time */
  lastUpdated?: string;
  userAgent: string;
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
}

export enum UserStatusDto {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
}

export enum UserType {
  CUSTOMER = 'CUSTOMER',
  BUSINESS = 'BUSINESS',
  APPLICATION = 'APPLICATION',
}

export interface UserVerificationCreate {
  /** Verification type and provider, e.g. KYC, KYB, etc. */
  type?: VerificationType;
}

/** Verification document */
export interface VerificationDocument {
  file_type?: string;
  filename?: string;
  /** @format uuid */
  id: string;
  /** @format int64 */
  size?: number;
  status: VerificationStatus;
  sub_type?: IdDocSubType;
  type: IdDocType;
}

export enum VerificationStatus {
  INIT = 'INIT',
  PENDING = 'PENDING',
  GREEN = 'GREEN',
  REJECT = 'REJECT',
  RETRY = 'RETRY',
  UNDEFINED = 'UNDEFINED',
  PENDINGL2F = 'PENDING_L2F',
}

/**
 * Verification type and provider, e.g. KYC, KYB, etc.
 * @example "SUMSUB"
 */
export enum VerificationType {
  SUMSUB = 'SUMSUB',
  PAYSPOT_OFFLINE = 'PAYSPOT_OFFLINE',
  OFFLINE = 'OFFLINE',
  KYB_MANUAL = 'KYB_MANUAL',
  KYBLAYER2 = 'KYB_LAYER2',
  KYCLAYER2 = 'KYC_LAYER2',
  KYB_SUMSUB = 'KYB_SUMSUB',
}

interface BaseBaseTwoFactorAuthenticator {
  active: boolean;
  /** @example "oob" */
  authenticator_type: string;
  /** @format uuid */
  id: string;
}

type BaseBaseTwoFactorAuthenticatorAuthenticatorTypeMapping<Key, Type> = {
  authenticator_type: Key;
} & Type;

/** Base authentication request */
interface BaseBaseAuthRequest {
  audience?: string;
  grant_type: string;
  scope?: string;
}

type BaseBaseAuthRequestGrantTypeMapping<Key, Type> = {
  grant_type: Key;
} & Type;

/** invoice payment info */
interface BaseBaseInvoicePaymentDto {
  /** id of invoice payment */
  id?: string;
  invoiceId: string;
  /** payment status */
  status?: PaymentStatus;
  /** type of payment */
  type: string;
}

type BaseBaseInvoicePaymentDtoTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Object for merchant, invoice info */
interface BaseBaseInvoiceDto {
  /**
   * Amount required to complete invoice
   * @example 0
   */
  amount: number;
  /**
   * Currency slug in internal format
   * @example "EUR"
   */
  currency: string;
  /**
   * field for client_id in external system
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  externalClientId?: string;
  /**
   * invoice id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  /**
   * Date of the payment of this invoice
   * @format int64
   */
  paymentDate?: number;
  /** type of invoice */
  type: string;
}

type BaseBaseInvoiceDtoTypeMapping<Key, Type> = {
  type: Key;
} & Type;

type BaseBaseAdminUserVerification = object;

type BaseBaseAdminUserVerificationTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BaseBaseUserVerification {
  /** @format uuid */
  id?: string;
  status?: VerificationStatus;
  /**
   * Verification type
   * @example "SUMSUB"
   */
  type: string;
}

type BaseBaseUserVerificationTypeMapping<Key, Type> = {
  type: Key;
} & Type;

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.stg.darknet.piefi.app/reg',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Registration
 * @version 0.2.0-1b98a0ac
 * @baseUrl https://api.stg.darknet.piefi.app/reg
 *
 * Registration and authorisation service for other services
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  admin = {
    /**
     * No description
     *
     * @tags Admin
     * @name AdminListCompanyInfo
     * @summary List company info
     * @request GET:/admin/company
     * @secure
     */
    adminListCompanyInfo: (
      query?: {
        search?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @default "id" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseCompanyInfo, ApiErrorResponse>({
        path: `/admin/company`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminDeleteCompanyInfo
     * @summary Delete company info
     * @request DELETE:/admin/company/{id}
     * @secure
     */
    adminDeleteCompanyInfo: (
      id: string,
      query: {
        /** @format int32 */
        partnerId: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/company/${id}`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetCompanyInfo
     * @summary Retrieve company info by id
     * @request GET:/admin/company/{id}
     * @secure
     */
    adminGetCompanyInfo: (id: string, params: RequestParams = {}) =>
      this.request<CompanyInfo, ApiErrorResponse>({
        path: `/admin/company/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminUpdateCompanyInfo
     * @summary Update company info
     * @request PUT:/admin/company/{id}
     * @secure
     */
    adminUpdateCompanyInfo: (id: string, data: CompanyInfo, params: RequestParams = {}) =>
      this.request<CompanyInfo, ApiErrorResponse>({
        path: `/admin/company/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListGroups
     * @summary List groups for partner
     * @request GET:/admin/group
     * @secure
     */
    adminListGroups: (
      query?: {
        search?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @default "id" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminGroup, ApiErrorResponse>({
        path: `/admin/group`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Create group. Must pass complete group object, except users.
     *
     * @tags Admin
     * @name AdminCreateGroup
     * @summary Create group
     * @request POST:/admin/group
     * @secure
     */
    adminCreateGroup: (data: AdminGroup, params: RequestParams = {}) =>
      this.request<AdminGroup, ApiErrorResponse>({
        path: `/admin/group`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Delete group
     *
     * @tags Admin
     * @name AdminDeleteGroup
     * @summary Delete group
     * @request DELETE:/admin/group/{groupId}
     * @secure
     */
    adminDeleteGroup: (groupId: number, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/group/${groupId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Update group. Must pass complete group object, except users. All fields, except users, will be overridden.
     *
     * @tags Admin
     * @name AdminUpdateGroup
     * @summary Update group
     * @request PUT:/admin/group/{groupId}
     * @secure
     */
    adminUpdateGroup: (groupId: number, data: AdminGroup, params: RequestParams = {}) =>
      this.request<AdminGroup, ApiErrorResponse>({
        path: `/admin/group/${groupId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Remove member of user group.
     *
     * @tags Admin
     * @name AdminGroupDeleteMember
     * @summary Remove member of a group
     * @request DELETE:/admin/group/{groupId}/members
     * @secure
     */
    adminGroupDeleteMember: (
      groupId: number,
      query: {
        userIds: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminGroup, ApiErrorResponse>({
        path: `/admin/group/${groupId}/members`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description List member of user group.
     *
     * @tags Admin
     * @name AdminGroupListMembers
     * @summary List members of group
     * @request GET:/admin/group/{groupId}/members
     * @secure
     */
    adminGroupListMembers: (
      groupId: number,
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @default "createdAt" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminUser, ApiErrorResponse>({
        path: `/admin/group/${groupId}/members`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Add member to user group.
     *
     * @tags Admin
     * @name AdminGroupAddMember
     * @summary Add member to group
     * @request PUT:/admin/group/{groupId}/members
     * @secure
     */
    adminGroupAddMember: (
      groupId: number,
      query: {
        userIds: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminGroup, ApiErrorResponse>({
        path: `/admin/group/${groupId}/members`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Returns 200 with Company Info Individual Info objects if success
     *
     * @tags Admin
     * @name AdminUpdateCompanyInfoIndividual
     * @summary Admin update individual info
     * @request PUT:/admin/individual/{uuid}
     * @secure
     */
    adminUpdateCompanyInfoIndividual: (uuid: string, data: Individual, params: RequestParams = {}) =>
      this.request<Individual, ApiErrorResponse>({
        path: `/admin/individual/${uuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListPartners
     * @summary List partners
     * @request GET:/admin/partner
     * @secure
     */
    adminListPartners: (params: RequestParams = {}) =>
      this.request<PartnerDTO[], ApiErrorResponse>({
        path: `/admin/partner`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Update partner. Must pass complete partner object,. All fields will be overridden.
     *
     * @tags Admin
     * @name AdminUpdatePartner
     * @summary Update partner
     * @request PUT:/admin/partner/{partnerId}
     * @secure
     */
    adminUpdatePartner: (partnerId: number, data: PartnerDTO, params: RequestParams = {}) =>
      this.request<PartnerDTO, ApiErrorResponse>({
        path: `/admin/partner/${partnerId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Generic stats of registration service
     *
     * @tags Admin
     * @name GetStats
     * @summary Generic stats
     * @request GET:/admin/stats
     * @secure
     */
    getStats: (params: RequestParams = {}) =>
      this.request<Record<string, number>, ApiErrorResponse>({
        path: `/admin/stats`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetSumSubApplication
     * @request GET:/admin/sum-sub/{verificationId}
     * @secure
     */
    adminGetSumSubApplication: (verificationId: string, params: RequestParams = {}) =>
      this.request<IndividualApplicantDataResponse, ApiErrorResponse>({
        path: `/admin/sum-sub/${verificationId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetDocumentStatus
     * @request GET:/admin/sum-sub/{verificationId}/documents
     * @secure
     */
    adminGetDocumentStatus: (verificationId: string, params: RequestParams = {}) =>
      this.request<ApplicantRequiredIdDocsStatus, ApiErrorResponse>({
        path: `/admin/sum-sub/${verificationId}/documents`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetDocumentImage
     * @request GET:/admin/sum-sub/{verificationId}/image/{docId}
     * @secure
     */
    adminGetDocumentImage: (
      verificationId: string,
      docId: string,
      query?: {
        /** @default false */
        deletedFilterDisabled?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<File, ApiErrorResponse>({
        path: `/admin/sum-sub/${verificationId}/image/${docId}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name GetTermsAndConditions
     * @request GET:/admin/terms-and-conditions
     * @secure
     */
    getTermsAndConditions: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 5
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseTermsAndConditionsDto, ApiErrorResponse>({
        path: `/admin/terms-and-conditions`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name UpdateTermsAndConditions
     * @request POST:/admin/terms-and-conditions
     * @secure
     */
    updateTermsAndConditions: (data: TermsAndConditionsDto, params: RequestParams = {}) =>
      this.request<TermsAndConditionsDto, ApiErrorResponse>({
        path: `/admin/terms-and-conditions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name GetPartnerActualTermsAndConditions
     * @request GET:/admin/terms-and-conditions/{partnerName}
     * @secure
     */
    getPartnerActualTermsAndConditions: (partnerName: string, params: RequestParams = {}) =>
      this.request<TermsAndConditionsDto, ApiErrorResponse>({
        path: `/admin/terms-and-conditions/${partnerName}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListUser
     * @request GET:/admin/user
     * @secure
     */
    adminListUser: (
      query?: {
        /** @default false */
        deletedFilterDisabled?: boolean;
        search?: string;
        partner?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @default "createdAt" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminUser, ApiErrorResponse>({
        path: `/admin/user`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminRecallAllRefreshTokens
     * @summary Recall all refresh tokens
     * @request DELETE:/admin/user/session/logout
     * @secure
     */
    adminRecallAllRefreshTokens: (params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/user/session/logout`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetUser
     * @request GET:/admin/user/{userId}
     * @secure
     */
    adminGetUser: (
      userId: string,
      query?: {
        /** @default false */
        deletedFilterDisabled?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminUser, ApiErrorResponse>({
        path: `/admin/user/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Returns 200 with User Info objects if success
     *
     * @tags Admin
     * @name AdminGetUserGroups
     * @summary List all user groups
     * @request GET:/admin/user/{userId}/groups
     * @secure
     */
    adminGetUserGroups: (userId: string, params: RequestParams = {}) =>
      this.request<GroupDto[], ApiErrorResponse>({
        path: `/admin/user/${userId}/groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminEditUserInfo
     * @summary Edit personal user info
     * @request PUT:/admin/user/{userId}/info
     * @secure
     */
    adminEditUserInfo: (
      userId: string,
      data: AdminUser,
      query?: {
        /** @default false */
        deletedFilterDisabled?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminUser, ApiErrorResponse>({
        path: `/admin/user/${userId}/info`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetUserLimits
     * @summary Get user limits by user id
     * @request GET:/admin/user/{userId}/limits
     * @secure
     */
    adminGetUserLimits: (userId: string, params: RequestParams = {}) =>
      this.request<UserLimits, ApiErrorResponse>({
        path: `/admin/user/${userId}/limits`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminGetUserSessions
     * @summary Get All Active Sessions by user id
     * @request GET:/admin/user/{userId}/session
     * @secure
     */
    adminGetUserSessions: (
      userId: string,
      query?: {
        active?: boolean;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseUserSession, ApiErrorResponse>({
        path: `/admin/user/${userId}/session`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminLogoutUser
     * @summary Logout user with given id fromeu.esterna.registration.feature.verification.controller given sessions
     * @request DELETE:/admin/user/{userId}/session/logout
     * @secure
     */
    adminLogoutUser: (userId: string, data: UserSession[], params: RequestParams = {}) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/admin/user/${userId}/session/logout`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminEditUserStatus
     * @summary Switch user enable status
     * @request PUT:/admin/user/{userId}/status
     * @secure
     */
    adminEditUserStatus: (
      userId: string,
      query: {
        newUserStatus: UserStatus;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminUser, ApiErrorResponse>({
        path: `/admin/user/${userId}/status`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminListUserScopes
     * @summary All user scopes
     * @request GET:/admin/user_scopes
     * @secure
     */
    adminListUserScopes: (
      query?: {
        search?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @default "id" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminUserScope, ApiErrorResponse>({
        path: `/admin/user_scopes`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieve all verification or by given user id.
     *
     * @tags Admin
     * @name AdminGetVerifications
     * @summary Get all verifications
     * @request GET:/admin/verification/
     * @secure
     */
    adminGetVerifications: (
      query: {
        /** @format uuid */
        userId: string;
        /** @default false */
        deletedFilterDisabled?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<BaseAdminUserVerification[], ApiErrorResponse>({
        path: `/admin/verification/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates new individual verification by given id with provided info.Email and phone must be confirmed. Next step is complete verification in SumSub framework using provided token
     *
     * @tags Admin
     * @name AdminCreateIndividualVerification
     * @summary Create verification
     * @request POST:/admin/verification/individual
     * @secure
     */
    adminCreateIndividualVerification: (
      query: {
        /** @format uuid */
        userId: string;
      },
      data: UserVerificationCreate,
      params: RequestParams = {}
    ) =>
      this.request<BaseAdminUserVerification, ApiErrorResponse>({
        path: `/admin/verification/individual`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates new user verification by given id with provided info.Email and phone must be confirmed. Next step is complete verification in SumSub framework using provided token
     *
     * @tags Admin
     * @name AdminCreateUserVerification
     * @summary Create verification
     * @request POST:/admin/verification/user
     * @secure
     */
    adminCreateUserVerification: (
      query: {
        /** @format uuid */
        userId: string;
      },
      data: UserVerificationCreate,
      params: RequestParams = {}
    ) =>
      this.request<BaseAdminUserVerification, ApiErrorResponse>({
        path: `/admin/verification/user`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve verification.
     *
     * @tags Admin
     * @name AdminGetVerificationByUuid
     * @summary Get verification
     * @request GET:/admin/verification/{verificationId}
     * @secure
     */
    adminGetVerificationByUuid: (
      verificationId: string,
      query?: {
        /** @default false */
        deletedFilterDisabled?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<BaseAdminUserVerification, ApiErrorResponse>({
        path: `/admin/verification/${verificationId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update User verification object, whole object must be passed. Status will be overridden
     *
     * @tags Admin
     * @name AdminUpdateVerification
     * @summary Update verification
     * @request PUT:/admin/verification/{verificationId}
     * @secure
     */
    adminUpdateVerification: (verificationId: string, data: BaseAdminUserVerification, params: RequestParams = {}) =>
      this.request<BaseAdminUserVerification, ApiErrorResponse>({
        path: `/admin/verification/${verificationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all docs of verification.
     *
     * @tags Admin
     * @name AdminGetVerificationDocuments
     * @summary Get all docs of verification
     * @request GET:/admin/verification/{verificationId}/docs
     * @secure
     */
    adminGetVerificationDocuments: (
      verificationId: string,
      query?: {
        /** @default false */
        deletedFilterDisabled?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<VerificationDocument[], ApiErrorResponse>({
        path: `/admin/verification/${verificationId}/docs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload document to a verification by uuid
     *
     * @tags Admin
     * @name AdminUploadDocument
     * @summary Upload document to a verification by uuid
     * @request POST:/admin/verification/{verificationId}/docs
     * @secure
     */
    adminUploadDocument: (
      verificationId: string,
      data: {
        /** @format binary */
        file: File;
        request: DocumentUploadRequest;
      },
      params: RequestParams = {}
    ) =>
      this.request<VerificationDocument, ApiErrorResponse>({
        path: `/admin/verification/${verificationId}/docs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete document by uuid
     *
     * @tags Admin
     * @name AdminDeleteDocument
     * @summary Delete document by uuid
     * @request DELETE:/admin/verification/{verificationId}/docs/{docId}
     * @secure
     */
    adminDeleteDocument: (verificationId: string, docId: string, params: RequestParams = {}) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/admin/verification/${verificationId}/docs/${docId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update verification document.
     *
     * @tags Admin
     * @name AdminUpdateVerificationDocument
     * @summary Update verification document
     * @request PUT:/admin/verification/{verificationId}/docs/{docId}
     * @secure
     */
    adminUpdateVerificationDocument: (
      verificationId: string,
      docId: string,
      data: VerificationDocument,
      params: RequestParams = {}
    ) =>
      this.request<VerificationDocument, ApiErrorResponse>({
        path: `/admin/verification/${verificationId}/docs/${docId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve raw document by uuid
     *
     * @tags Admin
     * @name AdminDownloadDocument
     * @summary Retrieve raw document by uuid
     * @request GET:/admin/verification/{verificationId}/docs/{docId}/download
     * @secure
     */
    adminDownloadDocument: (verificationId: string, docId: string, params: RequestParams = {}) =>
      this.request<File, ApiErrorResponse>({
        path: `/admin/verification/${verificationId}/docs/${docId}/download`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminLinkVerification
     * @summary link existed verification to user
     * @request PUT:/admin/verification/{verificationId}/link
     * @secure
     */
    adminLinkVerification: (
      verificationId: string,
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/admin/verification/${verificationId}/link`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminSubmitVerification
     * @summary Submit user verification
     * @request POST:/admin/verification/{verificationId}/submit
     * @secure
     */
    adminSubmitVerification: (verificationId: string, params: RequestParams = {}) =>
      this.request<number, ApiErrorResponse>({
        path: `/admin/verification/${verificationId}/submit`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminSyncVerification
     * @summary Sync fixed info for verification
     * @request POST:/admin/verification/{verificationId}/sync
     * @secure
     */
    adminSyncVerification: (verificationId: string, params: RequestParams = {}) =>
      this.request<
        | KybLayer2UserVerification
        | KybManualUserVerification
        | KycLayer2UserVerification
        | OffileUserVerification
        | PaySpotOfflineUserVerification
        | SumSubUserVerification,
        ApiErrorResponse
      >({
        path: `/admin/verification/${verificationId}/sync`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminUnlinkVerification
     * @summary link existed verification to user
     * @request PUT:/admin/verification/{verificationId}/unlink
     * @secure
     */
    adminUnlinkVerification: (
      verificationId: string,
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/admin/verification/${verificationId}/unlink`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  adminDiscounts = {
    /**
     * @description Get discount codes list
     *
     * @tags AdminDiscounts
     * @name AdminGetDiscountCodes
     * @summary Get discount codes
     * @request GET:/admin/discounts
     * @secure
     */
    adminGetDiscountCodes: (
      query?: {
        search?: string;
        discountPercent?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseDiscountRedeem, ApiErrorResponse>({
        path: `/admin/discounts`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Creates new discount code with provided info
     *
     * @tags AdminDiscounts
     * @name AdminCreateDiscountCodes
     * @summary Create discount code
     * @request POST:/admin/discounts
     * @secure
     */
    adminCreateDiscountCodes: (data: DiscountRedeem[], params: RequestParams = {}) =>
      this.request<DiscountRedeem[], ApiErrorResponse>({
        path: `/admin/discounts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update discount code
     *
     * @tags AdminDiscounts
     * @name RevokeDiscountCode
     * @summary Update discount code
     * @request DELETE:/admin/discounts/{discountId}
     * @secure
     */
    revokeDiscountCode: (discountId: string, params: RequestParams = {}) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/admin/discounts/${discountId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Update discount code
     *
     * @tags AdminDiscounts
     * @name AdminUpdateDiscountCode
     * @summary Update discount code
     * @request PUT:/admin/discounts/{discountId}
     * @secure
     */
    adminUpdateDiscountCode: (discountId: string, data: DiscountRedeem, params: RequestParams = {}) =>
      this.request<DiscountRedeem, ApiErrorResponse>({
        path: `/admin/discounts/${discountId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description list of discount redeem
     *
     * @tags AdminDiscounts
     * @name AdminGetDiscountRedeems
     * @summary Get discount redeem
     * @request GET:/admin/discounts/{discountId}/redeem
     * @secure
     */
    adminGetDiscountRedeems: (
      discountId: string,
      query?: {
        search?: string;
        discountPercent?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseDiscountRedeem, ApiErrorResponse>({
        path: `/admin/discounts/${discountId}/redeem`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  merchantAdminSubscription = {
    /**
     * @description Returns all subscription details
     *
     * @tags merchant & admin subscription
     * @name AdminGetSubscriptionDetails
     * @summary Get all subscription details
     * @request GET:/admin/subscription/details/subscription_details
     * @secure
     */
    adminGetSubscriptionDetails: (
      query?: {
        /** @format int32 */
        partnerId?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminSubscriptionDetailsDto, ApiErrorResponse>({
        path: `/admin/subscription/details/subscription_details`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Create a new subscription details
     *
     * @tags merchant & admin subscription
     * @name AdminCreateSubscriptionDetails
     * @summary Create a new subscription details
     * @request POST:/admin/subscription/details/subscription_details
     * @secure
     */
    adminCreateSubscriptionDetails: (data: AdminSubscriptionDetailsDto, params: RequestParams = {}) =>
      this.request<AdminSubscriptionDetailsDto, ApiErrorResponse>({
        path: `/admin/subscription/details/subscription_details`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags merchant & admin subscription
     * @name AdminUpdateSubscriptionDetails
     * @summary Update the subscription details
     * @request PUT:/admin/subscription/details/subscription_details/{subscriptionDetailsId}
     * @secure
     */
    adminUpdateSubscriptionDetails: (
      subscriptionDetailsId: string,
      data: AdminSubscriptionDetailsDto,
      params: RequestParams = {}
    ) =>
      this.request<AdminSubscriptionDetailsDto, ApiErrorResponse>({
        path: `/admin/subscription/details/subscription_details/${subscriptionDetailsId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns all subscriptions
     *
     * @tags merchant & admin subscription
     * @name AdminGetSubscriptions
     * @summary Get all subscriptions
     * @request GET:/admin/subscription/entries/subscription_entities
     * @secure
     */
    adminGetSubscriptions: (
      query?: {
        status?: string;
        search?: string;
        /** @format int32 */
        partnerId?: number;
        userId?: string;
        /** @default false */
        deletedFilterDisabled?: boolean;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminSubscriptionDto, ApiErrorResponse>({
        path: `/admin/subscription/entries/subscription_entities`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Create a new subscription for user
     *
     * @tags merchant & admin subscription
     * @name AdminCreateSubscription
     * @summary Create a new subscription for user
     * @request POST:/admin/subscription/entries/subscription_entities
     * @secure
     */
    adminCreateSubscription: (data: AdminSubscriptionInitDto, params: RequestParams = {}) =>
      this.request<AdminSubscriptionDto, ApiErrorResponse>({
        path: `/admin/subscription/entries/subscription_entities`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags merchant & admin subscription
     * @name AdminDeleteSubscription
     * @request DELETE:/admin/subscription/entries/subscription_entities/{uuid}
     */
    adminDeleteSubscription: (uuid: string, params: RequestParams = {}) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/admin/subscription/entries/subscription_entities/${uuid}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags merchant & admin subscription
     * @name AdminCancelSubscription
     * @request PUT:/admin/subscription/entries/subscription_entities/{uuid}/cancel
     */
    adminCancelSubscription: (uuid: string, params: RequestParams = {}) =>
      this.request<AdminSubscriptionDto, ApiErrorResponse>({
        path: `/admin/subscription/entries/subscription_entities/${uuid}/cancel`,
        method: 'PUT',
        ...params,
      }),
  };
  adminUserNotes = {
    /**
     * No description
     *
     * @tags admin-user-notes
     * @name GetUserNotesByUserId
     * @summary Get user notes by user id
     * @request GET:/admin/user/{userId}/note
     * @secure
     */
    getUserNotesByUserId: (userId: string, params: RequestParams = {}) =>
      this.request<UserNote[], ApiErrorResponse>({
        path: `/admin/user/${userId}/note`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-user-notes
     * @name AddUserNote
     * @summary Add user note
     * @request POST:/admin/user/{userId}/note
     * @secure
     */
    addUserNote: (userId: string, data: UserNoteCreate, params: RequestParams = {}) =>
      this.request<UserNote, ApiErrorResponse>({
        path: `/admin/user/${userId}/note`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-user-notes
     * @name DeleteUserNoteByNoteId
     * @summary Delete user note by note id
     * @request DELETE:/admin/user/{userId}/note/{noteId}
     * @secure
     */
    deleteUserNoteByNoteId: (userId: string, noteId: string, params: RequestParams = {}) =>
      this.request<UserNote, ApiErrorResponse>({
        path: `/admin/user/${userId}/note/${noteId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-user-notes
     * @name GetUserNoteByNoteId
     * @summary Get user note by note id
     * @request GET:/admin/user/{userId}/note/{noteId}
     * @secure
     */
    getUserNoteByNoteId: (userId: string, noteId: string, params: RequestParams = {}) =>
      this.request<UserNote, ApiErrorResponse>({
        path: `/admin/user/${userId}/note/${noteId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-user-notes
     * @name UpdateUserNote
     * @summary Update user note
     * @request PUT:/admin/user/{userId}/note/{noteId}
     * @secure
     */
    updateUserNote: (userId: string, noteId: string, data: UserNoteUpdate, params: RequestParams = {}) =>
      this.request<UserNote, ApiErrorResponse>({
        path: `/admin/user/${userId}/note/${noteId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  authentication = {
    /**
     * @description One time password  requested by 'sendOneTimePassword', DEPRECTAED: Client must use MFA instead of legacy OTP.
     *
     * @tags Authentication
     * @name SendOneTimePassword
     * @summary Send one time password
     * @request GET:/auth/sendOneTimePassword
     * @deprecated
     */
    sendOneTimePassword: (
      query?: {
        phone?: string;
        email?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/auth/sendOneTimePassword`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description OAuth2.0 endpoint, supported grant types: `client_credentials`, `refresh_token`, `password`
     *
     * @tags Authentication
     * @name GetToken
     * @summary OAuth2.0 endpoint
     * @request POST:/auth/token
     */
    getToken: (data: BaseAuthRequest, params: RequestParams = {}) =>
      this.request<AuthenticationResponse, ApiErrorResponse>({
        path: `/auth/token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  discounts = {
    /**
     * No description
     *
     * @tags Discounts
     * @name GetDiscounts
     * @request GET:/discounts
     */
    getDiscounts: (params: RequestParams = {}) =>
      this.request<DiscountRedeem[], ApiErrorResponse>({
        path: `/discounts`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Discounts
     * @name RedeemDiscount
     * @request POST:/discounts/redeem
     */
    redeemDiscount: (data: DiscountRedeem, params: RequestParams = {}) =>
      this.request<DiscountRedeem, ApiErrorResponse>({
        path: `/discounts/redeem`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Discounts
     * @name RevokeDiscount
     * @request POST:/discounts/revoke
     */
    revokeDiscount: (data: DiscountRedeem, params: RequestParams = {}) =>
      this.request<DiscountRedeem, ApiErrorResponse>({
        path: `/discounts/revoke`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  layer2WebhookController = {
    /**
     * No description
     *
     * @tags layer-2-webhook-controller
     * @name Webhook1
     * @request POST:/layer2/webhook
     */
    webhook1: (data: Layer2EventDto, params: RequestParams = {}) =>
      this.request<JsonNode, ApiErrorResponse>({
        path: `/layer2/webhook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  twoFactor = {
    /**
     * @description Create new Two Factor authenticator, return private key and challenge to activate.
     *
     * @tags Two Factor
     * @name CreateTwoFactorAuthentication
     * @summary Create Two Factor authenticator
     * @request POST:/mfa/associate
     */
    createTwoFactorAuthentication: (data: TwoFactorAuthenticationCreateRequest, params: RequestParams = {}) =>
      this.request<TwoFactorAuthenticationResponse, ApiErrorResponse>({
        path: `/mfa/associate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Return list of Two Factor authentications
     *
     * @tags Two Factor
     * @name GetListOfTwoFactorAuthentications
     * @summary List of Two Factor authentications
     * @request GET:/mfa/authenticators
     */
    getListOfTwoFactorAuthentications: (
      query?: {
        onlyActive?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        (
          | BaseTwoFactorAuthenticator
          | OobTwoFactorAuthenticator
          | TotpTwoFactorAuthenticator
          | U2FTwoFactorAuthenticator
        )[],
        ApiErrorResponse
      >({
        path: `/mfa/authenticators`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create challenge for selected Two Factor authenticator,
     *
     * @tags Two Factor
     * @name CreateChallenge
     * @summary Create challenge for Two Factor authenticator
     * @request POST:/mfa/challenge
     */
    createChallenge: (data: TwoFactorAuthenticationChallengeRequest, params: RequestParams = {}) =>
      this.request<TwoFactorAuthenticationChallengeResponse, ApiErrorResponse>({
        path: `/mfa/challenge`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  subscriptions = {
    /**
     * @description Returns all subscriptions
     *
     * @tags Subscriptions
     * @name GetSubscriptions
     * @summary Get all subscriptions
     * @request GET:/subscription
     */
    getSubscriptions: (params: RequestParams = {}) =>
      this.request<SubscriptionEntityDto[], ApiErrorResponse>({
        path: `/subscription`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Start subscription for user, returns invoice response for payment
     *
     * @tags Subscriptions
     * @name StartSubscription
     * @summary Start subscription
     * @request POST:/subscription
     */
    startSubscription: (data: SubscriptionInitDto, params: RequestParams = {}) =>
      this.request<InvoiceMerchantResponse, ApiErrorResponse>({
        path: `/subscription`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get available subscriptions for user
     *
     * @tags Subscriptions
     * @name GetSubscriptionsInfo
     * @summary Get available subscriptions
     * @request GET:/subscription/details/available
     */
    getSubscriptionsInfo: (params: RequestParams = {}) =>
      this.request<SubscriptionDetailsDto[], ApiErrorResponse>({
        path: `/subscription/details/available`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name Webhook
     * @request POST:/subscription/webhook
     * @secure
     */
    webhook: (data: RecurrentInvoiceDto, params: RequestParams = {}) =>
      this.request<string, ApiErrorResponse>({
        path: `/subscription/webhook`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Cancel subscription
     *
     * @tags Subscriptions
     * @name CancelSubscription
     * @summary Cancel subscription
     * @request DELETE:/subscription/{subscriptionId}
     */
    cancelSubscription: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<SubscriptionEntityDto, ApiErrorResponse>({
        path: `/subscription/${subscriptionId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Returns subscription by id
     *
     * @tags Subscriptions
     * @name GetSubscriptionById
     * @summary Get subscription by id
     * @request GET:/subscription/{subscriptionId}
     */
    getSubscriptionById: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<OriginalInvoiceDto | RecurrentInvoiceDto, ApiErrorResponse>({
        path: `/subscription/${subscriptionId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Update subscription payment account
     *
     * @tags Subscriptions
     * @name UpdateSubscriptionPaymentAccount
     * @summary Update subscription payment account
     * @request PUT:/subscription/{subscriptionId}/payment-account
     */
    updateSubscriptionPaymentAccount: (
      subscriptionId: string,
      query: {
        accountId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponse>({
        path: `/subscription/${subscriptionId}/payment-account`,
        method: 'PUT',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name SubscriptionRenew
     * @request PUT:/subscription/{subscriptionId}/renew
     * @deprecated
     */
    subscriptionRenew: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<InvoiceMerchantResponse, ApiErrorResponse>({
        path: `/subscription/${subscriptionId}/renew`,
        method: 'PUT',
        ...params,
      }),
  };
  user = {
    /**
     * @description Creates new user, by default user is not approved.If confirm code not provided, user would not be created. OTP code will be send.
     *
     * @tags User
     * @name CreateUser
     * @summary Create new user
     * @request POST:/user
     */
    createUser: (data: UserCreate, params: RequestParams = {}) =>
      this.request<AuthenticationResponse, ApiErrorResponse>({
        path: `/user`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with User Info objects if success
     *
     * @tags User
     * @name GetUserGroups
     * @summary List all user groups
     * @request GET:/user/groups
     * @secure
     */
    getUserGroups: (params: RequestParams = {}) =>
      this.request<GroupDto[], ApiErrorResponse>({
        path: `/user/groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with User Info objects if success
     *
     * @tags User
     * @name GetUserInfo
     * @summary Retrieve user info
     * @request GET:/user/info
     * @secure
     */
    getUserInfo: (params: RequestParams = {}) =>
      this.request<UserInfo, ApiErrorResponse>({
        path: `/user/info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with User Info objects if success
     *
     * @tags User
     * @name UpdateUserInfo
     * @summary Update user info with PII
     * @request PATCH:/user/info
     * @secure
     */
    updateUserInfo: (data: UserInfo, params: RequestParams = {}) =>
      this.request<UserInfo, ApiErrorResponse>({
        path: `/user/info`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with User Limits objects if success
     *
     * @tags User
     * @name GetLimits
     * @summary Get user limits
     * @request GET:/user/limits
     * @secure
     */
    getLimits: (params: RequestParams = {}) =>
      this.request<UserLimits, ApiErrorResponse>({
        path: `/user/limits`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userPhysicalAddress = {
    /**
     * @description Returns 200 with User Address objects if success
     *
     * @tags User Physical Address
     * @name ListAddress
     * @summary List all address
     * @request GET:/user/address
     * @secure
     */
    listAddress: (params: RequestParams = {}) =>
      this.request<UserAddress[], ApiErrorResponse>({
        path: `/user/address`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with User Address objects if success
     *
     * @tags User Physical Address
     * @name CreateAddress
     * @summary Create an address
     * @request POST:/user/address
     * @secure
     */
    createAddress: (data: UserAddress, params: RequestParams = {}) =>
      this.request<UserAddress, ApiErrorResponse>({
        path: `/user/address`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes user address, couldn't delete residential address. Returns 200
     *
     * @tags User Physical Address
     * @name DeleteUserAddress
     * @summary Delete address
     * @request DELETE:/user/address/{uuid}
     * @secure
     */
    deleteUserAddress: (uuid: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/user/address/${uuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns 200 with User Address if success
     *
     * @tags User Physical Address
     * @name UpdateAddress
     * @summary Update address
     * @request PUT:/user/address/{uuid}
     * @secure
     */
    updateAddress: (uuid: string, data: UserAddress, params: RequestParams = {}) =>
      this.request<UserAddress, ApiErrorResponse>({
        path: `/user/address/${uuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with User Email objects if success
     *
     * @tags User Physical Address
     * @name UpdateAddressSetPrimaryResidential
     * @summary Set address as primary residential address
     * @request POST:/user/address/{uuid}/residential
     * @secure
     */
    updateAddressSetPrimaryResidential: (uuid: string, params: RequestParams = {}) =>
      this.request<UserAddress, ApiErrorResponse>({
        path: `/user/address/${uuid}/residential`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userEmails = {
    /**
     * @description Returns 200 with User Email objects if success
     *
     * @tags User Emails
     * @name ListEmail
     * @summary List all emails
     * @request GET:/user/email
     * @secure
     */
    listEmail: (params: RequestParams = {}) =>
      this.request<UserEmailAddressDto[], ApiErrorResponse>({
        path: `/user/email`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with User Email objects if success
     *
     * @tags User Emails
     * @name CreateEmail
     * @summary Create email
     * @request POST:/user/email
     * @secure
     */
    createEmail: (data: UserEmailAddressDto, params: RequestParams = {}) =>
      this.request<UserEmailAddressDto, ApiErrorResponse>({
        path: `/user/email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description If no code provided, new confirmation code will be sent and 202 will be returned. If code provided, code will be checked and primary email will be updated and 200 will be returned. Old email will be marked deleted
     *
     * @tags User Emails
     * @name UpdatePrimaryEmail
     * @summary Update primary email
     * @request PUT:/user/email
     * @secure
     */
    updatePrimaryEmail: (data: UserPrimaryEmailUpdate, params: RequestParams = {}) =>
      this.request<UserEmailAddressDto, ApiErrorResponse>({
        path: `/user/email`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes email, couldn't delete primary email. Returns 200.
     *
     * @tags User Emails
     * @name DeleteEmail
     * @summary Delete an email
     * @request DELETE:/user/email/{uuid}
     * @secure
     */
    deleteEmail: (uuid: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/user/email/${uuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns 200 with User Email objects if success
     *
     * @tags User Emails
     * @name CreateEmailRequestConfirm
     * @summary Create confirmation code for email
     * @request POST:/user/email/{uuid}
     * @secure
     */
    createEmailRequestConfirm: (uuid: string, params: RequestParams = {}) =>
      this.request<UserEmailAddressDto, ApiErrorResponse>({
        path: `/user/email/${uuid}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Confirm email with code. If email was the only one confirmed, it becomes primary.Returns 200 with User Email objects if success
     *
     * @tags User Emails
     * @name CreateEmailConfirm
     * @summary Confirm email with code
     * @request POST:/user/email/{uuid}/confirm
     * @secure
     */
    createEmailConfirm: (uuid: string, data: EmailConfirmation, params: RequestParams = {}) =>
      this.request<UserEmailAddressDto, ApiErrorResponse>({
        path: `/user/email/${uuid}/confirm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with User Email objects if success
     *
     * @tags User Emails
     * @name UpdateEmailSetPrimary
     * @summary Set email primary
     * @request POST:/user/email/{uuid}/primary
     * @secure
     */
    updateEmailSetPrimary: (uuid: string, params: RequestParams = {}) =>
      this.request<UserEmailAddressDto, ApiErrorResponse>({
        path: `/user/email/${uuid}/primary`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userBusiness = {
    /**
     * @description Returns 200 with Company Info objects if success, 204 if no content
     *
     * @tags User Business
     * @name GetCompanyInfo
     * @summary Retrieve company info
     * @request GET:/user/info/company
     * @secure
     */
    getCompanyInfo: (params: RequestParams = {}) =>
      this.request<CompanyInfo, ApiErrorResponse>({
        path: `/user/info/company`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with Company Info objects if success
     *
     * @tags User Business
     * @name UpdateCompanyInfo
     * @summary Update company info
     * @request PUT:/user/info/company
     * @secure
     */
    updateCompanyInfo: (data: CompanyInfo, params: RequestParams = {}) =>
      this.request<CompanyInfo, ApiErrorResponse>({
        path: `/user/info/company`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with Company Info Individual objects if success
     *
     * @tags User Business
     * @name CreateCompanyInfoIndividual
     * @summary Create individual for company info
     * @request POST:/user/info/company/individual
     * @secure
     */
    createCompanyInfoIndividual: (data: Individual, params: RequestParams = {}) =>
      this.request<Individual, ApiErrorResponse>({
        path: `/user/info/company/individual`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with Company Info Individual Info objects if success
     *
     * @tags User Business
     * @name DeleteCompanyInfoIndividual
     * @summary Delete individual info
     * @request DELETE:/user/info/company/individual/{uuid}
     * @secure
     */
    deleteCompanyInfoIndividual: (uuid: string, params: RequestParams = {}) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/user/info/company/individual/${uuid}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns 200 with Company Info Individual Info objects if success
     *
     * @tags User Business
     * @name UpdateCompanyInfoIndividual
     * @summary Update individual info
     * @request PUT:/user/info/company/individual/{uuid}
     * @secure
     */
    updateCompanyInfoIndividual: (uuid: string, data: Individual, params: RequestParams = {}) =>
      this.request<Individual, ApiErrorResponse>({
        path: `/user/info/company/individual/${uuid}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns 200 with Company Info Individuals objects if success, 204 if no content
     *
     * @tags User Business
     * @name GetCompanyInfoIndividuals
     * @summary Retrieve company info individuals
     * @request GET:/user/info/company/individuals
     * @secure
     */
    getCompanyInfoIndividuals: (params: RequestParams = {}) =>
      this.request<Individual[], ApiErrorResponse>({
        path: `/user/info/company/individuals`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userPhone = {
    /**
     * @description If no code provided, new confirmation code will be sent and 202 will be returned. If code provided, code will be checked and phone will be created and 200 will be returned
     *
     * @tags User Phone
     * @name CreatePhone
     * @summary Create user phone
     * @request POST:/user/phone
     * @secure
     */
    createPhone: (data: UserPhoneNumberUpdate, params: RequestParams = {}) =>
      this.request<UserPhoneNumberCreate, ApiErrorResponse>({
        path: `/user/phone`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description If no code provided, new confirmation code will be sent and 202 will be returned. If code provided, code will be checked and phone will be updated and 200 will be returned
     *
     * @tags User Phone
     * @name UpdatePhone
     * @summary Update user phone
     * @request PUT:/user/phone
     * @secure
     */
    updatePhone: (data: UserPhoneNumberUpdate, params: RequestParams = {}) =>
      this.request<UserPhoneNumberCreate, ApiErrorResponse>({
        path: `/user/phone`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Confirm phone. Returns 200 if success
     *
     * @tags User Phone
     * @name ConfirmPhone
     * @summary Confirm phone
     * @request POST:/user/phone/confirm
     * @secure
     */
    confirmPhone: (data: UserPhoneNumberUpdate, params: RequestParams = {}) =>
      this.request<UserPhoneNumberCreate, ApiErrorResponse>({
        path: `/user/phone/confirm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Request phone confirmation code. Returns 200 if success
     *
     * @tags User Phone
     * @name RequestConfirmPhone
     * @summary Request phone code
     * @request POST:/user/phone/requestConfirm
     * @secure
     */
    requestConfirmPhone: (data: UserPhoneNumberUpdate, params: RequestParams = {}) =>
      this.request<JsonNode, ApiErrorResponse>({
        path: `/user/phone/requestConfirm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  userNotifications = {
    /**
     * No description
     *
     * @tags User Notifications
     * @name RegisterDevice
     * @summary Register device for notifications
     * @request POST:/user/registerDevice
     * @secure
     */
    registerDevice: (data: DeviceDto, params: RequestParams = {}) =>
      this.request<DeviceDto, ApiErrorResponse>({
        path: `/user/registerDevice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  userPassword = {
    /**
     * @description Set new password with signed code for password reset. Returns 200 with User Info objects if success.
     *
     * @tags User Password
     * @name ResetPassword
     * @summary Reset Password, phase 2
     * @request POST:/user/resetPassword
     */
    resetPassword: (data: UserResetPassword, params: RequestParams = {}) =>
      this.request<UserInfo, ApiErrorResponse>({
        path: `/user/resetPassword`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Requests signed code for password reset with TFA code. If no code provided in request, new would be send to username (email or password).Response 200 is signed payload for reset password. Response 202 if code is sent. Response the same if user not found.
     *
     * @tags User Password
     * @name ResetPasswordConfirm
     * @summary Reset Password, phase 1
     * @request POST:/user/resetPasswordRequest
     */
    resetPasswordConfirm: (data: UserResetPasswordRequest, params: RequestParams = {}) =>
      this.request<string, ApiErrorResponse>({
        path: `/user/resetPasswordRequest`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  userSessions = {
    /**
     * No description
     *
     * @tags User Sessions
     * @name GetUserActiveSessions
     * @summary Get All Active Sessions
     * @request GET:/user/session
     * @secure
     */
    getUserActiveSessions: (params: RequestParams = {}) =>
      this.request<UserSession[], ApiErrorResponse>({
        path: `/user/session`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Logout from list of sessions or all sessions (if list is empty)
     *
     * @tags User Sessions
     * @name LogoutUser
     * @summary Logout from sessions
     * @request PUT:/user/session/logout
     * @secure
     */
    logoutUser: (data: UserSession[], params: RequestParams = {}) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/user/session/logout`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Logout from current session (by token)
     *
     * @tags User Sessions
     * @name LogoutCurrentSession
     * @summary Logout
     * @request DELETE:/user/session/logout/current
     * @secure
     */
    logoutCurrentSession: (params: RequestParams = {}) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/user/session/logout/current`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  userTermsAndConditions = {
    /**
     * @description Returns 200 with Terms and Conditions object if success
     *
     * @tags User Terms and Conditions
     * @name GetLatestTermsAndConditions
     * @summary Retrieve latest terms and conditions
     * @request GET:/user/terms-and-conditions
     * @secure
     */
    getLatestTermsAndConditions: (params: RequestParams = {}) =>
      this.request<TermsAndConditionsDto, ApiErrorResponse>({
        path: `/user/terms-and-conditions`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns 200 with Terms and Conditions object if success
     *
     * @tags User Terms and Conditions
     * @name SignTermsAndConditions
     * @summary Sign terms and conditions
     * @request PUT:/user/terms-and-conditions
     * @secure
     */
    signTermsAndConditions: (data: TermsAndConditionsDto, params: RequestParams = {}) =>
      this.request<TermsAndConditionsDto, ApiErrorResponse>({
        path: `/user/terms-and-conditions`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  verification = {
    /**
     * @description Retrieve a verification status
     *
     * @tags Verification
     * @name GetVerificationStatus
     * @summary Retrieve a verification status
     * @request GET:/verification
     * @secure
     */
    getVerificationStatus: (
      query?: {
        /** @default false */
        validate?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        | KybLayer2UserVerification
        | KybManualUserVerification
        | KycLayer2UserVerification
        | OffileUserVerification
        | PaySpotOfflineUserVerification
        | SumSubUserVerification,
        ApiErrorResponse
      >({
        path: `/verification`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates new user verification with provided info.Email and phone must be confirmed. Next step is complete verification in SumSub framework using provided token
     *
     * @tags Verification
     * @name CreateVerification
     * @summary Create verification
     * @request POST:/verification
     * @secure
     */
    createVerification: (data: UserVerificationCreate, params: RequestParams = {}) =>
      this.request<
        | KybLayer2UserVerification
        | KybManualUserVerification
        | KycLayer2UserVerification
        | OffileUserVerification
        | PaySpotOfflineUserVerification
        | SumSubUserVerification,
        ApiErrorResponse
      >({
        path: `/verification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a verification by UUID
     *
     * @tags Verification
     * @name GetVerificationByUuid
     * @summary Retrieve a verification by UUID
     * @request GET:/verification/{kycId}
     */
    getVerificationByUuid: (
      kycId: string,
      query?: {
        /** @default false */
        validate?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        | KybLayer2UserVerification
        | KybManualUserVerification
        | KycLayer2UserVerification
        | OffileUserVerification
        | PaySpotOfflineUserVerification
        | SumSubUserVerification,
        ApiErrorResponse
      >({
        path: `/verification/${kycId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload document to a verification by uuid
     *
     * @tags Verification
     * @name UploadDocument
     * @summary Upload document to a verification by uuid
     * @request POST:/verification/{kycId}/docs
     */
    uploadDocument: (
      kycId: string,
      data: {
        /** @format binary */
        file: File;
        request: DocumentUploadRequest;
      },
      params: RequestParams = {}
    ) =>
      this.request<VerificationDocument, ApiErrorResponse>({
        path: `/verification/${kycId}/docs`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete document by uuid
     *
     * @tags Verification
     * @name DeleteDocument
     * @summary Delete document by uuid
     * @request DELETE:/verification/{kycId}/docs/{docId}
     * @secure
     */
    deleteDocument: (kycId: string, docId: string, params: RequestParams = {}) =>
      this.request<Unit, ApiErrorResponse>({
        path: `/verification/${kycId}/docs/${docId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve document by uuid
     *
     * @tags Verification
     * @name GetDocument
     * @summary Retrieve document by uuid
     * @request GET:/verification/{kycId}/docs/{docId}
     * @secure
     */
    getDocument: (kycId: string, docId: string, params: RequestParams = {}) =>
      this.request<File, ApiErrorResponse>({
        path: `/verification/${kycId}/docs/${docId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update verification status from INIT to PENDING
     *
     * @tags Verification
     * @name UpdateVerificationStatus
     * @summary Update verification status to pending
     * @request PUT:/verification/{uuid}/status
     * @secure
     */
    updateVerificationStatus: (uuid: string, params: RequestParams = {}) =>
      this.request<
        | KybLayer2UserVerification
        | KybManualUserVerification
        | KycLayer2UserVerification
        | OffileUserVerification
        | PaySpotOfflineUserVerification
        | SumSubUserVerification,
        ApiErrorResponse
      >({
        path: `/verification/${uuid}/status`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
