/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import SingleSelectList from 'components/elements/single-select-list';

import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { cutId } from 'utils/strings';

const SidebarPartnersSelect = observer(() => {
  const { partnerStore, layoutStore } = useBaseStores();

  const options = useMemo(
    () =>
      partnerStore.availablePartners.map((x) => ({
        id: x.id?.toString() ?? '',
        label: x.name ?? '',
        additionalLabel: x.clientId ? cutId(x.clientId) : undefined,
        value: x,
      })),
    [partnerStore.availablePartners]
  );

  const selectPartner = useCallback(
    (partnerId: number) => {
      partnerStore.setPartner(partnerId);
      layoutStore.toggleSidebar(false);
    },
    [layoutStore, partnerStore]
  );

  return (
    <SingleSelectList
      selectedId={partnerStore.currentPartner?.id?.toString() ?? '-'}
      options={options}
      onApply={(val) => selectPartner(val.value.id)}
    />
  );
});

export const useSidebarPartersSelect = () => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.partners.title',
        defaultMessage: 'Partners',
      }),
      <SidebarPartnersSelect />
    );
  }, [intl, layoutStore]);

  return {
    showPartnersSidebar: showSidebar,
  };
};
