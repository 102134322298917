/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { observer } from 'mobx-react-lite';
import { formattedDate } from 'utils/dates';
import { TableProps } from 'antd';
import Table from 'components/core/table';
import BadgeTransactionStatus, {
  CombinedTransactionStatus,
} from 'components/core/badges/badge-transaction-status';
import { TransactionAccountModel } from '../../models/TransactionsModel';
import { useBaseStores } from 'providers/BaseStoresProvider';
import Button from '../core/button';
import { useSidebarJsonViewer } from '../sidebars/sidebar-json-viewer';
import { isEmpty } from 'utils/obj';
import useIntlTxReason from 'hooks/intl/tx-reason-types';
import { BalanceLogReasonType } from 'api/wallet';

type Props = {
  collection: CollectionWithPages<TransactionAccountModel, {}>;
  onTransactionClick?: (transaction: TransactionAccountModel) => void;
  hideColumns?: {
    value?: boolean;
    source?: boolean;
    status?: boolean;
  };
};
const TransactionsHistoryTable = observer(
  ({ collection, onTransactionClick, hideColumns }: Props) => {
    const intl = useIntl();
    const { showJsonViewerSidebar } = useSidebarJsonViewer();
    const { currencyStore } = useBaseStores();

    const { balanceReasonType } = useIntlTxReason();

    const columns: TableProps<TransactionAccountModel>['columns'] = [
      {
        title: intl.formatMessage({
          id: 'table.transactions_history.col.id',
          defaultMessage: 'Id',
        }),
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => <span>{id}</span>,
        width: 20,
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions_history.col.date',
          defaultMessage: 'Date',
        }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt: string) => (
          <span>{formattedDate(createdAt ?? '')}</span>
        ),
        width: 20,
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions_history.col.type',
          defaultMessage: 'Type',
        }),
        dataIndex: 'reasonType',
        key: 'reasonType',
        render: (type: string) => (
          <span>{balanceReasonType(type as BalanceLogReasonType)}</span>
        ),
        width: 10,
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions_history.col.amount',
          defaultMessage: 'Amount',
        }),
        dataIndex: 'amount',
        key: 'amount',
        render: (text: string, record: TransactionAccountModel) => (
          <span>
            {(Number(record?.change) ?? 0).toString()} {currencyStore.currenciesMap.get(record.currency)?.name}
          </span>
        ),
        width: 30,
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions_history.col.euroValue',
          defaultMessage: 'Euro value',
        }),
        dataIndex: 'value',
        key: 'value',
        render: (text: string, record: TransactionAccountModel) => (
          <span>
            {currencyStore.getEuroValue(
              record.currency,
              Number(record?.change)
            ) || '-'}
          </span>
        ),
        width: 30,
        hidden: hideColumns?.['value'],
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions_history.col.status',
          defaultMessage: 'Status',
        }),
        dataIndex: 'status',
        key: 'status',
        render: (status: CombinedTransactionStatus) => (
          <BadgeTransactionStatus status={status} />
        ),
        width: 10,
        hidden: hideColumns?.['status'],
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions.col.json',
          defaultMessage: 'JSON',
        }),
        dataIndex: 'source',
        key: 'source',
        render: (source) => (
          <Button
            variant={'secondary'}
            size={'small'}
            className='font-semibold'
            disabled={isEmpty(JSON.parse(source) as Object)}
            onClick={() => showJsonViewerSidebar(source ?? '{}')}
          >
            {intl.formatMessage({
              id: 'table.transactions_history.col.checkJson',
              defaultMessage: 'Check JSON',
            })}
          </Button>
        ),
        width: 10,
        hidden: hideColumns?.['source'],
      },
    ];

    return (
      <Table<TransactionAccountModel, {}>
        collection={collection}
        columns={columns}
        onRowClick={onTransactionClick}
      />
    );
  }
);

export default TransactionsHistoryTable;
