/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { observer } from 'mobx-react-lite';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { AdminWalletAccountModel } from 'models/WalletAccount';
import FilterButton from 'components/elements/filter-button';
import { useSidebarTxsFilter } from 'components/sidebars/sidebar-transactions-filter';
import { AdminTxRequestModel } from 'models/request/AdminTransactionsModel';
import SearchField from '../../../components/core/search-field';
import { useSetQueryParams } from '../../../hooks/collection-query-params';
import { useCallback } from 'react';
import { URL_SEARCH_PARAMS } from '../../../assets/config';
import { useIntl } from 'react-intl';

type Props = {
  collectData: CollectionWithPages<
    AdminWalletAccountModel,
    AdminTxRequestModel
  >;
};

const AccountsFilter = observer(({ collectData }: Props) => {
  const intl = useIntl();

  const { showTxsFiltersSidebar } = useSidebarTxsFilter({
    collection: collectData,
  });

  const { setFilterQueryParams } = useSetQueryParams();

  const onSearchChanged = useCallback(
    (val: string) => {
      setFilterQueryParams([
        { id: URL_SEARCH_PARAMS.page, value: '1' },
        { id: URL_SEARCH_PARAMS.search, value: val },
      ]);
    },
    [setFilterQueryParams]
  );

  return (
    <div className={styles.main}>
      <SearchField
        className={styles.search_input}
        placeholder={intl.formatMessage({
          id: 'page.approver.search.placeholder',
          defaultMessage: 'Search by id',
        })}
        value={collectData.filter.search ?? ''}
        onChange={onSearchChanged}
      />

      <FilterButton
        activeCount={collectData.totalFiltersCount}
        onClick={showTxsFiltersSidebar}
      />
    </div>
  );
});

export default AccountsFilter;
