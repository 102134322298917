/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** List of allowed budget for collateral. Can be null, if loan config allows no collateral. */
export type AccountMoney = {
  /** User account id */
  account: string;
  /** Amount of currency */
  amount: string;
  /**
   * Currency
   * @pattern ^[A-Z]+$
   */
  currency: string;
} | null;

/** Admin Loan Offer Configuration */
export interface AdminLoanOffer {
  /** @format uuid */
  id: string;
  /** Short name of loan offer */
  name: string;
  /** Description of loan offer */
  description: string;
  /**
   * Minimal principal allowed in this loan.
   * @format number
   * @example "100"
   */
  minPrincipalAmount?: string | null;
  /**
   * Currency of minimal principal.
   * @pattern ^[A-Z]+$
   * @example "usdt"
   */
  minPrincipalCurrency?: string | null;
  /**
   * Maximum principal allowed in this loan.
   * @format number
   * @example "10000"
   */
  maxPrincipalAmount?: string | null;
  /**
   * Currency of maximum principal.
   * @pattern ^[A-Z]+$
   * @example "usdt"
   */
  maxPrincipalCurrency?: string | null;
  /**
   * Currency of principal.
   * @pattern ^[A-Z]+$
   * @example "usdt"
   */
  principalCurrency: string;
  /**
   * List of allowed collateral currencies.
   * @pattern ^[A-Z;]+$
   * @example "btc;eth"
   */
  collateralAllowedCurrencies?: string | null;
  /**
   * Minimum allowed duration of `Loan`, ISO8601 Duration format.
   * @example "P1D"
   */
  minTerm?: string | null;
  /**
   * Maximum allowed duration of `Loan`, ISO8601 Duration format.
   * @example "P300D"
   */
  maxTerm?: string | null;
  /** Payment period type, inherited from config */
  paymentPeriodType: PaymentPeriodTypeEnum;
  /**
   * Payment period of `Loan`, ISO8601 Duration format.
   * @example "P30D"
   */
  paymentPeriod?: string | null;
  /**
   * Interest rate of `Loan`.
   * @format number
   * @example "0.1"
   */
  interestRate: string;
  /**
   * Collateral required rate.
   * @format number
   * @example "3.0"
   */
  collateralRate?: string | null;
  /**
   * Margin call LTV rate.
   * @format number
   * @example "1.2"
   */
  marginCallRate?: string;
  /**
   * Min LTV rate of Loin to recover from MC or liquidation.
   * @format number
   * @example "1.2"
   */
  minHealthRate?: string;
  feePoints: FeePoint[];
  /**
   * Liquidation LTV.
   * @format number
   * @example "1.1"
   */
  liquidationRate?: string;
  /**
   * Loan type
   * Supported types:
   * * BALLOON type (interest without principal payments).
   */
  type: LoanType;
}

export interface AdminLoanOfferUpdate {
  /** Admin Loan Offer Configuration */
  updatedLoanOffer: AdminLoanOffer;
  affectedLoans: Loan[];
}

export interface ApiError {
  key?: string;
  description: string;
}

export interface ApiErrorResponse {
  status: HttpStatus;
  message: string;
  traceId: string;
  errors?: ApiError[];
}

export interface FeePoint {
  /** @format uuid */
  id?: string;
  rate?: number;
  fee?: number;
  duration?: FeePointDuration;
}

export interface FeePointRecord {
  feePointDto: FeePoint;
  active: boolean;
  /** @format int64 */
  cycleNumber: number;
  /** @format date-time */
  cycleStartDateFrom?: string;
}

export enum HttpStatus {
  Value100CONTINUE = '100 CONTINUE',
  Value101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  Value102PROCESSING = '102 PROCESSING',
  Value103EARLYHINTS = '103 EARLY_HINTS',
  Value103CHECKPOINT = '103 CHECKPOINT',
  Value200OK = '200 OK',
  Value201CREATED = '201 CREATED',
  Value202ACCEPTED = '202 ACCEPTED',
  Value203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  Value204NOCONTENT = '204 NO_CONTENT',
  Value205RESETCONTENT = '205 RESET_CONTENT',
  Value206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  Value207MULTISTATUS = '207 MULTI_STATUS',
  Value208ALREADYREPORTED = '208 ALREADY_REPORTED',
  Value226IMUSED = '226 IM_USED',
  Value300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  Value301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  Value302FOUND = '302 FOUND',
  Value302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  Value303SEEOTHER = '303 SEE_OTHER',
  Value304NOTMODIFIED = '304 NOT_MODIFIED',
  Value305USEPROXY = '305 USE_PROXY',
  Value307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  Value308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  Value400BADREQUEST = '400 BAD_REQUEST',
  Value401UNAUTHORIZED = '401 UNAUTHORIZED',
  Value402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  Value403FORBIDDEN = '403 FORBIDDEN',
  Value404NOTFOUND = '404 NOT_FOUND',
  Value405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  Value406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  Value407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  Value408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  Value409CONFLICT = '409 CONFLICT',
  Value410GONE = '410 GONE',
  Value411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  Value412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  Value413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  Value413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  Value414URITOOLONG = '414 URI_TOO_LONG',
  Value414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  Value415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  Value416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  Value417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  Value418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  Value419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  Value420METHODFAILURE = '420 METHOD_FAILURE',
  Value421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  Value422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  Value423LOCKED = '423 LOCKED',
  Value424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  Value425TOOEARLY = '425 TOO_EARLY',
  Value426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  Value428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  Value429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  Value431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  Value451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  Value500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  Value501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  Value502BADGATEWAY = '502 BAD_GATEWAY',
  Value503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  Value504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  Value505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  Value506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  Value507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  Value508LOOPDETECTED = '508 LOOP_DETECTED',
  Value509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  Value510NOTEXTENDED = '510 NOT_EXTENDED',
  Value511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

/** User Loan */
export interface Loan {
  /**
   * A unique identifier for a Loan.
   * @format uuid
   */
  id?: string | null;
  /**
   * A unique identifier for a user.
   * @format uuid
   */
  userId?: string | null;
  /** List of allowed budget for collateral. Can be null, if loan config allows no collateral. */
  budget?: AccountMoney[] | null;
  /** List of allowed budget for collateral. Can be null, if loan config allows no collateral. */
  collateral?: AccountMoney[] | null;
  /**
   * Amount of Principal.
   * @format number
   * @example "10000"
   */
  principalAmount?: string;
  /**
   * Currency of Principal.
   * @pattern ^[A-Z]+$
   * @example "usdt"
   */
  principalCurrency?: string;
  /**
   * List of allowed collateral currencies.
   * @pattern ^[A-Z;]+$
   * @example "btc;eth"
   */
  collateralAllowedCurrencies?: string | null;
  /**
   * Interest rate of Loan.
   * @format number
   * @example "0.1"
   */
  interestRate?: string;
  feePoints: FeePoint[];
  /**
   * Collateral/Principal rate (aka leverage).
   * @format number
   * @example "3.0"
   */
  collateralRate?: string;
  /**
   * Margin call LTV rate.
   * @format number
   * @example "3.0"
   */
  marginCallRate?: string;
  /**
   * Min LTV rate of Loan to recover from MC or liquidation.
   * @format number
   * @example "3.0"
   */
  minHealthRate?: string;
  /**
   * Liquidation LTV Rate.
   * @format number
   * @example "3.0"
   */
  liquidationRate?: string;
  /**
   * Interest rate of Loan.
   * @format number
   * @example "110.1"
   */
  totalInterest?: string;
  /**
   * Currency of Total interest loan.
   * @pattern ^[A-Z]+$
   */
  totalInterestCurrency?: string;
  /**
   * Price of Collateral on Loan settlement.
   * @format number
   * @example "10.2"
   */
  settlementPriceAmount?: string;
  /**
   * Currency of Loan settlement point
   * @pattern ^[A-Z]+$
   */
  settlementPriceCurrency?: string;
  /**
   * Loan type
   * Supported types:
   * * BALLOON type (interest without principal payments).
   */
  type?: LoanType;
  /**
   * Loan start date.
   * @format date-time
   */
  startDate?: string;
  /**
   * Loan planned end date.
   * @format date-time
   */
  endDate?: string;
  /** Payment period type, inherited from config */
  paymentPeriodType?: PaymentPeriodTypeEnum;
  /**
   * Payment period of `Loan`, ISO8601 Duration format.
   * @example "P30D"
   */
  paymentPeriod?: string | null;
  /** Auto payment account. */
  paymentAccountId?: string | null;
  /** Beneficiary account, collateral would be paid to on Loan close. */
  beneficiaryAccountId?: string | null;
  /** List of loan payments. */
  payments?: LoanPayment[];
  /**
   * Current linked loan offer id. If null loan was changed manually.
   * @format uuid
   */
  currentLoanOfferId?: string;
  /**
   * Initial loan offer id.
   * @format uuid
   */
  initialLoanOfferId?: string;
  /** Current status of loan. */
  status?: LoanStatus;
  /** Status description. */
  statusDescription?: string;
}

export interface LoanConfig {
  /** @format uuid */
  id: string;
  name: string;
  description: string;
  minPrincipal?: Money;
  maxPrincipal?: Money;
  principalCurrency: string;
  collateralAllowedCurrencies: string[];
  minTerm?: LoanConfigMinTerm;
  maxTerm?: LoanConfigMinTerm;
  /** Payment period type, inherited from config */
  paymentPeriodType: PaymentPeriodTypeEnum;
  paymentPeriod?: LoanConfigMinTerm;
  interestRate?: number;
  feePoints: FeePoint[];
  collateralRate?: number;
  marginCallRate?: number;
  minHealthRate?: number;
  liquidationRate?: number;
  /**
   * Loan type
   * Supported types:
   * * BALLOON type (interest without principal payments).
   */
  type: LoanType;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format date-time */
  createdDate?: string;
}

export interface LoanCreateRequest {
  /**
   * Loan configuration identifier
   * @format uuid
   */
  loanOfferId: string;
  /** List of allowed budget for collateral. Can be null, if loan config allows no collateral. */
  budget?: AccountMoney[] | null;
  /** User Loan */
  loan?: Loan;
  /** List of collateral, must be signed. Can be null, if loan config allows no collateral. */
  loanSignature?: string | null;
  /** Account of user to debit principal of loan. */
  principalAccount: string;
  /** Requested amount of principal. */
  principalAmount: string;
  /**
   * Currency of requested principal.
   * @pattern ^[A-Z]+$
   */
  principalCurrency: string;
  /** Duration of loan in ISO8601 duration format, e.g. 'P350DT30M' */
  duration: string;
  /** Auto payment account. */
  paymentAccount?: string | null;
}

export interface LoanLtv {
  /** @format uuid */
  loanId: string;
  ltv: number;
}

/** Loan Offer Configuration */
export interface LoanOffer {
  /** @format uuid */
  id: string;
  /** Short name of loan offer */
  name: string;
  /** Description of loan offer */
  description: string;
  /**
   * Minimal principal allowed in this loan.
   * @format number
   * @example "100"
   */
  minPrincipalAmount?: string | null;
  /**
   * Currency of minimal principal.
   * @pattern ^[A-Z]+$
   * @example "usdt"
   */
  minPrincipalCurrency?: string | null;
  /**
   * Maximum principal allowed in this loan.
   * @format number
   * @example "10000"
   */
  maxPrincipalAmount?: string | null;
  /**
   * Currency of maximum principal.
   * @pattern ^[A-Z]+$
   * @example "usdt"
   */
  maxPrincipalCurrency?: string | null;
  /**
   * Currency of principal.
   * @pattern ^[A-Z]+$
   * @example "usdt"
   */
  principalCurrency: string;
  /**
   * List of allowed collateral currencies.
   * @pattern ^[A-Z;]+$
   * @example "btc;eth"
   */
  collateralAllowedCurrencies?: string | null;
  /**
   * Minimum allowed duration of `Loan`, ISO8601 Duration format.
   * @example "P1D"
   */
  minTerm?: string | null;
  /**
   * Maximum allowed duration of `Loan`, ISO8601 Duration format.
   * @example "P300D"
   */
  maxTerm?: string | null;
  /** Payment period type, inherited from config */
  paymentPeriodType: PaymentPeriodTypeEnum;
  /**
   * Payment period of `Loan`, ISO8601 Duration format.
   * @example "P30D"
   */
  paymentPeriod?: string | null;
  /**
   * Interest rate of `Loan`.
   * @format number
   * @example "0.1"
   */
  interestRate: string;
  /**
   * Collateral required rate.
   * @format number
   * @example "3.0"
   */
  collateralRate?: string | null;
  /**
   * Margin call LTV rate.
   * @format number
   * @example "1.2"
   */
  marginCallRate?: string;
  /**
   * Min LTV rate of Loin to recover from MC or liquidation.
   * @format number
   * @example "1.2"
   */
  minHealthRate?: string;
  feePoints: FeePoint[];
  /**
   * Liquidation LTV.
   * @format number
   * @example "1.1"
   */
  liquidationRate?: string;
  /**
   * Loan type
   * Supported types:
   * * BALLOON type (interest without principal payments).
   */
  type: LoanType;
}

/** List of loan payments. */
export interface LoanPayment {
  /**
   * A unique identifier for a LoanPayment.
   * @format uuid
   */
  id?: string | null;
  /** Type of payment. */
  type?: LoanPaymentType;
  /**
   * @format number
   * @example "10.2"
   */
  interestAmount?: string;
  /** @pattern ^[A-Z]+$ */
  interestCurrency?: string;
  /**
   * @format number
   * @example "10.2"
   */
  principalAmount?: string;
  /** @pattern ^[A-Z]+$ */
  principalCurrency?: string;
  /**
   * @format number
   * @example "10.2"
   */
  feeAmount?: string;
  /** @pattern ^[A-Z]+$ */
  feeCurrency?: string;
  status: LoanPaymentStatus;
  /** @format date-time */
  plannedPaymentDate?: string;
  /** @format date-time */
  paymentDate?: string;
  /** @format uuid */
  paymentId?: string;
}

export enum LoanPaymentStatus {
  UNDEFINED = 'UNDEFINED',
  INIT = 'INIT',
  PAID = 'PAID',
  LATE = 'LATE',
}

/** Type of payment. */
export enum LoanPaymentType {
  PREPAYMENT = 'PREPAYMENT',
  REGULAR = 'REGULAR',
  FEE = 'FEE',
}

/** Current status of loan. */
export enum LoanStatus {
  UNDEFINED = 'UNDEFINED',
  INIT = 'INIT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
  LENT = 'LENT',
  MARGIN_CALL = 'MARGIN_CALL',
  LIQUIDATING = 'LIQUIDATING',
  LIQUIDATED = 'LIQUIDATED',
  LATE = 'LATE',
  PAID = 'PAID',
  CLOSED = 'CLOSED',
}

/**
 * Loan type
 * Supported types:
 * * BALLOON type (interest without principal payments).
 */
export enum LoanType {
  UNDEFINED = 'UNDEFINED',
  BALLOON = 'BALLOON',
}

/** Margin call */
export interface MarginCall {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  loanId?: string;
  status?: MarginCallStatus;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  minHealthDiff?: Money;
  /** @format date-time */
  liquidationDate?: string;
}

export enum MarginCallStatus {
  UNDEFINED = 'UNDEFINED',
  ACTIVE = 'ACTIVE',
  SUPPRESSED = 'SUPPRESSED',
  FULFILLED = 'FULFILLED',
}

export interface Money {
  /** Amount of currency */
  amount: string;
  /**
   * Currency
   * @pattern ^[A-Z]+$
   */
  currency: string;
}

export interface PageableResponseLoan {
  data: Loan[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseLoanOffer {
  data: LoanOffer[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseLoanPayment {
  data: LoanPayment[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseMarginCall {
  data: MarginCall[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

/** Payment period type, inherited from config */
export enum PaymentPeriodTypeEnum {
  FIXED_LENGTH = 'FIXED_LENGTH',
  FIXED_DAY = 'FIXED_DAY',
}

export interface RevisionIntegerLoan {
  metadata?: RevisionMetadataInteger;
  /** User Loan */
  entity?: Loan;
  /** @format date-time */
  revisionInstant?: string;
  /** @format date-time */
  requiredRevisionInstant?: string;
  /** @format int32 */
  revisionNumber?: number;
  /** @format int32 */
  requiredRevisionNumber?: number;
}

export interface RevisionIntegerLoanConfig {
  metadata?: RevisionMetadataInteger;
  entity?: LoanConfig;
  /** @format date-time */
  revisionInstant?: string;
  /** @format date-time */
  requiredRevisionInstant?: string;
  /** @format int32 */
  revisionNumber?: number;
  /** @format int32 */
  requiredRevisionNumber?: number;
}

export interface RevisionIntegerLoanPayment {
  metadata?: RevisionMetadataInteger;
  /** List of loan payments. */
  entity?: LoanPayment;
  /** @format date-time */
  revisionInstant?: string;
  /** @format date-time */
  requiredRevisionInstant?: string;
  /** @format int32 */
  revisionNumber?: number;
  /** @format int32 */
  requiredRevisionNumber?: number;
}

export interface RevisionIntegerMarginCall {
  metadata?: RevisionMetadataInteger;
  /** Margin call */
  entity?: MarginCall;
  /** @format date-time */
  revisionInstant?: string;
  /** @format date-time */
  requiredRevisionInstant?: string;
  /** @format int32 */
  revisionNumber?: number;
  /** @format int32 */
  requiredRevisionNumber?: number;
}

export interface RevisionMetadataInteger {
  /** @format date-time */
  revisionInstant?: string;
  /** @format date-time */
  requiredRevisionInstant?: string;
  /** @format int32 */
  revisionNumber?: number;
  delegate?: object;
  revisionType?: RevisionType;
  /** @format int32 */
  requiredRevisionNumber?: number;
}

export enum RevisionType {
  UNKNOWN = 'UNKNOWN',
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface RevisionsIntegerLoan {
  content?: RevisionIntegerLoan[];
  latestRevision?: RevisionIntegerLoan;
  empty?: boolean;
}

export interface RevisionsIntegerLoanConfig {
  content?: RevisionIntegerLoanConfig[];
  latestRevision?: RevisionIntegerLoanConfig;
  empty?: boolean;
}

export interface RevisionsIntegerLoanPayment {
  content?: RevisionIntegerLoanPayment[];
  latestRevision?: RevisionIntegerLoanPayment;
  empty?: boolean;
}

export interface RevisionsIntegerMarginCall {
  content?: RevisionIntegerMarginCall[];
  latestRevision?: RevisionIntegerMarginCall;
  empty?: boolean;
}

export interface FeePointDurationUnits {
  durationEstimated?: boolean;
  timeBased?: boolean;
  dateBased?: boolean;
}

export interface FeePointDuration {
  /** @format int64 */
  seconds?: number;
  zero?: boolean;
  /** @format int32 */
  nano?: number;
  negative?: boolean;
  units?: FeePointDurationUnits[];
}

export interface LoanConfigMinTermDuration {
  /** @format int64 */
  seconds?: number;
  zero?: boolean;
  /** @format int32 */
  nano?: number;
  negative?: boolean;
}

export interface LoanConfigMinTermUnits {
  durationEstimated?: boolean;
  duration?: LoanConfigMinTermDuration;
  timeBased?: boolean;
  dateBased?: boolean;
}

export interface LoanConfigMinTerm {
  /** @format int64 */
  seconds?: number;
  zero?: boolean;
  /** @format int32 */
  nano?: number;
  negative?: boolean;
  units?: LoanConfigMinTermUnits[];
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL:
        axiosConfig.baseURL || 'https://api.stg.darknet.piefi.app/wallet',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Loans
 * @version 0.0.1
 * @baseUrl https://api.stg.darknet.piefi.app/wallet
 *
 * API for interactions with loans.
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  loans = {
    /**
     * @description Get single instance of an `Loan`.
     *
     * @tags Loan
     * @name GetLoan
     * @summary Retrieve a single Loan
     * @request GET:/loans/{loanId}
     * @secure
     */
    getLoan: (loanId: string, params: RequestParams = {}) =>
      this.request<Loan, ApiErrorResponse>({
        path: `/loans/${loanId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update instance of an `Loan`. Only user defined field can be changed.
     *
     * @tags Loan
     * @name UpdateLoan
     * @summary Update Loan
     * @request PUT:/loans/{loanId}
     * @secure
     */
    updateLoan: (loanId: string, data: Loan, params: RequestParams = {}) =>
      this.request<Loan, ApiErrorResponse>({
        path: `/loans/${loanId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Pay `LoanPayment` entity.
     *
     * @tags Loan
     * @name PayLoanPayment
     * @summary Pay loan payment
     * @request PUT:/loans/{loanId}/payments/{paymentId}/pay
     * @secure
     */
    payLoanPayment: (
      loanId: string,
      paymentId: string,
      query: {
        accountId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<LoanPayment, ApiErrorResponse>({
        path: `/loans/${loanId}/payments/${paymentId}/pay`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description List of `Loan` entities.
     *
     * @tags Loan
     * @name GetLoans
     * @summary List of loans
     * @request GET:/loans
     * @secure
     */
    getLoans: (params: RequestParams = {}) =>
      this.request<Loan[], ApiErrorResponse>({
        path: `/loans`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create `Loan` entity.
     *
     * @tags Loan
     * @name CreateLoan
     * @summary Create new loan
     * @request POST:/loans
     * @secure
     */
    createLoan: (
      data: LoanCreateRequest,
      query?: {
        /** @default false */
        dryRun?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<Loan, ApiErrorResponse>({
        path: `/loans`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Close `Loan`. Collateral if exists will be returned to selected account.
     *
     * @tags Loan
     * @name CloseLoan
     * @summary Close a single Loan
     * @request POST:/loans/{loanId}/close
     * @secure
     */
    closeLoan: (
      loanId: string,
      query: {
        beneficiaryAccountId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Loan, ApiErrorResponse>({
        path: `/loans/${loanId}/close`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description List `LoanPayment` entities.
     *
     * @tags Loan
     * @name GetLoanPayments
     * @summary List of loan payments
     * @request GET:/loans/{loanId}/payments
     * @secure
     */
    getLoanPayments: (loanId: string, params: RequestParams = {}) =>
      this.request<LoanPayment[], ApiErrorResponse>({
        path: `/loans/${loanId}/payments`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all loan's margin calls
     *
     * @tags Loan
     * @name GetMarginCalls
     * @summary Get all margin calls
     * @request GET:/loans/{loanId}/marginCalls
     * @secure
     */
    getMarginCalls: (loanId: string, params: RequestParams = {}) =>
      this.request<MarginCall[], ApiErrorResponse>({
        path: `/loans/${loanId}/marginCalls`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get active loan's margin call
     *
     * @tags Loan
     * @name GetActiveMarginCall
     * @summary Get active margin call
     * @request GET:/loans/{loanId}/marginCall/active
     * @secure
     */
    getActiveMarginCall: (loanId: string, params: RequestParams = {}) =>
      this.request<MarginCall, ApiErrorResponse>({
        path: `/loans/${loanId}/marginCall/active`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get current ltv for loan by id and user id
     *
     * @tags Loan
     * @name GetLoanLtv
     * @summary Get current ltv for loan
     * @request GET:/loans/{loanId}/ltv
     * @secure
     */
    getLoanLtv: (loanId: string, params: RequestParams = {}) =>
      this.request<LoanLtv, ApiErrorResponse>({
        path: `/loans/${loanId}/ltv`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get active fee point with additional info
     *
     * @tags Loan
     * @name GetLoanActiveFeePoint
     * @summary Get active fee point
     * @request GET:/loans/{loanId}/fee-point/active
     * @secure
     */
    getLoanActiveFeePoint: (loanId: string, params: RequestParams = {}) =>
      this.request<FeePointRecord, ApiErrorResponse>({
        path: `/loans/${loanId}/fee-point/active`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description List of `LoanOffer` entities.
     *
     * @tags Loan
     * @name GetLoanOffers
     * @summary List of loan offers
     * @request GET:/loans/offers/
     * @secure
     */
    getLoanOffers: (params: RequestParams = {}) =>
      this.request<LoanOffer[], ApiErrorResponse>({
        path: `/loans/offers/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  admin = {
    /**
     * @description Get loan payemnt by id
     *
     * @tags Admin Loan Payments
     * @name AdminGetLoanPayment
     * @summary Get loan payment by id
     * @request GET:/admin/loans/payments/{loanPaymentId}
     * @secure
     */
    adminGetLoanPayment: (loanPaymentId: string, params: RequestParams = {}) =>
      this.request<LoanPayment, ApiErrorResponse>({
        path: `/admin/loans/payments/${loanPaymentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Update loan payment by id, allowed to change payment date and principal amount. Recalculation of future payments is possible.
     *
     * @tags Admin Loan Payments
     * @name UpdateLoan1
     * @summary Update loan payment
     * @request PUT:/admin/loans/payments/{loanPaymentId}
     * @secure
     */
    updateLoan1: (
      loanPaymentId: string,
      data: LoanPayment,
      params: RequestParams = {}
    ) =>
      this.request<LoanPayment, ApiErrorResponse>({
        path: `/admin/loans/payments/${loanPaymentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get margin call by id
     *
     * @tags Admin Margin Calls
     * @name AdminGetLoanPayment1
     * @summary Get margin call
     * @request GET:/admin/loans/margin-calls/{marginCallId}
     * @secure
     */
    adminGetLoanPayment1: (marginCallId: string, params: RequestParams = {}) =>
      this.request<MarginCall, ApiErrorResponse>({
        path: `/admin/loans/margin-calls/${marginCallId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Update margin call by id. Allows to update margin call status.
     *
     * @tags Admin Margin Calls
     * @name UpdateLoan2
     * @summary Update margin call
     * @request PUT:/admin/loans/margin-calls/{marginCallId}
     * @secure
     */
    updateLoan2: (
      marginCallId: string,
      data: MarginCall,
      params: RequestParams = {}
    ) =>
      this.request<MarginCall, ApiErrorResponse>({
        path: `/admin/loans/margin-calls/${marginCallId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get loan by id
     *
     * @tags Admin Loans
     * @name AdminGetLoan
     * @summary Get loan by id
     * @request GET:/admin/loans/loans/{loanId}
     * @secure
     */
    adminGetLoan: (loanId: string, params: RequestParams = {}) =>
      this.request<Loan, ApiErrorResponse>({
        path: `/admin/loans/loans/${loanId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Update loan by id
     *
     * @tags Admin Loans
     * @name UpdateLoan3
     * @summary Update loan
     * @request PUT:/admin/loans/loans/{loanId}
     * @secure
     */
    updateLoan3: (loanId: string, data: Loan, params: RequestParams = {}) =>
      this.request<Loan, ApiErrorResponse>({
        path: `/admin/loans/loans/${loanId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get loan offer by id
     *
     * @tags Admin Loans Offers
     * @name AdminGetLoanOffer
     * @summary Get loan offer by id
     * @request GET:/admin/loans/loan-offer/{loanOfferId}
     * @secure
     */
    adminGetLoanOffer: (loanOfferId: string, params: RequestParams = {}) =>
      this.request<LoanOffer, ApiErrorResponse>({
        path: `/admin/loans/loan-offer/${loanOfferId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update loan offer by id. If `dryRun` is `true`, only return updated loan offer and affected loans without saving. If `dryRun` is `false`, loan offer updated. And if `forceUpdate` is `true`, loans in `forceUpdateLoans` is updated. Following fields in loan are updated: - interest rate - fee points - min health rate - margin call rate - liquidation rate - payment plan These updates can cause recalculation of payment plan and loan status. Including Margin Call and Liquidation.
     *
     * @tags Admin Loans Offers
     * @name AdminUpdateLoanOffer
     * @summary Update loan offer
     * @request PUT:/admin/loans/loan-offer/{loanOfferId}
     * @secure
     */
    adminUpdateLoanOffer: (
      loanOfferId: string,
      data: LoanOffer,
      query?: {
        /** @default false */
        dryRun?: boolean;
        /** @default false */
        forceUpdate?: boolean;
        forceUpdateLoans?: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<AdminLoanOfferUpdate, ApiErrorResponse>({
        path: `/admin/loans/loan-offer/${loanOfferId}`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Force execute loan payment by id. Transfer principal and fee payments from requested account.
     *
     * @tags Admin Loan Payments
     * @name AdminLoanPaymentExecute
     * @summary Execute payment by id
     * @request POST:/admin/loans/payments/{loanPaymentId}/execute
     * @secure
     */
    adminLoanPaymentExecute: (
      loanPaymentId: string,
      query: {
        accountId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<LoanPayment, ApiErrorResponse>({
        path: `/admin/loans/payments/${loanPaymentId}/execute`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Execute loan by id, mark loan as LENT. Transfer principal and collateral to corresponding accounts.
     *
     * @tags Admin Loans
     * @name AdminLoanExecute
     * @summary Execute loan
     * @request POST:/admin/loans/loans/{loanId}/execute
     * @secure
     */
    adminLoanExecute: (loanId: string, params: RequestParams = {}) =>
      this.request<Loan, ApiErrorResponse>({
        path: `/admin/loans/loans/${loanId}/execute`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all loan offers
     *
     * @tags Admin Loans Offers
     * @name AdminGetLoanOffers
     * @summary Get all loan offers
     * @request GET:/admin/loans/loan-offer
     * @secure
     */
    adminGetLoanOffers: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseLoanOffer, ApiErrorResponse>({
        path: `/admin/loans/loan-offer`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create new loan offer
     *
     * @tags Admin Loans Offers
     * @name AdminCreateLoanOffer
     * @summary Create loan offer
     * @request POST:/admin/loans/loan-offer
     * @secure
     */
    adminCreateLoanOffer: (data: LoanOffer, params: RequestParams = {}) =>
      this.request<LoanOffer, ApiErrorResponse>({
        path: `/admin/loans/loan-offer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all payments
     *
     * @tags Admin Loan Payments
     * @name AdminGetLoanPayments
     * @summary Get all payments
     * @request GET:/admin/loans/payments
     * @secure
     */
    adminGetLoanPayments: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @format uuid */
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseLoanPayment, ApiErrorResponse>({
        path: `/admin/loans/payments`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get history Loan Payment by id
     *
     * @tags Admin Loan Payments
     * @name GetLoanPaymentAuditHistory
     * @summary Get history of Loan Payment
     * @request GET:/admin/loans/payments/{loanPaymentId}/history
     * @secure
     */
    getLoanPaymentAuditHistory: (
      loanPaymentId: string,
      params: RequestParams = {}
    ) =>
      this.request<RevisionsIntegerLoanPayment, ApiErrorResponse>({
        path: `/admin/loans/payments/${loanPaymentId}/history`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all margin calls
     *
     * @tags Admin Margin Calls
     * @name AdminGetLoanPayments1
     * @summary Get all margin calls
     * @request GET:/admin/loans/margin-calls
     * @secure
     */
    adminGetLoanPayments1: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @format uuid */
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseMarginCall, ApiErrorResponse>({
        path: `/admin/loans/margin-calls`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get history Loan Margin Call by id
     *
     * @tags Admin Margin Calls
     * @name GetLoanPaymentAuditHistory1
     * @summary Get history of Loan Margin Call
     * @request GET:/admin/loans/margin-calls/{marginCallId}/history
     * @secure
     */
    getLoanPaymentAuditHistory1: (
      marginCallId: string,
      params: RequestParams = {}
    ) =>
      this.request<RevisionsIntegerMarginCall, ApiErrorResponse>({
        path: `/admin/loans/margin-calls/${marginCallId}/history`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all loan
     *
     * @tags Admin Loans
     * @name AdminGetLoans
     * @summary Get all loans
     * @request GET:/admin/loans/loans
     * @secure
     */
    adminGetLoans: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @format uuid */
        userId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseLoan, ApiErrorResponse>({
        path: `/admin/loans/loans`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get current ltv for loan by id
     *
     * @tags Admin Loans
     * @name GetLoanLtv1
     * @summary Get current ltv for loan
     * @request GET:/admin/loans/loans/{loanId}/ltv
     * @secure
     */
    getLoanLtv1: (loanId: string, params: RequestParams = {}) =>
      this.request<LoanLtv, ApiErrorResponse>({
        path: `/admin/loans/loans/${loanId}/ltv`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get history Loan by id
     *
     * @tags Admin Loans
     * @name GetLoanAuditHistory
     * @summary Get history of Loan
     * @request GET:/admin/loans/loans/{loanId}/history
     * @secure
     */
    getLoanAuditHistory: (loanId: string, params: RequestParams = {}) =>
      this.request<RevisionsIntegerLoan, ApiErrorResponse>({
        path: `/admin/loans/loans/${loanId}/history`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get active fee point with additional info
     *
     * @tags Admin Loans
     * @name GetLoanActiveFeePoint1
     * @summary Get active fee point
     * @request GET:/admin/loans/loans/{loanId}/fee-point/active
     * @secure
     */
    getLoanActiveFeePoint1: (loanId: string, params: RequestParams = {}) =>
      this.request<FeePointRecord, ApiErrorResponse>({
        path: `/admin/loans/loans/${loanId}/fee-point/active`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get audited history of change of loan offer by id
     *
     * @tags Admin Loans Offers
     * @name GetLoanAuditHistory1
     * @summary Get history offer Loan Offer
     * @request GET:/admin/loans/loan-offer/{loanOfferId}/history
     * @secure
     */
    getLoanAuditHistory1: (loanOfferId: string, params: RequestParams = {}) =>
      this.request<RevisionsIntegerLoanConfig, ApiErrorResponse>({
        path: `/admin/loans/loan-offer/${loanOfferId}/history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
