/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

/** Object for merchant, invoice info */
export type BaseInvoiceDto = BaseBaseInvoiceDto &
  (
    | BaseBaseInvoiceDtoTypeMapping<'RECURRENT', RecurrentInvoiceDto>
    | BaseBaseInvoiceDtoTypeMapping<'ORIGINAL', OriginalInvoiceDto>
  );

/** Object for merchant, info for invoice creation */
export type BaseInvoiceMerchantRequest = BaseBaseInvoiceMerchantRequest &
  BaseBaseInvoiceMerchantRequestTypeMapping<'RECURRENT', InvoiceMerchantSubscriptionRequest>;

/** invoice payment info */
export type BaseInvoicePaymentDto = BaseBaseInvoicePaymentDto &
  (
    | BaseBaseInvoicePaymentDtoTypeMapping<'PLATFORM', PlatformInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'EXTERNAL', ExternalInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'CHECKOUT', CheckoutInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'STRIPE', StripeInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'INWIZO', InwizoInvoicePaymentDto>
    | BaseBaseInvoicePaymentDtoTypeMapping<'ITEZ', ItezInvoicePaymentDto>
  );

export type BaseInvoicePayRequest = BaseBaseInvoicePayRequest &
  (
    | BaseBaseInvoicePayRequestTypeMapping<'PLATFORM', InvoicePlatformPayRequest>
    | BaseBaseInvoicePayRequestTypeMapping<'CHECKOUT', InvoiceCheckoutPayRequest>
    | BaseBaseInvoicePayRequestTypeMapping<'EXTERNAL', InvoiceExternalPayRequest>
  );

export interface ApiError {
  key?: string;
  description: string;
}

export interface ApiErrorResponse {
  status: HttpStatus;
  message: string;
  traceId: string;
  errors?: ApiError[];
}

export type CheckoutInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  /** checkout session ret value */
  session?: JsonNode;
};

/** country code for invoice billing */
export enum CountryCode {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AC = 'AC',
  AU = 'AU',
  AQ = 'AQ',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  VG = 'VG',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  TW = 'TW',
  CX = 'CX',
  PF = 'PF',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  SZ = 'SZ',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  AN = 'AN',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TA = 'TA',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  VI = 'VI',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VA = 'VA',
  VE = 'VE',
  VN = 'VN',
  UM = 'UM',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
  PS = 'PS',
  QZ = 'QZ',
  XK = 'XK',
}

export type ExternalInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'>;

export enum HttpStatus {
  Value100CONTINUE = '100 CONTINUE',
  Value101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  Value102PROCESSING = '102 PROCESSING',
  Value103EARLYHINTS = '103 EARLY_HINTS',
  Value103CHECKPOINT = '103 CHECKPOINT',
  Value200OK = '200 OK',
  Value201CREATED = '201 CREATED',
  Value202ACCEPTED = '202 ACCEPTED',
  Value203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  Value204NOCONTENT = '204 NO_CONTENT',
  Value205RESETCONTENT = '205 RESET_CONTENT',
  Value206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  Value207MULTISTATUS = '207 MULTI_STATUS',
  Value208ALREADYREPORTED = '208 ALREADY_REPORTED',
  Value226IMUSED = '226 IM_USED',
  Value300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  Value301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  Value302FOUND = '302 FOUND',
  Value302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  Value303SEEOTHER = '303 SEE_OTHER',
  Value304NOTMODIFIED = '304 NOT_MODIFIED',
  Value305USEPROXY = '305 USE_PROXY',
  Value307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  Value308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  Value400BADREQUEST = '400 BAD_REQUEST',
  Value401UNAUTHORIZED = '401 UNAUTHORIZED',
  Value402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  Value403FORBIDDEN = '403 FORBIDDEN',
  Value404NOTFOUND = '404 NOT_FOUND',
  Value405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  Value406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  Value407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  Value408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  Value409CONFLICT = '409 CONFLICT',
  Value410GONE = '410 GONE',
  Value411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  Value412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  Value413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  Value413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  Value414URITOOLONG = '414 URI_TOO_LONG',
  Value414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  Value415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  Value416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  Value417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  Value418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  Value419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  Value420METHODFAILURE = '420 METHOD_FAILURE',
  Value421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  Value422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  Value423LOCKED = '423 LOCKED',
  Value424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  Value425TOOEARLY = '425 TOO_EARLY',
  Value426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  Value428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  Value429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  Value431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  Value451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  Value500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  Value501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  Value502BADGATEWAY = '502 BAD_GATEWAY',
  Value503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  Value504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  Value505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  Value506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  Value507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  Value508LOOPDETECTED = '508 LOOP_DETECTED',
  Value509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  Value510NOTEXTENDED = '510 NOT_EXTENDED',
  Value511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

export type InvoiceCheckoutPayRequest = UtilRequiredKeys<BaseBaseInvoicePayRequest, 'invoiceId' | 'type'>;

export interface InvoiceEntity {
  id?: string;
  clientId?: string;
  invoiceType?: string;
  description?: string;
  transactionId: string;
  amount: number;
  currency: string;
  redirectUrl?: string;
  callbackUrl?: string;
  status?: string;
  /** @format int32 */
  regId?: number;
  merchant: Merchant;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format date-time */
  createdDate?: string;
  feeAmount: number;
}

export type InvoiceExternalPayRequest = UtilRequiredKeys<BaseBaseInvoicePayRequest, 'invoiceId' | 'type'> & {
  someData: string;
};

/** Object for merchant, with info about new invoice */
export interface InvoiceMerchantResponse {
  /** @format int32 */
  result: number;
  desc: string;
  id?: string;
  payUrl?: string;
  transactionId?: string;
}

export interface InvoiceMerchantStatusResponse {
  id?: string;
  /** @format int64 */
  creationTimestamp?: number;
  payments?: InvoicePayment[];
  /** Status of current invoice different for sub types */
  status?: InvoiceStatus;
  /**
   * Amount expected to be deposited
   * @format number
   */
  expectedDeposit?: string;
  /** @format int64 */
  addressDueTimestamp?: number;
}

export type InvoiceMerchantSubscriptionRequest = BaseBaseInvoiceMerchantRequest & {
  /** internal payment account id (if null -- probably external payment) */
  accountId?: string;
  /** merchant's subscription id */
  subscriptionId: string;
  /** interval of recurrent invoice */
  recurrentInvoiceInterval: RecurrentInvoiceInterval;
  /**
   * next payment date in long format
   * @format int64
   */
  nextPaymentDate?: number;
  /** status of recurrent invoice */
  status: RecurrentInvoiceStatus;
  /**
   * initial payment amount
   * @example 0
   */
  initialPaymentAmount: number;
};

export interface InvoicePayment {
  id?: string;
  invoice: OriginalInvoice;
  /** payment status */
  status: PaymentStatus;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  paymentType?: string;
}

/** Invoice payment provider type */
export enum InvoicePaymentProviderType {
  PLATFORM = 'PLATFORM',
  CHECKOUT = 'CHECKOUT',
  INWIZO = 'INWIZO',
  ITEZ = 'ITEZ',
  STRIPE = 'STRIPE',
  EXTERNAL = 'EXTERNAL',
}

export type InvoicePlatformPayRequest = UtilRequiredKeys<BaseBaseInvoicePayRequest, 'invoiceId' | 'type'> & {
  /**
   * Internal account id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  accountId: string;
};

/** Status of current invoice different for sub types */
export enum InvoiceStatus {
  INIT = 'INIT',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
}

export enum InvoiceType {
  ORIGINAL = 'ORIGINAL',
  RECURRENT = 'RECURRENT',
}

export type InwizoInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  someData?: string;
};

export type ItezInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  someData?: string;
};

/** checkout session ret value */
export type JsonNode = object;

export interface Merchant {
  id?: string;
  name: string;
  callbackUrl?: string;
  paymentUrl?: string;
  successUrl?: string;
  failedUrl?: string;
  autoConvertToEupi: boolean;
  merchantAccount: string;
  invoiceFeeRate: number;
  recurrentInvoiceFeeRate: number;
  partner: Partner;
  billingName: string;
  billingCountry: string;
  billingCity: string;
}

export interface MerchantCreateDTO {
  /** Id, which will be encrypted in token */
  id: string;
  /** Merchant name */
  name: string;
  /** Url for callbacks (notifications about invoice status update) */
  callbackUrl: string;
  /** Url with address which will be generate for any invoice payment of current merchant */
  paymentUrl: string;
  autoConvertToEupi: boolean;
  /** Internal account for fee */
  merchantAccount: string;
  /** Default rate for all merchant's invoices */
  invoiceFeeRate: number;
  /** Default rate for all merchant's recurrent invoices */
  recurrentInvoiceFeeRate: number;
  /** partner's id */
  partner: string;
  /** name for invoice billing */
  billingName: string;
  /** city for invoice billing */
  billingCity: string;
  /** country code for invoice billing */
  billingCountry: CountryCode;
}

export interface MerchantDTO {
  id?: string;
  name?: string;
  paymentUrl?: string;
  callbackUrl?: string;
  autoConvertToEupi?: boolean;
  partner?: string;
}

export interface OriginalInvoice {
  invoice?: InvoiceEntity;
  id?: string;
  clientId?: string;
  invoiceType?: string;
  description?: string;
  transactionId: string;
  amount: number;
  currency: string;
  redirectUrl?: string;
  callbackUrl?: string;
  status?: string;
  /** @format int32 */
  regId?: number;
  merchant: Merchant;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format date-time */
  createdDate?: string;
  feeAmount: number;
  recurrentInvoice?: RecurrentInvoice;
  payments?: InvoicePayment[];
  discountCode?: string;
  amountBeforeDiscount?: number;
}

export type OriginalInvoiceDto = UtilRequiredKeys<BaseBaseInvoiceDto, 'amount' | 'currency' | 'type'> & {
  /** Status of current invoice different for sub types */
  status?: InvoiceStatus;
  /** List of payments */
  payments?: (
    | CheckoutInvoicePaymentDto
    | ExternalInvoicePaymentDto
    | InwizoInvoicePaymentDto
    | ItezInvoicePaymentDto
    | PlatformInvoicePaymentDto
    | StripeInvoicePaymentDto
  )[];
  /** Discount code, that was applied to this invoice */
  discountCode?: string;
  /** Base invoice amount, which shows that discount was applied */
  AmountBeforeDiscount?: number;
};

export interface PageableResponseBaseInvoiceDto {
  data: (OriginalInvoiceDto | RecurrentInvoiceDto)[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface Partner {
  /** @format int32 */
  id?: number;
  name: string;
  desc?: string;
  code: string;
  clientId: string;
  clientSecret: string;
}

export interface PartnerCreateDTO {
  name: string;
  desc: string;
  code: string;
  commissionPercent: number;
  clientId: string;
  clientSecret: string;
}

export interface PartnerDTO {
  /** @format int32 */
  id?: number;
  name?: string;
  desc?: string;
  code?: string;
  clientId?: string;
  clientSecret?: string;
}

/** payment status */
export enum PaymentStatus {
  INIT = 'INIT',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITHOUT_FEE = 'COMPLETED_WITHOUT_FEE',
  FAILED = 'FAILED',
}

export type PlatformInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  /** internal account for payment */
  accountId?: string;
};

export interface RecurrentInvoice {
  invoice?: InvoiceEntity;
  id?: string;
  clientId?: string;
  invoiceType?: string;
  description?: string;
  transactionId: string;
  amount: number;
  currency: string;
  redirectUrl?: string;
  callbackUrl?: string;
  status?: string;
  /** @format int32 */
  regId?: number;
  merchant: Merchant;
  /** @format date-time */
  lastModifiedDate?: string;
  /** @format date-time */
  createdDate?: string;
  feeAmount: number;
  initialPaymentAmount?: number;
  /** interval of recurrent invoice */
  recurrentInvoiceInterval?: RecurrentInvoiceInterval;
  /** @format date-time */
  nextPaymentDate?: string;
  /** @format date-time */
  lastPaymentDate?: string;
  paymentAccountId?: string;
  invoices: OriginalInvoice[];
}

export type RecurrentInvoiceDto = UtilRequiredKeys<BaseBaseInvoiceDto, 'amount' | 'currency' | 'type'> & {
  /**
   * Date of the next payment for this recurrent invoice
   * @format int64
   */
  nextPaymentDate?: number;
  /**
   * Amount of first payment for this recurrent invoice
   * @example 0
   */
  initialPaymentAmount: number;
  /** status of recurrent invoice */
  status?: RecurrentInvoiceStatus;
  /** List of linked invoices for payment. */
  linkedInvoices: OriginalInvoiceDto[];
  /** interval of recurrent invoice */
  recurrentInvoiceInterval: RecurrentInvoiceInterval;
};

/** interval of recurrent invoice */
export enum RecurrentInvoiceInterval {
  DAYS30 = 'DAYS30',
  DAYS365 = 'DAYS365',
}

/** status of recurrent invoice */
export enum RecurrentInvoiceStatus {
  INIT = 'INIT',
  TRIAL = 'TRIAL',
  ACTIVE = 'ACTIVE',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  CANCELLED = 'CANCELLED',
  DEACTIVATING = 'DEACTIVATING',
}

export type StripeInvoicePaymentDto = UtilRequiredKeys<BaseBaseInvoicePaymentDto, 'invoiceId' | 'type'> & {
  someData?: string;
};

/** Object for merchant, invoice info */
interface BaseBaseInvoiceDto {
  /**
   * field for client_id in external system
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  externalClientId?: string;
  /**
   * invoice id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  /**
   * Amount required to complete invoice
   * @example 0
   */
  amount: number;
  /**
   * Currency slug in internal format
   * @example "EUR"
   */
  currency: string;
  /**
   * Date of the payment of this invoice
   * @format int64
   */
  paymentDate?: number;
  /** type of invoice */
  type: string;
}

type BaseBaseInvoiceDtoTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Object for merchant, info for invoice creation */
interface BaseBaseInvoiceMerchantRequest {
  /** External client id */
  clientId?: string;
  /** External transaction id */
  transactionId?: string;
  /** External description of invoice */
  desc?: string;
  /**
   * Amount of invoice
   * @format number
   */
  amount?: string;
  /** Currency of invoice */
  currency?: string;
  /** URL for redirect after payment */
  redirectUrl?: string;
  /** URL for send payment status if he changes */
  callbackUrl?: string;
  /**
   * InvoiceMerchantRequest type
   * @default "ORIGINAL"
   */
  type?: string;
}

type BaseBaseInvoiceMerchantRequestTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** invoice payment info */
interface BaseBaseInvoicePaymentDto {
  /** id of invoice payment */
  id?: string;
  invoiceId: string;
  /** payment status */
  status?: PaymentStatus;
  /** type of payment */
  type: string;
}

type BaseBaseInvoicePaymentDtoTypeMapping<Key, Type> = {
  type: Key;
} & Type;

interface BaseBaseInvoicePayRequest {
  /**
   * payment id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  paymentId?: string;
  /**
   * Internal invoice id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  invoiceId: string;
  /** Discount redeem code */
  discountCode?: string;
  /** Invoice payment provider type */
  type: InvoicePaymentProviderType;
}

type BaseBaseInvoicePayRequestTypeMapping<Key, Type> = {
  type: Key;
} & Type;

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.stg.darknet.piefi.app/acquiring',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Acquiring
 * @version 0.3.0-f9304874
 * @baseUrl https://api.stg.darknet.piefi.app/acquiring
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  merchant = {
    /**
     * @description Set payment account, available for merchant with
     *
     * @tags merchant
     * @name UpdateRecurrentInvoicePaymentAccount
     * @summary Update payment account of recurrent invoice
     * @request PUT:/merchant/invoice/recurrent/{invoiceId}/payment/account
     * @secure
     */
    updateRecurrentInvoicePaymentAccount: (
      invoiceId: string,
      query: {
        accountId: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponse>({
        path: `/merchant/invoice/recurrent/${invoiceId}/payment/account`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description If Success returns Invoice id and pay url
     *
     * @tags merchant
     * @name Invoice
     * @summary Allow merchant to create new invoices
     * @request POST:/merchant/invoice
     * @secure
     */
    invoice: (data: BaseInvoiceMerchantRequest | InvoiceMerchantSubscriptionRequest, params: RequestParams = {}) =>
      this.request<InvoiceMerchantResponse, ApiErrorResponse>({
        path: `/merchant/invoice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description invoice info for merchant
     *
     * @tags merchant
     * @name GetInvoice
     * @summary Get invoice object
     * @request GET:/merchant/invoice/{invoiceId}
     * @secure
     */
    getInvoice: (invoiceId: string, params: RequestParams = {}) =>
      this.request<OriginalInvoiceDto | RecurrentInvoiceDto, ApiErrorResponse>({
        path: `/merchant/invoice/${invoiceId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description If Success returns 200
     *
     * @tags merchant
     * @name RevokeInvoice
     * @summary Allow merchant to revoke invoice
     * @request DELETE:/merchant/invoice/{invoiceId}
     * @secure
     */
    revokeInvoice: (invoiceId: string, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/merchant/invoice/${invoiceId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description If Success return InvoiceStatus object
     *
     * @tags merchant
     * @name Status
     * @summary Allow merchant to get info about invoice by Id
     * @request GET:/merchant/invoice/{invoiceId}/status
     * @secure
     */
    status: (invoiceId: string, params: RequestParams = {}) =>
      this.request<InvoiceMerchantStatusResponse, ApiErrorResponse>({
        path: `/merchant/invoice/${invoiceId}/status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description If Success returns InvoiceStatus object and sends callback
     *
     * @tags merchant
     * @name SendCallback
     * @summary Allow merchant to force send callback
     * @request GET:/merchant/invoice/{invoiceId}/sendCallback
     * @secure
     */
    sendCallback: (invoiceId: string, params: RequestParams = {}) =>
      this.request<InvoiceMerchantStatusResponse, ApiErrorResponse>({
        path: `/merchant/invoice/${invoiceId}/sendCallback`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  invoicePaymentController = {
    /**
     * No description
     *
     * @tags InvoicePaymentController
     * @name UpdatePayment
     * @summary Invoice payment update
     * @request PUT:/invoice/payment
     * @secure
     */
    updatePayment: (
      data: InvoiceCheckoutPayRequest | InvoiceExternalPayRequest | InvoicePlatformPayRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        | CheckoutInvoicePaymentDto
        | ExternalInvoicePaymentDto
        | InwizoInvoicePaymentDto
        | ItezInvoicePaymentDto
        | PlatformInvoicePaymentDto
        | StripeInvoicePaymentDto,
        ApiErrorResponse
      >({
        path: `/invoice/payment`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoicePaymentController
     * @name PaymentGetOrCreate
     * @summary Invoice payment get or create
     * @request POST:/invoice/payment
     * @secure
     */
    paymentGetOrCreate: (
      data: InvoiceCheckoutPayRequest | InvoiceExternalPayRequest | InvoicePlatformPayRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        | CheckoutInvoicePaymentDto
        | ExternalInvoicePaymentDto
        | InwizoInvoicePaymentDto
        | ItezInvoicePaymentDto
        | PlatformInvoicePaymentDto
        | StripeInvoicePaymentDto,
        ApiErrorResponse
      >({
        path: `/invoice/payment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoicePaymentController
     * @name Pay
     * @summary Invoice payment confirm
     * @request POST:/invoice/payment/pay
     * @secure
     */
    pay: (
      data: InvoiceCheckoutPayRequest | InvoiceExternalPayRequest | InvoicePlatformPayRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        | CheckoutInvoicePaymentDto
        | ExternalInvoicePaymentDto
        | InwizoInvoicePaymentDto
        | ItezInvoicePaymentDto
        | PlatformInvoicePaymentDto
        | StripeInvoicePaymentDto,
        ApiErrorResponse
      >({
        path: `/invoice/payment/pay`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  adminMerchantController = {
    /**
     * No description
     *
     * @tags admin-merchant-controller
     * @name UpdateMerchant
     * @summary admin update merchant
     * @request PUT:/admin/merchant/{merchantId}/
     * @secure
     */
    updateMerchant: (merchantId: string, data: MerchantDTO, params: RequestParams = {}) =>
      this.request<MerchantDTO, ApiErrorResponse>({
        path: `/admin/merchant/${merchantId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-merchant-controller
     * @name DeleteMerchant
     * @summary admin delete merchant
     * @request DELETE:/admin/merchant/{merchantId}/
     * @secure
     */
    deleteMerchant: (merchantId: string, params: RequestParams = {}) =>
      this.request<string, ApiErrorResponse>({
        path: `/admin/merchant/${merchantId}/`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-merchant-controller
     * @name ListMerchant1
     * @summary admin get merchant
     * @request GET:/admin/merchant
     * @secure
     */
    listMerchant1: (params: RequestParams = {}) =>
      this.request<MerchantDTO[], ApiErrorResponse>({
        path: `/admin/merchant`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin-merchant-controller
     * @name CreateMerchant
     * @summary admin create merchant
     * @request POST:/admin/merchant
     * @secure
     */
    createMerchant: (data: MerchantCreateDTO, params: RequestParams = {}) =>
      this.request<MerchantDTO, ApiErrorResponse>({
        path: `/admin/merchant`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  paymentWebhookController = {
    /**
     * No description
     *
     * @tags PaymentWebhookController
     * @name PlatformWebhook
     * @summary Invoice platform payment webhook
     * @request POST:/payment/webhook/platform
     * @secure
     */
    platformWebhook: (data: string, params: RequestParams = {}) =>
      this.request<string, ApiErrorResponse>({
        path: `/payment/webhook/platform`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentWebhookController
     * @name ExternalWebhook
     * @summary Invoice external payment webhook
     * @request POST:/payment/webhook/external
     * @secure
     */
    externalWebhook: (data: string, params: RequestParams = {}) =>
      this.request<string, ApiErrorResponse>({
        path: `/payment/webhook/external`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentWebhookController
     * @name CheckoutSuccessRedirect
     * @summary Checkout successful redirect
     * @request POST:/payment/checkout/redirect/success
     */
    checkoutSuccessRedirect: (
      query: {
        'cko-payment-session-id': string;
        'cko-session-id': string;
        'cko-payment-id': string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, ApiErrorResponse>({
        path: `/payment/checkout/redirect/success`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentWebhookController
     * @name CheckoutFailedRedirect
     * @summary Checkout failed redirect
     * @request POST:/payment/checkout/redirect/failed
     */
    checkoutFailedRedirect: (
      query: {
        'cko-payment-session-id': string;
        'cko-session-id': string;
        'cko-payment-id': string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, ApiErrorResponse>({
        path: `/payment/checkout/redirect/failed`,
        method: 'POST',
        query: query,
        ...params,
      }),
  };
  adminPartnerController = {
    /**
     * @description return list of actual partners in acquiring service
     *
     * @tags admin-partner-controller
     * @name ListMerchant
     * @summary admin get partners controller
     * @request GET:/admin/partner
     * @secure
     */
    listMerchant: (params: RequestParams = {}) =>
      this.request<PartnerDTO[], ApiErrorResponse>({
        path: `/admin/partner`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description return list of actual partners in acquiring service
     *
     * @tags admin-partner-controller
     * @name CreatePartner
     * @summary admin create partner controller
     * @request POST:/admin/partner
     * @secure
     */
    createPartner: (data: PartnerCreateDTO, params: RequestParams = {}) =>
      this.request<PartnerDTO, ApiErrorResponse>({
        path: `/admin/partner`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  adminInvoices = {
    /**
     * @description Create payment and pay invoice by admin with direct transfer
     *
     * @tags admin invoices
     * @name AdminPayInvoice
     * @summary admin pay invoice
     * @request POST:/admin/invoice/pay
     * @secure
     */
    adminPayInvoice: (data: any, params: RequestParams = {}) =>
      this.request<
        | CheckoutInvoicePaymentDto
        | ExternalInvoicePaymentDto
        | InwizoInvoicePaymentDto
        | ItezInvoicePaymentDto
        | PlatformInvoicePaymentDto
        | StripeInvoicePaymentDto,
        ApiErrorResponse
      >({
        path: `/admin/invoice/pay`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get pageable invoices.
     *
     * @tags admin invoices
     * @name AdminGetInvoices
     * @summary admin get invoices
     * @request GET:/admin/invoice
     * @secure
     */
    adminGetInvoices: (
      query?: {
        status?: string;
        type?: InvoiceType;
        search?: string;
        userId?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBaseInvoiceDto, ApiErrorResponse>({
        path: `/admin/invoice`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  itezController = {
    /**
     * No description
     *
     * @tags itez-controller
     * @name CreateWidget
     * @request GET:/itez/
     * @deprecated
     */
    createWidget: (
      query: {
        accountId: string;
        email: string;
        fromAmount?: number;
        toAmount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, ApiErrorResponse>({
        path: `/itez/`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  invoice = {
    /**
     * @description return invoices linked to user
     *
     * @tags invoice
     * @name GetInvoices
     * @summary Get User Invoices
     * @request GET:/invoice
     * @secure
     */
    getInvoices: (
      query?: {
        status?: string;
        type?: InvoiceType;
        search?: string;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseBaseInvoiceDto, ApiErrorResponse>({
        path: `/invoice`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description return invoice if it's available to user
     *
     * @tags invoice
     * @name GetInvoiceById
     * @summary Get Invoice by id
     * @request GET:/invoice/{id}
     * @secure
     */
    getInvoiceById: (id: string, params: RequestParams = {}) =>
      this.request<OriginalInvoiceDto | RecurrentInvoiceDto, ApiErrorResponse>({
        path: `/invoice/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
}
