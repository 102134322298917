/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { TableProps } from 'antd';
import Table from '../core/table';
import { BaseInvoiceModel } from 'models/InvoicesModel';
import { bigNumberToStr } from 'utils/bignumber';
import { formattedDate } from 'utils/dates';
import { RecurrentInvoiceInterval } from '../../api/acquiring';

type Props = {
  collection: CollectionWithPages<BaseInvoiceModel, {}>;
  onRowClick?: (item: BaseInvoiceModel) => void;
};

const InvoicesTable = observer(({ collection, onRowClick }: Props) => {
  const intl = useIntl();

  const columns: TableProps<BaseInvoiceModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.invoices.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{id}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.invoices.col.type',
        defaultMessage: 'Type',
      }),
      dataIndex: 'type',
      key: 'type',
      render: (type) => <span>{type}</span>,
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.invoices.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <span>{status}</span>,
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.invoices.col.amount',
        defaultMessage: 'Amount',
      }),
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => (
        <span>
          {bigNumberToStr(record.amount)} {record.currency}
        </span>
      ),
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.invoices.col.initialPaymentAmount',
        defaultMessage: 'Initial Payment Amount',
      }),
      dataIndex: 'initialPaymentAmount',
      key: 'initialPaymentAmount',
      render: (initialPaymentAmount) => (
        <span>
          {initialPaymentAmount ? bigNumberToStr(initialPaymentAmount) : ''}
        </span>
      ),
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.invoices.col.paymentDate',
        defaultMessage: 'Payment Date',
      }),
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (paymentDate: string) => (
        <span>{paymentDate ? formattedDate(paymentDate) : ''}</span>
      ),
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.invoices.col.nextPaymentDate',
        defaultMessage: 'Next Payment Date',
      }),
      dataIndex: 'nextPaymentDate',
      key: 'nextPaymentDate',
      render: (nextPaymentDate: string) => (
        <span>{nextPaymentDate ? formattedDate(nextPaymentDate) : ''}</span>
      ),
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.invoices.col.recurrentInvoiceInterval',
        defaultMessage: 'Recurrent Invoice Interval',
      }),
      dataIndex: 'recurrentInvoiceInterval',
      key: 'recurrentInvoiceInterval',
      render: (recurrentInvoiceInterval: RecurrentInvoiceInterval) => (
        <span>{recurrentInvoiceInterval}</span>
      ),
      width: 30,
    },
  ];

  return (
    <Table<BaseInvoiceModel, {}>
      collection={collection}
      columns={columns}
      onRowClick={onRowClick}
    />
  );
});

export default InvoicesTable;
