/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AccountType, AdminAccount, AdminAccountUser } from 'api/wallet';

export { VerificationStatus as UserVerificationStatus } from 'api/auth';

export class AdminWalletAccountModel {
  constructor(dto: AdminAccount) {
    this.id = dto.accountId ?? '';
    this.accountId = dto.accountId;
    this.type = dto.type;
    this.users = dto.users;
    this.partnerId = dto.partnerId;
  }

  id: string;
  accountId?: string;
  type?: AccountType;
  users?: AdminAccountUser[];
  /** @format int64 */
  partnerId?: number;
}
