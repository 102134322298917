/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import FormField from 'components/form/form-field';
import Button from 'components/core/button';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { Controller, useForm } from 'react-hook-form';
import { ApiErrorResponse, Individual, IndividualType } from 'api/auth';
import FormSelect from 'components/form/form-select';
import { capitalizeString } from 'utils/strings';

const SidebarIndividualEdit = observer(
  ({
    individual,
    onSubmit,
  }: {
    individual: Individual;
    onSubmit: (form: Individual) => Promise<ApiErrorResponse | undefined>;
  }) => {
    const intl = useIntl();

    const { layoutStore } = useBaseStores();

    const { handleSubmit, control, setError } = useForm<Individual>({
      mode: 'onChange',
      defaultValues: individual,
    });

    const onSubmitForm = useCallback(
      async (form: Individual) => {
        const result = await onSubmit(form);

        if (result && 'errors' in result && Array.isArray(result.errors)) {
          result.errors.forEach(({ key, description }) => {
            setError(key as any, {
              type: 'custom',
              message: description,
            });
          });

          return;
        }

        layoutStore.toggleSidebar(false);
      },
      [layoutStore, onSubmit, setError]
    );

    const options = Object.values(IndividualType).map((item) => ({
      id: item,
      label: capitalizeString(item).replaceAll('_', ' '),
      value: item,
    }));

    return (
      <div className={styles.main}>
        <Controller
          control={control}
          name='title'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.title',
                defaultMessage: 'Title',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.title.placeholder',
                defaultMessage: 'Enter title...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
        <div className={styles.two_fields}>
          <Controller
            control={control}
            name='firstName'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.first_name',
                  defaultMessage: 'First name',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.first_name.placeholder',
                  defaultMessage: 'Enter first name...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='lastName'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.lastName',
                  defaultMessage: 'Last Name',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.lastName.placeholder',
                  defaultMessage: 'Enter last bame...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='middleName'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.middleName',
                  defaultMessage: 'Middle Name',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.middleName.placeholder',
                  defaultMessage: 'Enter middle name...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='nationality'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.nationality',
                  defaultMessage: 'Nationality',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.phone.placeholder',
                  defaultMessage: 'Enter nationality...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='dateOfBirth'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.date_of_birth',
                  defaultMessage: 'Date Of Birth',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.date_of_birth.placeholder',
                  defaultMessage: 'Enter date Of birth...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='emailAddress'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.email',
                  defaultMessage: 'Email',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.email.placeholder',
                  defaultMessage: 'Enter email...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='individualType'
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormSelect
                  label={intl.formatMessage({
                    id: 'sidebar.edit_user.individual_type',
                    defaultMessage: 'Individual type',
                  })}
                  options={options}
                  onChange={(value) => onChange(value?.value as string)}
                  value={options.find((item) => item.value === value)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name='passportNumber'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.passport_number',
                  defaultMessage: 'Passport Number',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.passport_number.placeholder',
                  defaultMessage: 'Enter passport number...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='percentageOwnership'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.percentage_ownership',
                  defaultMessage: 'Percentage Ownership',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.percentage_ownership.placeholder',
                  defaultMessage: 'Enter percentage ownership...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='taxReferenceNumber'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.tax_reference_number',
                  defaultMessage: 'Tax Reference Number',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.tax_reference_number.placeholder',
                  defaultMessage: 'Enter tax reference number...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </div>
        <div className={styles.line} />
        <div className={styles.two_fields}>
          <Controller
            control={control}
            name='mailingAddress.country'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.country',
                  defaultMessage: 'Country',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.country.placeholder',
                  defaultMessage: 'Enter country...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='mailingAddress.state'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.state',
                  defaultMessage: 'State',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.state.placeholder',
                  defaultMessage: 'Enter state...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='mailingAddress.town'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.town',
                  defaultMessage: 'Town',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.town.placeholder',
                  defaultMessage: 'Enter town...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='mailingAddress.street'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.street',
                  defaultMessage: 'Street',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.street.placeholder',
                  defaultMessage: 'Enter street...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='mailingAddress.subStreet'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.sub_street',
                  defaultMessage: 'Sub street',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.sub_street.placeholder',
                  defaultMessage: 'Enter sub street...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='mailingAddress.buildingName'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.building_name',
                  defaultMessage: 'Building Name',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.building_name.placeholder',
                  defaultMessage: 'Enter building name...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='mailingAddress.buildingNumber'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.building_number',
                  defaultMessage: 'Building Number',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.building_number.placeholder',
                  defaultMessage: 'Enter building number...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='mailingAddress.flatNumber'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.flat_number',
                  defaultMessage: 'Flat number',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.flat_number.placeholder',
                  defaultMessage: 'Enter flat number...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='mailingAddress.postCode'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.post_code',
                  defaultMessage: 'Post Code',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.post_code.placeholder',
                  defaultMessage: 'Enter post code...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.actions}>
          <Button variant='primary' onClick={handleSubmit(onSubmitForm)}>
            {intl.formatMessage({
              id: 'sidebar.edit_user.submit',
              defaultMessage: 'Submit',
            })}
          </Button>
        </div>
      </div>
    );
  }
);

export const useSidebarIndividualEdit = ({
  onSubmit,
}: {
  onSubmit: (individual: Individual) => Promise<ApiErrorResponse | undefined>;
}) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(
    (individual: Individual) => {
      layoutStore.toggleSidebar(
        true,
        intl.formatMessage({
          id: 'sidebar.edit_info',
          defaultMessage: 'Company info',
        }),
        <SidebarIndividualEdit individual={individual} onSubmit={onSubmit} />
      );
    },
    [intl, layoutStore, onSubmit]
  );

  return {
    showEditIndividualSidebar: showSidebar,
  };
};
