import { TotalInfo } from '../total';
import { CurrencySelect } from 'components/elements/currency-select';
import { Input } from 'antd';
import { useIntl } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormValues } from '..';
import { observer } from 'mobx-react-lite';
import styles from './index.module.css';
import { useOperationStore } from '../../provider/OperationStoreProvider';
import { useBaseStores } from 'providers/BaseStoresProvider';

export const ExchangeInputs = observer(() => {
  const { currencyStore } = useBaseStores();
  const { accountBalances, exchange } = useOperationStore();
  const { control } = useFormContext<FormValues>();
  const intl = useIntl();

  const selectOptions = accountBalances.from.map((item) => ({
    id: item.currency,
    value: item.currency,
    label: item.currency,
    icon: currencyStore.currenciesMap.get(item.currency)?.iconUrl,
  }));

  const toSelectOptions = accountBalances.to.map((item) => ({
    id: item.currency,
    value: item.currency,
    label: item.currency,
    icon: currencyStore.currenciesMap.get(item.currency)?.iconUrl,
  }));

  const [currency, fromAmount] = useWatch({
    control,
    name: ['fromCurrency', 'fromAmount'],
  });

  const balance = accountBalances.from.find(
    (item) => item.currency === currency
  );

  const preExchangeData = exchange.feeData.value?.data;

  return (
    <div className={styles.box}>
      <span className={styles.title}>
        {intl.formatMessage({
          id: 'sidebar.operation.exchange_amount',
          defaultMessage: 'Exchange amount',
        })}
      </span>

      <div className={styles.card}>
        <div className={styles.column}>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'sidebar.operation.amount',
              defaultMessage: 'Amount',
            })}
          </span>
          <Controller
            rules={{
              required: intl.formatMessage({
                id: 'validation.field_required',
                defaultMessage: 'Field is required',
              }),
              validate: (v, values) => {
                const balance = accountBalances.from.find(
                  (item) => item.currency === values.fromCurrency
                );
                if (Number(v) === 0)
                  return intl.formatMessage({
                    id: 'validation.field_greater_than_0',
                    defaultMessage: 'Value must be greater than 0',
                  });
                if (Number(v) > Number(balance?.balance || 0))
                  return intl.formatMessage({
                    id: 'validation.insufficient_balance',
                    defaultMessage: `Insufficient balance`,
                  });
              },
            }}
            control={control}
            name='fromAmount'
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Input
                    value={value}
                    size='large'
                    placeholder='0.00'
                    onChange={onChange}
                    addonAfter={
                      <Controller
                        rules={{
                          required: intl.formatMessage({
                            id: 'validation.field_required',
                            defaultMessage: 'Field is required',
                          }),
                        }}
                        control={control}
                        name='fromCurrency'
                        render={({
                          field: {
                            value: selectValue,
                            onChange: onSelectChange,
                          },
                          fieldState: { error },
                        }) => (
                          <CurrencySelect
                            options={selectOptions}
                            value={selectOptions.find(
                              (item) => item.value === selectValue
                            )}
                            onChange={(value) =>
                              onSelectChange(value?.value as string)
                            }
                          />
                        )}
                      />
                    }
                  />
                  {error?.message && (
                    <span className={styles.error}>{error.message}</span>
                  )}
                  {currency && (
                    <span className={styles.balance}>
                      {Number(balance?.balance || 0)} {currency}
                    </span>
                  )}
                </>
              );
            }}
          />
        </div>

        <div className={styles.column}>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'sidebar.operation.сonversion_result',
              defaultMessage: 'Conversion result',
            })}
          </span>

          <Input
            value={Number(exchange.feeData.value?.data.toAmount || 0)}
            readOnly
            size='large'
            placeholder='0.00'
            addonAfter={
              <Controller
                rules={{
                  required: intl.formatMessage({
                    id: 'validation.field_required',
                    defaultMessage: 'Field is required',
                  }),
                }}
                control={control}
                name='toCurrency'
                render={({
                  field: { value: selectValue, onChange: onSelectChange },
                  fieldState: { error },
                }) => (
                  <CurrencySelect
                    options={toSelectOptions}
                    value={toSelectOptions.find(
                      (item) => item.value === selectValue
                    )}
                    onChange={(value) => onSelectChange(value?.value as string)}
                  />
                )}
              />
            }
          />
          <div className={styles.swap}>
            {preExchangeData && fromAmount && (
              <span className={styles.label}>
                1 {preExchangeData?.fromCurrency} ={' '}
                {Number(preExchangeData?.toAmount || 0)}{' '}
                {preExchangeData?.toCurrency}
              </span>
            )}
          </div>
        </div>

        <TotalInfo />
      </div>
    </div>
  );
});
