/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { UploadStore } from '../store/UploadStore';

const storeContext = createContext<UploadStore>({} as UploadStore);

type Props = {
  children?: React.ReactNode;
};
export const ProvideUploadStore = observer(({ children }: Props) => {
  const { partnerStore, authApi } = useBaseStores();

  const store = useLocalObservable(() => new UploadStore(authApi));

  useEffect(() => {
    if (!partnerStore.currentPartner?.id) {
      return;
    }

    //store.init(partnerStore.currentPartner.id);
  }, [partnerStore.currentPartner?.id, store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useUploadStore = () => {
  return useContext(storeContext);
};
