/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';

import { AuthApi, LoansApi } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoanModel } from 'models/LoanModel';
import { UserModel } from 'models/UserModel';
import { LoanLtvModel } from 'models/LoanLtvModel';
import { AdminUserGroupsModel } from 'models/UserGroupsModel';
import { CollateralTransactionsModel } from 'models/CollateralTransactionsModel';
import { CollateralInfoModel, ExtendDto } from 'models/CollateralInfoModel';
import { CurrencyStore } from 'stores/CurrencyStore';
import { AccountMoney } from 'api/loans';

export class LoanDetailsPageStore {
  constructor(
    private authApi: AuthApi,
    private loansApi: LoansApi,
    private currencyStore: CurrencyStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _partnerId: number = 0;
  private _userId: string = '';
  private _detailsLoan: LoanModel | null = null;
  private _loanCollateralTransactions:
    | CollateralTransactionsModel[]
    | undefined = undefined;
  private _loanCollateralInfo: CollateralInfoModel[] | undefined = undefined;
  private _detailsUser: UserModel | null = null;
  private _detailsUserGroups: AdminUserGroupsModel[] | null = null;
  private _loanLtv: LoanLtvModel | null = null;

  get detailsLoan() {
    return this._detailsLoan;
  }

  get loanCollateralTransactions() {
    return this._loanCollateralTransactions;
  }

  get loanCollateralInfo() {
    return this._loanCollateralInfo;
  }

  get detailsUser() {
    return this._detailsUser;
  }

  get loanLtv() {
    return this._loanLtv;
  }

  get userGroups() {
    return this._detailsUserGroups;
  }

  async getUserInfo(userId: string) {
    const result = await this.authApi.admin.adminGetUser(userId);
    const user = new UserModel(result.data);

    runInAction(() => {
      this._detailsUser = user;
    });
  }

  async getUserGroups(userId: string) {
    const result = await this.authApi.admin.adminGetUserGroups(userId);
    const items = result.data.map((x) => new AdminUserGroupsModel(x));

    runInAction(() => {
      this._detailsUserGroups = items;
    });
  }

  async getLoanLtv(loanId: string) {
    const result = await this.loansApi.admin.getLoanLtv1(loanId);
    const loanLtv = new LoanLtvModel(result.data);

    runInAction(() => {
      this._loanLtv = loanLtv;
    });
  }

  async init(loanId: string, partnerId: number) {
    const result = await this.loansApi.admin.adminGetLoan(loanId);
    const loan = new LoanModel(result.data);

    const collateralTransactions = result.data.payments?.map(
      (x) => new CollateralTransactionsModel(x)
    );

    const loanCollateralInfoItems = result.data.collateral
      ?.map((coin) => {
        const exchangeResult = this.currencyStore.exchangeCurrencies({
          from: coin?.currency?.toUpperCase() || '',
          to: 'USD',
          amount: new BigNumber(coin?.amount || 0),
        });

        if (!exchangeResult) return null;

        const { original, targetAmount } = exchangeResult;

        return {
          ...coin,
          rate: original.eurPrice,
          amountTotal: targetAmount,
        };
      })
      .filter((item) => item !== null) as (AccountMoney & ExtendDto)[];

    const loanCollateralInfo = loanCollateralInfoItems?.map(
      (x) => new CollateralInfoModel(x)
    );

    await this.getLoanLtv(loanId);

    runInAction(() => {
      this._detailsLoan = loan;
      this._loanCollateralTransactions = collateralTransactions;
      this._loanCollateralInfo = loanCollateralInfo;
      this._partnerId = partnerId;

      this._userId = this._detailsLoan?.userId || '';
      this.getUserInfo(this._userId);
      this.getUserGroups(this._userId);
    });
  }

  async refresh() {
    if (!this.detailsLoan) {
      return;
    }

    return this.init(this.detailsLoan.id, this._partnerId);
  }
}
