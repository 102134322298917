/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Button from 'components/core/button';
import InputField from 'components/core/input-field';

import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

const LoginPage = observer(() => {
  const intl = useIntl();

  const { authStore } = useBaseStores();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const onLogin = useCallback(() => {
    authStore.signInWithPassword(userName, password);
  }, [authStore, password, userName]);

  return (
    <div className={styles.main}>
      <div className={styles.wrapContent}>
        <InputField
          placeholder={intl.formatMessage({
            id: 'page.login.username',
            defaultMessage: 'Username',
          })}
          value={userName}
          onChange={(val) => setUserName(val)}
        />
        <InputField
          placeholder={intl.formatMessage({
            id: 'page.login.password',
            defaultMessage: 'Password',
          })}
          type='password'
          value={password}
          onChange={(val) => setPassword(val)}
        />

        <Button variant='primary' onClick={onLogin}>
          {intl.formatMessage({
            id: 'page.login.continue',
            defaultMessage: 'Continue',
          })}
        </Button>
      </div>
    </div>
  );
});

export default LoginPage;
