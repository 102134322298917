/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';
import { useIntl } from 'react-intl';
import { cutId } from 'utils/strings';
import { FaPen } from 'react-icons/fa6';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { observer } from 'mobx-react-lite';
import { AdminGroupModel } from 'models/GroupModel';
import { TableProps } from 'antd';
import Table from '../core/table';
import { useSidebarUserGroupCreate } from 'components/sidebars/sidebar-user-group-create';

type Props = {
  collection: CollectionWithPages<AdminGroupModel, {}>;
  refresh: () => void;
};

const GroupsTable = observer(({ collection, refresh }: Props) => {
  const intl = useIntl();

  const columns: TableProps<AdminGroupModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.groups.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{cutId(id?.toString() ?? '0')}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.groups.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span>{name}</span>,
      width: 60,
    },
    {
      title: intl.formatMessage({
        id: 'table.groups.col.status',
        defaultMessage: 'Action',
      }),
      dataIndex: 'system',
      key: 'system',
      render: () => (
        <button>
          <FaPen />
        </button>
      ),
      width: 20,
    },
  ];

  const { showCreateUserGroupSidebar } = useSidebarUserGroupCreate({
    onSubmit: refresh,
  });

  return (
    <Table<AdminGroupModel, {}>
      collection={collection}
      columns={columns}
      onRowClick={(item) => showCreateUserGroupSidebar(item)}
    />
  );
});

export default GroupsTable;
