/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { v4 as uuidv4 } from 'uuid';

export const getUuid = () => {
  return uuidv4();
};
