/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow.svg';

import React, { useCallback, useState } from 'react';

type Props = {
  title: string;
  children: React.ReactNode;
  collapse?: boolean;
  className?: string;
};
const Collapse = ({ title, collapse = true, children, className }: Props) => {
  const [visible, setVisible] = useState<boolean>(true);

  const toggleVisibility = useCallback(() => {
    setVisible((prev) => !prev);
  }, [setVisible]);

  return (
    <div>
      <div className={styles.title}>
        <h5 className={cn(styles.title, className)}>{title}</h5>

        {collapse && (
          <button
            className={cn(styles.btn, !visible && styles.content_hidden)}
            onClick={toggleVisibility}
          >
            <ArrowIcon />
          </button>
        )}
      </div>

      {visible && children}
    </div>
  );
};

export default Collapse;
