import React, { FC, useState } from 'react';
import styles from './index.module.css';
import { UserModel } from 'models/UserModel';
import { useIntl } from 'react-intl';
import { Popover, TableProps } from 'antd';
import { cutId } from 'utils/strings';
import { observer } from 'mobx-react-lite';
import FormLabel from 'components/form/form-label';
import Button from 'components/core/button';
import { UsersModal } from './users-modal';
import TableSimple from 'components/core/table-simple';
import { useFormContext, useWatch } from 'react-hook-form';
import { CreateGroupUserForm } from '..';

type UsersType = {
  isCreate?: boolean;
};

export const Members: FC<UsersType> = observer(({ isCreate }) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const { control, setValue, getValues } =
    useFormContext<CreateGroupUserForm>();
  const [rows, setRows] = useState<string[]>([]);

  const fields = useWatch<CreateGroupUserForm>({
    control,
    name: 'users',
  });

  const columns: TableProps<UserModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.users.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{cutId(id)}</span>,
      width: 120,
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.firstName',
        defaultMessage: 'First Name',
      }),
      dataIndex: 'firstName',
      key: 'firstName',
      render: (firstName: string) => <span>{firstName}</span>,
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.lastName',
        defaultMessage: 'Last Name',
      }),
      dataIndex: 'lastName',
      key: 'lastName',
      render: (lastName: string) => <span>{lastName}</span>,
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.phone',
        defaultMessage: 'Phone',
      }),
      dataIndex: 'phone',
      key: 'phone',
      render: (phone: string) => <span>{phone}</span>,
    },
    {
      title: intl.formatMessage({
        id: 'table.users.col.email',
        defaultMessage: 'Email',
      }),
      dataIndex: 'email',
      key: 'email',
      render: (email: string) => <span>{email}</span>,
    },
  ];

  const handleDelete = () => {
    const users = getValues('users');

    const filtered = users.filter((user) => !rows.includes(user.id));
    setValue('users', filtered, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setRows([]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <FormLabel
          text={intl.formatMessage({
            id: 'sidebar.create_group.users',
            defaultMessage: 'Users',
          })}
        />
        {/* <SearchField
          value=''
          onChange={() => {}}
          className={styles.search}
          placeholder={intl.formatMessage({
            id: 'page.users.search.placeholder',
            defaultMessage: 'Search by users',
          })}
        /> */}
        <div className={styles.buttons}>
          <Button
            variant='secondary'
            onClick={handleDelete}
            disabled={!rows.length}
          >
            {intl.formatMessage({
              id: 'page.groups.buttons.delete_user',
              defaultMessage: 'Delete user',
            })}
          </Button>
          <Popover
            content={<UsersModal isOpen={isOpen} />}
            trigger='click'
            placement='bottomRight'
            arrow={false}
            open={isOpen}
            onOpenChange={(v) => setIsOpen(v)}
            destroyTooltipOnHide
          >
            <Button variant='primary'>
              {intl.formatMessage({
                id: 'page.groups.buttons.add_user',
                defaultMessage: 'Add user',
              })}
            </Button>
          </Popover>
        </div>
      </div>
      <TableSimple
        className={styles.table}
        collection={fields as UserModel[]}
        columns={columns}
        rowKey='id'
        scroll={{ y: '400px' }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: rows,
          onChange(selectedRowKeys) {
            setRows(selectedRowKeys as string[]);
          },
        }}
      />
    </div>
  );
});
