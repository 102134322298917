/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { LoanDetailsPageStore } from '../store/LoanDetailsPageStore';

const storeContext = createContext<LoanDetailsPageStore>(
  {} as LoanDetailsPageStore
);

type Props = {
  loanId: string;
  children?: React.ReactNode;
};
export const ProviderLoanDetailsPageStore = observer(
  ({ children, loanId }: Props) => {
    const { partnerStore, authApi, loansApi, currencyStore } = useBaseStores();

    const store = useLocalObservable(
      () => new LoanDetailsPageStore(authApi, loansApi, currencyStore)
    );

    useEffect(() => {
      if (!partnerStore.currentPartner?.id) {
        return;
      }

      store.init(loanId, partnerStore.currentPartner.id);
    }, [partnerStore.currentPartner?.id, store, loanId]);

    return (
      <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
  }
);

export const useLoanDetailsPageStore = () => {
  return useContext(storeContext);
};
