import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormValues } from '..';
import { observer } from 'mobx-react-lite';
import { useOperationStore } from '../../provider/OperationStoreProvider';
import styles from './index.module.css';

export const Balance = observer(() => {
  const store = useOperationStore();
  const { control } = useFormContext<FormValues>();
  const currency = useWatch({
    control,
    name: 'currency',
  });

  const balance = store.accountBalances.from.find(
    (item) => item.currency === currency
  );

  return (
    <span className={styles.balance}>
      {Number(balance?.balance || 0)} {currency}
    </span>
  );
});
