/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';

type Props = {
  text: string;
  type: 'success' | 'warning' | 'danger' | 'neutral';
};

const Badge = ({ text, type }: Props) => {
  return (
    <div
      className={classNames(
        styles.main,
        {
          [styles.success]: type === 'success',
        },
        {
          [styles.warning]: type === 'warning',
        },
        {
          [styles.danger]: type === 'danger',
        },
        {
          [styles.neutral]: type === 'neutral',
        }
      )}
    >
      <span>{text}</span>
    </div>
  );
};

export default Badge;
