/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi, WalletApi } from 'api';
import { makeAutoObservable } from 'mobx';

export class LoanDashboardStore {
  constructor(
    private authApi: AuthApi,
    private walletApi: WalletApi
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async init() {}
}
