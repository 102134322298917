/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { TotalBalanceReportDto } from 'api/wallet';
import BigNumber from 'bignumber.js';
import { parseBigNumberZero } from 'utils/bignumber';

type ExtendDto = {
  rate?: BigNumber;
  amount?: BigNumber;
};

export class TotalBalanceModel {
  constructor(dto: TotalBalanceReportDto & ExtendDto) {
    this.currency = dto.currency ?? '';
    this.total = parseBigNumberZero(dto.total);
    this.partnerId = dto.partnerId ?? undefined;
    this.name = dto.currency ?? '';
    this.value = dto.amount
      ? parseFloat(Number(dto.amount).toFixed(2))
      : Number(0);
    this.rate = parseBigNumberZero(dto.rate);
    this.amount = parseBigNumberZero(dto.amount);
  }

  currency?: string;
  total?: BigNumber;
  partnerId?: number;
  name?: string;
  value?: number;

  rate?: BigNumber;
  amount?: BigNumber;
}
