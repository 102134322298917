/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import SearchField from 'components/core/search-field';

import { URL_SEARCH_PARAMS } from 'assets/config';
import { observer } from 'mobx-react-lite';
import { AdminListRequestModel } from 'models/request/AdminListRequestModel';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { AdminWalletAccountModel } from 'models/WalletAccount';

type Props = {
  collectData: CollectionWithPages<
    AdminWalletAccountModel,
    AdminListRequestModel
  >;
};

const AccountsFilter = observer(({ collectData }: Props) => {
  const intl = useIntl();

  const { setFilterQueryParams } = useSetQueryParams();

  const onSearchChanged = useCallback(
    (val: string) => {
      setFilterQueryParams([
        { id: URL_SEARCH_PARAMS.page, value: '1' },
        { id: URL_SEARCH_PARAMS.search, value: val },
      ]);
    },
    [setFilterQueryParams]
  );

  return (
    <div className={styles.main}>
      <SearchField
        className={styles.search_input}
        placeholder={intl.formatMessage({
          id: 'page.accounts.search.placeholder',
          defaultMessage: 'Search by accounts',
        })}
        value={collectData.filter.search ?? ''}
        onChange={onSearchChanged}
      />
    </div>
  );
});

export default AccountsFilter;
