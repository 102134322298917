/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';
import ColorCircle from 'components/core/color-circle';
import TableSimple from 'components/core/table-simple';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { TableProps } from 'antd';
import { bigNumberToStr } from 'utils/bignumber';
import { TotalBalanceModel } from 'models/TotalBalanceModel';
import { EURO_SIGN } from 'assets/config';
import { useBaseStores } from '../../providers/BaseStoresProvider';

type Props = {
  collection: TotalBalanceModel[] | null;
};

const TotalBalancesTable = observer(({ collection }: Props) => {
  const { currencyStore } = useBaseStores();
  const intl = useIntl();

  if (!collection) {
    return null;
  }

  const columns: TableProps<TotalBalanceModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.total_balances.col.asset',
        defaultMessage: 'Asset',
      }),
      dataIndex: 'currency',
      key: 'currency',
      render: (currency: string, record: TotalBalanceModel, index: number) => (
        <span>
          <ColorCircle colorIndex={index} />
          {currencyStore.currenciesMap.get(currency)?.name}
        </span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.total_balances.col.quantity',
        defaultMessage: 'Quantity',
      }),
      dataIndex: 'total',
      key: 'total',
      render: (total: BigNumber) => <span>{bigNumberToStr(total, 6)}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.total_balances.col.price',
        defaultMessage: 'Price',
      }),
      dataIndex: 'rate',
      key: 'rate',
      render: (rate: BigNumber) => (
        <span>
          {EURO_SIGN} {bigNumberToStr(rate)}
        </span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.total_balances.col.amount',
        defaultMessage: 'Amount',
      }),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: BigNumber) => (
        <span>
          {EURO_SIGN} {bigNumberToStr(amount, 2)}
        </span>
      ),
      width: 20,
    },
  ];

  return (
    <TableSimple<TotalBalanceModel>
      collection={collection}
      columns={columns}
      size='small'
    />
  );
});

export default TotalBalancesTable;
