/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { LOCAL_STORAGE_KEYS } from 'assets/config';
import { ReactNode } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

type SidebarData = {
  show: boolean;
  title: string | null;
  content: ReactNode;
};

type ModalData = {
  show: boolean;
  title: string | null;
  content: ReactNode;
};

type NavbarData = {
  title: string | null;
  content: ReactNode;
};

export class LayoutStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _navbarData: NavbarData = {
    title: null,
    content: null,
  };

  private _sidebarData: SidebarData = {
    show: false,
    title: null,
    content: null,
  };

  private _modalData: ModalData = {
    show: false,
    title: null,
    content: null,
  };

  private _menuCollapsed: boolean = false;

  get menuCollapsed() {
    return this._menuCollapsed;
  }

  get navbarData() {
    return this._navbarData;
  }

  get sidebarData() {
    return this._sidebarData;
  }

  get modalData() {
    return this._modalData;
  }

  async init() {
    runInAction(() => {
      this._menuCollapsed =
        localStorage.getItem(LOCAL_STORAGE_KEYS.layoutMenu) === 'true';
    });
  }

  toggleMenuCollapsed() {
    runInAction(() => {
      this._menuCollapsed = !this._menuCollapsed;
    });

    localStorage.removeItem(LOCAL_STORAGE_KEYS.layoutMenu);
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.layoutMenu,
      this._menuCollapsed.toString()
    );
  }

  setNavbarTitle(title: string | null) {
    runInAction(() => {
      this._navbarData.title = title;
    });
  }

  setSidebarTitle(title: string | null) {
    runInAction(() => {
      this._sidebarData.title = title;
    });
  }

  setNavbarContent(content: ReactNode) {
    runInAction(() => {
      this._navbarData.content = content;
    });
  }

  toggleSidebar(show: boolean, title?: string, content?: ReactNode) {
    runInAction(() => {
      this._sidebarData = {
        show: show,
        title: title ?? null,
        content: content ?? null,
      };
    });

    this.updateBodyNoScroll();
  }

  toggleModal(show: boolean, title?: string, content?: ReactNode) {
    runInAction(() => {
      this._modalData = {
        show: show,
        title: title ?? null,
        content: content ?? null,
      };
    });

    this.updateBodyNoScroll();
  }

  private updateBodyNoScroll() {
    const noScroll =
      this.sidebarData.show === true || this.modalData.show === true;

    if (!noScroll) {
      document.body.classList.remove('no-scroll');
      return;
    }

    document.body.classList.add('no-scroll');
  }
}
