/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AccountUserRole, AdminAccountUser } from 'api/wallet';

export class AccountUserModel {
  constructor(dto: AdminAccountUser) {
    this.id = dto.accountId;
    this.userId = dto.userId;
    this.shortName = dto.shortName;
    this.role = dto.role;
    this.createdAt = dto.createdAt;
  }

  id: string;
  userId: string;
  shortName: string;
  role: AccountUserRole;
  createdAt?: string;
}
