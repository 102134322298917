import Button from 'components/core/button';
import styles from './index.module.css';
import { observer } from 'mobx-react-lite';
import { useAccountDetailPageStore } from '../provider/StoreProvider';
import { ReactComponent as WithdrawIconSvg } from 'assets/images/icons/withdraw.svg';
import { ReactComponent as TransferIconSvg } from 'assets/images/icons/transfer.svg';
import { ReactComponent as ExchangeIconSvg } from 'assets/images/icons/exchange.svg';
import { useSidebarOperationCreate } from 'components/sidebars/sidebar-operation-create';
import { OperationType } from 'api/wallet';
import { useIntl } from 'react-intl';

export const WalletInfo = observer(() => {
  const intl = useIntl();
  const store = useAccountDetailPageStore();

  const { showCreateUserGroupSidebar } = useSidebarOperationCreate({
    onSubmit: () => {},
  });

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <span>{store.account?.shortName}</span>
        <div className={styles.buttons}>
          {/* <Button
            variant='secondary'
            onClick={() =>
              showCreateUserGroupSidebar(
                store.userId,
                OperationType.DEPOSIT,
                store.accountId
              )
            }
          >
            <DepositIconSvg className={styles.icon} />{' '}
            {intl.formatMessage({
              id: 'page.user_details.deposit',
              defaultMessage: 'Deposit',
            })}
          </Button> */}
          <Button
            variant='secondary'
            onClick={() =>
              showCreateUserGroupSidebar(
                store.userId,
                OperationType.TRANSFER,
                store.accountId
              )
            }
          >
            <TransferIconSvg className={styles.icon} />{' '}
            {intl.formatMessage({
              id: 'page.user_details.transfer',
              defaultMessage: 'Transfer',
            })}
          </Button>
          <Button
            variant='secondary'
            onClick={() =>
              showCreateUserGroupSidebar(
                store.userId,
                OperationType.WITHDRAW,
                store.accountId
              )
            }
          >
            <WithdrawIconSvg className={styles.icon} />
            {intl.formatMessage({
              id: 'page.user_details.withdraw',
              defaultMessage: 'Withdraw',
            })}
          </Button>
          <Button
            variant='secondary'
            onClick={() =>
              showCreateUserGroupSidebar(
                store.userId,
                OperationType.EXCHANGE,
                store.accountId
              )
            }
          >
            <ExchangeIconSvg className={styles.icon} />
            {intl.formatMessage({
              id: 'page.user_details.exchange',
              defaultMessage: 'Exchange',
            })}
          </Button>
        </div>
      </div>
      <div className={styles.column}>
        <span className={styles.balanceTitle}>Total balance</span>
        <span className={styles.balance}>${store.balances.total}</span>
      </div>
    </div>
  );
});
