/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import imagePlaceholderUrl from 'assets/images/image_placeholder.svg';
import BigNumber from 'bignumber.js';

import { Currency } from 'api/currency';

export class CurrencyModel {
  constructor(dto: Currency) {
    this.id = dto.slug?.toUpperCase() ?? '';
    this.name = dto.shortName ?? '';
    this.description = dto.description;
    this.iconUrl = dto.iconUrl ?? imagePlaceholderUrl;
    this.eurPrice = dto.eurPrice ? BigNumber(dto.eurPrice) : undefined;
  }

  id: string;
  name: string;
  description?: string;
  iconUrl?: string;
  eurPrice?: BigNumber;
}
