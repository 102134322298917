import React from 'react';
import { FormValues, Step } from '..';
import Button from 'components/core/button';
import { useFormContext } from 'react-hook-form';
import { useOperationStore } from '../../provider/OperationStoreProvider';

import styles from './index.module.css';
import { useIntl } from 'react-intl';
import { SummaryInfo } from '../../summary-info';
import FormField from 'components/form/form-field';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { observer } from 'mobx-react-lite';

type StepSummaryProps = {
  setStep: (step: Step) => void;
  handleSubmit: () => void;
};

export const StepSummary = observer(
  ({ setStep, handleSubmit: onSuccess }: StepSummaryProps) => {
    const intl = useIntl();
    const store = useOperationStore();
    const { handleSubmit, getValues } = useFormContext<FormValues>();
    const { currencyStore } = useBaseStores();
    const values = getValues();

    const transfer = async (data: FormValues) => {
      //const result = await store.transfer.create(data);
      //if (result) onSuccess();
    };

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <SummaryInfo
            options={[
              {
                title: intl.formatMessage({
                  id: 'sidebar.operation.to',
                  defaultMessage: 'To',
                }),
                value: values.toAccount,
              },
              {
                value: (
                  <div className={styles.amount}>
                    <img
                      width={20}
                      height={20}
                      alt='currency'
                      src={
                        currencyStore.currenciesMap.get(values.currency)
                          ?.iconUrl
                      }
                    />
                    <span className={styles.value}>{values.amount}</span>
                  </div>
                ),
                title: intl.formatMessage({
                  id: 'sidebar.operation.amount',
                  defaultMessage: 'Amount',
                }),
              },
              {
                value: `$${
                  currencyStore.currenciesMap
                    .get(values.currency)
                    ?.eurPrice?.toString() || ''
                }`,
                title: intl.formatMessage({
                  id: 'sidebar.operation.spot_price',
                  defaultMessage: 'Spot price',
                }),
              },
              {
                value:
                  currencyStore
                    .getEuroValue(values.currency, Number(values.amount || 0))
                    ?.toString() || '',
                title: intl.formatMessage({
                  id: 'sidebar.operation.total_value',
                  defaultMessage: 'Total value',
                }),
              },
            ]}
          />
          <FormField
            type='text_area'
            label={intl.formatMessage({
              id: 'sidebar.operation.note.title',
              defaultMessage: 'Note',
            })}
            disabled
            placeholder={intl.formatMessage({
              id: 'sidebar.operation.note.placeholder',
              defaultMessage:
                'Specify a reason (The user will see your message)',
            })}
            value={values.note}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            variant='primary'
            onClick={handleSubmit(transfer)}
            disabled={store.transfer.loading}
          >
            {intl.formatMessage({
              id: 'sidebar.operation.transfer',
              defaultMessage: 'Transfer',
            })}
          </Button>
          <Button variant='secondary' onClick={() => setStep('deposit')}>
            {intl.formatMessage({
              id: 'sidebar.operation.back',
              defaultMessage: 'Back',
            })}
          </Button>
        </div>
      </div>
    );
  }
);
