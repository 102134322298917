/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { SubscriptionsPageStore } from '../store/SubscriptionsPageStore';

const storeContext = createContext<SubscriptionsPageStore>(
  {} as SubscriptionsPageStore
);

type Props = {
  children?: React.ReactNode;
};

export const ProvideSubscriptionsPageStore = observer(({ children }: Props) => {
  const { authApi, notificationStore } = useBaseStores();

  const store = useLocalObservable(
    () => new SubscriptionsPageStore(authApi, notificationStore)
  );

  const { page, filter } = useParseCollectionQueryParams({
    collection: store.subscriptions,
  });

  useEffect(() => {
    store.init({
      filter: filter,
      page: page,
    });
  }, [filter, page, store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useSubscriptionsPageStore = () => {
  return useContext(storeContext);
};
