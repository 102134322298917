/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { TableProps } from 'antd';
import Table from '../core/table';
import { DiscountCodesModel } from 'models/DiscountCodesModel';
import { formattedDate } from 'utils/dates';

type Props = {
  collection: CollectionWithPages<DiscountCodesModel, {}>;
};

const PromoCodesTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  const columns: TableProps<DiscountCodesModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.promoCodes.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{id}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.promoCodes.col.code',
        defaultMessage: 'Code',
      }),
      dataIndex: 'code',
      key: 'code',
      render: (code: string) => <span>{code}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.promoCodes.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span>{name}</span>,
      width: 30,
    },

    {
      title: intl.formatMessage({
        id: 'table.promoCodes.col.description',
        defaultMessage: 'Description',
      }),
      dataIndex: 'description',
      key: 'description',
      render: (description: string) => <span>{description}</span>,
      width: 30,
    },

    {
      title: intl.formatMessage({
        id: 'table.promoCodes.col.discountAmount',
        defaultMessage: 'Discount Amount',
      }),
      dataIndex: 'discount_amount',
      key: 'discount_amount',
      render: (_, record) => <span>{record.discount_amount}</span>,
      width: 30,
    },

    {
      title: intl.formatMessage({
        id: 'table.promoCodes.col.discountPercent',
        defaultMessage: 'Discount Percent',
      }),
      dataIndex: 'discount_percent',
      key: 'discount_percent',
      render: (_, record) => <span>{record.discount_percent}%</span>,
      width: 30,
    },

    {
      title: intl.formatMessage({
        id: 'table.promoCodes.col.created_date',
        defaultMessage: 'Created Date',
      }),
      dataIndex: 'created_date',
      key: 'created_date',
      render: (created_date: string) => (
        <span>{created_date ? formattedDate(created_date) : ''}</span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.promoCodes.col.last_modified_date',
        defaultMessage: 'Last Modified Date',
      }),
      dataIndex: 'last_modified_date',
      key: 'last_modified_date',
      render: (last_modified_date: string) => (
        <span>
          {last_modified_date ? formattedDate(last_modified_date) : ''}
        </span>
      ),
      width: 20,
    },
  ];

  return (
    <Table<DiscountCodesModel, {}> collection={collection} columns={columns} />
  );
});

export default PromoCodesTable;
