/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import BackButton from 'components/elements/back-button';

import { ROUTES } from 'routes';
import { observer } from 'mobx-react-lite';
import { ProviderAccountDetailPageStore } from './provider/StoreProvider';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes, useParams } from 'react-router-dom';
import { WalletInfo } from './walletInfo';
import { Filters } from './filters';
import { Balances } from './balances';
import { History } from './history';

const AccountDetailsPageWithProvider = observer(() => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.user_details.title',
        defaultMessage: 'User',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <BackButton fallbackUrl={ROUTES.loans.list.fullPath} />
      </div>

      <WalletInfo />

      <Filters />

      <Routes>
        <Route
          element={<Balances />}
          path={ROUTES.userDetails.accountDetails.balances.path}
        />
        <Route
          element={<History />}
          path={ROUTES.userDetails.accountDetails.history.path}
        />

        <Route element={<Balances />} path='*' />
      </Routes>
    </div>
  );
});

const UserDetailsAccountTab = () => {
  const { accountId, userId } = useParams();

  if (!accountId) {
    return null;
  }

  return (
    <ProviderAccountDetailPageStore accountId={accountId} userId={userId!}>
      <AccountDetailsPageWithProvider />
    </ProviderAccountDetailPageStore>
  );
};

export default UserDetailsAccountTab;
