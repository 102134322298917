/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { observer } from 'mobx-react-lite';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import { useEffect } from 'react';
import TransactionsTable from 'components/tables/transactions-table';

const UserDetailsTransactionsTab = observer(() => {
  const userDetailsPageStore = useUserDetailsPageStore();

  const { page, filter } = useParseCollectionQueryParams({
    collection: userDetailsPageStore.transactions,
  });

  useEffect(() => {
    userDetailsPageStore.transactions.fetchData(filter, page);
  }, [filter, page, userDetailsPageStore.transactions]);

  return (
    <>
      <TransactionsTable collection={userDetailsPageStore.transactions} />
    </>
  );
});

export default UserDetailsTransactionsTab;
