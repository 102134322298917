/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import FormField from 'components/form/form-field';
import Button from 'components/core/button';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { Controller, useForm } from 'react-hook-form';
import { CompanyInfo as CompanyInfoApi } from 'api/auth';
import { transformBackendString } from 'utils/strings';

type CompanyInfo = CompanyInfoApi;

const SidebarCompanyUserEdit = observer(
  ({
    companyInfo,
    onSubmit,
  }: {
    companyInfo: CompanyInfo;
    onSubmit?: (form: CompanyInfo) => void;
  }) => {
    const intl = useIntl();

    const { layoutStore } = useBaseStores();

    const { handleSubmit, control } = useForm<CompanyInfo>({
      mode: 'onChange',
      defaultValues: companyInfo,
    });

    const onSubmitForm = useCallback(
      (form: CompanyInfo) => {
        onSubmit?.(form);
        layoutStore.toggleSidebar(false);
      },
      [layoutStore, onSubmit]
    );

    return (
      <div className={styles.main}>
        <Controller
          control={control}
          name='companyName'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.first_name',
                defaultMessage: 'First name',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.first_name.placeholder',
                defaultMessage: 'Enter first name...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='email'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              label={intl.formatMessage({
                id: 'sidebar.edit_user.email',
                defaultMessage: 'Email',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.edit_user.email.placeholder',
                defaultMessage: 'Enter email...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <div className={styles.two_fields}>
          <Controller
            control={control}
            name='phone'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.phone',
                  defaultMessage: 'Phone',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.phone.placeholder',
                  defaultMessage: 'Enter phone number...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='country'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.edit_user.country',
                  defaultMessage: 'Country',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.edit_user.country.placeholder',
                  defaultMessage: 'Enter country...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.line} />

        <div className={styles.two_fields}>
          {Object.entries(companyInfo.meta || {}).map(([key]) => (
            <Controller
              control={control}
              name={`meta.${key}`}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={transformBackendString(key)}
                  placeholder={`Enter ${transformBackendString(
                    key
                  ).toLowerCase()}...`}
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          ))}
        </div>

        <div className={styles.actions}>
          <Button variant='primary' onClick={handleSubmit(onSubmitForm)}>
            {intl.formatMessage({
              id: 'sidebar.edit_user.submit',
              defaultMessage: 'Submit',
            })}
          </Button>
        </div>
      </div>
    );
  }
);

export const useSidebarCompanyInfoEdit = ({
  onChange,
}: {
  onChange: (companyInfo: CompanyInfo) => void;
}) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const onFormSubmit = useCallback(
    (commpanyInfo: CompanyInfo) => {
      onChange(commpanyInfo);
    },
    [onChange]
  );

  const showSidebar = useCallback(
    (companyInfo: CompanyInfo) => {
      layoutStore.toggleSidebar(
        true,
        intl.formatMessage({
          id: 'sidebar.edit_info',
          defaultMessage: 'Company info',
        }),
        <SidebarCompanyUserEdit
          companyInfo={companyInfo}
          onSubmit={(value) => onFormSubmit(value)}
        />
      );
    },
    [intl, layoutStore, onFormSubmit]
  );

  return {
    showEditCompanyInfoSidebar: showSidebar,
  };
};
