/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { CardHolderApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable } from 'mobx';
import { FilterModel } from 'models/filter/FilterModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { readStrFromQueryParams } from 'utils/queryParams';
import { CardsModel } from 'models/CardsModel';
import { AdminCardsModel } from 'models/request/AdminCardsModel';
import { CardStatus } from 'api/card-holder';

export class CardsPageStore {
  constructor(private cardHolderApi: CardHolderApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _cards = new CollectionWithPages<CardsModel, AdminCardsModel>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.cardHolderApi.adminCards.adminListCards({
        ...params,
      });

      const items = result.data.data.map((x) => new CardsModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminCardsModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.search
      );

      const userIdStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.userId
      );

      const status = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.status
      );

      if (searchStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.search,
          value: {
            search: searchStr,
          },
        });
      }

      if (userIdStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.userId,
          value: {
            userId: userIdStr,
          },
        });
      }

      if (status) {
        filters.push({
          id: URL_SEARCH_PARAMS.status,
          value: {
            status: status as CardStatus,
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  get cards() {
    return this._cards;
  }

  async init(props: { filter: FilterModel<AdminCardsModel>[]; page: number }) {
    const { filter, page } = props;

    await this._cards.fetchData(filter, page);
  }
}
