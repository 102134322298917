/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GroupDto } from 'api/auth';

export class AdminUserGroupsModel {
  constructor(dto: GroupDto) {
    this.name = dto.name?.toString() ?? '';
    this.partner = dto.partner;
  }

  name: string;
  partner: string;
}
