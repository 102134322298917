/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';
import { Table as TableAnt } from 'antd';
import { observer } from 'mobx-react-lite';
import { ColumnsType, TableProps as AntTableProps } from 'antd/lib/table';
import { AnyObject } from 'antd/es/_util/type';

export type SizeType = 'small' | 'middle' | 'large' | undefined;

type TableProps<T extends AnyObject> = {
  columns: ColumnsType<T>;
  collection: T[] | undefined;
  onRowClick?: (item: T) => void;
  className?: string;
  size?: SizeType;
} & AntTableProps;

const TableSimple = observer(
  <T extends AnyObject>({
    columns,
    collection,
    onRowClick,
    className,
    size,
    ...props
  }: TableProps<T>) => {
    return (
      <div className={classNames(className, styles.main)}>
        <div
          className={classNames(
            styles.table_container,
            onRowClick && styles['table-row-clickable']
          )}
        >
          <TableAnt<T>
            dataSource={collection}
            columns={columns}
            pagination={false}
            rowKey={(record) => JSON.stringify(record)} // universal rowKey
            onRow={(record) => ({
              onClick: () => onRowClick?.(record),
            })}
            scroll={{ x: true }}
            size={size || 'middle'}
            {...props}
          />
        </div>
      </div>
    );
  }
);

export default TableSimple;
