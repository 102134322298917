/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { TransactionsPageStore } from '../store/TxPageStore';
import { useIntl } from 'react-intl';

const storeContext = createContext<TransactionsPageStore>(
  {} as TransactionsPageStore
);

type Props = {
  children?: React.ReactNode;
};

export const ProvideTransactionsPageStore = observer(({ children }: Props) => {
  const { approverApi, notificationStore } = useBaseStores();
  const intl = useIntl();

  const store = useLocalObservable(
    () => new TransactionsPageStore(approverApi, notificationStore, intl)
  );

  const { page, filter } = useParseCollectionQueryParams({
    collection: store.transactions,
  });

  useEffect(() => {
    store.init({
      filter: filter,
      page: page,
    });
  }, [filter, page, store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useTransactionsPageStore = () => {
  return useContext(storeContext);
};
