/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React from 'react';
import classNames from 'classnames';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'negative'
  | 'positive'
  | 'black';

export type ButtonSizes = 'small' | 'medium' | 'large';

type ButtonProps = {
  variant: ButtonVariants;
  size?: ButtonSizes;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, size, className, children, ...buttonProps }, ref) => {
    return (
      <button
        {...buttonProps}
        className={classNames(
          styles.button,
          { [styles.small]: size === 'small' },
          { [styles.large]: size === 'large' },
          { [styles.primary]: variant === 'primary' },
          { [styles.secondary]: variant === 'secondary' },
          { [styles.black]: variant === 'black' },
          { [styles.negative]: variant === 'negative' },
          { [styles.positive]: variant === 'positive' },
          className
        )}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

export default Button;
