/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { TableProps } from 'antd';
import Table from '../core/table';
import { CardsModel } from 'models/CardsModel';
import { bigNumberToStr } from 'utils/bignumber';
import BigNumber from 'bignumber.js';

type Props = {
  collection: CollectionWithPages<CardsModel, {}>;
};

const CardsTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  const columns: TableProps<CardsModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.cards.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{id}</span>,
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.userId',
        defaultMessage: 'User Id',
      }),
      dataIndex: 'userId',
      key: 'userId',
      render: (userId: string) => <span>{userId}</span>,
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.accountId',
        defaultMessage: 'Account Id',
      }),
      dataIndex: 'accountId',
      key: 'accountId',
      render: (accountId: string) => <span>{accountId}</span>,
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.additionalCardInfo',
        defaultMessage: 'Additional Card Info',
      }),
      dataIndex: 'additionalCardInfo',
      key: 'additionalCardInfo',
      render: (additionalCardInfo) => (
        <span>{JSON.stringify(additionalCardInfo, null, 2)}</span>
      ),
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <span>{status}</span>,
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.balance',
        defaultMessage: 'Balance',
      }),
      dataIndex: 'balance',
      key: 'balance',
      render: (balance: BigNumber) => <span>{bigNumberToStr(balance)} </span>,
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.last4',
        defaultMessage: 'Last 4',
      }),
      dataIndex: 'last4',
      key: 'last4',
      render: (last4: string) => <span>{last4}</span>,
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.cardholderName',
        defaultMessage: 'Cardholder Name',
      }),
      dataIndex: 'cardholderName',
      key: 'cardholderName',
      render: (cardholderName: string) => <span>{cardholderName}</span>,
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.secondaryCardholderName',
        defaultMessage: 'Secondary Cardholder Name',
      }),
      dataIndex: 'secondaryCardholderName',
      key: 'secondaryCardholderName',
      render: (secondaryCardholderName: string) => (
        <span>{secondaryCardholderName}</span>
      ),
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.expireDate',
        defaultMessage: 'Expire Date',
      }),
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (expireDate: string) => <span>{expireDate}</span>,
    },

    {
      title: intl.formatMessage({
        id: 'table.cards.col.cardType',
        defaultMessage: 'Card Type',
      }),
      dataIndex: 'cardType',
      key: 'cardType',
      render: (cardType: string) => <span>{cardType} </span>,
    },
  ];

  return <Table<CardsModel, {}> collection={collection} columns={columns} />;
});

export default CardsTable;
