import { Segmented } from 'components/core/segmented';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes';

const options = [
  {
    label: 'Balances',
    value: 'balances',
  },
  {
    label: 'History',
    value: 'history',
  },
];

export const Filters = observer(() => {
  const { accountId, userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isHistory = location.pathname.includes(
    ROUTES.userDetails.accountDetails.history.path
  );
  const value = isHistory ? 'history' : 'balances';

  return (
    <div>
      <Segmented
        value={value}
        defaultValue={options[0].value}
        onChange={(value) => {
          navigate(
            value === 'history'
              ? ROUTES.userDetails.accountDetails.history.getFullPath(
                  userId!,
                  accountId!
                )
              : ROUTES.userDetails.accountDetails.balances.getFullPath(
                  userId!,
                  accountId!
                ),
            {
              replace: true,
            }
          );
        }}
        options={options}
      />
    </div>
  );
});
