/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import Badge from '../badge';
import { useCallback } from 'react';
import { ConfirmRequest, Withdraw } from 'api/approver';
import { useIntlTransactionsStatuses } from 'hooks/intl/transactions-statuses';

export type CombinedTransactionStatus =
  | ConfirmRequest['status']
  | Withdraw['status']
  | 'PENDING';

type Props = {
  status?: CombinedTransactionStatus | undefined;
};

const BadgeTransactionStatus = ({ status }: Props) => {
  const transactionStatus = status !== undefined ? status : 'PENDING';
  const { transactionsStatusToIntlText } = useIntlTransactionsStatuses();

  const statusToBadgeType = useCallback((status: CombinedTransactionStatus) => {
    switch (status) {
      case 'APPROVED':
      case 'APPROVING':
      case 'WITHDRAWN':
        return 'success' as const;

      case 'PROCESSING':
        return 'warning' as const;

      case 'REJECTING':
      case 'REJECTED':
      case 'FAILED':
      case 'CANCELED':
        return 'danger' as const;

      default:
        return 'neutral' as const;
    }
  }, []);

  return (
    <Badge
      text={transactionsStatusToIntlText(transactionStatus)}
      type={statusToBadgeType(transactionStatus)}
    />
  );
};

export default BadgeTransactionStatus;
