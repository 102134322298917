/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AdminDeposit, DepositStatusEnum } from 'api/wallet';

export class DepositModel {
  constructor(dto: AdminDeposit) {
    this.id = dto.id ?? '';
    this.depositType = dto.depositType;
    this.accountId = dto.accountId;
    this.currency = dto.currency;
    this.amount = dto.amount;
    this.blockchain = dto.blockchain;
    this.createdAt = dto.createdAt;
    this.updatedAt = dto.updatedAt;
    this.status = dto.status;
  }

  id: string;
  depositType?: string;
  accountId?: string;
  currency?: string;
  amount?: string;
  blockchain?: string;
  token?: string;
  createdAt?: string;
  updatedAt?: string;
  status?: DepositStatusEnum;
}
