/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';
import TableSimple from 'components/core/table-simple';
import ColorCircle from 'components/core/color-circle';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { TableProps } from 'antd';
import { bigNumberToStr } from 'utils/bignumber';
import { CollateralInfoModel } from 'models/CollateralInfoModel';
import { EURO_SIGN } from 'assets/config';

type Props = {
  collection: CollateralInfoModel[] | null;
};

const CollateralInfoTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  if (!collection) {
    return null;
  }

  const columns: TableProps<CollateralInfoModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_info.col.currency',
        defaultMessage: 'currency',
      }),
      dataIndex: 'currency',
      key: 'currency',
      render: (
        currency: string,
        record: CollateralInfoModel,
        index: number
      ) => (
        <span>
          <ColorCircle colorIndex={index} />
          {currency}
        </span>
      ),
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_info.col.amount',
        defaultMessage: 'amount',
      }),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: BigNumber) => <span>{bigNumberToStr(amount)}</span>,
      width: 60,
    },

    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_info.col.price',
        defaultMessage: 'price',
      }),
      dataIndex: 'rate',
      key: 'rate',
      render: (rate: BigNumber) => (
        <span>
          {EURO_SIGN} {bigNumberToStr(rate)}
        </span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_info.col.value',
        defaultMessage: 'value',
      }),
      dataIndex: 'amountTotal',
      key: 'amountTotal',
      render: (amountTotal: BigNumber) => (
        <span>
          {EURO_SIGN} {bigNumberToStr(amountTotal, 2)}
        </span>
      ),
      width: 20,
    },
  ];

  return (
    <TableSimple<CollateralInfoModel>
      collection={collection}
      columns={columns}
    />
  );
});

export default CollateralInfoTable;
