import FormSelect from 'components/form/form-select';
import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useExchangePairsPageStore } from '../provider/ExchangePairsProvider';

import styles from './index.module.css';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { useSearchParams } from 'react-router-dom';
import { readStrFromQueryParams } from 'utils/queryParams';

export const Filter = observer(() => {
  const intl = useIntl();
  const { partnerStore } = useBaseStores();
  const store = useExchangePairsPageStore();

  const [queryParams] = useSearchParams();

  const group = readStrFromQueryParams(queryParams, 'group');
  const partner = readStrFromQueryParams(queryParams, 'partner');
  const { setFilterQueryParams } = useSetQueryParams();

  useEffect(() => {
    setFilterQueryParams([
      { id: 'partner', value: partnerStore.availablePartners[0].name! },
    ]);
  }, [partnerStore.availablePartners]);

  useEffect(() => {
    setFilterQueryParams([{ id: 'group', value: store.groups[0]?.name! }]);
  }, [store.groups]);

  useEffect(() => {
    if (group && partner) {
      store.pairs.fetch({
        group: group,
        partner: partner,
      });
    }
  }, [store, group, partner]);

  const parnerOptions = partnerStore.availablePartners
    .filter(({ name }) => !!name)
    .map((item) => ({
      id: item.name!,
      label: item.name!,
      value: item.name!,
    }));

  const groupOptions = store.groups
    .filter(({ name }) => !!name)
    .map((item) => ({
      id: item.name!,
      label: item.name!,
      value: item.name!,
    }));

  return (
    <div className={styles.container}>
      {/*<FormSelect*/}
      {/*  showSearch*/}
      {/*  label={intl.formatMessage({*/}
      {/*    id: 'exchange.filter.partner_name',*/}
      {/*    defaultMessage: 'Partner name',*/}
      {/*  })}*/}
      {/*  options={parnerOptions}*/}
      {/*  onChange={(value) =>*/}
      {/*    setFilterQueryParams([{ id: 'partner', value: value?.value }])*/}
      {/*  }*/}
      {/*  value={parnerOptions.find((item) => item.value === partner)}*/}
      {/*/>*/}
      <FormSelect
        showSearch
        label={intl.formatMessage({
          id: 'exchange.filter.group_name',
          defaultMessage: 'Group name',
        })}
        options={groupOptions}
        onChange={(value) =>
          setFilterQueryParams([{ id: 'group', value: value?.value }])
        }
        value={groupOptions.find((item) => item.value === group)}
      />
    </div>
  );
});
