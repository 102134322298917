/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { COLORS } from 'styles/charts/colors';

type Props = {
  colorIndex: number;
};

const ColorCircle = ({ colorIndex }: Props) => {
  return (
    <span
      className={styles.color_dot}
      style={{
        backgroundColor: COLORS[colorIndex % COLORS.length],
      }}
    ></span>
  );
};

export default ColorCircle;
