/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { WithdrawalModel } from 'models/WithdrawalModel';
import { formattedDate } from 'utils/dates';
import { TableProps } from 'antd';
import Table from '../core/table';

type Props = {
  collection: CollectionWithPages<WithdrawalModel, {}>;
};

const WithdrawalsTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  const columns: TableProps<WithdrawalModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.withdrawals.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      render: (id: string) => <span>{id}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.withdrawals.col.type',
        defaultMessage: 'Type',
      }),
      dataIndex: 'type',
      render: (type) => <span>{type}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.withdrawals.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      render: (status) => <span>{status}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.withdrawals.col.amount',
        defaultMessage: 'Amount',
      }),
      dataIndex: 'amount',
      render: (amount) => <span>{amount}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.withdrawals.col.from',
        defaultMessage: 'From',
      }),
      dataIndex: 'fromAccount',
      render: (fromAccount) => <span>{fromAccount}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.withdrawals.col.to',
        defaultMessage: 'To',
      }),
      dataIndex: 'toAccount',
      render: (toAccount) => <span>{toAccount}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.withdrawals.col.created',
        defaultMessage: 'Created',
      }),
      dataIndex: 'createdAt',
      render: (createdAt) => (
        <span>{createdAt ? formattedDate(createdAt) : ''}</span>
      ),
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.withdrawals.col.updated',
        defaultMessage: 'Updated',
      }),
      dataIndex: 'updatedAt',
      render: (updatedAt) => (
        <span>{updatedAt ? formattedDate(updatedAt) : ''}</span>
      ),
      width: 20,
    },
  ];

  return (
    <Table<WithdrawalModel, {}> collection={collection} columns={columns} />
  );
});

export default WithdrawalsTable;
