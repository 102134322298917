/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { InvoicesPageStore } from '../store/InvoicesPageStore';

const storeContext = createContext<InvoicesPageStore>({} as InvoicesPageStore);

type Props = {
  children?: React.ReactNode;
};

export const ProvideInvoicesPageStore = observer(({ children }: Props) => {
  const { acquiringApi } = useBaseStores();

  const store = useLocalObservable(() => new InvoicesPageStore(acquiringApi));

  const { page, filter } = useParseCollectionQueryParams({
    collection: store.invoices,
  });

  useEffect(() => {
    store.init({
      filter: filter,
      page: page,
    });
  }, [filter, page, store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useInvoicesPageStore = () => {
  return useContext(storeContext);
};
