import classNames from 'classnames';
import Select, { SelectProps } from 'components/core/select';
import React from 'react';

import styles from './index.module.css';

export const CurrencySelect = <T extends object | string | number | boolean>(
  props: SelectProps<T>
) => {
  return (
    <Select
      {...props}
      className={classNames(props.className, styles.container)}
    />
  );
};
