import { AuthApi } from 'api';
import { DocumentUploadRequest, IdDocSubType, IdDocType } from 'api/auth';
import { makeAutoObservable, runInAction } from 'mobx';
import { ErrorMessage, parseApiError } from 'utils/error';

export class UploadStore {
  loading = false;
  errors: ErrorMessage[] = [];

  constructor(private authApi: AuthApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setErrors(value: ErrorMessage[]) {
    this.errors = value;
  }

  async upload(
    kycId: string,
    file: File,
    type: IdDocType,
    subType?: IdDocSubType
  ) {
    let errors: ErrorMessage[] = [];
    runInAction(() => {
      this.loading = true;
    });
    try {
      const request: DocumentUploadRequest = {
        type,
        subType,
      };
      await this.authApi.admin.adminUploadDocument(kycId, {
        file,
        request: new Blob([JSON.stringify(request)], {
          type: 'application/json',
        }) as any,
      });

      runInAction(() => {
        this.loading = false;
      });

      return true;
    } catch (e) {
      console.error(e);
      errors = parseApiError(e);
      runInAction(() => {
        this.errors = errors;
        this.loading = false;
      });

      return false;
    }
  }

  async delete(kycId: string, docId: string) {
    let errors: ErrorMessage[] = [];
    runInAction(() => {
      this.loading = true;
    });
    try {
      await this.authApi.admin.adminDeleteDocument(kycId, docId);
    } catch (e) {
      console.error(e);
      errors = parseApiError(e);
    }
    runInAction(() => {
      this.loading = false;
      this.errors = errors;
    });
  }
}
