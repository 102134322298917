import styles from './index.module.css';

type CardInfoProps = {
  options: { title: string; value: string }[];
};

export const CardInfo = ({ options }: CardInfoProps) => {
  return (
    <div className={styles.container}>
      {options.map(({ title, value }) => (
        <div className={styles.row}>
          <span className={styles.title}>{title}</span>
          <span className={styles.value}>{value}</span>
        </div>
      ))}
    </div>
  );
};
