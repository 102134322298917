/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { DiscountRedeem } from '../api/auth';

export class DiscountCodesModel {
  constructor(dto: DiscountRedeem) {
    this.code = dto.code ?? '';
    this.created_date = dto.created_date ?? '';
    this.description = dto.description ?? '';
    this.discount_amount = dto.discount_amount ?? 0;
    this.discount_currency = dto.discount_currency ?? '';
    this.discount_percent = dto.discount_percent ?? 0;
    this.discount_redeems = dto.discount_redeems ?? [];
    this.id = dto.id ?? '';
    this.last_modified_date = dto.last_modified_date ?? '';
    this.name = dto.name ?? '';
    this.redeemLimit = dto.redeemLimit ?? 0;
  }

  code: string;
  created_date?: string;
  description: string;
  discount_amount?: number;
  discount_currency?: string;
  discount_percent?: number;
  discount_redeems: DiscountRedeem[];
  id: string;
  last_modified_date?: string;
  name: string;
  redeemLimit?: number;
}
