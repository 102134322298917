/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import { ROUTES } from 'routes';
import { observer } from 'mobx-react-lite';
import { useLoanDetailsPageStore } from '../provider/StoreProvider';
import BadgeLoanStatus from 'components/core/badges/badge-loan-status';
import { cutId } from 'utils/strings';
import CopyButton from 'components/core/copy-button';
import UserDetailsBasicInfoField from '../../user-details/basic-info/field';
import { useIntl } from 'react-intl';
import HealthIndicator from 'components/core/health-indicator';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonIcon from 'components/core/button-icon';
import { ReactComponent as ArrowsRightIcon } from 'assets/images/icons/arrow-right-medium.svg';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Button from 'components/core/button';

const LoanDetailsBasicInfo = observer(() => {
  const loanDetailsPageStore = useLoanDetailsPageStore();
  const { detailsLoan, detailsUser, loanLtv, userGroups } =
    loanDetailsPageStore;
  const intl = useIntl();
  const navigate = useNavigate();

  const userGroupNames = useMemo(() => {
    return userGroups ? userGroups.map((item) => item.name).join(', ') : '';
  }, [userGroups]);

  const address = [
    detailsUser?.residentialAddress?.town || '',
    detailsUser?.residentialAddress?.street || '',
    detailsUser?.residentialAddress?.buildingNumber || '',
    detailsUser?.residentialAddress?.flatNumber || '',
  ]
    .filter(Boolean)
    .join(', ');

  const goToUserPage = (userId: string) => {
    navigate(ROUTES.userDetails.getFullPath(userId));
  };

  const handleManualActions = () => {
    console.log('Manual Actions clicked');
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <BadgeLoanStatus status={detailsLoan?.status} />
      </div>

      <div>
        <h6>
          loanOfferId <ArrowsRightIcon className={styles.svg} />
        </h6>
        <h2>
          {detailsLoan?.principalCurrency}{' '}
          {detailsLoan?.principalAmount?.toString()}
        </h2>
      </div>

      <div className={styles.body}>
        <div className={styles.body_group}>
          <UserDetailsBasicInfoField
            label={intl.formatMessage({
              id: 'page.loan_details.loanType',
              defaultMessage: 'Loan type',
            })}
            value={detailsLoan?.type}
          />

          <UserDetailsBasicInfoField
            label={intl.formatMessage({
              id: 'page.loan_details.managerAssociated',
              defaultMessage: 'Manager associated',
            })}
            value={detailsLoan?.status}
          />
        </div>

        <HealthIndicator value={loanLtv?.ltv || 0} className={'mt-4 mb-2'} />

        <h4>
          {intl.formatMessage({
            id: 'page.loan_details.userInformation',
            defaultMessage: 'User information',
          })}
        </h4>

        <div className={styles.user}>
          <div>
            <h5>
              {detailsUser?.lastName} {detailsUser?.firstName}
            </h5>
            <div className={styles.userId}>
              <span>{detailsLoan?.id && cutId(detailsLoan.userId)}</span>
              <CopyButton text={detailsLoan?.id ?? ''} />
            </div>
          </div>
          <div>
            <ButtonIcon
              onClick={() => goToUserPage(detailsLoan?.userId || '')}
            />
          </div>
        </div>

        <UserDetailsBasicInfoField
          label={intl.formatMessage({
            id: 'page.loan_details.email',
            defaultMessage: 'Email',
          })}
          value={detailsUser?.email}
        />

        <div className={styles.body_group}>
          <UserDetailsBasicInfoField
            label={intl.formatMessage({
              id: 'page.loan_details.country',
              defaultMessage: 'Country',
            })}
            value={detailsUser?.residentialAddress?.country}
          />
          <UserDetailsBasicInfoField
            label={intl.formatMessage({
              id: 'page.loan_details.postCode',
              defaultMessage: 'Post Code',
            })}
            value={detailsUser?.residentialAddress?.postCode}
          />
        </div>

        <UserDetailsBasicInfoField
          label={intl.formatMessage({
            id: 'page.loan_details.address',
            defaultMessage: 'Address',
          })}
          value={address}
        />

        <UserDetailsBasicInfoField
          label={intl.formatMessage({
            id: 'page.loan_details.groups',
            defaultMessage: 'Groups',
          })}
          value={userGroupNames}
        />

        <Button
          variant='primary'
          onClick={handleManualActions}
          className={styles.btn}
          disabled
        >
          <span>
            {intl.formatMessage({
              id: 'page.loan_details.manual_loan_actions',
              defaultMessage: 'Manual loan actions',
            })}
          </span>

          <MdKeyboardArrowDown size={24} />
        </Button>
      </div>
    </div>
  );
});

export default LoanDetailsBasicInfo;
