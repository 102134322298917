/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { ApiWithSecurityData, AuthApi } from 'api';
import { AuthenticationResponse } from 'api/auth';
import { AUTH_CLIENT_ID, LOCAL_STORAGE_KEYS, PARTNER_ID } from 'assets/config';
import { makeAutoObservable, runInAction } from 'mobx';

const REFRESH_SKEW_SECONDS = 30;

export class AuthStore {
  constructor(
    private authApi: AuthApi,
    private apisWithSecurityData: ApiWithSecurityData[]
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _accessToken: string | null = null;
  private _refreshToken: string | null = null;

  private _refreshTimeout: NodeJS.Timeout | null = null;

  get isLoggedIn() {
    return !!this._accessToken;
  }

  async init() {
    runInAction(() => {
      this._refreshToken =
        localStorage.getItem(LOCAL_STORAGE_KEYS.refreshToken) ?? null;
    });

    await this.refreshToken();
  }

  async signInWithPassword(userName: string, password: string) {
    try {
      const result = await this.authApi.authentication.getToken({
        client_id: AUTH_CLIENT_ID,
        grant_type: 'password',
        password: password,
        username: userName,
      });

      this.setAuthToken(result.data);
    } catch {
      this.setAuthToken({});
    }
  }

  async signOut() {
    this.setAuthToken({});
  }

  async refreshToken() {
    if (!this._refreshToken) {
      return;
    }

    try {
      const result = await this.authApi.authentication.getToken({
        grant_type: 'refresh_token',
        refresh_token: this._refreshToken,
      });

      this.setAuthToken(result.data);
    } catch {
      this.setAuthToken({});
    }
  }

  async sendOneTimePassword(phone: string) {
    const result = await this.authApi.authentication.sendOneTimePassword(
      {
        phone: phone,
      },
      { headers: { PartnerId: PARTNER_ID } }
    );

    return result.status === 204;
  }

  private setAuthToken({
    access_token,
    refresh_token,
    expires_in,
  }: AuthenticationResponse) {
    this.apisWithSecurityData.forEach((api) => {
      api.setSecurityData(access_token);
    });

    runInAction(() => {
      this._accessToken = access_token ?? null;
      this._refreshToken = refresh_token ?? null;
    });

    localStorage.removeItem(LOCAL_STORAGE_KEYS.refreshToken);
    clearTimeout(this._refreshTimeout ?? undefined);

    if (access_token && expires_in) {
      const timeoutSeconds = expires_in - REFRESH_SKEW_SECONDS;

      this._refreshTimeout =
        timeoutSeconds > 0
          ? setTimeout(() => {
              this.refreshToken();
            }, timeoutSeconds * 1000)
          : null;
    }

    if (refresh_token) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.refreshToken, refresh_token);
    }
  }
}
