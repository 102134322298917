/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';
import { useIntl } from 'react-intl';
import { cutId } from 'utils/strings';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { observer } from 'mobx-react-lite';
import { AdminCompanyModel } from 'models/CompanyModel';
import { useSidebarJsonViewer } from 'components/sidebars/sidebar-json-viewer';
import { isEmpty } from 'utils/obj';
import { TableProps } from 'antd';
import Table from 'components/core/table';
import Button from 'components/core/button';

type Props = {
  collection: CollectionWithPages<AdminCompanyModel, {}>;
};

const CompaniesTable = observer(({ collection }: Props) => {
  const intl = useIntl();
  const { showJsonViewerSidebar } = useSidebarJsonViewer();

  const columns: TableProps<AdminCompanyModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.companies.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <span>{cutId((id && id.toString()) ?? '0')}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.companies.col.companyName',
        defaultMessage: 'Company page',
      }),
      dataIndex: 'companyName',
      key: 'companyName',
      render: (companyName) => <span>{companyName}</span>,
      width: 10,
    },
    {
      title: intl.formatMessage({
        id: 'table.companies.col.email',
        defaultMessage: 'Email',
      }),
      dataIndex: 'email',
      key: 'email',
      render: (email) => <span>{email}</span>,
      width: 10,
    },
    {
      title: intl.formatMessage({
        id: 'table.companies.col.country',
        defaultMessage: 'Country',
      }),
      dataIndex: 'country',
      key: 'country',
      render: (country) => <span>{country ? country[0] : null}</span>,
      width: 10,
    },
    {
      title: intl.formatMessage({
        id: 'table.companies.col.phone',
        defaultMessage: 'Phone',
      }),
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => <span>{phone}</span>,
      width: 10,
    },
    {
      title: intl.formatMessage({
        id: 'table.companies.col.json',
        defaultMessage: 'JSON',
      }),
      dataIndex: 'json',
      key: 'json',
      render: (json) => (
        <Button
          variant={'secondary'}
          size={'small'}
          className='font-semibold'
          disabled={isEmpty(json as Object)}
          onClick={() =>
            showJsonViewerSidebar(json ? JSON.stringify(json) : '{}')
          }
        >
          {intl.formatMessage({
            id: 'table.companies.col.checkJson',
            defaultMessage: 'Check JSON',
          })}
        </Button>
      ),
      width: 85,
    },
  ];

  return (
    <Table<AdminCompanyModel, {}> collection={collection} columns={columns} />
  );
});

export default CompaniesTable;
