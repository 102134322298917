/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { formattedDate } from 'utils/dates';
import { DepositModel } from 'models/DepositModel';
import { TableProps } from 'antd';
import Table from '../core/table';

type Props = {
  collection: CollectionWithPages<DepositModel, {}>;
};

const DepositsTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  const columns: TableProps<DepositModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.deposits.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id) => <span>{id}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.deposits.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <span>{status}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.deposits.col.amount',
        defaultMessage: 'Amount',
      }),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <span>{amount}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.deposits.col.account',
        defaultMessage: 'Account',
      }),
      dataIndex: 'accountId',
      key: 'accountId',
      render: (accountId) => <span>{accountId}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.deposits.col.created',
        defaultMessage: 'Created',
      }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        <span>{createdAt ? formattedDate(createdAt) : ''}</span>
      ),
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.deposits.col.updated',
        defaultMessage: 'Updated',
      }),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => (
        <span>{updatedAt ? formattedDate(updatedAt) : ''}</span>
      ),
      width: 20,
    },
  ];

  return <Table<DepositModel, {}> collection={collection} columns={columns} />;
});

export default DepositsTable;
