import { observer } from 'mobx-react-lite';

import styles from './index.module.css';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useAccountDetailPageStore } from '../provider/StoreProvider';

export const Balances = observer(() => {
  const { currencyStore } = useBaseStores();
  const store = useAccountDetailPageStore();

  return (
    <div className={styles.container}>
      {store.balances.balances.map((balance) => (
        <div className={styles.card} key={balance.account}>
          <div className={styles.row}>
            <img
              width={28}
              height={28}
              alt='icon currency'
              src={currencyStore.currenciesMap.get(balance.currency)?.iconUrl}
            />
            <span className={styles.balance}>
              {Number(balance.balance)} {currencyStore.currenciesMap.get(balance.currency)?.name}
            </span>
          </div>
          <span className={styles.secondBalance}>
            {currencyStore.getEuroValue(
              balance.currency,
              Number(balance.balance)
            ) || '-'}
          </span>
        </div>
      ))}
    </div>
  );
});
