import joi from 'joi';
import styles from './index.module.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntlValidation } from 'hooks/intl/validation';
import { Info } from './info';
import { Members } from './members';
import {
  ProvideGroupsDetailsPageStore,
  useGroupsDetailsPageStore,
} from './provider/GroupsDetailsStoreProvider';
import { ActionButtons } from './action-buttons';
import { AdminGroupModel } from 'models/GroupModel';
import { UserModel } from 'models/UserModel';

export type CreateGroupUserForm = {
  name: string;
  users: UserModel[];
};

const SidebarUserGroupCreate = observer(
  ({ group, onSubmit }: { group?: AdminGroupModel; onSubmit: () => void }) => {
    const isCreate = !group;
    const usersPageStore = useGroupsDetailsPageStore();
    const { validationOptions } = useIntlValidation();

    const formScheme = useMemo(
      () =>
        joi.object<CreateGroupUserForm>({
          name: joi.string().empty('').required(),
          users: joi.array().required(),
        }),
      []
    );

    const form = useForm<CreateGroupUserForm>({
      resolver: joiResolver(formScheme, validationOptions),
      mode: 'onChange',
      defaultValues: async () => {
        if (isCreate)
          return {
            name: '',
            users: [],
          };

        const users = await usersPageStore.members.fetchFn();
        return {
          name: group?.name || '',
          users,
        };
      },
    });

    return (
      <FormProvider {...form}>
        <div className={styles.main}>
          <Info isCreate={isCreate} group={group} />
          <Members isCreate={isCreate} />
          <ActionButtons
            isCreate={isCreate}
            id={group?.id}
            onSubmit={onSubmit}
          />
        </div>
      </FormProvider>
    );
  }
);

export const useSidebarUserGroupCreate = ({
  onSubmit,
}: {
  onSubmit: () => void;
}) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(
    (group?: AdminGroupModel) => {
      layoutStore.toggleSidebar(
        true,
        intl.formatMessage(
          {
            id: group?.name
              ? 'sidebar.create_group.user_name'
              : 'sidebar.create_group',
            defaultMessage: group?.name
              ? `${group.name} group`
              : 'Create a group',
          },
          { name: group?.name }
        ),
        <ProvideGroupsDetailsPageStore groupId={Number(group?.id)}>
          <SidebarUserGroupCreate group={group} onSubmit={onSubmit} />
        </ProvideGroupsDetailsPageStore>
      );
    },
    [intl, layoutStore, onSubmit]
  );

  return {
    showCreateUserGroupSidebar: showSidebar,
  };
};
