/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useLayoutEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { OperationStore } from '../store/OperationStore';
import { OperationType } from 'api/wallet';

const storeContext = createContext<OperationStore>({} as OperationStore);

type Props = {
  userId: string;
  type: OperationType;
  children?: React.ReactNode;
};

export const OperationStoreProvider = observer(
  ({ children, userId, type }: Props) => {
    const { authApi, partnerStore, walletApi, notificationStore } =
      useBaseStores();

    const store = useLocalObservable(
      () => new OperationStore(authApi, walletApi, notificationStore)
    );

    useLayoutEffect(() => {
      if (!partnerStore.currentPartner) {
        return;
      }

      store.init({
        partnerId: partnerStore.currentPartner?.id || 0,
        userId,
        type,
      });
    }, [userId, store, partnerStore.currentPartner, type]);

    return (
      <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
  }
);

export const useOperationStore = () => {
  return useContext(storeContext);
};
