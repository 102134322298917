/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import FormLabel, { FormLabelType } from 'components/form/form-label';

import InputField, { InputFieldProps } from 'components/core/input-field';
import TextAreaField from 'components/core/text-area-field';

const FormField = (
  props: Omit<InputFieldProps, 'variant'> & {
    label?: string;
    labelType?: FormLabelType;
    type?: 'input' | 'text_area';
    required?: boolean;
  }
) => {
  return (
    <div className={styles.main}>
      {!!props.label && (
        <FormLabel
          text={props.label}
          required={props.required}
          type={props.labelType}
        />
      )}

      {(!props.type || props.type === 'input') && (
        <InputField {...props} variant='primary' />
      )}

      {props.type === 'text_area' && (
        <TextAreaField {...props} variant='primary' />
      )}
    </div>
  );
};

export default FormField;
