/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';
import {
  AccountMoney,
  FeePoint,
  Loan,
  LoanPayment,
  LoanStatus,
  LoanType,
  PaymentPeriodTypeEnum,
} from 'api/loans';
import { parseBigNumber } from '../utils/bignumber';

export class LoanModel {
  constructor(dto: Loan) {
    this.id = dto.id ?? '';
    this.userId = dto.userId ?? '';
    this.budget = dto.budget ?? undefined;
    this.collateral = dto.collateral ?? undefined;
    this.principalAmount = parseBigNumber(dto.principalAmount);
    this.principalCurrency = dto.principalCurrency;
    this.collateralAllowedCurrencies = dto.collateralAllowedCurrencies
      ? dto.collateralAllowedCurrencies.split(';')
      : undefined;
    this.interestRate = parseBigNumber(dto.interestRate);
    this.feePoints = dto.feePoints;
    this.collateralRate = parseBigNumber(dto.collateralRate);
    this.marginCallRate = parseBigNumber(dto.marginCallRate);
    this.minHealthRate = parseBigNumber(dto.minHealthRate);
    this.liquidationRate = parseBigNumber(dto.liquidationRate);
    this.totalInterest = dto.totalInterest;
    this.totalInterestCurrency = dto.totalInterestCurrency;
    this.settlementPriceAmount = parseBigNumber(dto.settlementPriceAmount);
    this.settlementPriceCurrency = dto.settlementPriceCurrency;
    this.type = dto.type;
    this.startDate = dto.startDate;
    this.endDate = dto.endDate;
    this.paymentPeriodType = dto.paymentPeriodType;
    this.paymentPeriod = dto.paymentPeriod ?? undefined;
    this.paymentAccountId = dto.paymentAccountId ?? undefined;
    this.beneficiaryAccountId = dto.beneficiaryAccountId ?? undefined;
    this.payments = dto.payments;
    this.currentLoanOfferId = dto.currentLoanOfferId;
    this.initialLoanOfferId = dto.initialLoanOfferId;
    this.status = dto.status;
    this.statusDescription = dto.statusDescription;
  }

  id: string;
  userId: string;
  budget?: AccountMoney[];
  collateral?: AccountMoney[];
  principalAmount?: BigNumber;
  principalCurrency?: string;
  collateralAllowedCurrencies?: string[];
  interestRate?: BigNumber;
  feePoints: FeePoint[];
  collateralRate?: BigNumber;
  marginCallRate?: BigNumber;
  minHealthRate?: BigNumber;
  liquidationRate?: BigNumber;
  totalInterest?: string;
  totalInterestCurrency?: string;
  settlementPriceAmount?: BigNumber;
  settlementPriceCurrency?: string;
  type?: LoanType;
  startDate?: string;
  endDate?: string;
  paymentPeriodType?: PaymentPeriodTypeEnum;
  paymentPeriod?: string;
  paymentAccountId?: string;
  beneficiaryAccountId?: string;
  payments?: LoanPayment[];
  currentLoanOfferId?: string;
  initialLoanOfferId?: string;
  status?: LoanStatus;
  statusDescription?: string;
}
