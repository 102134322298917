import { observer } from 'mobx-react-lite';
import { useCallback, useLayoutEffect } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import {
  OperationStoreProvider,
  useOperationStore,
} from './provider/OperationStoreProvider';
import { UserModel } from 'models/UserModel';
import { WithdrawPanel } from './withdraw-panel';
import { ExchangePanel } from './exchange-panel';
import { TransferPanel } from './transfer-panel';
import { useIntl } from 'react-intl';
import { OperationType } from 'api/wallet';
import { DepositPanel } from './deposit-panel';

export type CreateGroupUserForm = {
  name: string;
  users: UserModel[];
};

const SidebarOperationCreate = observer(
  ({ onSubmit, accountId }: { onSubmit: () => void; accountId?: string }) => {
    const { type } = useOperationStore();
    const intl = useIntl();
    const { layoutStore } = useBaseStores();

    useLayoutEffect(() => {
      layoutStore.setSidebarTitle(
        intl.formatMessage({
          id: `sidebar.operation.${type}`,
          defaultMessage: type[0] + type.slice(1).toLowerCase(),
        })
      );
    }, [layoutStore, intl, type]);

    const handleSubmit = () => {
      layoutStore.toggleSidebar(false);
      onSubmit();
    };

    if (type === OperationType.DEPOSIT)
      return <DepositPanel accountId={accountId} handleSubmit={handleSubmit} />;
    if (type === OperationType.EXCHANGE)
      return (
        <ExchangePanel accountId={accountId} handleSubmit={handleSubmit} />
      );
    if (type === OperationType.TRANSFER)
      return (
        <TransferPanel accountId={accountId} handleSubmit={handleSubmit} />
      );
    return <WithdrawPanel accountId={accountId} handleSubmit={handleSubmit} />;
  }
);

export const useSidebarOperationCreate = ({
  onSubmit,
}: {
  onSubmit: () => void;
}) => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(
    (userId: string, type: OperationType, accountId?: string) => {
      layoutStore.toggleSidebar(
        true,
        intl.formatMessage({
          id: `sidebar.operation.${type}`,
          defaultMessage: type[0] + type.slice(1).toLowerCase(),
        }),
        <OperationStoreProvider userId={userId} type={type}>
          <SidebarOperationCreate onSubmit={onSubmit} accountId={accountId} />
        </OperationStoreProvider>
      );
    },
    [layoutStore, onSubmit, intl]
  );

  return {
    showCreateUserGroupSidebar: showSidebar,
  };
};
