/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import classNames from 'classnames';

import { Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { ReactComponent as CopySvg } from 'assets/images/icons/copy.svg';

type Props = {
  text: string;
  className?: string;
};
const CopyButton = ({ text, className }: Props) => {
  const intl = useIntl();

  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  const onTooltipChange = useCallback(() => {
    setCopied(false);
  }, []);

  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <Tooltip
        title={
          copied === true
            ? intl.formatMessage({
                id: 'tooltip.copied',
                defaultMessage: 'Copied',
              })
            : intl.formatMessage({
                id: 'tooltip.copy',
                defaultMessage: 'Copy',
              })
        }
        onOpenChange={onTooltipChange}
      >
        <button className={classNames(className)}>
          <CopySvg />
        </button>
      </Tooltip>
    </CopyToClipboard>
  );
};

export default CopyButton;
