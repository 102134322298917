/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useIntl } from 'react-intl';
import { useCallback } from 'react';

export const useIntlUserApproved = () => {
  const intl = useIntl();

  const approvedBooleanToIntlText = useCallback(
    (approved: boolean) =>
      approved === true
        ? intl.formatMessage({
            id: 'common.approved',
            defaultMessage: 'Approved',
          })
        : intl.formatMessage({
            id: 'common.not_approved',
            defaultMessage: 'Not approved',
          }),
    [intl]
  );

  return {
    approvedBooleanToIntlText,
  };
};
