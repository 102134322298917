/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { ApproverApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable, runInAction } from 'mobx';
import { FilterModel } from 'models/filter/FilterModel';
import { AdminTxRequestModel } from 'models/request/AdminTransactionsModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import {
  readNumberFromQueryParams,
  readStrFromQueryParams,
} from 'utils/queryParams';
import { TransactionApproverModel } from 'models/TransactionsApproverModel';
import { RejectConfirmWithdrawalData } from 'api/approver';
import { NotificationStore } from 'stores/NotificationStore';
import { IntlShape } from 'react-intl';
import dayjs from 'dayjs';

export class TransactionsPageStore {
  constructor(
    private approverApi: ApproverApi,
    private notificationStore: NotificationStore,
    private intl: IntlShape
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  private _loading: boolean = false;

  private _transactions = new CollectionWithPages<
    TransactionApproverModel,
    AdminTxRequestModel
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result =
        await this.approverApi.requestsController.findConfirmRequests(params);
      const items = result.data.data.map(
        (x) => new TransactionApproverModel(x)
      );

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminTxRequestModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.search
      );

      const from = readNumberFromQueryParams(urlParams, URL_SEARCH_PARAMS.from);

      const to = readNumberFromQueryParams(urlParams, URL_SEARCH_PARAMS.to);

      const status = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.status
      );

      const requestType = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.requestType
      );

      if (status) {
        filters.push({
          id: URL_SEARCH_PARAMS.status,
          value: {
            status,
          },
        });
      }

      if (requestType) {
        filters.push({
          id: URL_SEARCH_PARAMS.requestType,
          value: {
            requestType,
          },
        });
      }

      if (from) {
        filters.push({
          id: URL_SEARCH_PARAMS.from,
          value: {
            from: dayjs.unix(from).toISOString(),
          },
        });
      }

      if (to) {
        filters.push({
          id: URL_SEARCH_PARAMS.to,
          value: {
            to: dayjs.unix(to).toISOString(),
          },
        });
      }

      if (searchStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.search,
          value: {
            search: searchStr,
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  approveTransaction = async (
    id: string,
    data: RejectConfirmWithdrawalData
  ) => {
    try {
      runInAction(() => {
        this._loading = true;
      });
      await this.approverApi.requestsController.approveConfirmRequest(
        id as string,
        data
      );

      this.notificationStore.notify({
        title: this.intl.formatMessage({
          id: 'sidebar.transactionReview.result.ApprovedSuccess',
          defaultMessage: 'Transaction Approved Successfully',
        }),
        type: 'success',
      });

      await this._transactions.refresh();
    } catch (error) {
      this.notificationStore.handleApiError(error);
    } finally {
      runInAction(() => {
        this._loading = false;
      });
    }
  };

  rejectTransaction = async (id: string, data: RejectConfirmWithdrawalData) => {
    try {
      runInAction(() => {
        this._loading = true;
      });
      await this.approverApi.requestsController.rejectConfirmRequest(
        id as string,
        data
      );

      this.notificationStore.notify({
        title: this.intl.formatMessage({
          id: 'sidebar.transactionReview.result.RejectedSuccess',
          defaultMessage: 'Transaction Rejected Successfully',
        }),
        type: 'success',
      });

      await this._transactions.refresh();
    } catch (error) {
      this.notificationStore.handleApiError(error);
    } finally {
      runInAction(() => {
        this._loading = false;
      });
    }
  };

  get loading() {
    return this._loading;
  }

  get transactions() {
    return this._transactions;
  }

  async init(props: {
    filter: FilterModel<AdminTxRequestModel>[];
    page: number;
  }) {
    const { filter, page } = props;

    await this._transactions.fetchData(filter, page);
  }
}
