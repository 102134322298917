/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const LoanMarginCallsTab = observer(() => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.loans.tabs.margin_calls',
        defaultMessage: 'Margin calls',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  return <div></div>;
});

export default LoanMarginCallsTab;
