/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import Table from '../core/table';

import { useIntl } from 'react-intl';
import { cutId } from 'utils/strings';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { observer } from 'mobx-react-lite';
import { useIntlLoanTypes } from 'hooks/intl/loan-types';
import { TableProps } from 'antd';
import { LoanOfferModel } from 'models/LoanOfferModel';
import { LoanType } from 'api/loans';

type Props = {
  collection: CollectionWithPages<LoanOfferModel, {}>;
};

const LoanOffersTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { loanTypeToIntlText } = useIntlLoanTypes();

  const columns: TableProps<LoanOfferModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.loan_offers.col.id',
        defaultMessage: 'ID',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{cutId(id)}</span>,
      width: 200,
    },
    {
      title: intl.formatMessage({
        id: 'table.loan_offers.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      width: 50,
    },
    {
      title: intl.formatMessage({
        id: 'table.loan_offers.col.type',
        defaultMessage: 'Type',
      }),
      dataIndex: 'type',
      key: 'type',
      render: (type: LoanType) => (
        <span>{loanTypeToIntlText(type ?? LoanType.UNDEFINED)}</span>
      ),
      width: 50,
    },
  ];

  return <Table collection={collection} columns={columns} />;
});

export default LoanOffersTable;
