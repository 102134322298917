/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AdminUserScope } from 'api/auth';

export { VerificationStatus as UserVerificationStatus } from 'api/auth';

export class AdminUserScopesModel {
  constructor(dto: AdminUserScope) {
    this.id = dto.id?.toString() ?? '';
    this.slug = dto.slug;
    this.description = dto.description;
  }

  id: string;
  description?: string;
  slug?: string;
}
