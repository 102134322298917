/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { observer } from 'mobx-react-lite';
import { TransactionApproverModel } from 'models/TransactionsApproverModel';
import { formattedDate } from 'utils/dates';
import { TableProps } from 'antd';
import Table from 'components/core/table';
import BadgeTransactionStatus, {
  CombinedTransactionStatus,
} from 'components/core/badges/badge-transaction-status';

type Props = {
  collection: CollectionWithPages<TransactionApproverModel, {}>;
  onTransactionClick?: (transaction: TransactionApproverModel) => void;
};
const TransactionsApproverTable = observer(
  ({ collection, onTransactionClick }: Props) => {
    const intl = useIntl();

    const columns: TableProps<TransactionApproverModel>['columns'] = [
      {
        title: intl.formatMessage({
          id: 'table.transactions_approver.col.id',
          defaultMessage: 'Id',
        }),
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => <span>{id}</span>,
        width: 20,
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions_approver.col.date',
          defaultMessage: 'Date',
        }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt: string) => (
          <span>{formattedDate(createdAt ?? '')}</span>
        ),
        width: 20,
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions_approver.col.type',
          defaultMessage: 'Type',
        }),
        dataIndex: 'type',
        key: 'type',
        render: (type: string) => <span>{type}</span>,
        width: 10,
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions_approver.col.change',
          defaultMessage: 'Amount change',
        }),
        dataIndex: 'amount',
        key: 'amount',
        render: (text: string, record: TransactionApproverModel) => (
          <span>
            {(Number(record?.amount) ?? 0).toString()} {record.symbol}
          </span>
        ),
        width: 30,
      },
      {
        title: intl.formatMessage({
          id: 'table.transactions_approver.col.status',
          defaultMessage: 'Status',
        }),
        dataIndex: 'status',
        key: 'status',
        render: (status: CombinedTransactionStatus) => (
          <BadgeTransactionStatus status={status} />
        ),
        width: 10,
      },
    ];

    return (
      <Table<TransactionApproverModel, {}>
        collection={collection}
        columns={columns}
        onRowClick={onTransactionClick}
      />
    );
  }
);

export default TransactionsApproverTable;
