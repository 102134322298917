import styles from './index.module.css';
import FormField from 'components/form/form-field';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import FormLabel from 'components/form/form-label';
import { CreateGroupUserForm } from '..';
import { useGroupsDetailsPageStore } from '../provider/GroupsDetailsStoreProvider';
import { AdminGroupModel } from 'models/GroupModel';
import { useState } from 'react';

type InfoProps = {
  isCreate?: boolean;
  group?: AdminGroupModel;
};

export const Info = observer(({ isCreate, group }: InfoProps) => {
  const intl = useIntl();
  const [isEdit, setIsEdit] = useState(false);

  const { control } = useFormContext<CreateGroupUserForm>();
  const store = useGroupsDetailsPageStore();

  return (
    <>
      {isCreate ? (
        <>
          <Controller
            control={control}
            name='name'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                required
                label={intl.formatMessage({
                  id: 'sidebar.create_offer.name',
                  defaultMessage: 'Name',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_offer.name.placeholder',
                  defaultMessage: 'Enter offer name...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </>
      ) : (
        <div className={styles.block}>
          <div className={styles.row}>
            <FormLabel
              text={intl.formatMessage({
                id: 'sidebar.create_group.group_information',
                defaultMessage: 'Group information',
              })}
            />
            <button className={styles.edit} onClick={() => setIsEdit(true)}>
              {intl.formatMessage({
                id: 'sidebar.create_group.edit',
                defaultMessage: 'Edit',
              })}
            </button>
          </div>
          <div className={styles.details}>
            <div className={styles['details-item']}>
              <span className={styles['details-label']}>
                {intl.formatMessage({
                  id: 'sidebar.create_group.name',
                  defaultMessage: 'Name',
                })}
              </span>

              <Controller
                control={control}
                name='name'
                render={({ field: { value, onChange } }) =>
                  isEdit ? (
                    <input
                      value={value}
                      className={styles['input']}
                      onChange={onChange}
                    />
                  ) : (
                    <span className={styles['details-value']}>
                      {group?.name}
                    </span>
                  )
                }
              />
            </div>
            <div className={styles['details-item']}>
              <span className={styles['details-label']}>
                {intl.formatMessage({
                  id: 'sidebar.create_group.group_id',
                  defaultMessage: 'Group ID',
                })}
              </span>
              <span className={styles['details-value']}>{group?.id}</span>
            </div>
            <div className={styles['details-item']}>
              <span className={styles['details-label']}>
                {intl.formatMessage({
                  id: 'sidebar.create_group.total_users',
                  defaultMessage: 'Total Users',
                })}
              </span>
              <span className={styles['details-value']}>
                {store.members.items.length}
              </span>
            </div>
          </div>
        </div>
      )}

      {/* <div className={styles.block}>
        <FormLabel
          text={intl.formatMessage({
            id: 'sidebar.create_group.condition_filters',
            defaultMessage: 'Condition filters',
          })}
        />
        <Button className={styles.button} size='small' variant='primary'>
          {intl.formatMessage({
            id: 'sidebar.create_group.add_filter',
            defaultMessage: 'Add filter',
          })}
          <FaPlus />
        </Button>
      </div>

      <div className={styles.block}>
        <FormLabel
          text={intl.formatMessage({
            id: 'sidebar.create_offer.exceptions',
            defaultMessage: 'Exceptions',
          })}
        />
        <div className={styles.row}>
          <Tag bordered={false} closable={isEdit}>
            Tag 3
          </Tag>
          <Button className={styles.button} size='small' variant='primary'>
            {intl.formatMessage({
              id: 'sidebar.create_group.add_exception',
              defaultMessage: 'Add exception',
            })}
            <FaPlus />
          </Button>
        </div>
      </div>
      <div className={styles.block}>
        <FormLabel
          text={intl.formatMessage({
            id: 'sidebar.create_group.group_color',
            defaultMessage: 'Group colour',
          })}
        />
      </div>

      <Controller
        control={control}
        name='description'
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            type='text_area'
            label={intl.formatMessage({
              id: 'sidebar.create_group.note',
              defaultMessage: 'Note',
            })}
            placeholder={intl.formatMessage({
              id: 'sidebar.create_group.note_placeholder',
              defaultMessage: 'Enter offer description...',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      /> */}
    </>
  );
});
