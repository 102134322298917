/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  ACQUIRING_API_URL,
  CARDHOLDER_API_URL,
  APPROVER_API_URL,
  AUTH_API_URL,
  CURRENCY_API_URL,
  WALLET_API_URL,
} from 'assets/config';
import {
  ApproverApi,
  AuthApi,
  CurrencyApi,
  WalletApi,
  AcquiringApi,
  CardHolderApi,
  LoansApi,
} from 'api';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getAuthSecurityWorker } from 'utils/api';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { AuthStore } from 'stores/AuthStore';
import { LanguageStore } from 'stores/LanguageStore';
import { LayoutStore } from 'stores/LayoutStore';
import { PartnerStore } from 'stores/PartnerStore';
import { CurrencyStore } from 'stores/CurrencyStore';
import { NotificationStore } from 'stores/NotificationStore';

type BaseStores = {
  notificationStore: NotificationStore;
  authStore: AuthStore;
  currencyStore: CurrencyStore;
  languageStore: LanguageStore;
  layoutStore: LayoutStore;
  partnerStore: PartnerStore;
  authApi: AuthApi;
  loansApi: LoansApi;
  currencyApi: CurrencyApi;
  walletApi: WalletApi;
  approverApi: ApproverApi;
  acquiringApi: AcquiringApi;
  cardHolderApi: CardHolderApi;
};

const baseStoreContext = createContext<BaseStores>({} as BaseStores);

export const ProvideBaseStores = observer(
  ({ children }: { children?: ReactNode }) => {
    const [initialized, setInitialized] = useState(false);

    const apis = useLocalObservable(() => ({
      auth: new AuthApi({
        baseURL: AUTH_API_URL,
        secure: true,
        securityWorker: getAuthSecurityWorker,
      }),
      loans: new LoansApi({
        baseURL: AUTH_API_URL,
        secure: true,
        securityWorker: getAuthSecurityWorker,
      }),
      wallet: new WalletApi({
        baseURL: WALLET_API_URL,
        secure: true,
        securityWorker: getAuthSecurityWorker,
      }),
      approver: new ApproverApi({
        baseURL: APPROVER_API_URL,
        secure: true,
        securityWorker: getAuthSecurityWorker,
      }),
      acquiring: new AcquiringApi({
        baseURL: ACQUIRING_API_URL,
        secure: true,
        securityWorker: getAuthSecurityWorker,
      }),
      cardHolder: new CardHolderApi({
        baseURL: CARDHOLDER_API_URL,
        secure: true,
        securityWorker: getAuthSecurityWorker,
      }),
      currency: new CurrencyApi({
        baseURL: CURRENCY_API_URL,
        secure: true,
        securityWorker: getAuthSecurityWorker,
      }),
    }));

    const notificationStore = useLocalObservable(() => new NotificationStore());
    const authStore = useLocalObservable(
      () => new AuthStore(apis.auth, Object.values(apis))
    );
    const partnerStore = useLocalObservable(
      () => new PartnerStore(apis.auth, authStore)
    );
    const currencyStore = useLocalObservable(
      () => new CurrencyStore(apis.currency)
    );
    const layoutStore = useLocalObservable(() => new LayoutStore());
    const languageStore = useLocalObservable(() => new LanguageStore());

    const stores = useMemo(
      () => ({
        notificationStore,
        authStore,
        currencyStore,
        languageStore,
        layoutStore,
        partnerStore,
        authApi: apis.auth,
        currencyApi: apis.currency,
        walletApi: apis.wallet,
        approverApi: apis.approver,
        acquiringApi: apis.acquiring,
        cardHolderApi: apis.cardHolder,
        loansApi: apis.loans,
      }),
      [
        apis,
        authStore,
        currencyStore,
        languageStore,
        layoutStore,
        partnerStore,
        notificationStore,
      ]
    );

    useEffect(() => {
      const init = async () => {
        languageStore.init();
        layoutStore.init();

        await authStore.init();
        await currencyStore.init();

        setInitialized(true);
      };

      init();

      return () => {};
    }, [authStore, currencyStore, languageStore, layoutStore, partnerStore]);

    if (!initialized) {
      return null;
    }

    return (
      <baseStoreContext.Provider value={stores}>
        <IntlProvider
          defaultLocale={languageStore.language.id}
          locale={languageStore.language.id}
          messages={languageStore.messages}
        >
          <BrowserRouter>{children}</BrowserRouter>
        </IntlProvider>
      </baseStoreContext.Provider>
    );
  }
);

export const useBaseStores = () => {
  return useContext(baseStoreContext);
};
