/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { BalanceLogReasonType } from 'api/wallet';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useIntlTxReason = () => {
  const intl = useIntl();

  const balanceReasonTypeMapping: Record<BalanceLogReasonType, string> =
    useMemo(() => {
      return {
        [BalanceLogReasonType.ORDER]: intl.formatMessage({
          id: 'common.reasonType.order',
          defaultMessage: 'Order',
        }),
        [BalanceLogReasonType.TRANSFER]: intl.formatMessage({
          id: 'common.reasonType.transfer',
          defaultMessage: 'Transfer',
        }),
        [BalanceLogReasonType.DEPOSIT]: intl.formatMessage({
          id: 'common.reasonType.deposit',
          defaultMessage: 'Deposit',
        }),
        [BalanceLogReasonType.BTC_DEPOSIT]: intl.formatMessage({
          id: 'common.reasonType.btc_deposit',
          defaultMessage: 'BTC deposit',
        }),
        [BalanceLogReasonType.ETH_DEPOSIT]: intl.formatMessage({
          id: 'common.reasonType.eth_deposit',
          defaultMessage: 'ETH deposit',
        }),
        [BalanceLogReasonType.TON_DEPOSIT]: intl.formatMessage({
          id: 'common.reasonType.ton_deposit',
          defaultMessage: 'TON deposit',
        }),
        [BalanceLogReasonType.BTC_WITHDRAWAL_REQUEST]: intl.formatMessage({
          id: 'common.reasonType.btc_withdrawal_request',
          defaultMessage: 'BTC withdrawal request',
        }),
        [BalanceLogReasonType.BTC_WITHDRAWAL_REJECT]: intl.formatMessage({
          id: 'common.reasonType.btc_withdrawal_reject',
          defaultMessage: 'BTC withdrawal reject',
        }),
        [BalanceLogReasonType.BTC_WITHDRAWAL_FAIL]: intl.formatMessage({
          id: 'common.reasonType.btc_withdrawal_fail',
          defaultMessage: 'BTC withdrawal fail',
        }),
        [BalanceLogReasonType.ETH_WITHDRAWAL_REQUEST]: intl.formatMessage({
          id: 'common.reasonType.eth_withdrawal_request',
          defaultMessage: 'ETH withdrawal request',
        }),
        [BalanceLogReasonType.ETH_WITHDRAWAL_REJECT]: intl.formatMessage({
          id: 'common.reasonType.eth_withdrawal_reject',
          defaultMessage: 'ETH withdrawal reject',
        }),
        [BalanceLogReasonType.ETH_WITHDRAWAL_FAIL]: intl.formatMessage({
          id: 'common.reasonType.eth_withdrawal_fail',
          defaultMessage: 'ETH withdrawal fail',
        }),
        [BalanceLogReasonType.TON_WITHDRAWAL_REQUEST]: intl.formatMessage({
          id: 'common.reasonType.ton_withdrawal_request',
          defaultMessage: 'TON withdrawal request',
        }),
        [BalanceLogReasonType.TON_WITHDRAWAL_REJECT]: intl.formatMessage({
          id: 'common.reasonType.ton_withdrawal_reject',
          defaultMessage: 'TON withdrawal reject',
        }),
        [BalanceLogReasonType.TON_WITHDRAWAL_FAIL]: intl.formatMessage({
          id: 'common.reasonType.ton_withdrawal_fail',
          defaultMessage: 'TON withdrawal fail',
        }),
        [BalanceLogReasonType.FIAT_WITHDRAWAL_REQUEST]: intl.formatMessage({
          id: 'common.reasonType.fiat_withdrawal_request',
          defaultMessage: 'FIAT withdrawal request',
        }),
        [BalanceLogReasonType.FIAT_WITHDRAWAL_REJECT]: intl.formatMessage({
          id: 'common.reasonType.fiat_withdrawal_reject',
          defaultMessage: 'FIAT withdrawal reject',
        }),
        [BalanceLogReasonType.DEPOSIT_FEE]: intl.formatMessage({
          id: 'common.reasonType.deposit_fee',
          defaultMessage: 'Deposit fee',
        }),
        [BalanceLogReasonType.WITHDRAWAL]: intl.formatMessage({
          id: 'common.reasonType.withdrawal',
          defaultMessage: 'Withdrawal',
        }),
        [BalanceLogReasonType.WITHDRAWAL_FEE]: intl.formatMessage({
          id: 'common.reasonType.withdrawal_fee',
          defaultMessage: 'Withdrawal fee',
        }),
        [BalanceLogReasonType.REJECTED_WITHDRAWAL_FEE]: intl.formatMessage({
          id: 'common.reasonType.rejected_withdrawal_fee',
          defaultMessage: 'Rejected withdrawal fee',
        }),
        [BalanceLogReasonType.FAILED_WITHDRAWAL_FEE]: intl.formatMessage({
          id: 'common.reasonType.failed_withdrawal_fee',
          defaultMessage: 'Failed withdrawal fee',
        }),
        [BalanceLogReasonType.ORDER_FEE]: intl.formatMessage({
          id: 'common.reasonType.order_fee',
          defaultMessage: 'Order fee',
        }),
        [BalanceLogReasonType.DIRECT_EXCHANGE]: intl.formatMessage({
          id: 'common.reasonType.direct_exchange',
          defaultMessage: 'Direct exchange',
        }),
        [BalanceLogReasonType.DIRECT_EXCHANGE_FEE]: intl.formatMessage({
          id: 'common.reasonType.direct_exchange_fee',
          defaultMessage: 'Direct exchange fee',
        }),
        [BalanceLogReasonType.LOCK_BALANCE]: intl.formatMessage({
          id: 'common.reasonType.lock_balance',
          defaultMessage: 'Lock balance',
        }),
        [BalanceLogReasonType.UNLOCK_BALANCE]: intl.formatMessage({
          id: 'common.reasonType.unlock_balance',
          defaultMessage: 'Unlock balance',
        }),
        [BalanceLogReasonType.UNKNOWN]: intl.formatMessage({
          id: 'common.reasonType.unknown',
          defaultMessage: 'Unknown',
        }),
      };
    }, [intl]);

  const balanceReasonType = useCallback(
    (status: BalanceLogReasonType) =>
      balanceReasonTypeMapping[status] || 'Unknown',
    [balanceReasonTypeMapping]
  );

  return {
    balanceReasonType,
  };
};

export default useIntlTxReason;
