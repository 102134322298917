/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

const LayoutPage = ({ children }: { children?: React.ReactNode }) => {
  return <main className={styles.main}>{children}</main>;
};

export default LayoutPage;
