/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  AdminAtmWithdraw,
  AdminBankTransferWithdraw,
  AdminCryptoWithdraw,
  AdminWithdraw,
  WithdrawStatus,
  WithdrawType,
} from 'api/wallet';

export class WithdrawalModel {
  constructor(
    dto:
      | AdminWithdraw
      | AdminAtmWithdraw
      | AdminBankTransferWithdraw
      | AdminCryptoWithdraw
  ) {
    this.id = dto.id ?? '';
    this.currency = dto.currency;
    this.amount = dto.amount;
    this.type = dto.type;
    this.fromAccount = dto.fromAccount;
    this.feeAmount = dto.feeAmount;
    this.status = dto.status;
    this.createdAt = dto.createdAt;
    this.updatedAt = dto.updatedAt;

    // AdminCryptoWithdraw
    if ('to' in dto) {
      this.toAccount = dto.to;
    }

    // AdminBankTransferWithdraw
    if ('iban' in dto) {
      this.iban = dto.iban;
    }
  }

  id: string;
  currency: string;
  amount: string;
  type: WithdrawType;
  fromAccount?: string;
  toAccount?: string;
  feeAmount?: string;
  status?: WithdrawStatus;
  createdAt?: string;
  updatedAt?: string;
  iban?: string;
}
