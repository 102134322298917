/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { observer } from 'mobx-react-lite';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import { useCallback, useEffect } from 'react';
import LoansTable from 'components/tables/loans-table';
import { useNavigate } from 'react-router-dom';
import { LoanModel } from 'models/LoanModel';
import { ROUTES } from 'routes';

const UserDetailsLoansTab = observer(() => {
  const userDetailsPageStore = useUserDetailsPageStore();
  const navigate = useNavigate();

  const onLoanClick = useCallback(
    (loan: LoanModel) => {
      navigate(ROUTES.loanDetails.getFullPath(loan.id));
    },
    [navigate]
  );

  const { page, filter } = useParseCollectionQueryParams({
    collection: userDetailsPageStore.loans,
  });

  useEffect(() => {
    userDetailsPageStore.loans.fetchData(filter, page);
  }, [filter, page, userDetailsPageStore.loans]);

  return (
    <>
      <LoansTable
        collection={userDetailsPageStore.loans}
        onLoanClick={onLoanClick}
      />
    </>
  );
});

export default UserDetailsLoansTab;
