/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const cutId = (id: string) => {
  return cutString(id, 8, 6);
};

export const cutString = (str: string, takeFirst: number, takeLast: number) => {
  if (!str || str.length <= takeFirst + takeLast) {
    return str;
  }

  return (
    str.substring(0, takeFirst) +
    '...' +
    str.substring(str.length - takeLast, str.length)
  );
};

export const capitalizeString = (str: string) => {
  if (!str || str.length === 0) {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const sanitizeNumberString = (str: string) => {
  if (!str || str.length === 0) {
    return '';
  }

  return str.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
};

export const transformBackendString = (value: string) => {
  return value
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^([a-z])/, function (match) {
      return match.toUpperCase();
    });
};
