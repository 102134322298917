/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React, { useState } from 'react';
import Button from 'components/core/button';
import FormListSingleSelect from 'components/form/form-list-single-select';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { SelectItemModel } from 'models/form/SelectItemModel';
import { CardsModel } from 'models/CardsModel';
import { AdminCardsModel } from 'models/request/AdminCardsModel';
import { CardStatus } from 'api/card-holder';
import SearchField from 'components/core/search-field';

const CARDS_STATUSES: CardStatus[] = [
  CardStatus.INIT,
  CardStatus.PENDING,
  CardStatus.ISSUED,
  CardStatus.ACTIVE,
  CardStatus.FROZEN,
  CardStatus.LOST,
  CardStatus.STOLEN,
  CardStatus.INACTIVE,
  CardStatus.CLOSED,
  CardStatus.REJECTED,
];

const selectOptionsCards = CARDS_STATUSES.map((type) => ({
  id: type.toLocaleUpperCase(),
  label: type,
  value: type,
}));

type Props = {
  collection: CollectionWithPages<CardsModel, AdminCardsModel>;
};

const SidebarCardsFilter = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();
  const { setFilterQueryParams } = useSetQueryParams();

  const [selectType, setSelectType] = useState<
    SelectItemModel<string> | undefined
  >(selectOptionsCards.find((x) => x.id === collection.filter.status));

  const [userId, setUserId] = useState<string>(collection.filter.userId || '');

  const onChangeSelectType = (val: SelectItemModel<string> | undefined) => {
    setSelectType(val);
  };

  const onChangeUserId = (val: string | '') => {
    setUserId(val);
  };

  const onApply = useCallback(() => {
    setFilterQueryParams([
      { id: URL_SEARCH_PARAMS.page, value: '1' },

      {
        id: URL_SEARCH_PARAMS.status,
        value: selectType ? selectType.id : undefined,
      },

      {
        id: URL_SEARCH_PARAMS.userId,
        value: userId,
      },
    ]);

    layoutStore.toggleSidebar(false);
  }, [layoutStore, setFilterQueryParams, selectType, userId]);

  return (
    <React.Fragment>
      <h5>
        {intl.formatMessage({
          id: 'sidebar.filters.cardsUserIdTitle',
          defaultMessage: 'User Id',
        })}
      </h5>
      <SearchField
        className={'mb-4'}
        placeholder={intl.formatMessage({
          id: 'sidebar.filters.cardsUserId',
          defaultMessage: 'Search by User ID',
        })}
        value={userId}
        onChange={onChangeUserId}
      />

      <FormListSingleSelect
        title={intl.formatMessage({
          id: 'sidebar.filters.cardStatus',
          defaultMessage: 'Cards status',
        })}
        options={selectOptionsCards}
        selected={selectType}
        onSelect={onChangeSelectType}
        collapse={true}
      />

      <Button className={styles.apply_btn} variant='primary' onClick={onApply}>
        {intl.formatMessage({
          id: 'sidebar.filters.apply',
          defaultMessage: 'Apply',
        })}
      </Button>
    </React.Fragment>
  );
});

export const useSidebarCardsFilter = ({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.filters',
        defaultMessage: 'Filters',
      }),
      <SidebarCardsFilter collection={collection} />
    );
  }, [collection, intl, layoutStore]);

  return {
    showCardsFiltersSidebar: showSidebar,
  };
};
