import styles from './index.module.css';

import { ROUTES } from 'routes';
import { observer } from 'mobx-react-lite';

import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { UserModel } from 'models/UserModel';
import { useNavigate } from 'react-router-dom';
import UsersTable from 'components/tables/users-table';
import { useUsersPageStore } from '../provider/UsersPageStoreProvider';

export const UsersTab = observer(() => {
  const intl = useIntl();
  const navigate = useNavigate();
  const usersPageStore = useUsersPageStore();

  const totalUsersText = useMemo(
    () =>
      usersPageStore.users.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.users.total',
              defaultMessage: 'Total {users}',
            },
            {
              users: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.users.total.count',
                      defaultMessage: '{count} users',
                    },
                    { count: usersPageStore.users.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, usersPageStore.users.totalItems]
  );

  const onUserClick = useCallback(
    (user: UserModel) => {
      navigate(ROUTES.userDetails.getFullPath(user.id));
    },
    [navigate]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.users.title',
        defaultMessage: 'Users',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalUsersText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalUsersText]);

  return (
    <UsersTable collection={usersPageStore.users} onUserClick={onUserClick} />
  );
});
