/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { TableProps } from 'antd';
import Table from '../core/table';
import { SubscriptionsUserModel } from 'models/SubscriptionsUserModel';
import { bigNumberToStr } from 'utils/bignumber';
import Button from '../core/button';
import { RecurrentInvoiceStatus } from '../../api/auth';
import { cutId } from 'utils/strings';
import CopyButton from 'components/core/copy-button';

type Props = {
  collection: CollectionWithPages<SubscriptionsUserModel, {}>;
  onCancel: (subscription: SubscriptionsUserModel) => void;
  onDelete: (subscription: SubscriptionsUserModel) => void;
};

const SubscriptionsTable = observer(
  ({ collection, onCancel, onDelete }: Props) => {
    const intl = useIntl();

    const columns: TableProps<SubscriptionsUserModel>['columns'] = [
      {
        title: intl.formatMessage({
          id: 'table.subscriptions.col.id',
          defaultMessage: 'Id',
        }),
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => (
          <div className='flex items-center gap-2'>
            <span>{cutId(id)}</span>
            <CopyButton text={id} />
          </div>
        ),
        width: 20,
      },
      {
        title: intl.formatMessage({
          id: 'table.invoices.col.name',
          defaultMessage: 'Name',
        }),
        dataIndex: 'details',
        key: 'details',
        render: (_, record) => <span>{record.details?.name || '-'}</span>,
        width: 30,
      },
      {
        title: intl.formatMessage({
          id: 'table.invoices.col.amount',
          defaultMessage: 'Amount',
        }),
        dataIndex: 'amount',
        key: 'amount',
        render: (_, record) => (
          <span>
            {bigNumberToStr(record.amount)} {record.currency}
          </span>
        ),
        width: 30,
      },
      {
        title: intl.formatMessage({
          id: 'table.subscriptions.col.initialPaymentAmount',
          defaultMessage: 'Initial Payment Amount',
        }),
        dataIndex: 'initialPaymentAmount',
        key: 'initialPaymentAmount',
        render: (initialPaymentAmount) => (
          <span>{bigNumberToStr(initialPaymentAmount)}</span>
        ),
        width: 30,
      },
      {
        title: intl.formatMessage({
          id: 'table.subscriptions.col.status',
          defaultMessage: 'Status',
        }),
        dataIndex: 'status',
        key: 'status',
        render: (status) => <span>{status}</span>,
        width: 30,
      },
      {
        title: intl.formatMessage({
          id: 'table.subscriptions.col.controls',
          defaultMessage: 'Controls',
        }),
        dataIndex: 'controls',
        key: 'controls',
        render: (_, record) => (
          <div className='flex flex-wrap gap-1'>
            <Button
              variant={'secondary'}
              size={'small'}
              className='font-semibold'
              disabled={[
                RecurrentInvoiceStatus.CANCELLED,
                RecurrentInvoiceStatus.DEACTIVATING,
                RecurrentInvoiceStatus.PAYMENT_FAILED,
                RecurrentInvoiceStatus.PAYMENT_REJECTED,
              ].includes(record.status)}
              onClick={() => onCancel(record)}
            >
              {intl.formatMessage({
                id: 'table.subscriptions.col.cancel',
                defaultMessage: 'Cancel',
              })}
            </Button>

            <Button
              variant={'secondary'}
              size={'small'}
              className='font-semibold'
              onClick={() => onDelete(record)}
              disabled={record.deleted}
            >
              {intl.formatMessage({
                id: 'table.subscriptions.col.delete',
                defaultMessage: 'Delete',
              })}
            </Button>
          </div>
        ),
        width: 10,
      },
    ];

    return (
      <Table<SubscriptionsUserModel, {}>
        collection={collection}
        columns={columns}
      />
    );
  }
);

export default SubscriptionsTable;
