/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import TransactionsFilter from './tx-filter';

import { observer } from 'mobx-react-lite';
import {
  ProvideTransactionsPageStore,
  useTransactionsPageStore,
} from './provider/StoreProvider';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import TransactionsHistoryTable from 'components/tables/transactions-history-table';

const TransactionsPageWithProvider = observer(() => {
  const intl = useIntl();
  const transactionsPageStore = useTransactionsPageStore();

  const totalText = useMemo(
    () =>
      transactionsPageStore.transactions.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.transactions.total',
              defaultMessage: 'Total {transactions}',
            },
            {
              transactions: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.transactions.total.count',
                      defaultMessage: '{count} transactions',
                    },
                    { count: transactionsPageStore.transactions.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, transactionsPageStore.transactions.totalItems]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.transactions.title',
        defaultMessage: 'Transactions history',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalText]);

  return (
    <div className={styles.main}>
      <TransactionsFilter collectData={transactionsPageStore.transactions} />
      <TransactionsHistoryTable
        collection={transactionsPageStore.transactions}
      />
    </div>
  );
});

const TransactionsAllPage = () => {
  return (
    <ProvideTransactionsPageStore>
      <TransactionsPageWithProvider />
    </ProvideTransactionsPageStore>
  );
};

export default TransactionsAllPage;
