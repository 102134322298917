/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Image from 'components/core/image';
import DashboardSection from '../section';

import { observer } from 'mobx-react-lite';
import { useDashboardPageStore } from '../provider/DashboardPageStoreProvider';
import { useIntl } from 'react-intl';
import { unixTsToDateStr } from 'utils/dates';
import { bigNumberToStr } from 'utils/bignumber';
import { EURO_SIGN } from 'assets/config';

const DashboardCurrencies = observer(() => {
  const intl = useIntl();
  const store = useDashboardPageStore();

  if (!store.currencies) {
    return null;
  }

  return (
    <DashboardSection
      title={intl.formatMessage({
        id: 'page.dashboard.exchange_rates',
        defaultMessage: 'Exchange currencies rates',
      })}
      additionalInfo={intl.formatMessage(
        {
          id: 'page.dashboard.exchange_rates.last_update',
          defaultMessage: 'Last update: {date}',
        },
        {
          date: unixTsToDateStr(store.lastUpdatedAt, 'DD MMMM HH:mm:ss'),
        }
      )}
    >
      <div className={styles.currencies_grid}>
        {store.currencies.map((x) => (
          <div key={x.id} className={styles.currency}>
            <div className={styles.img_section}>
              <Image
                className={styles.currency_img}
                src={x.iconUrl}
                width={28}
                height={28}
              />
              <span className={styles.short_name}>{x.name}</span>
            </div>
            <div className={styles.currency_info_row}>
              <div className={styles.currency_info_name}>
                <span className={styles.description}>{x.description}</span>
              </div>
              {!!x.eurPrice && (
                <div className={styles.price}>
                  {bigNumberToStr(x.eurPrice, 4)} {EURO_SIGN}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </DashboardSection>
  );
});

export default DashboardCurrencies;
