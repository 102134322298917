/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import Table from '../core/table';
import { useIntl } from 'react-intl';
import { cutId } from 'utils/strings';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { observer } from 'mobx-react-lite';
import { LoanModel } from 'models/LoanModel';
import { formattedDate } from 'utils/dates';
import { LoanType } from 'api/loans';
import { useIntlLoanTypes } from 'hooks/intl/loan-types';
import { bigNumberToStr } from 'utils/bignumber';
import { TableProps } from 'antd';
import BadgeLoanStatus from '../core/badges/badge-loan-status';

const DATE_FORMAT = 'DD.MM.YYYY';

type Props = {
  collection: CollectionWithPages<LoanModel, {}>;
  onLoanClick?: (loan: LoanModel) => void;
};

const LoansTable = observer(({ collection, onLoanClick }: Props) => {
  const intl = useIntl();

  const { loanTypeToIntlText } = useIntlLoanTypes();

  const columns: TableProps<LoanModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.loans.col.id',
        defaultMessage: 'ID',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{cutId(id)}</span>,
      width: 200,
    },
    {
      title: intl.formatMessage({
        id: 'table.loans.col.type',
        defaultMessage: 'Type',
      }),
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <span>{loanTypeToIntlText(type ?? LoanType.UNDEFINED)}</span>
      ),
      width: 50,
    },
    {
      title: intl.formatMessage({
        id: 'table.loans.col.amount',
        defaultMessage: 'Amount',
      }),
      dataIndex: 'principalAmount',
      key: 'principalAmount',
      render: (principalAmount, model) => (
        <span>
          {principalAmount
            ? `${bigNumberToStr(principalAmount, 2)} ${model.principalCurrency}`
            : ''}
        </span>
      ),
      width: 150,
    },
    {
      title: intl.formatMessage({
        id: 'table.loans.col.interest',
        defaultMessage: 'Interest rate',
      }),
      dataIndex: 'interestRate',
      key: 'interestRate',
      render: (interestRate) => (
        <span>{interestRate ? `${bigNumberToStr(interestRate, 2)}%` : ''}</span>
      ),
      width: 50,
    },
    {
      title: intl.formatMessage({
        id: 'table.loans.col.start_date',
        defaultMessage: 'Start date',
      }),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate) => (
        <span>{startDate ? formattedDate(startDate, DATE_FORMAT) : ''}</span>
      ),
      width: 100,
    },
    {
      title: intl.formatMessage({
        id: 'table.loans.col.end_date',
        defaultMessage: 'End date',
      }),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate) => (
        <span>{endDate ? formattedDate(endDate, DATE_FORMAT) : ''}</span>
      ),
      width: 100,
    },
    {
      title: intl.formatMessage({
        id: 'table.loans.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BadgeLoanStatus status={status} />,
      width: 50,
    },
  ];

  return (
    <Table<LoanModel, {}>
      collection={collection}
      columns={columns}
      onRowClick={onLoanClick}
    />
  );
});

export default LoansTable;
