/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';
import CopyButton from 'components/core/copy-button';

type Props = {
  label: string;
  value?: string | null | undefined;
  disableCopy?: boolean;
  className?: string;
};
const UserDetailsBasicInfoField = ({
  label,
  value,
  disableCopy,
  className,
}: Props) => {
  if (!value) {
    return null;
  }

  return (
    <div className={classNames(styles.main, className)}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>

      {disableCopy !== true && (
        <div className={styles.copy_btn}>
          <CopyButton text={value} />
        </div>
      )}
    </div>
  );
};

export default UserDetailsBasicInfoField;
