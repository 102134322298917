/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useIntl } from 'react-intl';
import { useCallback } from 'react';

export const useIntlUserEnabled = () => {
  const intl = useIntl();

  const enabledBooleanToIntlText = useCallback(
    (enabled: boolean) =>
      enabled === true
        ? intl.formatMessage({
            id: 'common.account_enabled',
            defaultMessage: 'Active',
          })
        : intl.formatMessage({
            id: 'common.account_disabled',
            defaultMessage: 'Disabled',
          }),
    [intl]
  );

  return {
    enabledBooleanToIntlText,
  };
};
