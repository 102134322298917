/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { parseBigNumberZero } from 'utils/bignumber';
import BigNumber from 'bignumber.js';
import { AdminCard, CardStatus } from '../api/card-holder';

export class CardsModel {
  constructor(dto: AdminCard) {
    this.id = dto.id ?? '';
    this.userId = dto.userId;
    this.accountId = dto.accountId ?? '';
    this.additionalCardInfo = dto.additionalCardInfo;
    this.status = dto.status;
    this.balance = parseBigNumberZero(dto.balance);

    this.last4 = dto.last4;
    this.cardholderName = dto.cardholderName;
    this.secondaryCardholderName = dto.secondaryCardholderName;
    this.expireDate = dto.expireDate;
    this.cardType = dto.cardType;
  }

  id: string;
  userId: string;
  accountId: string;
  additionalCardInfo?: object;
  status?: CardStatus;
  balance?: BigNumber;
  last4?: string;
  cardholderName?: string;
  secondaryCardholderName?: string;
  expireDate?: string;
  cardType?: string;
}
