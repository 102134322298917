import React from 'react';
import { CardInfo } from '../../сard-info';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormValues } from '..';

export const TotalInfo = observer(() => {
  const intl = useIntl();
  const { currencyStore } = useBaseStores();
  const { control } = useFormContext<FormValues>();
  const [currency, amount] = useWatch({
    control,
    name: ['currency', 'amount'],
  });

  // useEffect(() => {
  //   if (fromAccount) loadBalances(fromAccount);
  // }, [loadBalances, fromAccount]);

  return (
    <CardInfo
      options={[
        {
          value: `$${
            currencyStore.currenciesMap.get(currency)?.eurPrice?.toString() ||
            ''
          }`,
          title: intl.formatMessage({
            id: 'sidebar.operation.amount',
            defaultMessage: 'Spot price',
          }),
        },
        {
          value:
            currencyStore
              .getEuroValue(currency, Number(amount || 0))
              ?.toString() || '',
          title: intl.formatMessage({
            id: 'sidebar.operation.amount',
            defaultMessage: 'Total value',
          }),
        },
      ]}
    />
  );
});
