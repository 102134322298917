/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React, { useState } from 'react';
import styles from './index.module.css';
import { ReactComponent as More } from 'assets/images/icons/more.svg';
import { Popover } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import TextAreaField from 'components/core/text-area-field';
import Button from 'components/core/button';
import { useIntl } from 'react-intl';
import { AdminUserNoteModel } from 'models/AdminUserNoteModel';
import dayjs from 'dayjs';

type NoteProps = {
  note: AdminUserNoteModel;
  onDelete: (id: string) => void;
  onUpdate: (note: string) => void;
};

export const Note = ({ note, onDelete, onUpdate }: NoteProps) => {
  const intl = useIntl();
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { note: note.note },
  });

  const handleCancel = () => {
    reset();
    setIsEdit(false);
  };

  const handleSave = () => {
    handleSubmit(async (data) => {
      onUpdate(data.note);
      setIsEdit(false);
    })();
  };

  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <span>Admin</span>
        <div className={styles.row}>
          {note.createdAt && (
            <span className={styles.date}>
              {dayjs(note.createdAt).format('MMM DD, YYYY')} •{' '}
              {dayjs(note.createdAt).format('HH:mm')}
            </span>
          )}
          <Popover
            content={
              <div className={styles.actions}>
                <button
                  className={styles.action}
                  onClick={() => {
                    setIsEdit(true);
                    setIsOpen(false);
                  }}
                >
                  Edit
                </button>
                <button
                  className={styles.action}
                  onClick={() => onDelete(note.id)}
                >
                  Delete
                </button>
              </div>
            }
            open={isOpen}
            trigger='click'
            onOpenChange={(value) => setIsOpen(value)}
            arrow={false}
            placement='bottom'
          >
            <button>
              <More />
            </button>
          </Popover>
        </div>
      </div>
      {isEdit ? (
        <div className={styles.edit}>
          <Controller
            control={control}
            name='note'
            render={({ field }) => (
              <TextAreaField
                {...field}
                variant='secondary'
                placeholder='Write a note about the user here'
              />
            )}
          />
          <div className={styles.buttons}>
            <Button
              variant='secondary'
              className={styles.button}
              onClick={handleCancel}
            >
              {intl.formatMessage({
                id: 'page.user_details.notes.cancel',
                defaultMessage: 'Cancel',
              })}
            </Button>
            <Button
              variant='black'
              className={styles.button}
              onClick={handleSave}
            >
              {intl.formatMessage({
                id: 'page.user_details.notes.save',
                defaultMessage: 'Save',
              })}
            </Button>
          </div>
        </div>
      ) : (
        <p className={styles.p}>{note.note}</p>
      )}
    </div>
  );
};
