/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Image from 'components/core/image';
import FormLabel from '../form-label';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow.svg';

import { Checkbox } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { SelectItemModel } from 'models/form/SelectItemModel';

export type layoutVariant = '2col' | '3col';

type Props<T> = {
  title: string;
  selected?: SelectItemModel<T>[];
  required?: boolean;
  collapse?: boolean;
  layout?: layoutVariant;
  options: SelectItemModel<T>[];
  onSelect: (values: SelectItemModel<T>[]) => void;
};
const FormListMultiSelect = <T extends object | string | number | boolean>({
  title,
  selected,
  options,
  onSelect,
  required,
  collapse,
  layout,
}: Props<T>) => {
  const [visible, setVisible] = useState<boolean>(true);

  const toggleVisibility = useCallback(() => {
    setVisible((prev) => !prev);
  }, [setVisible]);

  const onChange = useCallback(
    (values: string[] | number[]) => {
      const selectedValues = options.filter((x) =>
        values.find((y) => y === x.id)
      );

      onSelect(selectedValues);
    },
    [onSelect, options]
  );

  const selectedIds = useMemo(() => selected?.map((x) => x.id), [selected]);

  return (
    <div>
      <div className={styles.title}>
        <FormLabel text={title} required={required} />

        {collapse && (
          <button
            className={cn(styles.btn, !visible && styles.content_hidden)}
            onClick={toggleVisibility}
          >
            <ArrowIcon />
          </button>
        )}
      </div>

      {visible && (
        <Checkbox.Group onChange={onChange} value={selectedIds}>
          <div
            className={cn(
              styles.list,
              { [styles.col2]: layout === '2col' },
              { [styles.col3]: layout === '3col' }
            )}
          >
            {options.map((x) => (
              <Checkbox key={x.id} value={x.id}>
                <div className={styles.label}>
                  {!!x.icon ? (
                    <>
                      {typeof x.icon === 'string' ? (
                        <Image src={x.icon} width={20} height={20} />
                      ) : (
                        x.icon
                      )}
                    </>
                  ) : null}
                  <span>{x.label}</span>
                </div>
              </Checkbox>
            ))}
          </div>
        </Checkbox.Group>
      )}
    </div>
  );
};

export default FormListMultiSelect;
