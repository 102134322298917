/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import dayjs from 'dayjs';
import Button from 'components/core/button';
import FormListSingleSelect from 'components/form/form-list-single-select';
import DateTimePickerRange from 'components/core/datepicker-range';
import React, { useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { AdminWalletAccountModel } from 'models/WalletAccount';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { AdminTxRequestModel } from 'models/request/AdminTransactionsModel';
import { BalanceLogReasonType } from 'api/wallet';
import { useIntlTxReason } from 'hooks/intl/tx-reason-types';
import { TransactionApproverModel } from 'models/TransactionsApproverModel';
import { DatePickerSize } from 'components/core/datepicker';
import { SelectItemModel } from 'models/form/SelectItemModel';
import FormListMultiSelect from 'components/form/form-list-multi-select';
import Collapse from 'components/core/collapse';

type Props = {
  collection: CollectionWithPages<
    AdminWalletAccountModel | TransactionApproverModel,
    AdminTxRequestModel
  >;
};
const SidebarTxsFilter = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { balanceReasonType } = useIntlTxReason();

  const { currencyStore, layoutStore } = useBaseStores();

  const selectOptions = useMemo(
    () =>
      Object.values(BalanceLogReasonType).map((type) => ({
        id: type,
        value: type,
        label: balanceReasonType(type),
      })),
    [balanceReasonType]
  );

  const availableCurrencies = useMemo(
    () =>
      currencyStore.currencies.map((x) => ({
        id: x.id,
        label: x.name,
        value: x.name,
        icon: x.iconUrl,
      })),
    [currencyStore.currencies]
  );

  const [selectType, setSelectType] = useState<
    SelectItemModel<string> | undefined
  >(selectOptions.find((x) => x.id === collection.filter.reasonType));

  const [dateMinValue, setMinDateValue] = useState<Date | null | undefined>(
    collection.filter.from ? new Date(collection.filter.from) : undefined
  );

  const [dateMaxValue, setMaxDateValue] = useState<Date | null | undefined>(
    collection.filter.to ? new Date(collection.filter.to) : undefined
  );

  const [selectedCurrencies, setSelectedCurrencies] = useState<
    SelectItemModel<string>[]
  >(
    collection.filter.currencies
      ? collection.filter.currencies.split(',').map((item) => ({
          id: item.trim().toLocaleUpperCase(),
          label: item.trim(),
          value: item.trim().toLocaleUpperCase(),
        }))
      : []
  );

  const { setFilterQueryParams } = useSetQueryParams();

  const onApply = useCallback(() => {
    setFilterQueryParams([
      { id: URL_SEARCH_PARAMS.page, value: '1' },
      {
        id: URL_SEARCH_PARAMS.from,
        value: dateMinValue ? dayjs(dateMinValue).unix().toString() : undefined,
      },
      {
        id: URL_SEARCH_PARAMS.to,
        value: dateMaxValue ? dayjs(dateMaxValue).unix().toString() : undefined,
      },
      {
        id: URL_SEARCH_PARAMS.currencies,
        value:
          selectedCurrencies.length > 0
            ? selectedCurrencies.map((item) => item.id).join(',')
            : undefined,
      },
      {
        id: URL_SEARCH_PARAMS.reasonType,
        value: selectType ? selectType.id : undefined,
      },
    ]);
    layoutStore.toggleSidebar(false);
  }, [
    layoutStore,
    setFilterQueryParams,
    selectType,
    dateMaxValue,
    dateMinValue,
    selectedCurrencies,
  ]);

  const onChangeRange = (startDate: Date | null, endDate: Date | null) => {
    setMinDateValue(startDate);
    setMaxDateValue(endDate);
  };

  const onChangeSelect = (val: SelectItemModel<string> | undefined) => {
    setSelectType(val);
  };

  const onChangeSelectCurrencies = (values: SelectItemModel<string>[]) => {
    setSelectedCurrencies(values);
  };

  return (
    <React.Fragment>
      <Collapse
        title={intl.formatMessage({
          id: 'sidebar.filters.dateTx',
          defaultMessage: 'Transactions date',
        })}
      >
        <div className={styles.section}>
          <div className={styles.datePickWrap}>
            <div className={styles.dateCol}>
              <h4>
                {intl.formatMessage({
                  id: 'sidebar.filters.from',
                  defaultMessage: 'From',
                })}
              </h4>
            </div>

            <div className={styles.dateCol}>
              <h4>
                {intl.formatMessage({
                  id: 'sidebar.filters.to',
                  defaultMessage: 'To',
                })}
              </h4>
            </div>
          </div>

          <DateTimePickerRange
            placeholder={[
              intl.formatMessage({
                id: 'sidebar.start_date.placeholder',
                defaultMessage: 'Select min',
              }),
              intl.formatMessage({
                id: 'sidebar.end_date.placeholder',
                defaultMessage: 'Select max',
              }),
            ]}
            value={[dateMinValue ?? undefined, dateMaxValue ?? undefined]}
            onChange={onChangeRange}
            allowEmpty={[true, true]}
            size={DatePickerSize.Large}
          />
        </div>
      </Collapse>

      <div>
        <FormListSingleSelect
          title={intl.formatMessage({
            id: 'sidebar.filters.reason',
            defaultMessage: 'Reason type',
          })}
          options={selectOptions}
          selected={selectType}
          onSelect={onChangeSelect}
          collapse={true}
        />
      </div>

      <div className={'mb-4'}>
        <FormListMultiSelect
          title={intl.formatMessage({
            id: 'sidebar.filters.available_currencies',
            defaultMessage: 'Select currencies',
          })}
          options={availableCurrencies}
          selected={selectedCurrencies}
          onSelect={onChangeSelectCurrencies}
          collapse={true}
          layout={'3col'}
        />
      </div>

      <Button className={styles.apply_btn} variant='primary' onClick={onApply}>
        {intl.formatMessage({
          id: 'sidebar.filters.apply',
          defaultMessage: 'Apply',
        })}
      </Button>
    </React.Fragment>
  );
});

export const useSidebarTxsFilter = ({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.filters',
        defaultMessage: 'Filters',
      }),
      <SidebarTxsFilter collection={collection} />
    );
  }, [collection, intl, layoutStore]);

  return {
    showTxsFiltersSidebar: showSidebar,
  };
};
