/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import UsersFilter from './users-filter';

import { observer } from 'mobx-react-lite';
import {
  ProvideUsersPageStore,
  useUsersPageStore,
} from './provider/UsersPageStoreProvider';
import { GroupsTab } from './groups-tab';
import { UsersTab } from './users-tab';
import {
  ProvideGroupsPageStore,
  useGroupsPageStore,
} from './provider/GroupsStoreProvider';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'routes';
import GroupsFilter from './groups-filter';

const UsersPageWithProvider = observer(() => {
  const usersPageStore = useUsersPageStore();

  return (
    <div className={styles.main}>
      <UsersFilter users={usersPageStore.users} />

      <UsersTab />
    </div>
  );
});

const UsersGroupsPageWithProvider = observer(() => {
  const groupsPageStore = useGroupsPageStore();

  return (
    <div className={styles.main}>
      <GroupsFilter users={groupsPageStore.groups} />

      <GroupsTab />
    </div>
  );
});

const UsersPage = () => {
  return (
    <Routes>
      <Route
        element={
          <ProvideUsersPageStore>
            <UsersPageWithProvider />
          </ProvideUsersPageStore>
        }
        index
      />
      <Route
        path={ROUTES.users.groups.path}
        element={
          <ProvideGroupsPageStore>
            <UsersGroupsPageWithProvider />
          </ProvideGroupsPageStore>
        }
      />
    </Routes>
  );
};

export default UsersPage;
