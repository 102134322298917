/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Button from 'components/core/button';
import FormListSingleSelect from 'components/form/form-list-single-select';
import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { AdminWalletAccountModel } from 'models/WalletAccount';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { AdminTxRequestModel } from 'models/request/AdminTransactionsModel';
import { TransactionApproverModel } from 'models/TransactionsApproverModel';
import { SelectItemModel } from 'models/form/SelectItemModel';
import DateTimePickerRange from 'components/core/datepicker-range';
import { DatePickerSize } from 'components/core/datepicker';
import dayjs from 'dayjs';
import FormListMultiSelect from 'components/form/form-list-multi-select';
import Collapse from 'components/core/collapse';

const FILTER_STATUSES = [
  'Pending',
  'Approving',
  'Approved',
  'Rejecting',
  'Rejected',
  'Failed',
];

const FILTER_TYPES = ['Withdraw', 'Deposit'];

const selectOptionsStatus = FILTER_STATUSES.map((type) => ({
  id: type.toLocaleUpperCase(),
  label: type,
  value: type.toLocaleUpperCase(),
}));

const selectOptionsType = FILTER_TYPES.map((type) => ({
  id: type.toLocaleUpperCase(),
  label: type,
  value: type.toLocaleUpperCase(),
}));

type Props = {
  collection: CollectionWithPages<
    AdminWalletAccountModel | TransactionApproverModel,
    AdminTxRequestModel
  >;
};
const SidebarTxsApproverFilter = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const [selectStatus, setSelectStatus] = useState<SelectItemModel<string>[]>(
    collection.filter.status
      ? collection.filter.status.split(',').map((item) => ({
          id: item.trim().toLocaleUpperCase(),
          label: item.trim(),
          value: item.trim().toLocaleUpperCase(),
        }))
      : []
  );

  const [selectType, setSelectType] = useState<
    SelectItemModel<string> | undefined
  >(selectOptionsType.find((x) => x.id === collection.filter.requestType));

  const [dateMinValue, setMinDateValue] = useState<Date | null | undefined>(
    collection.filter.from ? new Date(collection.filter.from) : undefined
  );

  const [dateMaxValue, setMaxDateValue] = useState<Date | null | undefined>(
    collection.filter.to ? new Date(collection.filter.to) : undefined
  );

  const { setFilterQueryParams } = useSetQueryParams();

  const onApply = useCallback(() => {
    setFilterQueryParams([
      { id: URL_SEARCH_PARAMS.page, value: '1' },
      {
        id: URL_SEARCH_PARAMS.from,
        value: dateMinValue ? dayjs(dateMinValue).unix().toString() : undefined,
      },
      {
        id: URL_SEARCH_PARAMS.to,
        value: dateMaxValue ? dayjs(dateMaxValue).unix().toString() : undefined,
      },
      {
        id: URL_SEARCH_PARAMS.status,
        value:
          selectStatus.length > 0
            ? selectStatus.map((item) => item.id).join(',')
            : undefined,
      },
      {
        id: URL_SEARCH_PARAMS.requestType,
        value: selectType ? selectType.id : undefined,
      },
    ]);
    layoutStore.toggleSidebar(false);
  }, [
    layoutStore,
    setFilterQueryParams,
    selectStatus,
    selectType,
    dateMaxValue,
    dateMinValue,
  ]);

  const onChangeRange = (startDate: Date | null, endDate: Date | null) => {
    setMinDateValue(startDate);
    setMaxDateValue(endDate);
  };

  const onChangeSelectStatus = (values: SelectItemModel<string>[]) => {
    setSelectStatus(values);
  };

  const onChangeSelectType = (val: SelectItemModel<string> | undefined) => {
    setSelectType(val);
  };

  return (
    <React.Fragment>
      <div>
        <FormListMultiSelect
          title={intl.formatMessage({
            id: 'sidebar.filters.status',
            defaultMessage: 'Transaction status',
          })}
          options={selectOptionsStatus}
          selected={selectStatus}
          onSelect={onChangeSelectStatus}
          collapse={true}
        />
      </div>

      <div>
        <FormListSingleSelect
          title={intl.formatMessage({
            id: 'sidebar.filters.requestType',
            defaultMessage: 'Request type',
          })}
          options={selectOptionsType}
          selected={selectType}
          onSelect={onChangeSelectType}
          collapse={true}
        />
      </div>

      <div>
        <Collapse
          title={intl.formatMessage({
            id: 'sidebar.filters.dateTx',
            defaultMessage: 'Transactions date',
          })}
        >
          <div className={styles.section}>
            <div className={styles.datePickWrap}>
              <div className={styles.dateCol}>
                <h4>
                  {intl.formatMessage({
                    id: 'sidebar.filters.from',
                    defaultMessage: 'From',
                  })}
                </h4>
              </div>

              <div className={styles.dateCol}>
                <h4>
                  {intl.formatMessage({
                    id: 'sidebar.filters.to',
                    defaultMessage: 'To',
                  })}
                </h4>
              </div>
            </div>

            <DateTimePickerRange
              placeholder={[
                intl.formatMessage({
                  id: 'sidebar.start_date.placeholder',
                  defaultMessage: 'Select min',
                }),
                intl.formatMessage({
                  id: 'sidebar.end_date.placeholder',
                  defaultMessage: 'Select max',
                }),
              ]}
              value={[dateMinValue ?? undefined, dateMaxValue ?? undefined]}
              onChange={onChangeRange}
              allowEmpty={[true, true]}
              size={DatePickerSize.Large}
            />
          </div>
        </Collapse>
      </div>

      <Button className={styles.apply_btn} variant='primary' onClick={onApply}>
        {intl.formatMessage({
          id: 'sidebar.filters.apply',
          defaultMessage: 'Apply',
        })}
      </Button>
    </React.Fragment>
  );
});

export const useSidebarTxsApproverFilter = ({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.filters',
        defaultMessage: 'Filters',
      }),
      <SidebarTxsApproverFilter collection={collection} />
    );
  }, [collection, intl, layoutStore]);

  return {
    showTxsFiltersSidebar: showSidebar,
  };
};
