/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import AccountsFilter from './accounts-filter';

import { observer } from 'mobx-react-lite';
import {
  ProvideAccountsPageStore,
  useAccountsPageStore,
} from './provider/StoreProvider';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import AccountsTable from '../../components/tables/accounts-table';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { TotalBalance } from './total-balance';

const AccountsPageWithProvider = observer(() => {
  const intl = useIntl();
  const accountsPageStore = useAccountsPageStore();
  const navigate = useNavigate();

  const totalText = useMemo(
    () =>
      accountsPageStore.accounts.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.accounts.total',
              defaultMessage: 'Total {accounts}',
            },
            {
              accounts: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.accounts.total.count',
                      defaultMessage: '{count} accounts',
                    },
                    { count: accountsPageStore.accounts.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, accountsPageStore.accounts.totalItems]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.accounts.title',
        defaultMessage: 'Accounts',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalText]);

  return (
    <div className={styles.main}>
      <AccountsFilter collectData={accountsPageStore.accounts} />
      <AccountsTable
        renderBalanceCell={(accountId) => (
          <TotalBalance accountId={accountId} />
        )}
        collection={accountsPageStore.accounts}
        onRowClick={(account) => {
          if (!account.users?.[0]?.userId || !account.accountId) return;
          navigate(
            ROUTES.userDetails.accountDetails.getFullPath(
              account.users?.[0].userId,
              account.accountId
            )
          );
        }}
      />
    </div>
  );
});

const UserAccountsPage = () => {
  return (
    <ProvideAccountsPageStore>
      <AccountsPageWithProvider />
    </ProvideAccountsPageStore>
  );
};

export default UserAccountsPage;
