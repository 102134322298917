import { observer } from 'mobx-react-lite';
import { useGroupsDetailsPageStore } from '../../provider/GroupsDetailsStoreProvider';
import React, { useEffect } from 'react';

import styles from './index.module.css';
import { useIntl } from 'react-intl';
import SearchField from 'components/core/search-field';
import { useFormContext, useWatch } from 'react-hook-form';
import { CreateGroupUserForm } from '../..';
import { UserModel } from 'models/UserModel';
import { Spin } from 'antd';

type UsersModalProps = {
  isOpen: boolean;
};

export const UsersModal = observer(({ isOpen }: UsersModalProps) => {
  const usersPageStore = useGroupsDetailsPageStore();
  const intl = useIntl();

  const { setValue, getValues, control } =
    useFormContext<CreateGroupUserForm>();

  const members = useWatch({ control, name: 'users' });

  useEffect(() => {
    usersPageStore.users.fetchData([]);

    return () => {
      usersPageStore.users.reset();
    };
  }, [usersPageStore]);

  const onSearchChanged = (val: string) => {
    usersPageStore.users.fetchData([{ id: 'search', value: { search: val } }]);
  };

  const addUser = (user: UserModel) => {
    const users = getValues('users') || [];
    setValue('users', [user, ...users], {
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const users = usersPageStore.users.items?.filter(
    (user) => !members.find((member) => member.id === user.id)
  );

  return (
    <div className={styles.container}>
      <span className={styles.title}>
        {intl.formatMessage({
          id: 'page.groups.buttons.add_user',
          defaultMessage: 'Add user',
        })}
      </span>
      <SearchField
        value={''}
        onChange={onSearchChanged}
        placeholder={intl.formatMessage({
          id: 'page.users.search.placeholder',
          defaultMessage: 'Search by users',
        })}
      />
      {usersPageStore.users.isLoading ? (
        <div className={styles.spinner}>
          <Spin />
        </div>
      ) : (
        <div className={styles.list}>
          {users?.map((item) => (
            <button className={styles.card} onClick={() => addUser(item)}>
              <div className={styles['card-row']}>
                <span className={styles['card-label']}>ID:</span>
                <span className={styles['card-value']}>{item.id}</span>
              </div>
              <div className={styles['card-row']}>
                <span className={styles['card-label']}>Email:</span>
                <span className={styles['card-value']}>{item.email}</span>
              </div>
              <div className={styles['card-row']}>
                <span className={styles['card-label']}>Name:</span>
                <span className={styles['card-value']}>
                  {item.firstName} {item.lastName}
                </span>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
});
