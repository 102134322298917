/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Tabs from 'components/core/tabs';
import LoansDashboardTab from './tabs/dashboard';
import LoanOffersTab from './tabs/offers';
import LoansListTab from './tabs/list';
import LoanMarginCallsTab from './tabs/margin_calls';
import LoanLiquidationsTab from './tabs/liquidations';
import LoanPaymentsTab from './tabs/payments';
import LoanComplianceTab from './tabs/compliance';

import { ROUTES } from 'routes';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';

const LoansPage = observer(() => {
  const intl = useIntl();

  const tabsData = useMemo(
    () => [
      {
        id: 'dashboard',
        text: intl.formatMessage({
          id: 'page.loans.tabs.dashboard',
          defaultMessage: 'Loan dashboard',
        }),
        navLink: ROUTES.loans.fullPath,
      },
      {
        id: 'offers',
        text: intl.formatMessage({
          id: 'page.loans.tabs.offers',
          defaultMessage: 'Loan offers',
        }),
        navLink: ROUTES.loans.offers.fullPath,
      },
      {
        id: 'list',
        text: intl.formatMessage({
          id: 'page.loans.tabs.list',
          defaultMessage: 'Loans',
        }),
        navLink: ROUTES.loans.list.fullPath,
      },
      {
        id: 'margin_calls',
        text: intl.formatMessage({
          id: 'page.loans.tabs.margin_calls',
          defaultMessage: 'Margin calls',
        }),
        navLink: ROUTES.loans.margin_calls.fullPath,
      },
      {
        id: 'liquidations',
        text: intl.formatMessage({
          id: 'page.loans.tabs.liquidations',
          defaultMessage: 'Liquidations',
        }),
        navLink: ROUTES.loans.liquidations.fullPath,
      },
      {
        id: 'payments',
        text: intl.formatMessage({
          id: 'page.loans.tabs.payments',
          defaultMessage: 'Payments',
        }),
        navLink: ROUTES.loans.payments.fullPath,
      },
      {
        id: 'compliance',
        text: intl.formatMessage({
          id: 'page.loans.tabs.compliance',
          defaultMessage: 'Compliance',
        }),
        navLink: ROUTES.loans.compliance.fullPath,
      },
    ],
    [intl]
  );

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Tabs data={tabsData} />
      </div>

      <div className={styles.body}>
        <Routes>
          <Route element={<LoansDashboardTab />} index />
          <Route element={<LoanOffersTab />} path={ROUTES.loans.offers.path} />
          <Route element={<LoansListTab />} path={ROUTES.loans.list.path} />
          <Route
            element={<LoanMarginCallsTab />}
            path={ROUTES.loans.margin_calls.path}
          />
          <Route
            element={<LoanLiquidationsTab />}
            path={ROUTES.loans.liquidations.path}
          />
          <Route
            element={<LoanPaymentsTab />}
            path={ROUTES.loans.payments.path}
          />
          <Route
            element={<LoanComplianceTab />}
            path={ROUTES.loans.compliance.path}
          />
          <Route
            path='*'
            element={<Navigate to={ROUTES.loans.fullPath} replace />}
          />
        </Routes>
      </div>
    </div>
  );
});

export default LoansPage;
