/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import styles from './index.module.css';
import classNames from 'classnames';

export interface TextAreaFieldProps {
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  error?: string;
  variant?: 'primary' | 'secondary';
}

const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (
    {
      value,
      placeholder,
      disabled,
      onChange,
      onFocus,
      onBlur,
      className,
      error,
      variant,
    },
    ref
  ) => {
    const [inputValue, setStrValue] = useState(value);

    const handleInput = useCallback(
      (value: string) => {
        setStrValue(value);

        onChange?.(value);
      },
      [onChange]
    );

    useEffect(() => {
      if (inputValue !== value) {
        setStrValue(value);
      }
    }, [inputValue, value]);

    return (
      <div
        className={classNames(
          styles.main,
          { [styles.primary]: !variant || variant === 'primary' },
          { [styles.secondary]: variant === 'secondary' },
          className
        )}
      >
        <div
          className={classNames(styles.container, {
            [styles._disabled]: disabled,
          })}
        >
          <textarea
            ref={ref}
            className={styles.text_area}
            value={value ?? ''}
            placeholder={placeholder}
            onChange={(event) => handleInput(event.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete='off'
            disabled={disabled}
          />
        </div>

        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  }
);

export default TextAreaField;
