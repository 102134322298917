/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { LoanLtv } from 'api/loans';

export class LoanLtvModel {
  constructor(dto: LoanLtv) {
    this.loanId = dto.loanId?.toString() ?? '';
    this.ltv = dto.ltv;
  }

  loanId: string;
  ltv: number;
}
