/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export enum ChartColors {
  Blue = '#0177FD',
  Yellow = '#FFB833',
  Red = '#E16C68',
  Purple = '#BC5FC5',
  GreenLight = '#8EDC5D',
  Green = '#40AE5F',
  Gray = '#9B9BA8',
}

export const COLORS = [
  ChartColors.Green,
  ChartColors.Yellow,
  ChartColors.Red,
  ChartColors.Blue,
  ChartColors.GreenLight,
];
