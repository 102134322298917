/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Image from 'components/core/image';
import FormLabel from '../form-label';

import { Radio } from 'antd';
import { useCallback, useState } from 'react';
import { SelectItemModel } from 'models/form/SelectItemModel';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/arrow.svg';

type Props<T> = {
  title: string;
  selected?: SelectItemModel<T>;
  required?: boolean;
  collapse?: boolean;
  options: SelectItemModel<T>[];
  onSelect: (value?: SelectItemModel<T>) => void;
};
const FormListSingleSelect = <T extends object | string | number | boolean>({
  title,
  selected,
  options,
  onSelect,
  required,
  collapse,
}: Props<T>) => {
  const [visible, setVisible] = useState<boolean>(true);

  const toggleVisibility = useCallback(() => {
    setVisible((prev) => !prev);
  }, [setVisible]);

  const onChange = useCallback(
    (selection?: SelectItemModel<T>) => {
      onSelect(selection);
    },
    [onSelect]
  );
  return (
    <div>
      <div className={styles.title}>
        <FormLabel text={title} required={required} />

        {collapse && (
          <button
            className={cn(styles.btn, !visible && styles.content_hidden)}
            onClick={toggleVisibility}
          >
            <ArrowIcon />
          </button>
        )}
      </div>

      {visible && (
        <Radio.Group value={selected?.id}>
          <div className={styles.list}>
            {options.map((x) => (
              <Radio
                key={x.id}
                value={x.id}
                onClick={() => onChange(x.id !== selected?.id ? x : undefined)}
              >
                <div className={styles.label}>
                  {!!x.icon ? (
                    <>
                      {typeof x.icon === 'string' ? (
                        <Image src={x.icon} width={20} height={20} />
                      ) : (
                        x.icon
                      )}
                    </>
                  ) : null}
                  <span>{x.label}</span>
                </div>
              </Radio>
            ))}
          </div>
        </Radio.Group>
      )}
    </div>
  );
};

export default FormListSingleSelect;
