import React, { useEffect } from 'react';
import { OperationSelect } from '../../operation-select';
import styles from './index.module.css';
import { useIntl } from 'react-intl';
import Button from 'components/core/button';
import { Controller, useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useOperationStore } from '../../provider/OperationStoreProvider';
import FormField from 'components/form/form-field';
import { Input } from 'antd';
import { CurrencySelect } from 'components/elements/currency-select';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { Balance } from '../balance';
import { TotalInfo } from '../total';
import { FormValues, Step } from '..';

type StepTransferProps = {
  setStep: (step: Step) => void;
};

export const StepTransfer = observer(({ setStep }: StepTransferProps) => {
  const store = useOperationStore();
  const { currencyStore } = useBaseStores();
  const intl = useIntl();

  const { handleSubmit, control, getValues, setValue } =
    useFormContext<FormValues>();

  useEffect(() => {
    if (store.accountBalances.from.length) {
      const defaultCurrency = store.accountBalances.from[0].currency;
      setValue('currency', defaultCurrency);
    }
  }, [store.accountBalances.from, setValue]);

  const swapAccounts = () => {
    const { fromAccount, toAccount } = getValues();
    setValue('fromAccount', toAccount || '', { shouldValidate: true });
    setValue('toAccount', fromAccount || '', { shouldValidate: true });
  };

  const selectOptions = store.accountBalances.from.map((item) => ({
    id: item.currency,
    value: item.currency,
    label: item.currency,
    icon: currencyStore.currenciesMap.get(item.currency)?.iconUrl,
  }));

  return (
    <div className={styles.container}>
      <OperationSelect />
      <div className={styles.box}>
        <span className={styles.title}>
          {intl.formatMessage({
            id: 'sidebar.operation.accounts',
            defaultMessage: 'Accounts',
          })}
        </span>
        <div className={styles.card}>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'sidebar.operation.from',
              defaultMessage: 'From',
            })}
          </span>
          <Controller
            rules={{
              required: intl.formatMessage({
                id: 'validation.field_required',
                defaultMessage: 'Field is required',
              }),
            }}
            control={control}
            name='fromAccount'
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormField
                  required
                  placeholder={intl.formatMessage({
                    id: 'sidebar.operation.account_id',
                    defaultMessage: 'Account ID',
                  })}
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              );
            }}
          />
          <Button
            variant='secondary'
            className={styles.button}
            onClick={swapAccounts}
          >
            {intl.formatMessage({
              id: 'sidebar.operation.swap_accounts',
              defaultMessage: 'Swap accounts',
            })}
          </Button>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'sidebar.operation.to',
              defaultMessage: 'To',
            })}
          </span>

          <Controller
            rules={{
              required: intl.formatMessage({
                id: 'validation.field_required',
                defaultMessage: 'Field is required',
              }),
            }}
            control={control}
            name='toAccount'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                required
                placeholder={intl.formatMessage({
                  id: 'sidebar.operation.account_id',
                  defaultMessage: 'Account ID',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.box}>
        <span className={styles.title}>
          {intl.formatMessage({
            id: 'sidebar.operation.transfer_amount',
            defaultMessage: 'Transfer amount',
          })}
        </span>

        <div className={styles.card}>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'sidebar.operation.amount',
              defaultMessage: 'Amount',
            })}
          </span>
          <Controller
            rules={{
              required: intl.formatMessage({
                id: 'validation.field_required',
                defaultMessage: 'Field is required',
              }),
              validate: (v, values) => {
                const balance = store.accountBalances.from.find(
                  (item) => item.currency === values.currency
                );
                if (Number(v) === 0)
                  return intl.formatMessage({
                    id: 'validation.field_greater_than_0',
                    defaultMessage: 'Value must be greater than 0',
                  });
                if (Number(v) > Number(balance?.balance || 0))
                  return intl.formatMessage({
                    id: 'validation.insufficient_balance',
                    defaultMessage: `Insufficient balance`,
                  });
              },
            }}
            control={control}
            name='amount'
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Input
                    value={value}
                    size='large'
                    placeholder='0.00'
                    onChange={onChange}
                    addonAfter={
                      <Controller
                        rules={{
                          required: intl.formatMessage({
                            id: 'validation.field_required',
                            defaultMessage: 'Field is required',
                          }),
                        }}
                        control={control}
                        name='currency'
                        render={({
                          field: {
                            value: selectValue,
                            onChange: onSelectChange,
                          },
                          fieldState: { error },
                        }) => (
                          <CurrencySelect
                            options={selectOptions}
                            value={selectOptions.find(
                              (item) => item.value === selectValue
                            )}
                            onChange={(value) => onSelectChange(value?.value)}
                          />
                        )}
                      />
                    }
                  />
                  {error?.message && (
                    <span className={styles.error}>{error.message}</span>
                  )}
                </>
              );
            }}
          />
          <Balance />
          <TotalInfo />
        </div>
      </div>
      <Controller
        control={control}
        name='note'
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            type='text_area'
            label={intl.formatMessage({
              id: 'sidebar.operation.note.title',
              defaultMessage: 'Note',
            })}
            placeholder={intl.formatMessage({
              id: 'sidebar.operation.note.placeholder',
              defaultMessage:
                'Specify a reason (The user will see your message)',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Button
        variant='primary'
        onClick={handleSubmit(() => setStep('summary'))}
      >
        Continue
      </Button>
    </div>
  );
});
