/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { Radio } from 'antd';

export type SingleSelectListValue = {
  id: string;
  label: string;
  additionalLabel?: string;
  value?: any;
};

type Props = {
  selectedId: string;
  options: SingleSelectListValue[];
  onApply: (value: SingleSelectListValue) => void;
};
const SingleSelectList = ({ selectedId, options, onApply }: Props) => {
  return (
    <div className={styles.list}>
      {options.map((x) => (
        <button
          key={x.id}
          className={styles.item}
          onClick={(event) => {
            onApply(x);
            event.preventDefault();
          }}
        >
          <div className={styles.item_info}>
            <span>{x.label}</span>

            {!!x.additionalLabel && (
              <span className={styles.item_info_additional}>
                {x.additionalLabel}
              </span>
            )}
          </div>

          <Radio checked={selectedId === x.id} />
        </button>
      ))}
    </div>
  );
};

export default SingleSelectList;
