/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import SearchField from 'components/core/search-field';

import { URL_SEARCH_PARAMS } from 'assets/config';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { AdminSubscriptionModel } from 'models/request/AdminSubscriptionModel';
import { SubscriptionsUserModel } from 'models/SubscriptionsUserModel';
import FilterButton from 'components/elements/filter-button';
import { useSidebarSubscriptionsFilter } from 'components/sidebars/sidebar-subscriptions-filter';

type Props = {
  collectData: CollectionWithPages<
    SubscriptionsUserModel,
    AdminSubscriptionModel
  >;
};

const SubscriptionsFilter = observer(({ collectData }: Props) => {
  const intl = useIntl();

  const { setFilterQueryParams } = useSetQueryParams();

  const { showSubscriptionsFiltersSidebar } = useSidebarSubscriptionsFilter({
    collection: collectData,
  });

  const onSearchChanged = useCallback(
    (val: string) => {
      setFilterQueryParams([
        { id: URL_SEARCH_PARAMS.page, value: '1' },
        { id: URL_SEARCH_PARAMS.search, value: val },
      ]);
    },
    [setFilterQueryParams]
  );

  return (
    <div className={styles.main}>
      <SearchField
        className={styles.search_input}
        placeholder={intl.formatMessage({
          id: 'page.subscriptions.search.placeholder',
          defaultMessage: 'Search by subscriptions Id',
        })}
        value={collectData.filter.search ?? ''}
        onChange={onSearchChanged}
      />

      <FilterButton
        activeCount={collectData.totalFiltersCount}
        onClick={showSubscriptionsFiltersSidebar}
      />
    </div>
  );
});

export default SubscriptionsFilter;
