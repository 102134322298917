/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable } from 'mobx';
import { FilterModel } from 'models/filter/FilterModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { readStrFromQueryParams } from 'utils/queryParams';
import { SubscriptionsUserModel } from 'models/SubscriptionsUserModel';
import { AdminSubscriptionModel } from 'models/request/AdminSubscriptionModel';
import { NotificationStore } from 'stores/NotificationStore';
import { RecurrentInvoiceStatus } from 'api/auth';

export class SubscriptionsPageStore {
  constructor(
    private authApi: AuthApi,
    private notificationStore: NotificationStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _subscriptions = new CollectionWithPages<
    SubscriptionsUserModel,
    AdminSubscriptionModel
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result =
        await this.authApi.merchantAdminSubscription.adminGetSubscriptions({
          ...params,
        });

      const items = result.data.data.map((x) => new SubscriptionsUserModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminSubscriptionModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.search
      );

      const userIdStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.userId
      );

      const partnerId = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.partnerId
      );

      const status = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.status
      );

      if (searchStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.search,
          value: {
            search: searchStr,
          },
        });
      }

      if (userIdStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.userId,
          value: {
            userId: userIdStr,
          },
        });
      }

      if (partnerId != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.partnerId,
          value: {
            partnerId: +partnerId,
          },
        });
      }

      if (status) {
        filters.push({
          id: URL_SEARCH_PARAMS.status,
          value: {
            status: status as RecurrentInvoiceStatus,
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  get subscriptions() {
    return this._subscriptions;
  }

  async cancelSubscription(subscriptionId: string) {
    try {
      await this.authApi.merchantAdminSubscription.adminCancelSubscription(
        subscriptionId
      );
      this.notificationStore.notify({
        title: 'Subscription was successfully canceled',
        type: 'success',
      });

      await this._subscriptions.refresh();
    } catch (err) {
      this.notificationStore.handleApiError(err);
      console.error(err);
    }
  }

  async deleteSubscription(subscriptionId: string) {
    try {
      await this.authApi.merchantAdminSubscription.adminDeleteSubscription(
        subscriptionId
      );
      this.notificationStore.notify({
        title: 'Subscription was successfully deleted',
        type: 'success',
      });

      await this._subscriptions.refresh();
    } catch (err) {
      this.notificationStore.handleApiError(err);
      console.error(err);
    }
  }

  async init(props: {
    filter: FilterModel<AdminSubscriptionModel>[];
    page: number;
  }) {
    const { filter, page } = props;

    await this._subscriptions.fetchData(filter, page);
  }
}
