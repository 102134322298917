/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';
import joi from 'joi';
import styles from './index.module.css';
import Button from 'components/core/button';
import FormField from 'components/form/form-field';
import FormLabel from 'components/form/form-label';

import FormSelect from 'components/form/form-select';
import FormListMultiSelect from 'components/form/form-list-multi-select';
import FormListSingleSelect from 'components/form/form-list-single-select';
import { getUuid } from 'utils/uuid';
import { joiResolver } from '@hookform/resolvers/joi';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { Controller, useForm } from 'react-hook-form';
import { useIntlValidation } from 'hooks/intl/validation';
import { LoanOfferModel } from 'models/LoanOfferModel';
import { LoanType, PaymentPeriodTypeEnum } from 'api/loans';
import FormPeriodSelect, {
  PeriodOption,
} from 'components/form/form-period-select';
import { SelectItemModel } from 'models/form/SelectItemModel';

type CreateOfferForm = {
  name: string;
  description: string;
  principalCurrency: SelectItemModel<string>;
  principalAmountMin?: string;
  principalAmountMax?: string;
  collateralCurrencies?: SelectItemModel<string>[];
  interestRate: string;
  collateralRate?: string;
  marginCallRate?: string;
  minHealthRate?: string;
  liquidationRate?: string;
  minTerm?: SelectItemModel<string>;
  maxTerm?: SelectItemModel<string>;
  paymentPeriodType: SelectItemModel<PaymentPeriodTypeEnum>;
  paymentPeriod?: SelectItemModel<string>;
};

const MIN_NUMBER = 0;

const SidebarOfferCreate = observer(
  ({ onSubmit }: { onSubmit?: (offer: LoanOfferModel) => void }) => {
    const intl = useIntl();

    const { currencyStore, layoutStore } = useBaseStores();

    const { validationOptions } = useIntlValidation();

    const formScheme = useMemo(
      () =>
        joi.object<CreateOfferForm>({
          name: joi.string().empty('').required(),
          description: joi.string().empty('').required(),
          principalCurrency: joi.object().required(),
          collateralCurrencies: joi.array(),
          principalAmountMin: joi
            .number()
            .empty('')
            .min(MIN_NUMBER)
            .max(Number.MAX_SAFE_INTEGER),
          principalAmountMax: joi
            .number()
            .empty('')
            .min(MIN_NUMBER)
            .max(Number.MAX_SAFE_INTEGER),
          interestRate: joi.number().empty('').min(MIN_NUMBER).required(),
          collateralRate: joi.number().empty('').min(MIN_NUMBER),
          marginCallRate: joi.number().empty('').min(MIN_NUMBER),
          minHealthRate: joi.number().empty('').min(MIN_NUMBER),
          liquidationRate: joi.number().empty('').min(MIN_NUMBER),
          minTerm: joi.object(),
          maxTerm: joi.object(),
          paymentPeriodType: joi.object().required(),
          paymentPeriod: joi.object(),
        }),
      []
    );

    const availableCurrencies = useMemo(
      () =>
        currencyStore.currencies.map((x) => ({
          id: x.id,
          label: x.name,
          value: x.name,
          icon: x.iconUrl,
        })),
      [currencyStore.currencies]
    );

    const availablePeriodTypes = useMemo(
      () => [
        {
          id: 'fixed_day',
          label: intl.formatMessage({
            id: 'sidebar.create_offer.period_type.fixed_day',
            defaultMessage: 'Fixed day',
          }),
          value: PaymentPeriodTypeEnum.FIXED_DAY,
        },
        {
          id: 'fixed_length',
          label: intl.formatMessage({
            id: 'sidebar.create_offer.period_type.fixed_length',
            defaultMessage: 'Fixed length',
          }),
          value: PaymentPeriodTypeEnum.FIXED_LENGTH,
        },
      ],
      [intl]
    );

    const availableTerms = useMemo<PeriodOption[]>(
      () => [
        '1day',
        '7days',
        '14days',
        '1month',
        '3months',
        '6months',
        '1year',
      ],
      []
    );

    const availablePaymentPeriods = useMemo<PeriodOption[]>(
      () => ['1day', '7days', '14days', '1month'],
      []
    );

    const { handleSubmit, control, formState, watch, resetField } =
      useForm<CreateOfferForm>({
        resolver: joiResolver(formScheme, validationOptions),
        mode: 'onChange',
        defaultValues: {
          paymentPeriodType: availablePeriodTypes[0],
        },
      });

    const onSubmitForm = useCallback(
      (form: CreateOfferForm) => {
        onSubmit?.({
          id: getUuid(),
          type: LoanType.BALLOON,
          name: form.name,
          description: form.description,
          principalCurrency: form.principalCurrency.id,
          minPrincipalAmount: form.principalAmountMin
            ? BigNumber(form.principalAmountMin)
            : undefined,
          minPrincipalCurrency: form.principalAmountMin
            ? form.principalCurrency.id
            : undefined,
          maxPrincipalAmount: form.principalAmountMax
            ? BigNumber(form.principalAmountMax)
            : undefined,
          maxPrincipalCurrency: form.principalAmountMax
            ? form.principalCurrency.id
            : undefined,
          collateralAllowedCurrencies: form.collateralCurrencies
            ? form.collateralCurrencies.map((x) => x.id)
            : undefined,
          interestRate: BigNumber(form.interestRate),
          collateralRate: form.collateralRate
            ? BigNumber(form.collateralRate)
            : undefined,
          marginCallRate: form.marginCallRate
            ? BigNumber(form.marginCallRate)
            : undefined,
          minHealthRate: form.minHealthRate
            ? BigNumber(form.minHealthRate)
            : undefined,
          liquidationRate: form.liquidationRate
            ? BigNumber(form.liquidationRate)
            : undefined,
          minTerm: form.minTerm?.value,
          maxTerm: form.maxTerm?.value,
          paymentPeriodType: form.paymentPeriodType.value,
          paymentPeriod: form.paymentPeriod?.value,
          feePoints: [],
        });
        layoutStore.toggleSidebar(false);
      },
      [layoutStore, onSubmit]
    );

    const periodType = watch('paymentPeriodType');

    useEffect(() => {
      if (periodType.value === PaymentPeriodTypeEnum.FIXED_LENGTH) {
        return;
      }

      resetField('paymentPeriod');
    }, [periodType, resetField]);

    return (
      <div className={styles.main}>
        <Controller
          control={control}
          name='name'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              required
              label={intl.formatMessage({
                id: 'sidebar.create_offer.name',
                defaultMessage: 'Name',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.create_offer.name.placeholder',
                defaultMessage: 'Enter offer name...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='description'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              required
              type='text_area'
              label={intl.formatMessage({
                id: 'sidebar.create_offer.description',
                defaultMessage: 'Description',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.create_offer.description.placeholder',
                defaultMessage: 'Enter offer description...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <div className={styles.two_cols}>
          <Controller
            control={control}
            name='principalCurrency'
            render={({ field: { value, onChange } }) => (
              <FormListSingleSelect
                required
                title={intl.formatMessage({
                  id: 'sidebar.create_offer.principal_currency',
                  defaultMessage: 'Principal currency',
                })}
                options={availableCurrencies}
                selected={value}
                onSelect={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name='collateralCurrencies'
            render={({ field: { value, onChange } }) => (
              <FormListMultiSelect
                title={intl.formatMessage({
                  id: 'sidebar.create_offer.collateral_currencies',
                  defaultMessage: 'Collateral currencies',
                })}
                options={availableCurrencies}
                selected={value}
                onSelect={onChange}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name='interestRate'
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormField
              required
              label={intl.formatMessage({
                id: 'sidebar.create_offer.interest_rate',
                defaultMessage: 'Interest rate',
              })}
              placeholder={intl.formatMessage({
                id: 'sidebar.create_offer.interest_rate.placeholder',
                defaultMessage: 'Enter interest rate...',
              })}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='paymentPeriodType'
          render={({ field: { value, onChange } }) => (
            <FormSelect
              required
              label={intl.formatMessage({
                id: 'sidebar.create_offer.payment_period',
                defaultMessage: 'Payment period',
              })}
              options={availablePeriodTypes}
              onChange={onChange}
              value={value}
              placeholder={intl.formatMessage({
                id: 'sidebar.create_offer.terms.payment_period_type.placeholder',
                defaultMessage: 'Payment period type...',
              })}
            />
          )}
        />

        {periodType.value === PaymentPeriodTypeEnum.FIXED_LENGTH && (
          <Controller
            control={control}
            name='paymentPeriod'
            render={({ field: { value, onChange } }) => (
              <FormPeriodSelect
                options={availablePaymentPeriods}
                onChange={onChange}
                value={value}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_offer.terms.payment_period.placeholder',
                  defaultMessage: 'Payment period...',
                })}
              />
            )}
          />
        )}

        <div>
          <FormLabel
            text={intl.formatMessage({
              id: 'sidebar.create_offer.terms',
              defaultMessage: 'Loan terms',
            })}
          />

          <div className={styles.two_cols}>
            <Controller
              control={control}
              name='minTerm'
              render={({ field: { value, onChange } }) => (
                <FormPeriodSelect
                  label={intl.formatMessage({
                    id: 'sidebar.create_offer.terms.min',
                    defaultMessage: 'Min',
                  })}
                  labelType='xs-secondary'
                  options={availableTerms}
                  onChange={onChange}
                  value={value}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_offer.terms.min.placeholder',
                    defaultMessage: 'Min loan term...',
                  })}
                />
              )}
            />

            <Controller
              control={control}
              name='maxTerm'
              render={({ field: { value, onChange } }) => (
                <FormPeriodSelect
                  label={intl.formatMessage({
                    id: 'sidebar.create_offer.terms.max',
                    defaultMessage: 'Max',
                  })}
                  labelType='xs-secondary'
                  options={availableTerms}
                  onChange={onChange}
                  value={value}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_offer.terms.max.placeholder',
                    defaultMessage: 'Max loan term...',
                  })}
                />
              )}
            />
          </div>
        </div>

        <div>
          <FormLabel
            text={intl.formatMessage({
              id: 'sidebar.create_offer.principal_amount',
              defaultMessage: 'Principal amount',
            })}
          />

          <div className={styles.two_cols}>
            <Controller
              control={control}
              name='principalAmountMin'
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={intl.formatMessage({
                    id: 'sidebar.create_offer.min',
                    defaultMessage: 'Min',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_offer.principal_amount.min',
                    defaultMessage: 'Minimum amount...',
                  })}
                  labelType='xs-secondary'
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />

            <Controller
              control={control}
              name='principalAmountMax'
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={intl.formatMessage({
                    id: 'sidebar.create_offer.max',
                    defaultMessage: 'Max',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_offer.principal_amount.max',
                    defaultMessage: 'Maximum amount...',
                  })}
                  labelType='xs-secondary'
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div className={styles.two_cols}>
          <Controller
            control={control}
            name='collateralRate'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_offer.collateral_rate',
                  defaultMessage: 'Collateral rate',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_offer.collateral_rate.placeholder',
                  defaultMessage: 'Enter collateral rate...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='liquidationRate'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_offer.liquidation_rate',
                  defaultMessage: 'Liquidation rate',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_offer.liquidation_rate.placeholder',
                  defaultMessage: 'Enter liquidation rate...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.two_cols}>
          <Controller
            control={control}
            name='marginCallRate'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_offer.margin_call_rate',
                  defaultMessage: 'Margin call rate',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_offer.margin_call_rate.placeholder',
                  defaultMessage: 'Enter margin call rate...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='minHealthRate'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_offer.min_health_rate',
                  defaultMessage: 'Minimal health rate',
                })}
                placeholder={intl.formatMessage({
                  id: 'sidebar.create_offer.min_health_rate.placeholder',
                  defaultMessage: 'Enter min health rate...',
                })}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        </div>

        <div className={styles.actions}>
          <Button
            variant='primary'
            onClick={handleSubmit(onSubmitForm)}
            disabled={!formState.isValid}
          >
            {intl.formatMessage({
              id: 'sidebar.create_offer.submit',
              defaultMessage: 'Create a loan offer',
            })}
          </Button>
        </div>
      </div>
    );
  }
);

export const useSidebarOfferCreate = ({
  onSubmit,
}: {
  onSubmit: (offer: LoanOfferModel) => void;
}) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.create_offer',
        defaultMessage: 'Create a loan offer',
      }),
      <SidebarOfferCreate onSubmit={onSubmit} />
    );
  }, [intl, layoutStore, onSubmit]);

  return {
    showCreateOfferSidebar: showSidebar,
  };
};
