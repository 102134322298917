import TransactionsHistoryTable from 'components/tables/transactions-history-table';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useAccountDetailPageStore } from '../provider/StoreProvider';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';

export const History = observer(() => {
  const store = useAccountDetailPageStore();
  const { page, filter } = useParseCollectionQueryParams({
    collection: store.transactions,
  });

  useEffect(() => {
    if (store.accountId) store.transactions.fetchData(filter, page);
  }, [store, store.accountId, filter, page]);

  return (
    <TransactionsHistoryTable
      collection={store.transactions}
      hideColumns={{ value: true, status: true }}
    />
  );
});
