/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { AccountUserModel } from 'models/AccountUserModel';
import { formattedDate } from 'utils/dates';
import { cutId } from 'utils/strings';
import { TableProps } from 'antd';
import Table from '../core/table';

type Props = {
  onRowClick: (value: AccountUserModel) => void;
  collection: CollectionWithPages<AccountUserModel, {}>;
};

const AccountUsersTable = observer(({ collection, onRowClick }: Props) => {
  const intl = useIntl();

  const columns: TableProps<AccountUserModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.account_users.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{cutId(id)}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.account_users.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'shortName',
      key: 'shortName',
      render: (shortName) => <span>{shortName}</span>,
      width: 60,
    },
    {
      title: intl.formatMessage({
        id: 'table.account_users.col.role',
        defaultMessage: 'Role',
      }),
      dataIndex: 'role',
      key: 'role',
      render: (role) => <span>{role}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.account_users.col.created_at',
        defaultMessage: 'Created at',
      }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => (
        <span>{createdAt ? formattedDate(createdAt) : ''}</span>
      ),
      width: 20,
    },
  ];

  return (
    <Table<AccountUserModel, {}>
      collection={collection}
      columns={columns}
      onRowClick={onRowClick}
    />
  );
});

export default AccountUsersTable;
