/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const chartDataLine: { name: string; value: number }[] = [
  {
    name: 'Sun',
    value: 40,
  },
  {
    name: 'Mon',
    value: 60,
  },
  {
    name: 'Tue',
    value: 65,
  },
  {
    name: 'Wed',
    value: 45,
  },
  {
    name: 'Thu',
    value: 62,
  },
  {
    name: 'Fri',
    value: 80,
  },
  {
    name: 'Sat',
    value: 105,
  },
];

export const chartDataPie: { name: string; value: number }[] = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 700 },
  { name: 'Group D', value: 200 },
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 700 },
  { name: 'Group D', value: 200 },
];
