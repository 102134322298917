/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';

const defaultBNFormatting = {
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
};

export const bigNumberToStr = (
  number: BigNumber,
  decimalPlaces: number = 5
) => {
  if (!number.isFinite() || number.isNaN()) {
    return '-';
  }
  return number
    .dp(decimalPlaces, BigNumber.ROUND_DOWN)
    .toFormat(defaultBNFormatting);
};

export const parseBigNumber = (
  value: number | string | BigNumber | undefined
): BigNumber | undefined => {
  if (value === undefined || value === null || value === '') {
    return undefined;
  }

  return new BigNumber(value);
};

export const parseBigNumberZero = (
  value: number | string | BigNumber | undefined
): BigNumber => {
  return value !== undefined && value !== null && value !== ''
    ? new BigNumber(value)
    : new BigNumber(0);
};
