/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const readStrFromQueryParams = (
  queryParams: URLSearchParams,
  param: string
) => {
  const val = queryParams.get(param);

  if (!val || val === '') {
    return null;
  }

  return val;
};

export const readNumberFromQueryParams = (
  queryParams: URLSearchParams,
  param: string
) => {
  const val = readStrFromQueryParams(queryParams, param);

  if (!val) {
    return null;
  }

  const num = Number(val);

  if (Number.isNaN(num)) {
    return null;
  }

  return num;
};

export const readBooleanFromQueryParams = (
  queryParams: URLSearchParams,
  param: string
) => {
  const val = queryParams.get(param);

  switch (val) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};
