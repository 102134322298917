/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import imagePlaceholderUrl from 'assets/images/image_placeholder.svg';

type Props = {
  width: number;
  height: number;
  src?: string | undefined;
  className?: string | undefined;
  alt?: string | undefined;
};
const Image = React.forwardRef(
  ({ src, width, height, alt, className }: Props, ref: any) => {
    const [noImage, setNoImage] = useState(src == null);

    const handleOnError = useCallback(() => {
      setNoImage(true);
    }, []);

    return (
      <img
        ref={ref}
        className={classNames(styles.img, className)}
        src={!src || noImage ? imagePlaceholderUrl : src}
        width={width}
        height={height}
        onError={handleOnError}
        alt={alt ?? ''}
        loading='lazy'
      />
    );
  }
);

export default Image;
