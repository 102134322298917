/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';
import { AccountMoney } from 'api/loans';
import { parseBigNumberZero } from 'utils/bignumber';

export type ExtendDto = {
  rate?: BigNumber;
  amountTotal?: BigNumber;
};

export class CollateralInfoModel {
  constructor(dto: AccountMoney & ExtendDto) {
    if (!dto) {
      throw new Error('dto is null');
    }
    this.account = dto.account || '';
    this.amount = parseBigNumberZero(dto.amount);
    this.currency = dto.currency || '';
    this.rate = parseBigNumberZero(dto.rate);
    this.amountTotal = parseBigNumberZero(dto.amountTotal);
  }

  account: string;
  amount: BigNumber;
  currency: string;

  rate?: BigNumber;
  amountTotal?: BigNumber;
}
