/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import cn from 'classnames';
import Button from 'components/core/button';
import LineChart from 'components/charts/line-chart';
import PieChart from 'components/charts/pie-chart';
import Select from 'components/core/select';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { chartDataLine, chartDataPie } from './chartData';
import { ReactComponent as CalendarIconSvg } from 'assets/images/icons/calendar.svg';
import { SelectItemModel } from 'models/form/SelectItemModel';
import HealthIndicator from '../../components/core/health-indicator';

const selectOptions: SelectItemModel<string>[] = [
  {
    id: 'atm',
    value: 'atm',
    label: 'ATM',
    icon: 'https://s2.coinmarketcap.com/static/img/coins/32x32/1.png',
  },
  {
    id: 'bank-transfer',
    value: 'bank-transfer',
    label: 'Bank transfer',
    icon: <CalendarIconSvg />,
  },
  { id: 'crypto', value: 'crypto', label: 'Crypto' },
  { id: 'pay-spot', value: 'pay-spot', label: 'Pay spot' },
  {
    id: 'counterparty',
    value: 'counterparty',
    label: 'Counterparty',
    disabled: true,
  },
];

const ComponentsPage = observer(() => {
  const intl = useIntl();

  const { layoutStore, notificationStore } = useBaseStores();

  const [selectValue, setSelectValue] = useState<
    SelectItemModel<string> | undefined
  >(selectOptions[1]);

  const handleConsoleOutput = () => {
    console.log('Button clicked');
  };

  const handleNotificationSuccess = () => {
    notificationStore.notify({
      title: 'Notification Success',
      type: 'success',
    });
  };

  const handleNotificationError = () => {
    notificationStore.notify({
      title: 'Notification Error',
      message: 'Notification message',
      type: 'error',
      btn: (
        <div className='flex items-center gap-x-1'>
          <Button variant='primary' onClick={handleConsoleOutput}>
            Button
          </Button>

          <Button variant='primary' onClick={handleConsoleOutput}>
            Button
          </Button>
        </div>
      ),
    });
  };

  const handleNotificationWarning = () => {
    notificationStore.notify({
      title: 'Notification Warning',
      message: 'Notification message',
      type: 'warning',
    });
  };

  const handleNotificationInfo = () => {
    notificationStore.notify({
      title: 'Notification Info',
      message: 'Notification message',
      type: 'info',
    });
  };

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.components.title',
        defaultMessage: 'Components',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  return (
    <div className={styles.main}>
      <div className={styles.col2}>
        <div className={styles.chart_container}>
          <LineChart data={chartDataLine} variant={'line'} />
        </div>
        <div className={styles.chart_container}>
          <LineChart
            data={chartDataLine}
            variant={'line'}
            minimalView={true}
            color={'gray'}
          />
        </div>
        <div className={styles.chart_container}>
          <LineChart data={chartDataLine} />
        </div>
        <div className={styles.chart_container}>
          <LineChart data={chartDataLine} minimalView={true} color={'red'} />
        </div>
        <div className={cn(styles.chart_container, styles.chart_big)}>
          <PieChart data={chartDataPie} />
        </div>
        <div className={styles.chart_container}>
          <PieChart data={chartDataPie} minimalView={true} />
        </div>
      </div>

      <div className={styles.col}>
        <p>Slected value = {selectValue?.value}</p>
        <Select
          options={selectOptions}
          onChange={setSelectValue}
          variant='outlined'
          value={selectOptions[1]}
        />
        <Select
          options={selectOptions}
          onChange={setSelectValue}
          variant='filled'
          placeholder={intl.formatMessage({
            id: 'page.select.title',
            defaultMessage: 'Withdraw type',
          })}
        />
        <Select
          options={selectOptions}
          darkMode={true}
          onChange={setSelectValue}
          variant='filled'
          placeholder={intl.formatMessage({
            id: 'page.select.title',
            defaultMessage: 'Withdraw type',
          })}
        />
        <Select
          options={selectOptions}
          onChange={setSelectValue}
          variant='borderless'
          placeholder={intl.formatMessage({
            id: 'page.select.title',
            defaultMessage: 'Withdraw type',
          })}
        />

        <HealthIndicator value={17} />
        <HealthIndicator value={27} />
        <HealthIndicator value={60} />
        <HealthIndicator value={87} />
      </div>

      <div className={styles.col}>
        <h2>Notifications</h2>

        <Button variant={'primary'} onClick={handleNotificationSuccess}>
          Notification Success
        </Button>
        <Button variant={'primary'} onClick={handleNotificationError}>
          Notification Error
        </Button>
        <Button variant={'primary'} onClick={handleNotificationInfo}>
          Notification Info
        </Button>
        <Button variant={'primary'} onClick={handleNotificationWarning}>
          Notification Warning
        </Button>

        <h1>H1 Title 36px</h1>
        <h2>H2 Title 32px</h2>
        <h3>H3 Title 26px</h3>
        <h4>H4 Title 22px</h4>
        <h5>H5 Title 16px</h5>
        <h6>H6 Title 16px</h6>
      </div>
    </div>
  );
});

export default ComponentsPage;
