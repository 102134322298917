import styles from '../index.module.css';
import Button from 'components/core/button';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { useGroupsDetailsPageStore } from '../provider/GroupsDetailsStoreProvider';
import { CreateGroupUserForm } from '..';
import { useBaseStores } from 'providers/BaseStoresProvider';

type ActionButtonsProps = {
  isCreate: boolean;
  id?: string;
  onSubmit: () => void;
};

export const ActionButtons = ({
  isCreate,
  id,
  onSubmit,
}: ActionButtonsProps) => {
  const intl = useIntl();
  const { reset, handleSubmit, formState } =
    useFormContext<CreateGroupUserForm>();

  const store = useGroupsDetailsPageStore();
  const { layoutStore, partnerStore } = useBaseStores();

  const onSubmitForm = async (form: CreateGroupUserForm) => {
    const fn = isCreate ? store.createGroup : store.updateGroup;
    await fn({
      id: Number(id),
      name: form.name,
      system: true,
      partner: partnerStore.currentPartner?.name!,
      userIds: form.users.map((item) => item.id),
    });
    onSubmit();
    layoutStore.toggleSidebar(false);
  };

  return (
    <>
      <div className={styles.actions}>
        <Button
          variant='secondary'
          onClick={() => {
            reset();
          }}
          disabled={!formState.isDirty}
        >
          {intl.formatMessage({
            id: 'sidebar.create_group.reset',
            defaultMessage: 'Reset',
          })}
        </Button>

        <Button
          variant='primary'
          onClick={handleSubmit(onSubmitForm)}
          disabled={!formState.isDirty || store.loading}
        >
          {intl.formatMessage({
            id: 'sidebar.create_group.save',
            defaultMessage: 'Save',
          })}
        </Button>
      </div>
    </>
  );
};
