/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import styles from './index.module.css';
import Button from 'components/core/button';
import { useUploadStore } from '../provider/UploadStoreProvider';
import { KybVerificationFileModel } from 'models/KybVerificationModel';
import { UploadStore } from '../store/UploadStore';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import { UserDetailsPageStore } from 'modules/user-details/store/UserDetailsPageStore';

type Props = {
  file: KybVerificationFileModel;
  uploadStore: UploadStore;
  store: UserDetailsPageStore;
  onSuccess: () => void;
};

const DeleteDocument = observer(({ file, uploadStore, onSuccess }: Props) => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();

  const deleteDocument = async () => {
    await uploadStore.delete(file.verificationId, file.id);
    layoutStore.toggleModal(false);
    onSuccess();
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        {intl.formatMessage({
          id: 'page.user_details.kyb.sure_delete',
          defaultMessage: `Are you sure you want to delete ${file.filename}?`,
        })}
      </h2>

      <div className={styles.btns}>
        <Button
          className={styles.btn}
          variant='primary'
          onClick={deleteDocument}
        >
          {intl.formatMessage({
            id: 'page.user_details.kyb.yes',
            defaultMessage: 'Yes',
          })}
        </Button>
        <Button
          className={styles.btn}
          variant='secondary'
          onClick={() => layoutStore.toggleModal(false)}
        >
          {intl.formatMessage({
            id: 'page.user_details.kyb.no',
            defaultMessage: 'No',
          })}
        </Button>
      </div>
    </div>
  );
});

export const useDeleteDocumentKyb = () => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();
  const uploadStore = useUploadStore();
  const store = useUserDetailsPageStore();

  const showDeleteModal = useCallback(
    (file: KybVerificationFileModel, onSuccess: () => void) => {
      layoutStore.toggleModal(
        true,
        intl.formatMessage({
          id: 'page.user_details.modal.delete_document',
          defaultMessage: 'Delete Document',
        }),
        <DeleteDocument
          file={file}
          uploadStore={uploadStore}
          store={store}
          onSuccess={onSuccess}
        />
      );
    },
    [intl, layoutStore, uploadStore, store]
  );

  return {
    showDeleteModal,
  };
};
