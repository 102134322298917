/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { observer } from 'mobx-react-lite';
import { TransactionAccountModel } from 'models/TransactionsModel';
import { formattedDate } from 'utils/dates';
import { useSidebarJsonViewer } from 'components/sidebars/sidebar-json-viewer';
import { TableProps } from 'antd';
import Table from 'components/core/table';
import Button from 'components/core/button';
import { useBaseStores } from '../../providers/BaseStoresProvider';

type Props = {
  collection: CollectionWithPages<TransactionAccountModel, {}>;
};
const TransactionsTable = observer(({ collection }: Props) => {
  const { currencyStore } = useBaseStores();
  const intl = useIntl();
  const { showJsonViewerSidebar } = useSidebarJsonViewer();

  const columns: TableProps<TransactionAccountModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.transactions.col.date',
        defaultMessage: 'Date',
      }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => (
        <span>{formattedDate(createdAt ?? '')}</span>
      ),
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.transactions.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{id}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.transactions.col.type',
        defaultMessage: 'Type',
      }),
      dataIndex: 'reasonType',
      key: 'reasonType',
      render: (reasonType) => <span>{reasonType}</span>,
      width: 10,
    },
    {
      title: intl.formatMessage({
        id: 'table.transactions.col.acc',
        defaultMessage: 'Account',
      }),
      dataIndex: 'account',
      key: 'account',
      render: (account: string) => <span>{account}</span>,
      width: 10,
    },
    {
      title: intl.formatMessage({
        id: 'table.transactions.col.change',
        defaultMessage: 'Amount change',
      }),
      dataIndex: 'change',
      key: 'change',
      render: (_, record) => (
        <span>
          {(+record.change ?? 0).toString()} {currencyStore.currenciesMap.get(record.currency)?.name}
        </span>
      ),
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.transactions.col.json',
        defaultMessage: 'JSON',
      }),
      dataIndex: 'source',
      key: 'source',
      render: (source) => (
        <Button
          variant={'secondary'}
          size={'small'}
          className='font-semibold'
          onClick={() => showJsonViewerSidebar(source ?? '{}')}
        >
          {intl.formatMessage({
            id: 'table.transactions.col.checkJson',
            defaultMessage: 'Check JSON',
          })}
        </Button>
      ),
      width: 10,
    },
  ];

  return (
    <Table<TransactionAccountModel, {}>
      collection={collection}
      columns={columns}
    />
  );
});

export default TransactionsTable;
