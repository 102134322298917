/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Button from 'components/core/button';
import LoanOffersTable from 'components/tables/loan-offers-table';

import { observer, useLocalObservable } from 'mobx-react-lite';
import { LoanOffersListStore } from 'modules/loans/stores/LoanOffersListStore';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FaPlus } from 'react-icons/fa6';
import { useSidebarOfferCreate } from 'components/sidebars/sidebar-offer-create';
import { LoanOfferModel } from 'models/LoanOfferModel';

const LoanOffersTab = observer(() => {
  const intl = useIntl();

  const { loansApi } = useBaseStores();

  const offersStore = useLocalObservable(
    () => new LoanOffersListStore(loansApi)
  );

  const { layoutStore } = useBaseStores();

  const createOffer = useCallback(
    (offer: LoanOfferModel) => {
      offersStore.createLoanOffer(offer);
    },
    [offersStore]
  );

  const { showCreateOfferSidebar } = useSidebarOfferCreate({
    onSubmit: createOffer,
  });

  useEffect(() => {
    offersStore.init();
  }, [offersStore]);

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.loans.tabs.offers',
        defaultMessage: 'Loan offers',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Button variant='primary' onClick={showCreateOfferSidebar}>
          <span>
            {intl.formatMessage({
              id: 'page.loans.offers.create_offer',
              defaultMessage: 'Create a loan offer',
            })}
          </span>

          <FaPlus />
        </Button>
      </div>

      <LoanOffersTable collection={offersStore.loanOffers} />
    </div>
  );
});

export default LoanOffersTab;
