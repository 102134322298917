/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { DashboardPageStore } from '../store/DashboardPageStore';

const storeContext = createContext<DashboardPageStore>(
  {} as DashboardPageStore
);

type Props = {
  children?: React.ReactNode;
};
export const ProvideDashboardPageStore = observer(({ children }: Props) => {
  const { partnerStore, currencyStore, authApi, walletApi } = useBaseStores();

  const store = useLocalObservable(
    () => new DashboardPageStore(currencyStore, authApi, walletApi)
  );

  useEffect(() => {
    if (!partnerStore.currentPartner?.id) {
      return;
    }

    store.init(partnerStore.currentPartner.id);
  }, [partnerStore.currentPartner?.id, store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useDashboardPageStore = () => {
  return useContext(storeContext);
};
