/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi } from 'api';
import { PartnerDTO } from 'api/auth';
import { LOCAL_STORAGE_KEYS } from 'assets/config';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { AuthStore } from './AuthStore';

export class PartnerStore {
  constructor(
    private authApi: AuthApi,
    authStore: AuthStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => authStore.isLoggedIn,
      (isLoggedIn) => {
        if (!isLoggedIn) {
          return;
        }

        this.init();
      }
    );
  }

  private _currentPartner: PartnerDTO | null = null;
  private _availablePartners: PartnerDTO[] = [];

  get currentPartner() {
    return this._currentPartner;
  }

  get availablePartners() {
    return this._availablePartners;
  }

  async init() {
    await this.fetchPartners();

    if (this._availablePartners.length === 0) {
      return;
    }

    const localStoragePartnerId = localStorage.getItem(
      LOCAL_STORAGE_KEYS.partner
    );

    const partnerId = localStoragePartnerId
      ? Number(localStoragePartnerId)
      : null;

    runInAction(() => {
      this._currentPartner = !!partnerId
        ? this._availablePartners.find((x) => x.id === partnerId) ?? null
        : this._availablePartners[0];
    });
  }

  setPartner(partnerId: number) {
    const partner = this._availablePartners.find((x) => x.id === partnerId);

    if (!partner) {
      return;
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.partner, partnerId.toString());

    runInAction(() => {
      this._currentPartner = partner;
    });
  }

  private async fetchPartners() {
    try {
      const result = await this.authApi.admin.adminListPartners();

      runInAction(() => {
        this._availablePartners = result.data;
      });
    } catch (err) {
      console.error(err);
    }
  }
}
