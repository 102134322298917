/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import dayjs from 'dayjs';

export const DATE_FORMAT = 'DD MMMM HH:mm:ss';

export const unixTsToDateStr = (timestamp: number, dateFormat: string) => {
  return dayjs.unix(timestamp).format(dateFormat);
};

export const formattedDate = (
  val: string | number,
  dateFormat = 'DD.MM.YYYY HH:mm'
) => dayjs(val).format(dateFormat);

export const getUnixTsNow = () => dayjs().unix();
