/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AsyncValidationOptions } from 'joi';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

type ValidationData = {
  validationOptions: AsyncValidationOptions;
};

export const useIntlValidation: () => ValidationData = () => {
  const intl = useIntl();

  const validationMessages = useMemo(
    () => ({
      'any.required': intl.formatMessage({
        id: 'validation.field_required',
        defaultMessage: 'Field is required',
      }),
      'any.invalid': intl.formatMessage({
        id: 'validation.incorrect_format',
        defaultMessage: 'Incorrect format',
      }),
      'date.base': intl.formatMessage({
        id: 'validation.incorrect_date',
        defaultMessage: 'Incorrect date',
      }),
      'number.base': intl.formatMessage({
        id: 'validation.incorrect_format',
        defaultMessage: 'Incorrect format',
      }),
      'string.uri': intl.formatMessage({
        id: 'validation.incorrect_format',
        defaultMessage: 'Incorrect format',
      }),
      'number.min': intl.formatMessage({
        id: 'validation.value_low',
        defaultMessage: 'Value is too low',
      }),
      'number.max': intl.formatMessage({
        id: 'validation.value_high',
        defaultMessage: 'Value is too high',
      }),
    }),
    [intl]
  );

  return {
    validationOptions: { messages: validationMessages, abortEarly: false },
  };
};
