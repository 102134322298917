/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';
import { useIntl } from 'react-intl';
import { cutId } from 'utils/strings';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { observer } from 'mobx-react-lite';
import { AdminUserScopesModel } from 'models/UserScopesModel';
import { TableProps } from 'antd';
import Table from '../core/table';

type Props = {
  collection: CollectionWithPages<AdminUserScopesModel, {}>;
};

const UserScopesTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  const columns: TableProps<AdminUserScopesModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.user_scopes.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{cutId(id?.toString() ?? '0')}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.user_scopes.col.name',
        defaultMessage: 'slug',
      }),
      dataIndex: 'slug',
      key: 'slug',
      render: (slug: string) => <span>{slug}</span>,
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.user_scopes.col.description',
        defaultMessage: 'description',
      }),
      dataIndex: 'description',
      key: 'description',
      render: (description: string) => <span>{description}</span>,
      width: 30,
    },
    // {
    //   Header: intl.formatMessage({
    //     id: 'table.user_scopes.col.action',
    //     defaultMessage: 'Action',
    //   }),
    //   accessor: 'description',
    //   Cell: () => (
    //     <button>
    //       <FaPen />
    //     </button>
    //   ),
    //   width: 20,
    // },
  ];

  return (
    <Table<AdminUserScopesModel, {}>
      collection={collection}
      columns={columns}
    />
  );
});

export default UserScopesTable;
