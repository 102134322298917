/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { LoanDashboardStore } from 'modules/loans/stores/LoanDashboardStore';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const LoansDashboardTab = observer(() => {
  const intl = useIntl();

  const { authApi, walletApi } = useBaseStores();

  const dashboardStore = useLocalObservable(
    () => new LoanDashboardStore(authApi, walletApi)
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    dashboardStore.init();
  }, [dashboardStore]);

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.loans.tabs.dashboard',
        defaultMessage: 'Loan dashboard',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  return <div></div>;
});

export default LoansDashboardTab;
