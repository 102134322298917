import {
  RecurrentInvoiceInterval,
  OriginalInvoiceDto,
  RecurrentInvoiceDto,
  InvoiceType,
  InwizoInvoicePaymentDto,
  ItezInvoicePaymentDto,
  PlatformInvoicePaymentDto,
  StripeInvoicePaymentDto,
  ExternalInvoicePaymentDto,
  CheckoutInvoicePaymentDto,
} from 'api/acquiring';
import BigNumber from 'bignumber.js';
import { parseBigNumberZero } from 'utils/bignumber';

export class BaseInvoiceModel {
  constructor(dto: OriginalInvoiceDto | RecurrentInvoiceDto) {
    this.externalClientId = dto.externalClientId ?? '';
    this.id = dto.id?.toString() ?? '';
    this.amount = parseBigNumberZero(dto.amount);
    this.currency = dto.currency;
    this.paymentDate = dto.paymentDate;
    this.payments = undefined;
    this.type = dto.type;

    if (this.type.toUpperCase() === InvoiceType.ORIGINAL.toUpperCase()) {
      this.status = (dto as OriginalInvoiceDto).status;
    } else if (dto.type.toUpperCase() === InvoiceType.RECURRENT.toUpperCase()) {
      const recurrentDto = dto as RecurrentInvoiceDto;
      this.nextPaymentDate = recurrentDto.nextPaymentDate;
      this.initialPaymentAmount = parseBigNumberZero(
        recurrentDto.initialPaymentAmount
      );
      this.status = recurrentDto.status;
      this.recurrentInvoiceInterval = recurrentDto.recurrentInvoiceInterval;
    }

    if ('payments' in dto) {
      this.payments = dto.payments;
    }
    if ('linkedInvoices' in dto) {
      this.linkedInvoices = dto.linkedInvoices;
    }
  }

  externalClientId?: string;
  id: string;
  amount: BigNumber;
  currency: string;
  paymentDate?: number;
  type: string;
  status?: string;

  payments?: (
    | CheckoutInvoicePaymentDto
    | ExternalInvoicePaymentDto
    | InwizoInvoicePaymentDto
    | ItezInvoicePaymentDto
    | PlatformInvoicePaymentDto
    | StripeInvoicePaymentDto
  )[];

  // fields for RecurrentInvoiceDto
  nextPaymentDate?: number;
  initialPaymentAmount?: BigNumber;
  recurrentInvoiceInterval?: RecurrentInvoiceInterval;
  linkedInvoices?: OriginalInvoiceDto[];
}
