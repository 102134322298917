/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { makeAutoObservable, runInAction } from 'mobx';
import { FilterModel } from 'models/filter/FilterModel';

export class CollectionFilter<T> {
  constructor(
    private defaultFilter: FilterModel<T>[] = [],
    private ignoreCounterValues: string[] = []
  ) {
    makeAutoObservable(this);
  }

  private selectedFilter: FilterModel<T>[] = [];

  private get combinedFilter() {
    const selected = this.selectedFilter.filter((x) => x.value != null);

    return this.defaultFilter
      .filter((x) => !selected.find((y) => y.id === x.id))
      .concat(selected);
  }

  get totalCount() {
    return this.combinedFilter.filter(
      (x) => !this.ignoreCounterValues.find((y) => y === x.id)
    ).length;
  }

  get filterValue() {
    return Object.assign({}, ...this.combinedFilter.map((x) => x.value));
  }

  replace(filter: FilterModel<T>[]) {
    runInAction(() => {
      this.selectedFilter = filter;
    });
  }

  replaceSingle(filter: FilterModel<T>) {
    runInAction(() => {
      const existingIndex = this.selectedFilter.findIndex(
        (x) => x.id === filter.id
      );

      if (existingIndex === -1) {
        this.selectedFilter.push(filter);
        return;
      }

      this.selectedFilter[existingIndex] = filter;
    });
  }

  reset() {
    runInAction(() => {
      this.selectedFilter = [];
    });
  }
}
