/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { makeAutoObservable, runInAction } from 'mobx';

export class PaginationState {
  constructor() {
    makeAutoObservable(this);
  }

  private _currentPage: number = 1;
  private _maxPage: number = 1;
  private _itemsPerPage: number = 1;

  get maxPage() {
    return this._maxPage;
  }

  get currentPage() {
    return this._currentPage;
  }

  get itemsPerPage() {
    return this._itemsPerPage;
  }

  setItemsPerPage(itemsPerPage: number) {
    runInAction(() => {
      this._itemsPerPage = itemsPerPage;
    });
  }

  setMaxPage(maxPage: number) {
    if (maxPage <= 0) {
      maxPage = 1;
    }

    runInAction(() => {
      this._maxPage = maxPage;
    });

    if (this._currentPage > this._maxPage) {
      this.setCurrentPage(maxPage);
    }
  }

  setCurrentPage(page: number) {
    if (page <= 0) {
      page = 1;
    }

    runInAction(() => {
      this._currentPage = page;
    });
  }

  resetPage() {
    runInAction(() => {
      this._currentPage = 1;
    });
  }

  reset() {
    runInAction(() => {
      this._currentPage = 1;
      this._maxPage = 1;
    });
  }
}
