/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';
import ReactJson from 'react-json-view';

import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';

const SidebarJsonViewer = observer(({ source }: { source: string }) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <h4 className='font-semibold mb-2'>
        {intl.formatMessage({
          id: 'sidebar.json',
          defaultMessage: 'Source JSON',
        })}
      </h4>

      {source ? (
        <ReactJson theme='rjv-default' src={JSON.parse(source)} />
      ) : null}
    </React.Fragment>
  );
});

export const useSidebarJsonViewer = () => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = (sourceVal: string) => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.data',
        defaultMessage: 'Data',
      }),
      <SidebarJsonViewer source={sourceVal} />
    );
  };

  return {
    showJsonViewerSidebar: (sourceVal: string) => showSidebar(sourceVal),
  };
};
