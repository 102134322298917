import React from 'react';
import { FormValues, Step } from '..';
import Button from 'components/core/button';
import { useFormContext } from 'react-hook-form';
import { useOperationStore } from '../../provider/OperationStoreProvider';

import styles from './index.module.css';
import { useIntl } from 'react-intl';
import { SummaryInfo } from '../../summary-info';
import FormField from 'components/form/form-field';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { observer } from 'mobx-react-lite';
import { WithdrawType } from 'api/wallet';

type StepSummaryProps = {
  setStep: (step: Step) => void;
  handleSubmit: () => void;
};

export const StepSummary = observer(
  ({ setStep, handleSubmit: onSuccess }: StepSummaryProps) => {
    const intl = useIntl();
    const store = useOperationStore();
    const { handleSubmit, getValues } = useFormContext<FormValues>();
    const { currencyStore } = useBaseStores();
    const values = getValues();

    const transfer = async () => {
      const result = await store.exchange.create();
      if (result) onSuccess();
    };

    const result = store.exchange.feeData.value?.data;

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <SummaryInfo
            options={[
              {
                title: intl.formatMessage({
                  id: 'sidebar.operation.withdraw_type',
                  defaultMessage: 'Withdraw type',
                }),
                value: (
                  values.type[0] + values.type.slice(1).toLowerCase()
                ).replace('_', ' '),
              },
              {
                title: intl.formatMessage({
                  id: 'sidebar.operation.from',
                  defaultMessage: 'From',
                }),
                value: values.fromAccount,
              },
              ...(values.type === WithdrawType.COUNTERPARTY
                ? [
                    {
                      title: intl.formatMessage({
                        id: 'sidebar.operation.counterPartyId',
                        defaultMessage: 'Counterparty Id',
                      }),
                      value: values.counterPartyId,
                    },
                  ]
                : []),
              ...(values.type === WithdrawType.CRYPTO
                ? [
                    {
                      title: intl.formatMessage({
                        id: 'sidebar.operation.to',
                        defaultMessage: 'to',
                      }),
                      value: values.to,
                    },
                  ]
                : []),
              ...(values.type === WithdrawType.BANK_TRANSFER
                ? [
                    {
                      title: intl.formatMessage({
                        id: 'sidebar.operation.iban',
                        defaultMessage: 'IBAN',
                      }),
                      value: values.iban,
                    },
                    {
                      title: intl.formatMessage({
                        id: 'sidebar.operation.bicOrSwiftCode',
                        defaultMessage: 'BIC/SWIFT',
                      }),
                      value: values.bicOrSwiftCode,
                    },
                  ]
                : []),

              {
                value: (
                  <div className={styles.amount}>
                    <img
                      width={20}
                      height={20}
                      alt='currency'
                      src={
                        currencyStore.currenciesMap.get(values.currency)
                          ?.iconUrl
                      }
                    />
                    <span className={styles.value}>{values.amount}</span>
                  </div>
                ),
                title: intl.formatMessage({
                  id: 'sidebar.operation.amount',
                  defaultMessage: 'Amount',
                }),
              },
              {
                value: (
                  <div className={styles.amount}>
                    <span className={styles.value}>
                      1 {result?.fromCurrency} ={' '}
                      {Number(result?.signedRate?.rate || 0)}{' '}
                      {result?.toCurrency}
                    </span>
                  </div>
                ),
                title: intl.formatMessage({
                  id: 'sidebar.operation.rate',
                  defaultMessage: 'Rate',
                }),
              },
              {
                value: (
                  <div className={styles.amount}>
                    <img
                      width={20}
                      height={20}
                      alt='currency'
                      src={
                        currencyStore.currenciesMap.get(values.currency)
                          ?.iconUrl
                      }
                    />
                    <span className={styles.value}>
                      {Number(result?.toAmount || 0)}
                    </span>
                  </div>
                ),
                title: intl.formatMessage({
                  id: 'sidebar.operation.conversion_result',
                  defaultMessage: 'Conversion result',
                }),
              },
              {
                value:
                  currencyStore
                    .getEuroValue(
                      values.currency,
                      Number(result?.fromAmount || 0)
                    )
                    ?.toString() || '',
                title: intl.formatMessage({
                  id: 'sidebar.operation.total_value',
                  defaultMessage: 'Total value',
                }),
              },
              {
                value:
                  currencyStore
                    .getEuroValue(
                      values.currency,
                      Number(result?.feeAmount || 0)
                    )
                    ?.toString() || '',
                title: intl.formatMessage({
                  id: 'sidebar.operation.fee',
                  defaultMessage: 'Additional fee',
                }),
              },
            ]}
          />
          <FormField
            type='text_area'
            label={intl.formatMessage({
              id: 'sidebar.operation.note.title',
              defaultMessage: 'Note',
            })}
            disabled
            placeholder={intl.formatMessage({
              id: 'sidebar.operation.note.placeholder',
              defaultMessage:
                'Specify a reason (The user will see your message)',
            })}
            value={values.note}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            variant='primary'
            onClick={handleSubmit(transfer)}
            disabled={store.transfer.loading}
          >
            {intl.formatMessage({
              id: 'sidebar.operation.transfer',
              defaultMessage: 'Transfer',
            })}
          </Button>
          <Button variant='secondary' onClick={() => setStep('withdraw')}>
            {intl.formatMessage({
              id: 'sidebar.operation.back',
              defaultMessage: 'Back',
            })}
          </Button>
        </div>
      </div>
    );
  }
);
