/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import LoansTable from 'components/tables/loans-table';

import { observer, useLocalObservable } from 'mobx-react-lite';
import { LoanListStore } from 'modules/loans/stores/LoanListStore';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ROUTES } from 'routes';
import { useNavigate } from 'react-router-dom';
import { LoanModel } from 'models/LoanModel';

const LoansListTab = observer(() => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { loansApi } = useBaseStores();

  const listStore = useLocalObservable(() => new LoanListStore(loansApi));

  const { layoutStore } = useBaseStores();

  const onLoanClick = useCallback(
    (loan: LoanModel) => {
      navigate(ROUTES.loanDetails.getFullPath(loan.id));
    },
    [navigate]
  );

  useEffect(() => {
    listStore.init();
  }, [listStore]);

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.loans.tabs.list',
        defaultMessage: 'Loans',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  return <LoansTable collection={listStore.loans} onLoanClick={onLoanClick} />;
});

export default LoansListTab;
