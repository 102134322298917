/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmRequest, Withdraw } from '../../api/approver';

export const useIntlTransactionsStatuses = () => {
  const intl = useIntl();
  type CombinedTransactionStatus =
    | ConfirmRequest['status']
    | Withdraw['status'];

  const transactionsStatusToIntlText = useCallback(
    (status: CombinedTransactionStatus) => {
      switch (status) {
        case 'APPROVING':
          return intl.formatMessage({
            id: 'common.transaction_status.approving',
            defaultMessage: 'Approving',
          });

        case 'APPROVED':
          return intl.formatMessage({
            id: 'common.transaction_status.approved',
            defaultMessage: 'Approved',
          });

        case 'REJECTING':
          return intl.formatMessage({
            id: 'common.transaction_status.rejecting',
            defaultMessage: 'Rejecting',
          });

        case 'REJECTED':
          return intl.formatMessage({
            id: 'common.transaction_status.rejected',
            defaultMessage: 'Rejected',
          });

        case 'FAILED':
          return intl.formatMessage({
            id: 'common.transaction_status.failed',
            defaultMessage: 'Failed',
          });

        case 'PROCESSING':
          return intl.formatMessage({
            id: 'common.transaction_status.processing',
            defaultMessage: 'Processing',
          });

        case 'WITHDRAWN':
          return intl.formatMessage({
            id: 'common.transaction_status.withdrawn',
            defaultMessage: 'Withdrawn',
          });

        case 'CANCELED':
          return intl.formatMessage({
            id: 'common.transaction_status.canceled',
            defaultMessage: 'Canceled',
          });

        case 'PENDING':
        default:
          return intl.formatMessage({
            id: 'common.transaction_status.pending',
            defaultMessage: 'Pending',
          });
      }
    },
    [intl]
  );

  return {
    transactionsStatusToIntlText,
  };
};
