/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { UserDetailsNotesStore } from '../store/UserDetailsNotesStore';

const storeContext = createContext<UserDetailsNotesStore>(
  {} as UserDetailsNotesStore
);

type Props = {
  userId: string;
  children?: React.ReactNode;
};
export const ProvideUserNotesPageStore = observer(
  ({ children, userId }: Props) => {
    const { partnerStore, authApi } = useBaseStores();

    const store = useLocalObservable(() => new UserDetailsNotesStore(authApi));

    useEffect(() => {
      if (!partnerStore.currentPartner?.id) {
        return;
      }

      store.init(userId, partnerStore.currentPartner.id);
    }, [partnerStore.currentPartner?.id, store, userId]);

    return (
      <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
  }
);

export const useUserNotesPageStore = () => {
  return useContext(storeContext);
};
