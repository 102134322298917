/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { CardsPageStore } from '../store/CardsPageStore';

const storeContext = createContext<CardsPageStore>({} as CardsPageStore);

type Props = {
  children?: React.ReactNode;
};

export const ProvideCardsPageStore = observer(({ children }: Props) => {
  const { cardHolderApi } = useBaseStores();

  const store = useLocalObservable(() => new CardsPageStore(cardHolderApi));

  const { page, filter } = useParseCollectionQueryParams({
    collection: store.cards,
  });

  useEffect(() => {
    store.init({
      filter: filter,
      page: page,
    });
  }, [filter, page, store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useCardsPageStore = () => {
  return useContext(storeContext);
};
