/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';

import React, { useCallback, useEffect, useState } from 'react';

export type InputFieldProps = {
  value?: string | number;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  error?: string;
  icon?: React.ReactNode;
  variant?: 'primary' | 'secondary';
};
const InputField = ({
  value,
  placeholder,
  type,
  disabled,
  onChange,
  onFocus,
  onBlur,
  className,
  error,
  icon,
  variant,
}: InputFieldProps) => {
  const [inputValue, setStrValue] = useState(value);

  const handleInput = useCallback(
    (value: string) => {
      setStrValue(value);

      onChange?.(value);
    },
    [onChange]
  );

  useEffect(() => {
    if (inputValue !== value) {
      setStrValue(value);
    }
  }, [inputValue, value]);

  return (
    <div
      className={classNames(
        styles.main,
        { [styles.primary]: !variant || variant === 'primary' },
        { [styles.secondary]: variant === 'secondary' },
        className
      )}
    >
      <div className={styles.container}>
        {icon ?? null}

        <input
          className={styles.input}
          value={value ?? ''}
          placeholder={placeholder}
          type={type ?? 'text'}
          onChange={(event) => handleInput(event.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete='off'
          disabled={disabled}
        />
      </div>

      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default InputField;
