import React, { useEffect, useMemo } from 'react';
import { CardInfo } from '../../сard-info';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormValues } from '../';
import { useOperationStore } from '../../provider/OperationStoreProvider';
import { DirectExchange } from 'api/wallet';
import { debounce } from 'lodash';

export const TotalInfo = observer(() => {
  const intl = useIntl();
  const store = useOperationStore();
  const { currencyStore } = useBaseStores();
  const { control, setValue } = useFormContext<FormValues>();
  const values = useWatch({
    control,
  });
  const loadBalances = useMemo(
    () => debounce(store.accountBalances.fetch, 500),
    [store.accountBalances]
  );

  useEffect(() => {
    setValue('fromCurrency', '');
  }, [values.fromAccount, setValue]);

  useEffect(() => {
    setValue('toCurrency', '');
  }, [values.toAccount, setValue]);

  useEffect(() => {
    if (values.fromAccount) loadBalances(values.fromAccount);
  }, [loadBalances, values.fromAccount]);

  useEffect(() => {
    if (values.toAccount) loadBalances(values.toAccount, 'to');
  }, [loadBalances, values.toAccount]);

  useEffect(() => {
    if (
      !Number(values.fromAmount) ||
      !values.toCurrency ||
      !values.fromCurrency ||
      !values.toAccount
    )
      return;

    store.exchange.feeData.sync(
      { ...(values as DirectExchange) },
      {
        dryRun: true,
      },
      {
        headers: { PartnerId: store.partnerId },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.fromAmount,
    values.fromAccount,
    values.fromCurrency,
    values.toAccount,
    values.toCurrency,
    store,
  ]);

  return (
    <CardInfo
      options={[
        {
          value: `$${
            currencyStore.currenciesMap
              .get(values.fromCurrency!)
              ?.eurPrice?.toString() || ''
          }`,
          title: intl.formatMessage({
            id: 'sidebar.operation.spot_price',
            defaultMessage: `${values.fromCurrency} Spot price`,
          }),
        },
        ...(values.toCurrency
          ? [
              {
                value: `$${
                  currencyStore.currenciesMap
                    .get(values.toCurrency!)
                    ?.eurPrice?.toString() || ''
                }`,
                title: intl.formatMessage({
                  id: 'sidebar.operation.spot_price',
                  defaultMessage: `${values.toCurrency} Spot price`,
                }),
              },
            ]
          : []),
        {
          value:
            currencyStore
              .getEuroValue(values.fromCurrency, Number(values.fromAmount || 0))
              ?.toString() || '',
          title: intl.formatMessage({
            id: 'sidebar.operation.amount',
            defaultMessage: 'Total value',
          }),
        },
      ]}
    />
  );
});
