import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useAccountsPageStore } from '../provider/StoreProvider';
import BigNumber from 'bignumber.js';

type TotalBalanceProps = {
  accountId: string;
};

export const TotalBalance: FC<TotalBalanceProps> = observer(({ accountId }) => {
  const accountsPageStore = useAccountsPageStore();
  const [balance, setBalance] = useState<number>(0);

  useEffect(() => {
    const fetchBalance = async () => {
      const result = await accountsPageStore.fetchAccountBalance(accountId);

      if (result) {
        setBalance(result);
      }
    };
    fetchBalance();
  }, [accountsPageStore, accountId]);

  return <span>${BigNumber(balance).toFixed(2)}</span>;
});
