/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import CollateralInfoTable from 'components/tables/collateral-info-table';
import CollateralTransactionsTable from 'components/tables/collateral-transactions-table';
import PieChart from 'components/charts/pie-chart';

import { observer } from 'mobx-react-lite';
import { useLoanDetailsPageStore } from '../../provider/StoreProvider';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { EURO_SIGN } from 'assets/config';

const LoanDetailsManagementTab = observer(() => {
  const loanDetailsPageStore = useLoanDetailsPageStore();

  const { loanLtv, loanCollateralTransactions, loanCollateralInfo } =
    loanDetailsPageStore;

  const intl = useIntl();

  const chartData = useMemo(
    () =>
      loanCollateralInfo?.map((item) => ({
        name: item.currency,
        value: item.amountTotal?.decimalPlaces(2).toNumber() || 0,
      })),
    [loanCollateralInfo]
  );

  return (
    <>
      <div className={styles.main}>
        <div className={styles.plane}>
          <h3>
            {intl.formatMessage({
              id: 'page.loan_management.ltv',
              defaultMessage: 'Ltv',
            })}
          </h3>
          <p>{loanLtv?.ltv.toFixed(5)}</p>
        </div>

        <div className={styles.plane}>
          <h3>
            {intl.formatMessage({
              id: 'page.loan_management.feesManagement',
              defaultMessage: 'Fees management',
            })}
          </h3>
          <p>
            {intl.formatMessage({
              id: 'page.loan_management.underDevelopment',
              defaultMessage: 'Under Development',
            })}
          </p>
        </div>
      </div>

      <div className={styles.plane}>
        <h3 className={'-mb-2'}>
          {intl.formatMessage({
            id: 'page.loan_management.collateralInfo',
            defaultMessage: 'Collateral info',
          })}
        </h3>
        <div className={styles.chart_section}>
          <div className={styles.chart}>
            <h6>
              {intl.formatMessage({
                id: 'page.loan_management.multiCoinCollateral',
                defaultMessage: 'Multi coin collateral',
              })}
            </h6>
            <PieChart
              data={chartData}
              minimalView={true}
              currencySign={EURO_SIGN}
            />
          </div>
          <div className={styles.table}>
            <CollateralInfoTable collection={loanCollateralInfo ?? null} />
          </div>
        </div>
      </div>

      <div className={styles.plane}>
        <h3>
          {intl.formatMessage({
            id: 'page.loan_management.collateralTransactions',
            defaultMessage: 'Collateral transactions',
          })}
        </h3>
        <CollateralTransactionsTable
          collection={loanCollateralTransactions ?? null}
        />
      </div>
    </>
  );
});

export default LoanDetailsManagementTab;
