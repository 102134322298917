/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { PromoCodesPageStore } from '../store/PromoCodesPageStore';

const storeContext = createContext<PromoCodesPageStore>(
  {} as PromoCodesPageStore
);

type Props = {
  children?: React.ReactNode;
};

export const ProvidePromoCodesPageStore = observer(({ children }: Props) => {
  const { authApi } = useBaseStores();

  const store = useLocalObservable(() => new PromoCodesPageStore(authApi));

  const { page, filter } = useParseCollectionQueryParams({
    collection: store.promoCodes,
  });

  useEffect(() => {
    store.init({
      filter: filter,
      page: page,
    });
  }, [filter, page, store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const usePromoCodesPageStore = () => {
  return useContext(storeContext);
};
