/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.css';
import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import {
  DepositData,
  TransactionApproverModel,
} from 'models/TransactionsApproverModel';
import Button from 'components/core/button';
import { Controller, useForm } from 'react-hook-form';
import { useIntlValidation } from 'hooks/intl/validation';
import joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import TextAreaField from 'components/core/text-area-field';
import { useTransactionsPageStore } from 'modules/transactionsApprover/provider/StoreProvider';
import { DATE_FORMAT, unixTsToDateStr } from 'utils/dates';
import BadgeTransactionStatus, {
  CombinedTransactionStatus,
} from 'components/core/badges/badge-transaction-status';
import BigNumber from 'bignumber.js';
import { bigNumberToStr } from 'utils/bignumber';
import { ReactComponent as ApproveIconSvg } from 'assets/images/icons/approve.svg';
import { ReactComponent as RejectIconSvg } from 'assets/images/icons/reject.svg';
import { TransactionsPageStore } from '../../../modules/transactionsApprover/store/TxPageStore';

type TransactionReview = {
  reasonText: string;
};

type Props = {
  data: TransactionApproverModel;
  approve: (id: string, data: TransactionReview) => void;
  reject: (id: string, data: TransactionReview) => void;
  store: TransactionsPageStore;
};

const SidebarTransactionReview = observer(
  ({ data, approve, reject, store }: Props) => {
    const intl = useIntl();
    const { layoutStore, currencyStore } = useBaseStores();
    const { validationOptions } = useIntlValidation();
    const [depositData, setDepositData] = useState<DepositData | null>(null);
    const { loading } = store;

    useEffect(() => {
      const rawDepositData = data?.deposit;
      if (rawDepositData) {
        setDepositData(JSON.parse(rawDepositData));
      }
    }, [data?.deposit]);

    const formScheme = useMemo(
      () =>
        joi.object<TransactionReview>({
          reasonText: joi.string().empty('').required(),
        }),
      []
    );

    const { control, handleSubmit, reset, formState } = useForm({
      defaultValues: { reasonText: '' },
      resolver: joiResolver(formScheme, validationOptions),
    });

    const onApprove = useCallback(() => {
      handleSubmit(async (formData) => {
        await approve(data.id, formData);
        reset();
        layoutStore.toggleSidebar(false);
      })();
    }, [approve, data, layoutStore, reset, handleSubmit]);

    const onReject = useCallback(() => {
      handleSubmit(async (formData) => {
        await reject(data.id, formData);
        reset();
        layoutStore.toggleSidebar(false);
      })();
    }, [reject, data, layoutStore, reset, handleSubmit]);

    return (
      <React.Fragment>
        <h4>
          {intl.formatMessage({
            id: 'sidebar.transactionReview.depositOperation',
            defaultMessage: 'Deposit operation',
          })}
        </h4>

        <div className={styles.operation}>
          <div className={styles.value}>
            <h4 className={'mb-1'}>
              {bigNumberToStr(BigNumber(data.amount || 0), 7)}{' '}
              {data.symbol || '-'}
            </h4>
            <span className={styles.fiat_value}>
              {currencyStore.getEuroValue(data.symbol, Number(data.amount))}
            </span>
          </div>
          <BadgeTransactionStatus
            status={data.status as CombinedTransactionStatus}
          />
        </div>

        <h4>
          {intl.formatMessage({
            id: 'sidebar.transactionReview.requestInformation',
            defaultMessage: 'Request information',
          })}
        </h4>
        <div className={styles.section}>
          <ul className={styles.list}>
            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.type',
                  defaultMessage: 'Type',
                })}
              </span>
              <span className={styles.value}>{data.type || '-'}</span>
            </li>
            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.status',
                  defaultMessage: 'Status',
                })}
              </span>
              <span className={styles.value}>
                <BadgeTransactionStatus
                  status={data.status as CombinedTransactionStatus}
                />
              </span>
            </li>
            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.dateTime',
                  defaultMessage: 'Date & time',
                })}
              </span>
              <span className={styles.value}>{data.createdAt || '-'}</span>
            </li>
            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.processingId',
                  defaultMessage: 'Processing Id',
                })}
              </span>
              <span className={styles.value}>{data.id || '-'}</span>
            </li>
          </ul>
        </div>

        <h4>
          {intl.formatMessage({
            id: 'sidebar.transactionReview.depositInformation',
            defaultMessage: 'Deposit information',
          })}
        </h4>
        <div className={styles.section}>
          <ul className={styles.list}>
            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.depositId',
                  defaultMessage: 'Deposit ID',
                })}
              </span>
              <span className={styles.value}>{depositData?.id || '-'}</span>
            </li>

            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.blockchain',
                  defaultMessage: 'Blockchain',
                })}
              </span>
              <span className={styles.value}>
                {depositData?.blockchain || '-'}
              </span>
            </li>

            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.fromAddress',
                  defaultMessage: 'From address',
                })}
              </span>
              <span className={styles.value}>
                {depositData?.meta?.fromAddress || '-'}
              </span>
            </li>

            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.toAddress',
                  defaultMessage: 'To address',
                })}
              </span>
              <span className={styles.value}>
                {depositData?.meta?.toAddress || '-'}
              </span>
            </li>

            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.txHash',
                  defaultMessage: 'Tx Hash',
                })}
              </span>
              <span className={styles.value}>
                {depositData?.meta?.txHash || '-'}
              </span>
            </li>

            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.amount',
                  defaultMessage: 'Amount',
                })}
              </span>
              <span className={styles.value}>
                {bigNumberToStr(BigNumber(data.amount || 0), 7) || '-'}
              </span>
            </li>

            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.created',
                  defaultMessage: 'Created',
                })}
              </span>
              <span className={styles.value}>
                {unixTsToDateStr(depositData?.createdAt || 0, DATE_FORMAT) ||
                  '-'}
              </span>
            </li>

            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.transactionReview.depositInformation.updated',
                  defaultMessage: 'Updated',
                })}
              </span>
              <span className={styles.value}>
                {unixTsToDateStr(depositData?.updatedAt || 0, DATE_FORMAT) ||
                  '-'}
              </span>
            </li>
          </ul>
        </div>

        <div>
          <h5 className='font-semibold mb-2'>
            {intl.formatMessage({
              id: 'sidebar.transactionReview.note',
              defaultMessage: 'Note',
            })}
          </h5>

          <Controller
            control={control}
            name='reasonText'
            render={({ field, fieldState: { error } }) => (
              <TextAreaField
                {...field}
                error={error?.message}
                variant='secondary'
                placeholder={intl.formatMessage({
                  id: 'sidebar.transactionReview.textAreaPlaceholder',
                  defaultMessage: 'Write a note about your solution',
                })}
              />
            )}
          />
        </div>

        <div className={styles.btn_container}>
          <Button
            className={styles.apply_btn}
            variant='negative'
            onClick={onReject}
            disabled={!formState.isValid || loading}
          >
            {intl.formatMessage({
              id: 'sidebar.transactionReview.reject',
              defaultMessage: 'Reject',
            })}
            <RejectIconSvg />
          </Button>

          <Button
            className={styles.apply_btn}
            variant='positive'
            onClick={onApprove}
            disabled={!formState.isValid || loading}
          >
            {intl.formatMessage({
              id: 'sidebar.transactionReview.approve',
              defaultMessage: 'Approve',
            })}
            <ApproveIconSvg />
          </Button>
        </div>
      </React.Fragment>
    );
  }
);

export const useSidebarTransactionReview = () => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();
  const transactionsPageStore = useTransactionsPageStore();

  const showSidebar = (transactionData: TransactionApproverModel) => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.transactionReview',
        defaultMessage: 'Transactions review',
      }),
      <SidebarTransactionReview
        data={transactionData}
        approve={transactionsPageStore.approveTransaction}
        reject={transactionsPageStore.rejectTransaction}
        store={transactionsPageStore}
      />
    );
  };

  return {
    showTransactionReviewSidebar: (transactionData: TransactionApproverModel) =>
      showSidebar(transactionData),
  };
};
