/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';
import { UserNoteCreate } from 'api/auth';
import { AdminUserNoteModel } from 'models/AdminUserNoteModel';

export class UserDetailsNotesStore {
  constructor(private authApi: AuthApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _partnerId: number = 0;
  private _userId: string | null = null;

  private _notes = {
    notes: [] as AdminUserNoteModel[],
    loading: false,
    fetchNotes: async () => {
      const result = await this.authApi.adminUserNotes.getUserNotesByUserId(
        this._userId as string,
        {
          headers: { PartnerId: this._partnerId },
        }
      );

      runInAction(() => {
        this._notes.notes = result.data;
      });
    },
    add: async (data: UserNoteCreate) => {
      try {
        runInAction(() => {
          this._notes.loading = true;
        });
        await this.authApi.adminUserNotes.addUserNote(
          this._userId as string,
          data,
          {
            headers: { PartnerId: this._partnerId },
          }
        );
        this.notes.fetchNotes();
      } catch (error) {
        console.error(error);
      } finally {
        runInAction(() => {
          this._notes.loading = false;
        });
      }
    },
    update: async (id: string, data: UserNoteCreate) => {
      try {
        await this.authApi.adminUserNotes.updateUserNote(
          this._userId as string,
          id,
          data,
          {
            headers: { PartnerId: this._partnerId },
          }
        );
        this.notes.fetchNotes();
      } catch (error) {
        console.error(error);
      }
    },
    delete: async (id: string) => {
      try {
        await this.authApi.adminUserNotes.deleteUserNoteByNoteId(
          this._userId as string,
          id,
          {
            headers: { PartnerId: this._partnerId },
          }
        );
        this.notes.fetchNotes();
      } catch (error) {
        console.error(error);
      }
    },
  };

  get notes() {
    return this._notes;
  }

  async init(userId: string, partnerId: number) {
    runInAction(() => {
      this._userId = userId;
      this._partnerId = partnerId;
    });
  }
}
