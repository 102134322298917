/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { BalanceLog, BalanceLogReasonType } from 'api/wallet';

export { VerificationStatus as UserVerificationStatus } from 'api/auth';

// (ConfirmRequest | ConfirmDepositRequest | ConfirmWithdrawalRequest)[]
export class TransactionAccountModel {
  constructor(dto: BalanceLog) {
    this.id = dto.id ?? '';
    this.account = dto.account;
    this.change = dto.change;
    this.currency = dto.currency;
    this.createdAt = dto.createdAt;
    this.reasonType = dto.reasonType;
    this.reasonId = dto.reasonId;
    this.note = dto.note;
    this.source = dto.source;
  }

  id: string;
  account: string;
  change: string;
  currency: string;
  createdAt?: string;
  reasonId?: string;
  reasonType: BalanceLogReasonType;
  note?: string;
  source?: string;
}
