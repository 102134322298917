/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi } from 'api';
import { AdminGroup } from 'api/auth';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable, runInAction } from 'mobx';
import { UserModel } from 'models/UserModel';
import { AdminListRequestModel } from 'models/request/AdminListRequestModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';

export class GroupsDetailsPageStore {
  constructor(private authApi: AuthApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public loading = false;

  private _partnerId = 0;
  private _groupId = 0;

  private _users = new CollectionWithPages<UserModel, AdminListRequestModel>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.authApi.admin.adminListUser(params, {
        headers: { PartnerId: this._partnerId },
      });

      const items = result.data.data.map((x) => new UserModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 50,
  });

  get users() {
    return this._users;
  }

  private _members = {
    items: [] as UserModel[],
    fetchFn: async () => {
      const result = await this.authApi.admin.adminGroupListMembers(
        this._groupId,
        undefined,
        {
          headers: { PartnerId: this._partnerId },
        }
      );

      const items = result.data.data.map((x) => new UserModel(x));
      this._members.items = items;

      return items;
    },
  };

  get members() {
    return this._members;
  }

  public async createGroup({
    userIds,
    ...data
  }: AdminGroup & { userIds: string[] }) {
    const result = await this.authApi.admin.adminCreateGroup(data, {
      headers: { PartnerId: this._partnerId },
    });

    if (!userIds.length) return;

    runInAction(() => {
      this.loading = true;
    });

    try {
      await this.authApi.admin.adminGroupAddMember(
        result.data.id!,
        {
          userIds,
        },
        {
          headers: { PartnerId: this._partnerId },
          paramsSerializer: {
            indexes: null,
          },
        }
      );
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public async updateGroup({
    userIds,
    ...data
  }: AdminGroup & { userIds: string[] }) {
    const updateGroup = async () =>
      await this.authApi.admin.adminUpdateGroup(data.id!, data, {
        headers: { PartnerId: this._partnerId },
      });

    const newMembers = userIds.filter(
      (id) => !this.members.items.find((v) => v.id === id)
    );
    const oldMembers = this.members.items.filter(
      (member) => !userIds.includes(member.id)
    );

    const addMembers = async () => {
      if (!newMembers.length) return;
      await this.authApi.admin.adminGroupAddMember(
        data.id!,
        {
          userIds: newMembers,
        },
        {
          headers: { PartnerId: this._partnerId },
          paramsSerializer: {
            indexes: null,
          },
        }
      );
    };

    const removeMembers = async () => {
      if (!oldMembers.length) return;
      await this.authApi.admin.adminGroupDeleteMember(
        data.id!,
        {
          userIds: oldMembers.map((el) => el.id),
        },
        {
          headers: { PartnerId: this._partnerId },
          paramsSerializer: {
            indexes: null,
          },
        }
      );
    };

    try {
      runInAction(() => {
        this.loading = true;
      });
      await Promise.all([updateGroup(), addMembers(), removeMembers()]);
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async init(props: { partnerId: number; groupId?: number }) {
    const { partnerId, groupId } = props;
    runInAction(() => {
      this._partnerId = partnerId;
      this._groupId = groupId || 0;
    });
  }
}
