/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import styles from './index.module.css';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import Button from 'components/core/button';
import { GetProp, Upload, UploadProps } from 'antd';
import { UserDetailsPageStore } from 'modules/user-details/store/UserDetailsPageStore';
import { useUploadStore } from '../provider/UploadStoreProvider';
import { IdDocSubType, IdDocType } from 'api/auth';
import { UploadStore } from '../store/UploadStore';
import { Controller, useForm } from 'react-hook-form';
import FormSelect from 'components/form/form-select';
import { capitalizeString } from 'utils/strings';
import { UploadFile } from 'antd/lib';

type Props = {
  userStore: UserDetailsPageStore;
  uploadStore: UploadStore;
  verificationId: string;
  onSuccess: () => void;
};

type FormValues = {
  file: UploadFile;
  type: IdDocType;
  subType: IdDocSubType;
};
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const UploadDocument = observer(
  ({ userStore, uploadStore, verificationId, onSuccess }: Props) => {
    const intl = useIntl();
    const { layoutStore } = useBaseStores();

    const { handleSubmit, control, formState } = useForm<FormValues>({
      defaultValues: {
        type: IdDocType.COMPANY_DOC,
        subType: IdDocSubType.OTHER,
      },
    });

    const onSubmit = async (values: FormValues) => {
      if (!verificationId) return;
      const result = await uploadStore.upload(
        verificationId,
        values.file.originFileObj as FileType,
        values.type,
        values.subType
      );

      if (result) {
        onSuccess();
        layoutStore.toggleModal(false);
      }
    };

    const typeOptions = Object.keys(IdDocType).map((item) => ({
      id: item,
      label: capitalizeString(item.replaceAll('_', ' ')),
      value: item,
    }));
    const subTypeOptions = Object.keys(IdDocSubType).map((item) => ({
      id: item,
      label: capitalizeString(item.replaceAll('_', ' ')),
      value: item,
    }));

    return (
      <div className={styles.wrapper}>
        <Controller
          control={control}
          name='type'
          render={({ field: { value, onChange } }) => (
            <FormSelect
              required
              label={intl.formatMessage({
                id: 'page.user_details.kyb.save',
                defaultMessage: 'Document Type',
              })}
              options={typeOptions}
              onChange={(value) => onChange(value?.value as string)}
              value={typeOptions.find((item) => item.value === value)}
            />
          )}
        />
        <Controller
          control={control}
          name='subType'
          render={({ field: { value, onChange } }) => (
            <FormSelect
              required
              label={intl.formatMessage({
                id: 'page.user_details.kyb.document_subtype',
                defaultMessage: 'Document Subtype',
              })}
              options={subTypeOptions}
              onChange={(value) => onChange(value?.value as string)}
              value={subTypeOptions.find((item) => item.value === value)}
            />
          )}
        />
        <Controller
          control={control}
          rules={{ required: true }}
          name='file'
          render={({ field: { value, onChange } }) => {
            return (
              <Upload
                className={styles['upload_wrapper']}
                fileList={value ? [{ ...value, status: 'done' }] : undefined}
                customRequest={(data) => {}}
                onChange={({ file, fileList }) => {
                  if (!fileList.length) {
                    onChange(undefined);
                  } else {
                    onChange(file);
                  }
                }}
              >
                <div className={styles.upload}>Drag and drop</div>
              </Upload>
            );
          }}
        />

        <Button
          className={styles.btn}
          variant='primary'
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid}
        >
          {intl.formatMessage({
            id: 'page.user_details.kyb.save',
            defaultMessage: 'Save',
          })}
        </Button>
      </div>
    );
  }
);

export const useUploadDocumentKyb = () => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();
  const userDetailsPageStore = useUserDetailsPageStore();
  const uploadStore = useUploadStore();

  const showUploadModal = useCallback(
    (verificationId: string, onSuccess: () => void) => {
      layoutStore.toggleModal(
        true,
        intl.formatMessage({
          id: 'page.user_details.modal.upload_document',
          defaultMessage: 'Upload Document',
        }),
        <UploadDocument
          userStore={userDetailsPageStore}
          uploadStore={uploadStore}
          verificationId={verificationId}
          onSuccess={onSuccess}
        />
      );
    },
    [intl, layoutStore, userDetailsPageStore, uploadStore]
  );

  return {
    showUploadModal,
  };
};
