/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { observer } from 'mobx-react-lite';
import {
  ProvideExchangePairsStore,
  useExchangePairsPageStore,
} from './provider/ExchangePairsProvider';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { Filter } from './filter';
import ExchangePairTable from './table/exchange-pairs-table';

const ExchangePairPageWithProvider = observer(() => {
  const intl = useIntl();
  const store = useExchangePairsPageStore();

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.invoices.exchange_configuration',
        defaultMessage: 'Exchange pairs configuration',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  return (
    <div className={styles.main}>
      <Filter />
      <ExchangePairTable collection={store.pairs.data} />
    </div>
  );
});

const ExchangePairPage = () => {
  return (
    <ProvideExchangePairsStore>
      <ExchangePairPageWithProvider />
    </ProvideExchangePairsStore>
  );
};

export default ExchangePairPage;
