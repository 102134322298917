/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { ExchangePairsStore } from '../store/ExchangePairsStore';

const storeContext = createContext<ExchangePairsStore>(
  {} as ExchangePairsStore
);

type Props = {
  children?: React.ReactNode;
};

export const ProvideExchangePairsStore = observer(({ children }: Props) => {
  const { currencyApi, partnerStore, authApi } = useBaseStores();

  const store = useLocalObservable(
    () => new ExchangePairsStore(authApi, currencyApi)
  );

  useEffect(() => {
    if (!partnerStore.currentPartner?.id) {
      return;
    }

    store.init(partnerStore.currentPartner.id);
  }, [partnerStore.currentPartner?.id, store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useExchangePairsPageStore = () => {
  return useContext(storeContext);
};
