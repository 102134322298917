/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';
import { COLORS } from 'styles/charts/colors';

import {
  PieChart as PieChartReChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

type DataItem = {
  name?: string;
  value?: number;
  [key: string]: any;
};

export type ChartVariants = 'line' | 'area';

type Props = {
  placeholder?: string;
  data: DataItem[] | null | undefined;
  onChange?: (value: string) => void;
  variant?: ChartVariants;
  currencySign?: string;
  minimalView?: boolean;
};

const PieChart = (props: Props) => {
  const defaultCurrencySign = props.currencySign || '';

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
      className={classNames(styles.chart, classNames)}
    >
      <PieChartReChart width={800} height={400}>
        <Pie
          data={props.data || []}
          innerRadius={70}
          outerRadius={90}
          fill='#8884d8'
          paddingAngle={1}
          nameKey='name'
          dataKey='value'
          label={!props.minimalView}
        >
          {props.data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip
          formatter={(value, name) => [defaultCurrencySign + value, name]}
        />
      </PieChartReChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
