/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import DashboardUserCounters from './user-counters';
import DashboardCurrencies from './currencies';
import DashboardSection from './section';
import TotalBalancesTable from 'components/tables/total-balances-table';
import PieChart from 'components/charts/pie-chart';

import { observer } from 'mobx-react-lite';
import {
  ProvideDashboardPageStore,
  useDashboardPageStore,
} from './provider/DashboardPageStoreProvider';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { EURO_SIGN } from 'assets/config';
import { bigNumberToStr } from 'utils/bignumber';

const DashboardPageWithProvider = observer(() => {
  const intl = useIntl();
  const store = useDashboardPageStore();

  const { totalBalances, totalAllBalances } = store;

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.dashboard.title',
        defaultMessage: 'Dashboard',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  if (!store.initialized) {
    return null;
  }

  console.log(totalBalances)

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <DashboardSection
          additionalInfo={intl.formatMessage({
            id: 'page.dashboard.globalBalance',
            defaultMessage: 'Global balance',
          })}
        >
          <h3 className={'-mt-4'}>
            {EURO_SIGN}
            {bigNumberToStr(totalAllBalances, 2)}
          </h3>
        </DashboardSection>

        <DashboardSection
          title={intl.formatMessage({
            id: 'page.dashboard.currencies',
            defaultMessage: 'Currencies',
          })}
        >
          {totalBalances && totalBalances.length > 0 && (
            <div className={styles.chart}>
              <PieChart
                data={totalBalances}
                minimalView={true}
                currencySign={EURO_SIGN}
              />
            </div>
          )}
          <TotalBalancesTable collection={totalBalances} />
        </DashboardSection>
      </div>
      <DashboardUserCounters />
      <DashboardCurrencies />
    </div>
  );
});

const DashboardPage = () => {
  return (
    <ProvideDashboardPageStore>
      <DashboardPageWithProvider />
    </ProvideDashboardPageStore>
  );
};

export default DashboardPage;
