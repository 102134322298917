/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable } from 'mobx';
import { FilterModel } from 'models/filter/FilterModel';
import { AdminListRequestModel } from 'models/request/AdminListRequestModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { readStrFromQueryParams } from 'utils/queryParams';
import { DiscountCodesModel } from 'models/DiscountCodesModel';
import { AdminDiscountCodesModel } from 'models/request/AdminDiscountCodesModel';

export class PromoCodesPageStore {
  constructor(private authApi: AuthApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _promoCodes = new CollectionWithPages<
    DiscountCodesModel,
    AdminDiscountCodesModel
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.authApi.adminDiscounts.adminGetDiscountCodes({
        ...params,
      });

      const items = result.data.data.map((x) => new DiscountCodesModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminListRequestModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.search
      );

      if (searchStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.search,
          value: {
            search: searchStr,
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  get promoCodes() {
    return this._promoCodes;
  }

  async init(props: {
    filter: FilterModel<AdminListRequestModel>[];
    page: number;
  }) {
    const { filter, page } = props;

    await this._promoCodes.fetchData(filter, page);
  }
}
