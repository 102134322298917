/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const getAuthSecurityWorker = (
  accessToken: string | undefined | null
) =>
  accessToken
    ? {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    : {};
