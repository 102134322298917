/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { TransactionsPageStore } from '../store/TxPageStore';

const storeContext = createContext<TransactionsPageStore>(
  {} as TransactionsPageStore
);

type Props = {
  children?: React.ReactNode;
};

export const ProvideTransactionsPageStore = observer(({ children }: Props) => {
  const { walletApi, partnerStore } = useBaseStores();

  const store = useLocalObservable(() => new TransactionsPageStore(walletApi));

  const { page, filter } = useParseCollectionQueryParams({
    collection: store.transactions,
  });

  useEffect(() => {
    if (!partnerStore.currentPartner) return;

    store.init({
      partnerId: partnerStore.currentPartner.id ?? 0,
      filter: filter,
      page: page,
    });
  }, [filter, page, store, partnerStore.currentPartner]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useTransactionsPageStore = () => {
  return useContext(storeContext);
};
