/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { LoansApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable } from 'mobx';
import { LoanOfferModel } from 'models/LoanOfferModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';

export class LoanOffersListStore {
  constructor(private loansApi: LoansApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _loanOffers = new CollectionWithPages<
    LoanOfferModel,
    { page?: number; size?: number }
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.loansApi.admin.adminGetLoanOffers(params);

      const items = result.data.data.map((x) => new LoanOfferModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    itemsPerPage: 12,
  });

  get loanOffers() {
    return this._loanOffers;
  }

  async init() {
    this._loanOffers.refresh();
  }

  async createLoanOffer(offer: LoanOfferModel) {
    await this.loansApi.admin.adminCreateLoanOffer(LoanOfferModel.toDto(offer));

    await this._loanOffers.refresh();
  }
}
