/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React, { useEffect, useMemo } from 'react';
import styles from './index.module.css';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import Button from 'components/core/button';
import TextAreaField from 'components/core/text-area-field';
import { Note } from './note';
import { useUserNotesPageStore } from 'modules/user-details/provider/NotesStoreProvider';
import { observer } from 'mobx-react-lite';
import { useIntlValidation } from 'hooks/intl/validation';
import joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

type CreateNote = {
  note: string;
};

export const UserDetailsNotesTab = observer(() => {
  const intl = useIntl();

  const userDetailsPageStore = useUserNotesPageStore();

  const { validationOptions } = useIntlValidation();

  const formScheme = useMemo(
    () =>
      joi.object<CreateNote>({
        note: joi.string().empty('').required(),
      }),
    []
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { note: '' },
    resolver: joiResolver(formScheme, validationOptions),
  });

  useEffect(() => {
    userDetailsPageStore.notes.fetchNotes();
  }, [userDetailsPageStore]);

  const handleAddNote = () => {
    handleSubmit(async (data) => {
      userDetailsPageStore.notes.add(data);
      reset();
    })();
  };

  return (
    <div className={styles.card}>
      <div className={styles.col}>
        <h1>
          {intl.formatMessage({
            id: 'page.user_details.notes.title',
            defaultMessage: 'Notes',
          })}
        </h1>

        <div className={styles.list}>
          {!!userDetailsPageStore.notes.notes.length ? (
            userDetailsPageStore.notes.notes.map((note) => (
              <Note
                key={note.id}
                note={note}
                onDelete={userDetailsPageStore.notes.delete}
                onUpdate={(value) =>
                  userDetailsPageStore.notes.update(note.id, { note: value })
                }
              />
            ))
          ) : (
            <span>
              {intl.formatMessage({
                id: 'page.user_details.notes.empty',
                defaultMessage: 'Empty notes',
              })}
            </span>
          )}
        </div>
      </div>

      <div className={styles.col}>
        <h2>
          {intl.formatMessage({
            id: 'page.user_details.notes.new',
            defaultMessage: 'New notes',
          })}
        </h2>
        <div className={styles.col}>
          <Controller
            control={control}
            name='note'
            render={({ field, fieldState: { error } }) => (
              <TextAreaField
                {...field}
                error={error?.message}
                variant='secondary'
                placeholder={intl.formatMessage({
                  id: 'page.user_details.notes.addPlaceholder',
                  defaultMessage: 'Write a note about the user here',
                })}
              />
            )}
          />
          <Button
            variant='primary'
            className={styles.button}
            onClick={handleAddNote}
            disabled={userDetailsPageStore.notes.loading}
          >
            {intl.formatMessage({
              id: 'page.user_details.notes.add',
              defaultMessage: 'Add a note',
            })}
          </Button>
        </div>
      </div>
    </div>
  );
});
