/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import { UserSessionModel } from 'models/UserSessionModel';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { formattedDate } from 'utils/dates';
import { cutId } from 'utils/strings';
import { TableProps } from 'antd';
import Table from '../core/table';

type Props = {
  collection: CollectionWithPages<UserSessionModel, {}>;
};

const SessionsTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  const columns: TableProps<UserSessionModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.sessions.col.ip',
        defaultMessage: 'IP',
      }),
      dataIndex: 'ip',
      key: 'ip',
      render: (ip: string) => <span>{ip}</span>,
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.sessions.col.jti',
        defaultMessage: 'JTI',
      }),
      dataIndex: 'jti',
      key: 'jti',
      render: (jti: string) => <span>{cutId(jti)}</span>,
      width: 60,
    },
    {
      title: intl.formatMessage({
        id: 'table.sessions.col.creation',
        defaultMessage: 'Creation date',
      }),
      dataIndex: 'createDate',
      key: 'createDate',
      render: (createDate: string) => <span>{formattedDate(createDate)}</span>,
      width: 40,
    },
    {
      title: intl.formatMessage({
        id: 'table.sessions.col.expiration',
        defaultMessage: 'Expiration date',
      }),
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (expireDate: string) => <span>{formattedDate(expireDate)}</span>,
      width: 40,
    },
  ];

  return (
    <Table<UserSessionModel, {}> collection={collection} columns={columns} />
  );
});

export default SessionsTable;
