/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';
import { ChartColors } from 'styles/charts/colors';

import {
  LineChart as LineChartReChart,
  AreaChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

type DataItem = {
  name: string;
  value: number;
};

export type ChartVariants = 'line' | 'area';
export type ColorVariants = 'green' | 'red' | 'gray' | undefined;

type Props = {
  placeholder?: string;
  data: DataItem[] | null;
  onChange?: (value: string) => void;
  variant?: ChartVariants;
  color?: ColorVariants;
  minimalView?: boolean;
};

const LineChart = (props: Props) => {
  const getChartColor = (color: ColorVariants) => {
    switch (color) {
      case 'green':
        return ChartColors.Green;
      case 'red':
        return ChartColors.Red;
      case 'gray':
        return ChartColors.Gray;
      default:
        return ChartColors.Green;
    }
  };

  const chartColor = getChartColor(props.color);

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
      className={classNames(styles.chart, classNames)}
    >
      {props.variant === 'line' ? (
        <LineChartReChart
          data={props.data || undefined}
          margin={{
            top: 5,
            right: 15,
            left: -20,
            bottom: 5,
          }}
        >
          {!props.minimalView && (
            <>
              <CartesianGrid strokeDasharray='1 1' y={200} />
              <XAxis dataKey='name' tickLine={false} axisLine={false} />
              <YAxis
                tickLine={false}
                axisLine={false}
                tickCount={5}
                tick={true}
              />
            </>
          )}

          <Tooltip />
          <Line
            type='natural'
            dataKey='value'
            strokeWidth={2}
            stroke={chartColor}
            activeDot={{ r: 7 }}
          />
        </LineChartReChart>
      ) : (
        <AreaChart
          data={props.data || undefined}
          margin={{
            top: 5,
            right: 15,
            left: -20,
            bottom: 5,
          }}
        >
          {!props.minimalView && (
            <>
              <CartesianGrid strokeDasharray='1 1' y={200} />
              <XAxis dataKey='name' tickLine={false} axisLine={false} />
              <YAxis
                tickLine={false}
                axisLine={false}
                tickCount={5}
                tick={true}
              />
            </>
          )}
          <Tooltip />
          <Area
            type='natural'
            dataKey='value'
            strokeWidth={2}
            stroke={chartColor}
            fill={chartColor}
            fillOpacity={0.12}
            activeDot={{ r: 7 }}
          />
        </AreaChart>
      )}
    </ResponsiveContainer>
  );
};

export default LineChart;
