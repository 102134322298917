/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';
import { LoanPayment, LoanPaymentStatus, LoanPaymentType } from 'api/loans';
import { parseBigNumberZero } from '../utils/bignumber';

export class CollateralTransactionsModel {
  constructor(dto: LoanPayment) {
    this.id = dto.id ?? '';
    this.type = dto.type;
    this.interestAmount = dto.interestAmount;
    this.interestCurrency = dto.interestCurrency;
    this.principalAmount = parseBigNumberZero(dto.principalAmount);
    this.principalCurrency = dto.principalCurrency;
    this.feeAmount = dto.feeAmount;
    this.feeCurrency = dto.feeCurrency;
    this.status = dto.status;
    this.plannedPaymentDate = dto.plannedPaymentDate;
    this.paymentDate = dto.paymentDate;
    this.paymentId = dto.paymentId;
  }

  id: string;
  type?: LoanPaymentType;
  interestAmount?: string;
  interestCurrency?: string;
  principalAmount?: BigNumber;
  principalCurrency?: string;
  feeAmount?: string;
  feeCurrency?: string;
  status: LoanPaymentStatus;
  plannedPaymentDate?: string;
  paymentDate?: string;
  paymentId?: string;
}
