/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { notification } from 'antd';
import { ReactComponent as SuccessIconSvg } from 'assets/images/icons/success.svg';
import { ReactComponent as ErrorIconSvg } from 'assets/images/icons/error.svg';
import { ReactComponent as InfoIconSvg } from 'assets/images/icons/info.svg';
import { ReactComponent as CloseIconSvg } from 'assets/images/icons/close.svg';

type NotificationType = 'info' | 'error' | 'success' | 'warning';

export type NotificationProps = {
  title?: string;
  message?: string;
  closable?: boolean;
  type?: NotificationType;
  btn?: React.ReactNode;
  duration?: number | null;
};

export class NotificationStore {
  constructor() {
    notification.config({
      placement: 'topRight',
      top: 30,
      duration: 4,
      maxCount: 5,
    });
  }

  private defaultCloseDurationSec = 5;

  notify = ({
    title,
    message,
    closable,
    type,
    btn,
    duration,
  }: NotificationProps) => {
    type = type ?? 'info';

    notification.open({
      message: title,
      description: message,
      className: type,
      icon: this.getNotificationIcon(type),
      closeIcon: <CloseIconSvg />,
      btn: btn,
      closable: closable ?? true,
      duration: duration ?? this.defaultCloseDurationSec,
    });
  };

  private getNotificationIcon = (type: NotificationType) => {
    switch (type) {
      case 'warning':
      case 'error':
        return <ErrorIconSvg />;
      case 'success':
        return <SuccessIconSvg />;
      default:
        return <InfoIconSvg />;
    }
  };

  handleApiError = (error: unknown) => {
    if (
      error instanceof Error &&
      'response' in error &&
      (error as any).response
    ) {
      const axiosError = error as { response: { data: { message: string } } };
      this.notify({
        title: axiosError.response.data.message ?? 'Error',
        type: 'error',
      });
    }
  };
}
