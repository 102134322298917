/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import classNames from 'classnames';
import styles from './index.module.css';
import { DatePicker } from 'antd';
import { useCallback } from 'react';
import { ReactComponent as CalendarIconSvg } from 'assets/images/icons/calendar.svg';
import dayjs, { Dayjs } from 'dayjs';
import { dateFormatDefault, DatePickerSize } from '../datepicker';

const { RangePicker } = DatePicker;

type Props = {
  minDate?: Date | undefined | null;
  maxDate?: Date | undefined | null;
  placeholder?: [string, string];
  dateFormat?: string;
  allowEmpty?: [boolean, boolean];
  disabled?: boolean;
  value?: [Date | undefined, Date | undefined] | null;
  onChange?: (startDate: Date | null, endDate: Date | null) => void;
  size?: DatePickerSize;
};

const DateTimePickerRange = (props: Props) => {
  const handleDateChange = useCallback(
    (dates: [Dayjs | null, Dayjs | null] | null) => {
      if (dates) {
        const [startDate, endDate] = dates;
        props.onChange?.(
          startDate ? startDate.toDate() : null,
          endDate ? endDate.toDate() : null
        );
      } else {
        props.onChange?.(null, null);
      }
    },
    [props]
  );

  return (
    <div className={classNames(styles.picker_container)}>
      <RangePicker
        value={
          props.value && [
            props.value[0] !== undefined ? dayjs(props.value[0]) : undefined,
            props.value[1] !== undefined ? dayjs(props.value[1]) : undefined,
          ]
        }
        onChange={handleDateChange}
        format={props.dateFormat || dateFormatDefault}
        placeholder={props.placeholder}
        suffixIcon={<CalendarIconSvg />}
        size={props.size}
        allowClear={true}
        allowEmpty={props.allowEmpty || [false, false]}
        minDate={props.minDate ? dayjs(props.minDate) : undefined}
        maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
      />
    </div>
  );
};

export default DateTimePickerRange;
