/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import InvoicesFilter from './invoices-filter';

import { observer } from 'mobx-react-lite';
import {
  ProvideInvoicesPageStore,
  useInvoicesPageStore,
} from './provider/StoreProvider';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import InvoicesTable from 'components/tables/invoices-table';
import { useSidebarInvoiceInfo } from 'components/sidebars/sidebar-invoice-info';

const InvoicesPageWithProvider = observer(() => {
  const intl = useIntl();
  const invoicesPageStore = useInvoicesPageStore();

  const totalText = useMemo(
    () =>
      invoicesPageStore.invoices.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.invoices.total',
              defaultMessage: 'Total {invoices}',
            },
            {
              invoices: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.invoices.total.count',
                      defaultMessage: '{count} invoices',
                    },
                    { count: invoicesPageStore.invoices.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, invoicesPageStore.invoices.totalItems]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.invoices.title',
        defaultMessage: 'Invoices',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalText]);

  const { showInvoiceInfo } = useSidebarInvoiceInfo();

  return (
    <div className={styles.main}>
      <InvoicesFilter collectData={invoicesPageStore.invoices} />
      <InvoicesTable
        collection={invoicesPageStore.invoices}
        onRowClick={showInvoiceInfo}
      />
    </div>
  );
});

const UserInvoicesPage = () => {
  return (
    <ProvideInvoicesPageStore>
      <InvoicesPageWithProvider />
    </ProvideInvoicesPageStore>
  );
};

export default UserInvoicesPage;
