/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import SearchField from 'components/core/search-field';
import FilterButton from 'components/elements/filter-button';

import { URL_SEARCH_PARAMS } from 'assets/config';
import { observer } from 'mobx-react-lite';
import { UserModel } from 'models/UserModel';
import { AdminListRequestModel } from 'models/request/AdminListRequestModel';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { useSidebarUsersFilter } from 'components/sidebars/sidebar-users-filter';
import { NavigationTab } from '../navigation-tabs';

type Props = {
  users: CollectionWithPages<UserModel, AdminListRequestModel>;
};

const UsersFilter = observer(({ users }: Props) => {
  const intl = useIntl();

  const { showUserFiltersSidebar } = useSidebarUsersFilter({
    collection: users,
  });

  const { setFilterQueryParams } = useSetQueryParams();

  const onSearchChanged = useCallback(
    (val: string) => {
      setFilterQueryParams([
        { id: URL_SEARCH_PARAMS.page, value: '1' },
        { id: URL_SEARCH_PARAMS.search, value: val },
      ]);
    },
    [setFilterQueryParams]
  );

  return (
    <div className={styles.main}>
      <NavigationTab />
      <div className={styles.filters}>
        <SearchField
          className={styles.search_input}
          placeholder={intl.formatMessage({
            id: 'page.users.search.placeholder',
            defaultMessage: 'Search by users',
          })}
          value={users.filter.search ?? ''}
          onChange={onSearchChanged}
        />

        <FilterButton
          activeCount={users.totalFiltersCount}
          onClick={showUserFiltersSidebar}
        />
      </div>
    </div>
  );
});

export default UsersFilter;
