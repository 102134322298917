/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi, CurrencyApi } from 'api';
import { AdminExchangePair, ExchangePairConfigurationDto } from 'api/currency';
import { makeAutoObservable, runInAction } from 'mobx';
import { AdminGroupModel } from 'models/GroupModel';

export class ExchangePairsStore {
  constructor(
    private authApi: AuthApi,
    private currencyApi: CurrencyApi
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public groups: AdminGroupModel[] = [];
  public loading = false;

  private _pairs = {
    data: null as ExchangePairConfigurationDto | null,
    loading: false,
    fetch: async (query: { partner: string; group: string }) => {
      runInAction(() => {
        this._pairs.loading = true;
      });

      try {
        const result =
          await this.currencyApi.admin.adminGetAllExchangePairConfigs(query);
        runInAction(() => {
          this._pairs.loading = false;
          this._pairs.data = result.data;
        });
      } catch (error) {
        runInAction(() => {
          this._pairs.loading = false;
        });
      }
    },
  };

  get pairs() {
    return this._pairs;
  }

  async init(partnerId: number) {
    const result = await this.authApi.admin.adminListGroups(
      {
        size: 10000,
      },
      {
        headers: { PartnerId: partnerId },
      }
    );

    const items = result.data.data.map((x) => new AdminGroupModel(x));

    runInAction(() => {
      this.groups = items;
    });
  }

  async updateExchangePair(data: AdminExchangePair) {
    this.loading = true;
    try {
      const result =
        await this.currencyApi.admin.adminUpdateExchangePairSettings(data);
      runInAction(() => {
        this.loading = false;
        this._pairs.data = result.data;

        this.pairs.fetch({ partner: data.partner, group: data.group });
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
