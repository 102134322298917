/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { LoanStatus } from 'api/loans';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useIntlLoanStatuses = () => {
  const intl = useIntl();

  const loanStatusToIntlText = useCallback(
    (status: LoanStatus) => {
      switch (status) {
        case LoanStatus.APPROVED:
          return intl.formatMessage({
            id: 'common.loan_status.approved',
            defaultMessage: 'Approved',
          });

        case LoanStatus.CLOSED:
          return intl.formatMessage({
            id: 'common.loan_status.closed',
            defaultMessage: 'Closed',
          });

        case LoanStatus.FAILED:
          return intl.formatMessage({
            id: 'common.loan_status.failed',
            defaultMessage: 'Failed',
          });

        case LoanStatus.INIT:
          return intl.formatMessage({
            id: 'common.loan_status.init',
            defaultMessage: 'Init',
          });

        case LoanStatus.LATE:
          return intl.formatMessage({
            id: 'common.loan_status.late',
            defaultMessage: 'Late',
          });

        case LoanStatus.LENT:
          return intl.formatMessage({
            id: 'common.loan_status.lent',
            defaultMessage: 'Lent',
          });

        case LoanStatus.LIQUIDATED:
          return intl.formatMessage({
            id: 'common.loan_status.liquidated',
            defaultMessage: 'Liquidated',
          });

        case LoanStatus.LIQUIDATING:
          return intl.formatMessage({
            id: 'common.loan_status.liquidating',
            defaultMessage: 'Liquidating',
          });

        case LoanStatus.MARGIN_CALL:
          return intl.formatMessage({
            id: 'common.loan_status.margin_call',
            defaultMessage: 'Margin call',
          });

        case LoanStatus.PAID:
          return intl.formatMessage({
            id: 'common.loan_status.paid',
            defaultMessage: 'Paid',
          });

        case LoanStatus.REJECTED:
          return intl.formatMessage({
            id: 'common.loan_status.rejected',
            defaultMessage: 'Rejected',
          });

        case LoanStatus.UNDEFINED:
          return intl.formatMessage({
            id: 'common.loan_status.undefined',
            defaultMessage: 'Undefined',
          });
      }
    },
    [intl]
  );

  return {
    loanStatusToIntlText,
  };
};
