/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import SubscriptionsFilter from './subscriptions-filter';

import { observer } from 'mobx-react-lite';
import {
  ProvideSubscriptionsPageStore,
  useSubscriptionsPageStore,
} from './provider/StoreProvider';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import SubscriptionsTable from 'components/tables/subscriptions-table';

const SubscriptionsPageWithProvider = observer(() => {
  const intl = useIntl();
  const subscriptionsPageStore = useSubscriptionsPageStore();

  const totalText = useMemo(
    () =>
      subscriptionsPageStore.subscriptions.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.subscriptions.total',
              defaultMessage: 'Total {subscriptions}',
            },
            {
              subscriptions: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.subscriptions.total.count',
                      defaultMessage: '{count} subscriptions',
                    },
                    { count: subscriptionsPageStore.subscriptions.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, subscriptionsPageStore.subscriptions.totalItems]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.subscriptions.title',
        defaultMessage: 'Subscriptions',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalText]);

  return (
    <div className={styles.main}>
      <SubscriptionsFilter collectData={subscriptionsPageStore.subscriptions} />
      <SubscriptionsTable
        collection={subscriptionsPageStore.subscriptions}
        onCancel={(subscription) =>
          subscriptionsPageStore.cancelSubscription(subscription.id)
        }
        onDelete={(subscription) =>
          subscriptionsPageStore.deleteSubscription(subscription.id)
        }
      />
    </div>
  );
});

const UserSubscriptionsPage = () => {
  return (
    <ProvideSubscriptionsPageStore>
      <SubscriptionsPageWithProvider />
    </ProvideSubscriptionsPageStore>
  );
};

export default UserSubscriptionsPage;
