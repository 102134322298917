/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import PromoCodesFilter from './promo-codes-filter';

import { observer } from 'mobx-react-lite';
import {
  ProvidePromoCodesPageStore,
  usePromoCodesPageStore,
} from './provider/StoreProvider';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import PromoCodesTable from 'components/tables/promo-codes-table';

const PromoCodesPageWithProvider = observer(() => {
  const intl = useIntl();
  const PromoCodesPageStore = usePromoCodesPageStore();

  const totalText = useMemo(
    () =>
      PromoCodesPageStore.promoCodes.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.promoCodes.total',
              defaultMessage: 'Total {promoCodes}',
            },
            {
              promoCodes: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.promoCodes.total.count',
                      defaultMessage: '{count} promo codes',
                    },
                    { count: PromoCodesPageStore.promoCodes.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, PromoCodesPageStore.promoCodes.totalItems]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.promoCodes.title',
        defaultMessage: 'Promo Codes',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalText]);

  return (
    <div className={styles.main}>
      <PromoCodesFilter collectData={PromoCodesPageStore.promoCodes} />
      <PromoCodesTable collection={PromoCodesPageStore.promoCodes} />
    </div>
  );
});

const PromoCodesPage = () => {
  return (
    <ProvidePromoCodesPageStore>
      <PromoCodesPageWithProvider />
    </ProvidePromoCodesPageStore>
  );
};

export default PromoCodesPage;
