/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import cn from 'classnames';
import styles from './index.module.css';

export type FormLabelType = 'base' | 'xs-secondary';

type Props = {
  text: string;
  required?: boolean;
  type?: FormLabelType;
};

const FormLabel = ({ text, required, type }: Props) => {
  return (
    <div
      className={cn(styles.main, {
        [styles.xs_secondary]: type === 'xs-secondary',
      })}
    >
      {required !== true ? text : `${text} *`}
    </div>
  );
};

export default FormLabel;
