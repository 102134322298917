/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { observer } from 'mobx-react-lite';

import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import GroupsTable from 'components/tables/groups-table';
import { useGroupsPageStore } from '../provider/GroupsStoreProvider';

export const GroupsTab = observer(() => {
  const intl = useIntl();
  const groupsPageStore = useGroupsPageStore();

  const totalText = useMemo(
    () =>
      groupsPageStore.groups.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.groups.total',
              defaultMessage: 'Total {groups}',
            },
            {
              groups: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.groups.total.count',
                      defaultMessage: '{count} groups',
                    },
                    { count: groupsPageStore.groups.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, groupsPageStore.groups.totalItems]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.groups.title',
        defaultMessage: 'Groups',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalText]);

  return (
    <GroupsTable
      collection={groupsPageStore.groups}
      refresh={() => groupsPageStore.groups.refresh()}
    />
  );
});
