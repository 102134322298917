/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import FormLabel, { FormLabelType } from '../form-label';
import Select, { SelectProps } from 'components/core/select';

type Props<T> = SelectProps<T> & {
  label?: string;
  labelType?: FormLabelType;
  labelSecondary?: boolean;
  required?: boolean;
};

const FormSelect = <T extends object | string | number | boolean>(
  props: Props<T>
) => {
  return (
    <div>
      {!!props.label && (
        <FormLabel
          text={props.label}
          type={props.labelType}
          required={props.required}
        />
      )}

      <Select {...props} />
    </div>
  );
};

export default FormSelect;
