import { ApiError } from 'api/auth';

export type ErrorMessage = {
  key?: string;
  description: string;
};

export const UnknownError: ErrorMessage = {
  description: 'Unknown error',
};

export const parseApiError = (e: any): ErrorMessage[] => {
  if (e?.response?.data?.errors && e.response.data.errors.length > 0) {
    return e.response.data.errors as ApiError[];
  }

  if (e?.response?.data?.message) {
    return [{ description: e.response.data.message }];
  }

  if (e?.message) {
    return [{ description: e.message }];
  }

  return [UnknownError];
};
