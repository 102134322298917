/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { UserVerificationStatus } from 'models/UserModel';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useIntlUserVerificationStatuses = () => {
  const intl = useIntl();

  const verificationStatusToIntlText = useCallback(
    (status: UserVerificationStatus) => {
      switch (status) {
        case UserVerificationStatus.GREEN:
          return intl.formatMessage({
            id: 'common.verification_status.approved',
            defaultMessage: 'Approved',
          });

        case UserVerificationStatus.INIT:
          return intl.formatMessage({
            id: 'common.verification_status.init',
            defaultMessage: 'In Progress',
          });

        case UserVerificationStatus.PENDING:
          return intl.formatMessage({
            id: 'common.verification_status.pending',
            defaultMessage: 'Pending',
          });

        case UserVerificationStatus.PENDINGL2F:
          return intl.formatMessage({
            id: 'common.verification_status.pendingl2f',
            defaultMessage: 'Pending L2F',
          });

        case UserVerificationStatus.REJECT:
          return intl.formatMessage({
            id: 'common.verification_status.reject',
            defaultMessage: 'Declined',
          });

        case UserVerificationStatus.RETRY:
          return intl.formatMessage({
            id: 'common.verification_status.retry',
            defaultMessage: 'Retry',
          });

        case UserVerificationStatus.UNDEFINED:
        default:
          return intl.formatMessage({
            id: 'common.verification_status.undefined',
            defaultMessage: 'Not started',
          });
      }
    },
    [intl]
  );

  return {
    verificationStatusToIntlText,
  };
};
