/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import classNames from 'classnames';

import { Pagination, Table as TableAnt } from 'antd';
import { observer } from 'mobx-react-lite';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { ColumnsType } from 'antd/lib/table';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { useCallback } from 'react';

interface TableProps<T extends { id: string }, F extends object> {
  columns: ColumnsType<T>;
  collection: CollectionWithPages<T, F>;
  onRowClick?: (item: T) => void;
  className?: string;
}

const Table = observer(
  <T extends { id: string }, F extends object>({
    columns,
    collection,
    onRowClick,
    className,
  }: TableProps<T, F>) => {
    const { setFilterQueryParams } = useSetQueryParams();

    const changePageQueryParam = useCallback(
      (page: number) => {
        if (!!collection.getFilterFromQueryParams) {
          setFilterQueryParams([
            { id: collection.pageQueryParamId, value: page.toString() },
          ]);
          return;
        }

        collection.fetchData([
          { id: collection.pageQueryParamId, value: page.toString() as any },
        ]);
      },
      [collection, setFilterQueryParams]
    );

    return (
      <div className={classNames(className, styles.main)}>
        <div
          className={classNames(
            styles.table_container,
            onRowClick && 'table-row-clickable'
          )}
        >
          <TableAnt<T>
            dataSource={collection.items ?? []}
            columns={columns}
            loading={collection.isLoading}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record) => ({
              onClick: () => onRowClick?.(record),
            })}
            scroll={{ x: true }}
          />
        </div>

        {collection.pagination.maxPage > 1 && (
          <Pagination
            current={collection.pagination.currentPage}
            pageSize={collection.pagination.itemsPerPage}
            total={collection.totalItems}
            onChange={changePageQueryParam}
            showQuickJumper={false}
            showSizeChanger={false}
          />
        )}
      </div>
    );
  }
);

export default Table;
