/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { CompanyInfo } from 'api/auth';

export { VerificationStatus as UserVerificationStatus } from 'api/auth';

export class AdminCompanyModel {
  constructor(dto: CompanyInfo) {
    this.id = dto.id?.toString() ?? '';
    this.companyName = dto.companyName;
    this.controlScheme = dto.controlScheme;
    this.country = dto.country;
    this.email = dto.email;
    this.incorporatedOn = dto.incorporatedOn;
    this.legalAddress = dto.legalAddress;
    this.phone = dto.phone;
    this.postalAddress = dto.postalAddress;
    this.registrationLocation = dto.registrationLocation;
    this.registrationNumber = dto.registrationNumber;
    this.taxId = dto.taxId;
    this.type = dto.type;
    this.website = dto.website;
    this.json = dto.meta;
  }

  id: string;
  companyName: string;
  controlScheme?: string;
  /**
   * @minLength 3
   * @maxLength 3
   */
  country?: string;
  email?: string;
  incorporatedOn?: string;
  legalAddress?: string;
  phone?: string;
  postalAddress?: string;
  registrationLocation?: string;
  registrationNumber: string;
  taxId?: string;
  type?: string;
  website?: string;
  json?: CompanyInfo['meta'];
}
