/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { CurrencyApi } from 'api';
import BigNumber from 'bignumber.js';
import { makeAutoObservable, runInAction } from 'mobx';
import { CurrencyModel } from 'models/CurrencyModel';
import { getUnixTsNow } from 'utils/dates';
import { EURO_SIGN } from 'assets/config';
import { bigNumberToStr } from 'utils/bignumber';

const REFRESH_COOLDOWN_SEC = 10;

type ExchangeProps = {
  from: string;
  to: string;
  amount: BigNumber;
};

export class CurrencyStore {
  constructor(private currencyApi: CurrencyApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _currenciesMap = new Map<string, CurrencyModel>();
  private _lastUpdatedAt: number = 0;

  get currencies() {
    return Array.from(this._currenciesMap.values());
  }

  get currenciesMap() {
    return this._currenciesMap;
  }

  get lastUpdatedAt() {
    return this._lastUpdatedAt;
  }

  async init() {
    await this.fetchCurrencies();
  }

  exchangeCurrencies({ from, to, amount }: ExchangeProps) {
    const original = this._currenciesMap.get(from);
    const target = this._currenciesMap.get(to);

    if (!original?.eurPrice || !target?.eurPrice) {
      return null;
    }

    const originalAmountEur = amount.multipliedBy(original.eurPrice);
    const targetAmount = originalAmountEur.dividedBy(target.eurPrice);

    return {
      original: original,
      target: target,
      originalAmount: amount,
      targetAmount: targetAmount,
    };
  }

  getEuroValue(coin = 'BTC', amount = 0, getPrice = false) {
    const exchangeResult = this.exchangeCurrencies({
      from: coin.toUpperCase() || '',
      to: 'USD',
      amount: new BigNumber(amount || 0),
    });

    if (!exchangeResult) return null;

    const { original, targetAmount } = exchangeResult;
    const price = original.eurPrice;

    if (getPrice)
      return EURO_SIGN + bigNumberToStr(new BigNumber(price || 0), 4);

    return EURO_SIGN + bigNumberToStr(targetAmount, 2);
  }

  private async fetchCurrencies() {
    const timeNow = getUnixTsNow();

    if (timeNow < this._lastUpdatedAt + REFRESH_COOLDOWN_SEC) {
      return;
    }

    const result = await this.currencyApi.currencies.getAllCurrencies();

    runInAction(() => {
      this._currenciesMap.clear();
      result.data.forEach((x) => {
        const model = new CurrencyModel(x);
        this._currenciesMap.set(model.id, model);
      });
      this._lastUpdatedAt = timeNow;
    });
  }
}
