/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { makeAutoObservable, runInAction } from 'mobx';
import { CollectionFilter } from './CollectionFilter';
import { FilterModel } from 'models/filter/FilterModel';
import { PaginationState } from './PaginationState';

type FetchResult<T> = {
  items: T[];
  totalItems: number;
  totalPages: number;
};

type ConstructorProps<T, F> = {
  fetchFn: (params: F) => Promise<FetchResult<T>>;
  itemsPerPage: number;
  pageQueryParamId: string;
  defaultFilter?: FilterModel<F>[];
  getFilterFromQueryParamsFn?: (params: URLSearchParams) => FilterModel<F>[];
  filterCounterIgnoreValues?: string[];
};

export class CollectionWithPages<T extends { id: string }, F extends object> {
  constructor(private props: ConstructorProps<T, F>) {
    this.pagination.setItemsPerPage(props.itemsPerPage);

    makeAutoObservable(this);
  }

  private _items: T[] | null = null;
  private _totalItems: number = 0;
  private _pagination = new PaginationState();
  private _loading: boolean = false;

  private storeFilter = new CollectionFilter(
    this.props.defaultFilter,
    this.props.filterCounterIgnoreValues
  );

  get items() {
    return this._items;
  }

  get totalItems() {
    return this._totalItems;
  }

  get pagination() {
    return this._pagination;
  }

  get filter() {
    return this.storeFilter.filterValue as Omit<F, 'page' | 'size'>;
  }

  get totalFiltersCount() {
    return this.storeFilter.totalCount;
  }

  get pageQueryParamId() {
    return this.props.pageQueryParamId;
  }

  get isLoading() {
    return this._loading;
  }

  async fetchData(filter: FilterModel<F>[], page: number = 1) {
    this.storeFilter.replace(filter);
    this.pagination.setCurrentPage(page);

    await this.fetch();
  }

  async refresh() {
    await this.fetch();
  }

  reset() {
    runInAction(() => {
      this._loading = false;
      this._items = null;
      this.storeFilter.reset();
      this.pagination.reset();
    });
  }

  getPageFromQueryParams(queryParams: URLSearchParams) {
    const pageParsed = Number(queryParams.get(this.props.pageQueryParamId));
    const page = pageParsed && !Number.isNaN(pageParsed) ? pageParsed : 1;

    return page;
  }

  getFilterFromQueryParams(queryParams: URLSearchParams) {
    return this.props.getFilterFromQueryParamsFn?.(queryParams) ?? [];
  }

  private async fetch() {
    try {
      runInAction(() => {
        this._loading = true;
      });

      const page = this.pagination.currentPage;
      const currentFilter = this.storeFilter.filterValue;
      const { fetchFn } = this.props;
      const itemsPerPage = this.pagination.itemsPerPage;

      const result = await fetchFn({
        ...currentFilter,
        page: page - 1,
        size: itemsPerPage,
      });

      const items = result.items;
      const maxPage = result.totalPages;

      runInAction(() => {
        this.pagination.setMaxPage(maxPage);
        this._totalItems = result.totalItems;
        this._items = items;
        this._loading = false;
      });

      // Outside of boundries - need to refetch
      if (maxPage > 0 && page > maxPage) await this.fetch();
    } catch {
      runInAction(() => {
        this._loading = false;
      });
    }
  }
}

export type BaseCollectionWithPages = CollectionWithPages<{ id: string }, {}>;
