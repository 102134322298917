/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';

import { useSidebarPartersSelect } from 'components/sidebars/sidebar-partners-select';
import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';

const LayoutNavbar = observer(() => {
  const intl = useIntl();

  const { layoutStore, partnerStore } = useBaseStores();

  const { showPartnersSidebar } = useSidebarPartersSelect();

  return (
    <div className={styles.main}>
      <div className={styles.title_container}>
        {!!layoutStore.navbarData.title && (
          <span className={styles.title}>{layoutStore.navbarData.title}</span>
        )}

        {!!layoutStore.navbarData.content && (
          <div className={styles.content}>{layoutStore.navbarData.content}</div>
        )}
      </div>

      {/*{!!partnerStore.currentPartner && (*/}
      {/*  <div className={styles.user_container}>*/}
      {/*    <button className={styles.partner} onClick={showPartnersSidebar}>*/}
      {/*      <span className={styles.partner_name}>*/}
      {/*        {partnerStore.currentPartner?.name}*/}
      {/*      </span>*/}

      {/*      <span className={styles.partner_label}>*/}
      {/*        {intl.formatMessage({*/}
      {/*          id: 'navbar.partner',*/}
      {/*          defaultMessage: 'Partner',*/}
      {/*        })}*/}
      {/*      </span>*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
});

export default LayoutNavbar;
