import { OperationSelect } from '../../operation-select';
import styles from './index.module.css';
import { useIntl } from 'react-intl';
import Button from 'components/core/button';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import FormField from 'components/form/form-field';

import { FormValues, Step } from '..';
import { WithdrawInputs } from '../withdraw-inputs';
import { useOperationStore } from '../../provider/OperationStoreProvider';
import FormSelect from 'components/form/form-select';
import { WithdrawType } from 'api/wallet';

type StepTransferProps = {
  setStep: (step: Step) => void;
};

export const StepWithdraw = observer(({ setStep }: StepTransferProps) => {
  const intl = useIntl();
  const store = useOperationStore();

  const { handleSubmit, control } = useFormContext<FormValues>();

  const options = [
    WithdrawType.CRYPTO,
    WithdrawType.BANK_TRANSFER,
    WithdrawType.COUNTERPARTY,
  ].map((item) => ({
    id: item,
    label: intl.formatMessage({
      id: `sidebar.operation.${item}`,
      defaultMessage: (item[0] + item.slice(1).toLowerCase()).replace('_', ' '),
    }),
    value: item,
  }));

  const withdrawType = useWatch({ control, name: 'type' });

  return (
    <div className={styles.container}>
      <OperationSelect />
      <Controller
        rules={{
          required: intl.formatMessage({
            id: 'validation.field_required',
            defaultMessage: 'Field is required',
          }),
        }}
        control={control}
        name='type'
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <FormSelect
              label={intl.formatMessage({
                id: 'sidebar.create_offer.withdraw_type',
                defaultMessage: 'Withdraw type',
              })}
              variant='filled'
              options={options}
              onChange={(value) => onChange(value?.value as WithdrawType)}
              value={options.find((item) => item.value === value)}
            />
          );
        }}
      />

      <div className={styles.box}>
        <span className={styles.title}>
          {intl.formatMessage({
            id: 'sidebar.operation.accounts',
            defaultMessage: 'Accounts',
          })}
        </span>
        <div className={styles.card}>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'sidebar.operation.from',
              defaultMessage: 'From',
            })}
          </span>
          <Controller
            rules={{
              required: intl.formatMessage({
                id: 'validation.field_required',
                defaultMessage: 'Field is required',
              }),
            }}
            control={control}
            name='fromAccount'
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormField
                  required
                  placeholder={intl.formatMessage({
                    id: 'sidebar.operation.account_id',
                    defaultMessage: 'Account ID',
                  })}
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              );
            }}
          />
        </div>
        <div className={styles.card}>
          {withdrawType === WithdrawType.COUNTERPARTY && (
            <>
              <span className={styles.label}>
                {intl.formatMessage({
                  id: 'sidebar.operation.counerparty',
                  defaultMessage: 'Counertparty ID',
                })}
              </span>

              <Controller
                rules={{
                  required: intl.formatMessage({
                    id: 'validation.field_required',
                    defaultMessage: 'Field is required',
                  }),
                }}
                control={control}
                name='counterPartyId'
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormField
                    required
                    placeholder={intl.formatMessage({
                      id: 'sidebar.operation.counerparty',
                      defaultMessage: 'Counertparty ID',
                    })}
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />
            </>
          )}

          {withdrawType === WithdrawType.CRYPTO && (
            <>
              <span className={styles.label}>
                {intl.formatMessage({
                  id: 'sidebar.operation.to',
                  defaultMessage: 'To',
                })}
              </span>

              <Controller
                rules={{
                  required: intl.formatMessage({
                    id: 'validation.field_required',
                    defaultMessage: 'Field is required',
                  }),
                }}
                control={control}
                name='to'
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormField
                    required
                    placeholder={intl.formatMessage({
                      id: 'sidebar.operation.account_id',
                      defaultMessage: 'Account ID',
                    })}
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />
            </>
          )}

          {withdrawType === WithdrawType.BANK_TRANSFER && (
            <>
              <span className={styles.label}>
                {intl.formatMessage({
                  id: 'sidebar.operation.to',
                  defaultMessage: 'To',
                })}
              </span>

              <Controller
                rules={{
                  required: intl.formatMessage({
                    id: 'validation.field_required',
                    defaultMessage: 'Field is required',
                  }),
                }}
                control={control}
                name='iban'
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormField
                    required
                    placeholder={intl.formatMessage({
                      id: 'sidebar.operation.iban',
                      defaultMessage: 'IBAN number',
                    })}
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />
              <Controller
                rules={{
                  required: intl.formatMessage({
                    id: 'validation.field_required',
                    defaultMessage: 'Field is required',
                  }),
                }}
                control={control}
                name='bicOrSwiftCode'
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormField
                    required
                    placeholder={intl.formatMessage({
                      id: 'sidebar.operation.bic',
                      defaultMessage: 'BIC or SWIFT number',
                    })}
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />
            </>
          )}
        </div>
      </div>

      <WithdrawInputs />

      <Controller
        control={control}
        name='note'
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            type='text_area'
            label={intl.formatMessage({
              id: 'sidebar.operation.note.title',
              defaultMessage: 'Note',
            })}
            placeholder={intl.formatMessage({
              id: 'sidebar.operation.note.placeholder',
              defaultMessage:
                'Specify a reason (The user will see your message)',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Button
        variant='primary'
        disabled={
          store.exchange.feeData.loading || !store.exchange.feeData.value
        }
        onClick={handleSubmit(() => setStep('summary'))}
      >
        Continue
      </Button>
    </div>
  );
});
