import { Segmented } from 'components/core/segmented';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';

const options = [
  {
    label: 'Users',
    value: 'users',
  },
  {
    label: 'Groups',
    value: 'groups',
  },
];

export const NavigationTab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isGroup = location.pathname.includes(ROUTES.groups.path);
  const value = isGroup ? 'groups' : 'users';

  return (
    <Segmented
      value={value}
      defaultValue={options[0].value}
      onChange={(value) => {
        navigate(
          value === 'groups'
            ? ROUTES.users.groups.fullPath
            : ROUTES.users.users.path
        );
      }}
      options={options}
    />
  );
};
