/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { LoanStatus } from '../../../../api/loans';
import Badge from '../badge';
import { useIntlLoanStatuses } from 'hooks/intl/loan-statuses';
import { useCallback } from 'react';

type Props = {
  status?: LoanStatus | undefined;
};

const BadgeLoanStatus = ({ status }: Props) => {
  const loanStatus = status !== undefined ? status : LoanStatus.UNDEFINED;
  const { loanStatusToIntlText } = useIntlLoanStatuses();

  const statusToBadgeType = useCallback((status: LoanStatus) => {
    switch (status) {
      case LoanStatus.APPROVED:
      case LoanStatus.LENT:
      case LoanStatus.PAID:
        return 'success' as const;

      case LoanStatus.LATE:
      case LoanStatus.LIQUIDATING:
        return 'warning' as const;

      case LoanStatus.FAILED:
      case LoanStatus.MARGIN_CALL:
      case LoanStatus.LIQUIDATED:
      case LoanStatus.REJECTED:
        return 'danger' as const;

      default:
        return 'neutral' as const;
    }
  }, []);
  return (
    <Badge
      text={loanStatusToIntlText(loanStatus)}
      type={statusToBadgeType(loanStatus)}
    />
  );
};

export default BadgeLoanStatus;
