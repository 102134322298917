/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BaseCollectionWithPages } from 'stores/collection/CollectionWithPages';

type Props = {
  collection: BaseCollectionWithPages;
};

export const useParseCollectionQueryParams = ({ collection }: Props) => {
  const [queryParams] = useSearchParams();

  return {
    page: collection.getPageFromQueryParams(queryParams),
    filter: collection.getFilterFromQueryParams(queryParams),
  };
};

export const useSetQueryParams = () => {
  const [queryParams, setQueryParams] = useSearchParams();

  const setFilterQueryParams = useCallback(
    (values: { id: string; value?: string }[]) => {
      values.forEach((x) => {
        if (!x.value || x.value === '') {
          queryParams.delete(x.id);
          return;
        }

        queryParams.set(x.id, x.value);
      });

      setQueryParams(queryParams, { replace: true });
    },
    [queryParams, setQueryParams]
  );

  return {
    setFilterQueryParams,
  };
};
