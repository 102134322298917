import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { StepTransfer } from './step-transfer';
import { StepSummary } from './step-summary';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';

type DepositPanelProps = {
  accountId?: string;
  handleSubmit: () => void;
};

export type FormValues = {
  amount: string;
  toAccount: string;
  currency: string;
  note: string;
};

export type Step = 'deposit' | 'summary';

export const DepositPanel = observer(
  ({ accountId, handleSubmit }: DepositPanelProps) => {
    const intl = useIntl();
    const [step, setStep] = useState<Step>('deposit');

    const { layoutStore } = useBaseStores();

    useEffect(() => {
      layoutStore.setSidebarTitle(
        intl.formatMessage({
          id: `sidebar.operation.${step}`,
          defaultMessage: step[0].toUpperCase() + step.slice(1),
        })
      );
    }, [step, layoutStore, intl]);

    const form = useForm<FormValues>({
      mode: 'onChange',
      defaultValues: {
        toAccount: accountId,
        note: '',
        amount: '0',
      },
    });

    return (
      <FormProvider {...form}>
        {step === 'deposit' && <StepTransfer setStep={setStep} />}
        {step === 'summary' && (
          <StepSummary setStep={setStep} handleSubmit={handleSubmit} />
        )}
      </FormProvider>
    );
  }
);
