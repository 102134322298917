/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import { observer } from 'mobx-react-lite';
import {
  ProvideCompaniesPageStore,
  useCompaniesPageStore,
} from './provider/CompanyStoreProvider';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import CompaniesTable from 'components/tables/companies-table';

const CompaniesPageWithProvider = observer(() => {
  const intl = useIntl();
  const companiesPageStore = useCompaniesPageStore();

  const totalText = useMemo(
    () =>
      companiesPageStore.companies.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.company.total',
              defaultMessage: 'Companies {company}',
            },
            {
              company: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.company.total.count',
                      defaultMessage: '{count} company',
                    },
                    { count: companiesPageStore.companies.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, companiesPageStore.companies.totalItems]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.company.title',
        defaultMessage: 'Company info',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalText]);

  return (
    <div className={styles.main}>
      {/* <CompaniesFilter collectData={companiesPageStore.companies} /> */}
      <CompaniesTable collection={companiesPageStore.companies} />
    </div>
  );
});

const CompaniesPage = () => {
  return (
    <ProvideCompaniesPageStore>
      <CompaniesPageWithProvider />
    </ProvideCompaniesPageStore>
  );
};

export default CompaniesPage;
