/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { observer } from 'mobx-react-lite';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import { useEffect } from 'react';
import SubscriptionsTable from 'components/tables/subscriptions-table';
import { FaPlus } from 'react-icons/fa6';
import Button from 'components/core/button';
import { useSidebarSubscriptionCreate } from 'components/sidebars/sidebar-subscription-create';
import { useIntl } from 'react-intl';

const UserDetailsSubscriptionsTab = observer(() => {
  const userDetailsPageStore = useUserDetailsPageStore();
  const intl = useIntl();

  const { showSubscriptionCreateSidebar } = useSidebarSubscriptionCreate({
    subscriptionDetails: userDetailsPageStore.subscriptionDetails,
    onCreate: (subscriptionId: string) =>
      userDetailsPageStore.createSubscription(subscriptionId),
  });

  const { page, filter } = useParseCollectionQueryParams({
    collection: userDetailsPageStore.subscriptions,
  });

  useEffect(() => {
    userDetailsPageStore.subscriptions.fetchData(filter, page);
  }, [filter, page, userDetailsPageStore.subscriptions]);

  return (
    <>
      <div className='flex justify-end mb-4'>
        <Button
          variant='primary'
          onClick={() => showSubscriptionCreateSidebar()}
        >
          <span>
            {intl.formatMessage({
              id: 'page.userDetails.buttons.create_subscription',
              defaultMessage: 'To create a Subscription',
            })}
          </span>
          <FaPlus />
        </Button>
      </div>

      <SubscriptionsTable
        collection={userDetailsPageStore.subscriptions}
        onCancel={(subscription) =>
          userDetailsPageStore.cancelSubscription(subscription.id)
        }
        onDelete={(subscription) =>
          userDetailsPageStore.deleteSubscription(subscription.id)
        }
      />
    </>
  );
});

export default UserDetailsSubscriptionsTab;
