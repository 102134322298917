/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import Button from 'components/core/button';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { TableProps } from 'antd';
import TableSimple from 'components/core/table-simple';
import { Individual } from 'api/auth';

type Props = {
  collection: Individual[];
  onShowJSON: (value: Individual) => void;
  onShowSidebar: (value: Individual) => void;
  expandedRow: ({ individual }: { individual: Individual }) => JSX.Element;
};

const IndividualsTable = observer(
  ({
    collection,
    onShowJSON,
    onShowSidebar,
    expandedRow: ExpandedRow,
  }: Props) => {
    const intl = useIntl();

    const columns: TableProps<Individual>['columns'] = [
      {
        title: intl.formatMessage({
          id: 'table.kyb_verifications.col.name',
          defaultMessage: 'Name',
        }),
        dataIndex: 'firstName',
        key: 'firstName',
        render: (_, record) => (
          <span>
            {record.firstName} {record.lastName}
          </span>
        ),
        width: 60,
      },
      {
        title: intl.formatMessage({
          id: 'table.kyb_verifications.col.role',
          defaultMessage: 'Role',
        }),
        dataIndex: 'title',
        key: 'title',
        render: (value: string) => <span>{value}</span>,
        width: 20,
      },
      {
        title: intl.formatMessage({
          id: 'table.kyb_verifications.col.ownership',
          defaultMessage: 'Ownership',
        }),
        dataIndex: 'percentageOwnership',
        key: 'percentageOwnership',
        render: (id: string) => <span>{id}%</span>,
        width: 10,
      },
      {
        title: intl.formatMessage({
          id: 'table.kyb_verifications.col.action',
          defaultMessage: 'Action',
        }),
        dataIndex: 'verificationIds',
        render: (_, record) => (
          <div className='flex gap-2'>
            <Button
              variant='primary'
              className='w-full'
              onClick={() => onShowSidebar(record)}
            >
              {intl.formatMessage({
                id: 'table.kyb_verifications.col.edit',
                defaultMessage: 'Edit',
              })}
            </Button>
            <Button
              variant='primary'
              className='w-full'
              onClick={() => onShowJSON(record)}
            >
              {intl.formatMessage({
                id: 'table.kyb_verifications.col.show_json',
                defaultMessage: 'Show JSON',
              })}
            </Button>
          </div>
        ),
        width: 120,
      },
    ];

    return (
      <TableSimple<Individual>
        size='large'
        collection={collection}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => <ExpandedRow individual={record} />,
          rowExpandable: (record) => true,
        }}
      />
    );
  }
);

export default IndividualsTable;
