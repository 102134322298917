/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';
import {
  FeePoint,
  LoanOffer,
  LoanType,
  PaymentPeriodTypeEnum,
} from 'api/loans';
import { bigNumberToStr } from 'utils/bignumber';

export class LoanOfferModel {
  constructor(dto: LoanOffer) {
    this.id = dto.id;
    this.name = dto.name;
    this.description = dto.description;
    this.minPrincipalAmount = dto.minPrincipalAmount
      ? BigNumber(dto.minPrincipalAmount)
      : undefined;
    this.minPrincipalCurrency = dto.minPrincipalCurrency ?? undefined;
    this.maxPrincipalAmount = dto.maxPrincipalAmount
      ? BigNumber(dto.maxPrincipalAmount)
      : undefined;
    this.maxPrincipalCurrency = dto.maxPrincipalCurrency ?? undefined;
    this.principalCurrency = dto.principalCurrency;
    this.collateralAllowedCurrencies = dto.collateralAllowedCurrencies
      ? dto.collateralAllowedCurrencies.split(';')
      : undefined;
    this.minTerm = dto.minTerm ?? undefined;
    this.maxTerm = dto.maxTerm ?? undefined;
    this.paymentPeriodType = dto.paymentPeriodType;
    this.paymentPeriod = dto.paymentPeriod ?? undefined;
    this.interestRate = BigNumber(dto.interestRate);
    this.collateralRate = dto.collateralRate
      ? BigNumber(dto.collateralRate)
      : undefined;
    this.marginCallRate = dto.marginCallRate
      ? BigNumber(dto.marginCallRate)
      : undefined;
    this.minHealthRate = dto.minHealthRate
      ? BigNumber(dto.minHealthRate)
      : undefined;
    this.feePoints = dto.feePoints;
    this.liquidationRate = dto.liquidationRate
      ? BigNumber(dto.liquidationRate)
      : undefined;
    this.type = dto.type;
  }

  id: string;
  name: string;
  description: string;
  minPrincipalAmount?: BigNumber;
  minPrincipalCurrency?: string;
  maxPrincipalAmount?: BigNumber;
  maxPrincipalCurrency?: string;
  principalCurrency: string;
  collateralAllowedCurrencies?: string[];
  minTerm?: string;
  maxTerm?: string;
  paymentPeriodType: PaymentPeriodTypeEnum;
  paymentPeriod?: string;
  interestRate: BigNumber;
  collateralRate?: BigNumber;
  marginCallRate?: BigNumber;
  minHealthRate?: BigNumber;
  feePoints: FeePoint[];
  liquidationRate?: BigNumber;
  type: LoanType;

  static toDto(offer: LoanOfferModel): LoanOffer {
    return {
      id: offer.id,
      name: offer.name,
      description: offer.description,
      type: offer.type,
      interestRate: bigNumberToStr(offer.interestRate),
      feePoints: offer.feePoints,
      paymentPeriodType: offer.paymentPeriodType,
      paymentPeriod: offer.paymentPeriod,
      principalCurrency: offer.principalCurrency,
      collateralAllowedCurrencies: offer.collateralAllowedCurrencies
        ? offer.collateralAllowedCurrencies.join(';')
        : undefined,
      minPrincipalCurrency: offer.minPrincipalCurrency,
      minPrincipalAmount: offer.minPrincipalAmount
        ? bigNumberToStr(offer.minPrincipalAmount)
        : undefined,
      maxPrincipalCurrency: offer.maxPrincipalCurrency,
      maxPrincipalAmount: offer.maxPrincipalAmount
        ? bigNumberToStr(offer.maxPrincipalAmount)
        : undefined,
      collateralRate: offer.collateralRate
        ? bigNumberToStr(offer.collateralRate)
        : undefined,
      liquidationRate: offer.liquidationRate
        ? bigNumberToStr(offer.liquidationRate)
        : undefined,
      marginCallRate: offer.marginCallRate
        ? bigNumberToStr(offer.marginCallRate)
        : undefined,
      minHealthRate: offer.minHealthRate
        ? bigNumberToStr(offer.minHealthRate)
        : undefined,
      minTerm: offer.minTerm,
      maxTerm: offer.maxTerm,
    };
  }
}
