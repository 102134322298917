/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { AccountDetailsPageStore } from '../store/AccountDetailsPageStore';

const storeContext = createContext<AccountDetailsPageStore>(
  {} as AccountDetailsPageStore
);

type Props = {
  accountId: string;
  userId: string;
  children?: React.ReactNode;
};
export const ProviderAccountDetailPageStore = observer(
  ({ children, accountId, userId }: Props) => {
    const { partnerStore, authApi, walletApi, currencyStore } = useBaseStores();

    const store = useLocalObservable(
      () => new AccountDetailsPageStore(authApi, walletApi, currencyStore)
    );

    useEffect(() => {
      if (!partnerStore.currentPartner?.id) {
        return;
      }

      store.init(accountId, partnerStore.currentPartner.id, userId);
    }, [partnerStore.currentPartner?.id, store, accountId, userId]);

    return (
      <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
  }
);

export const useAccountDetailPageStore = () => {
  return useContext(storeContext);
};
