/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React, { useState } from 'react';
import Button from 'components/core/button';
import FormListSingleSelect from 'components/form/form-list-single-select';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { SelectItemModel } from 'models/form/SelectItemModel';
import { BaseInvoiceModel } from 'models/InvoicesModel';
import { AdminInvoiceModel } from 'models/request/AdminInvoiceModel';
import { InvoiceType } from 'api/acquiring';
import SearchField from '../../core/search-field';

const INVOICES_TYPES: InvoiceType[] = [
  InvoiceType.ORIGINAL,
  InvoiceType.RECURRENT,
];

const selectOptionsInvoices = INVOICES_TYPES.map((type) => ({
  id: type.toLocaleUpperCase(),
  label: type,
  value: type,
}));

type Props = {
  collection: CollectionWithPages<BaseInvoiceModel, AdminInvoiceModel>;
};

const SidebarInvoicesFilter = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();
  const { setFilterQueryParams } = useSetQueryParams();

  const [selectType, setSelectType] = useState<
    SelectItemModel<string> | undefined
  >(selectOptionsInvoices.find((x) => x.id === collection.filter.type));

  const [userId, setUserId] = useState<string>(collection.filter.userId || '');

  const onChangeSelectType = (val: SelectItemModel<string> | undefined) => {
    setSelectType(val);
  };

  const onChangeUserId = (val: string | '') => {
    setUserId(val);
  };

  const onApply = useCallback(() => {
    setFilterQueryParams([
      { id: URL_SEARCH_PARAMS.page, value: '1' },

      {
        id: URL_SEARCH_PARAMS.type,
        value: selectType ? selectType.id : undefined,
      },

      {
        id: URL_SEARCH_PARAMS.userId,
        value: userId,
      },
    ]);

    layoutStore.toggleSidebar(false);
  }, [layoutStore, setFilterQueryParams, selectType, userId]);

  return (
    <React.Fragment>
      <h5>
        {intl.formatMessage({
          id: 'sidebar.filters.invoicesUserIdTitle',
          defaultMessage: 'User Id',
        })}
      </h5>
      <SearchField
        className={'mb-4'}
        placeholder={intl.formatMessage({
          id: 'sidebar.filters.invoicesUserId',
          defaultMessage: 'Search by User ID',
        })}
        value={userId}
        onChange={onChangeUserId}
      />

      <FormListSingleSelect
        title={intl.formatMessage({
          id: 'sidebar.filters.invoicesType',
          defaultMessage: 'Invoices type',
        })}
        options={selectOptionsInvoices}
        selected={selectType}
        onSelect={onChangeSelectType}
        collapse={true}
      />

      <Button className={styles.apply_btn} variant='primary' onClick={onApply}>
        {intl.formatMessage({
          id: 'sidebar.filters.apply',
          defaultMessage: 'Apply',
        })}
      </Button>
    </React.Fragment>
  );
});

export const useSidebarInvoicesFilter = ({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.filters',
        defaultMessage: 'Filters',
      }),
      <SidebarInvoicesFilter collection={collection} />
    );
  }, [collection, intl, layoutStore]);

  return {
    showInvoicesFiltersSidebar: showSidebar,
  };
};
