/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React, { useMemo } from 'react';
import styles from './index.module.css';
import classNames from 'classnames';
import { ReactComponent as PointerIcon } from 'assets/images/icons/pointer.svg';
import { ReactComponent as HelpIcon } from 'assets/images/icons/help.svg';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';

type Props = {
  value: number | string;
  className?: string;
};

const HealthIndicator = ({ value, className }: Props) => {
  const intl = useIntl();

  const { clampedValue, message, colorClass } = useMemo(() => {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    const adjustedValue =
      numericValue <= 1
        ? Math.round(numericValue * 100)
        : Math.round(numericValue);
    const clampedValue = Math.min(Math.max(adjustedValue, 0), 100);

    let message;
    let colorClass;

    if (clampedValue < 25) {
      message = intl.formatMessage({
        id: 'common.healthIndicator.catastrophe',
        defaultMessage: 'This is a catastrophe',
      });
      colorClass = 'catastrophe';
    } else if (clampedValue < 50) {
      message = intl.formatMessage({
        id: 'common.healthIndicator.panic',
        defaultMessage: 'Time to panic',
      });
      colorClass = 'panic';
    } else if (clampedValue < 67) {
      message = intl.formatMessage({
        id: 'common.healthIndicator.problems',
        defaultMessage: 'Some problems',
      });
      colorClass = 'problems';
    } else {
      message = intl.formatMessage({
        id: 'common.healthIndicator.good',
        defaultMessage: 'All good',
      });
      colorClass = 'good';
    }

    return { clampedValue, message, colorClass };
  }, [value, intl]);

  return (
    <div className={classNames(styles.main, styles[colorClass], className)}>
      <div className={styles.header}>
        <div className={styles.header_text}>
          <span className={styles.percent}>{clampedValue}%</span>
          <span>{message}</span>
        </div>

        <Tooltip
          title={intl.formatMessage({
            id: 'common.healthIndicator.description',
            defaultMessage: 'Health Indicator',
          })}
        >
          <HelpIcon />
        </Tooltip>
      </div>

      <div className={styles.indicator_container}>
        <div
          className={styles.indicator_point}
          style={{ left: clampedValue + '%' }}
        >
          <PointerIcon />
        </div>

        <div className={styles.indicator}>
          <span className={styles.red} />
          <span className={styles.orange} />
          <span className={styles.yellow} />
          <span className={styles.green} />
        </div>

        <div className={styles.indicator_text}>
          <span>0%</span>
          <span>100%</span>
        </div>
      </div>
    </div>
  );
};

export default HealthIndicator;
