/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import Select, { SelectProps } from 'components/core/select';
import FormLabel, { FormLabelType } from '../form-label';

import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { SelectItemModel } from 'models/form/SelectItemModel';

export type PeriodOption =
  | '1day'
  | '7days'
  | '14days'
  | '1month'
  | '3months'
  | '6months'
  | '1year';

type Props = Omit<SelectProps<string>, 'options'> & {
  label?: string;
  labelType?: FormLabelType;
  options: PeriodOption[];
};

const FormPeriodSelect = (props: Props) => {
  const intl = useIntl();

  const periodOptionToSelectOption = useCallback(
    (option: PeriodOption): SelectItemModel<string> => {
      switch (option) {
        case '1day':
          return {
            id: option,
            label: intl.formatMessage({
              id: 'common.period.1day',
              defaultMessage: '1 Day',
            }),
            value: 'P1D',
          };

        case '7days':
          return {
            id: option,
            label: intl.formatMessage({
              id: 'common.period.7days',
              defaultMessage: '7 Days',
            }),
            value: 'P7D',
          };

        case '14days':
          return {
            id: option,
            label: intl.formatMessage({
              id: 'common.period.14days',
              defaultMessage: '14 Days',
            }),
            value: 'P14D',
          };

        case '1month':
          return {
            id: option,
            label: intl.formatMessage({
              id: 'common.period.1month',
              defaultMessage: '1 Month',
            }),
            value: 'P1M',
          };

        case '3months':
          return {
            id: option,
            label: intl.formatMessage({
              id: 'common.period.3months',
              defaultMessage: '3 Months',
            }),
            value: 'P3M',
          };

        case '6months':
          return {
            id: option,
            label: intl.formatMessage({
              id: 'common.period.6months',
              defaultMessage: '6 Months',
            }),
            value: 'P6M',
          };

        case '1year':
          return {
            id: option,
            label: intl.formatMessage({
              id: 'common.period.1year',
              defaultMessage: '1 Year',
            }),
            value: 'P1Y',
          };
      }
    },
    [intl]
  );

  const availableOptions: SelectItemModel<string>[] = useMemo(
    () => props.options.map((x) => periodOptionToSelectOption(x)),
    [periodOptionToSelectOption, props.options]
  );

  return (
    <div>
      {!!props.label && <FormLabel text={props.label} type={props.labelType} />}

      <Select {...props} options={availableOptions as any} />
    </div>
  );
};

export default FormPeriodSelect;
