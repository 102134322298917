/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { TableProps } from 'antd';
import TableSimple from 'components/core/table-simple';
import { cutString } from 'utils/strings';
import { formattedDate } from 'utils/dates';
import { bigNumberToStr } from 'utils/bignumber';
import { CollateralTransactionsModel } from 'models/CollateralTransactionsModel';
import BigNumber from 'bignumber.js';

type Props = {
  collection: CollateralTransactionsModel[] | null;
};

const CollateralTransactionsTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  if (!collection) {
    return null;
  }

  const columns: TableProps<CollateralTransactionsModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_transactions.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{cutString(id, 3, 3)}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_transactions.col.interestAmount',
        defaultMessage: 'Interest Amount',
      }),
      dataIndex: 'interestAmount',
      key: 'interestAmount',
      render: (interestAmount: string) => <span>{interestAmount}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_transactions.col.interestCurrency',
        defaultMessage: 'Interest Currency',
      }),
      dataIndex: 'interestCurrency',
      key: 'interestCurrency',
      render: (interestCurrency: string) => <span>{interestCurrency}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_transactions.col.plannedPaymentDate',
        defaultMessage: 'Planned Payment Date',
      }),
      dataIndex: 'plannedPaymentDate',
      key: 'plannedPaymentDate',
      render: (plannedPaymentDate) => (
        <span>
          {plannedPaymentDate ? formattedDate(plannedPaymentDate) : ''}
        </span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_transactions.col.principalAmount',
        defaultMessage: 'Principal Amount',
      }),
      dataIndex: 'principalAmount',
      key: 'principalAmount',
      render: (principalAmount: BigNumber) => (
        <span>{bigNumberToStr(principalAmount, 2)}</span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_transactions.col.principalCurrency',
        defaultMessage: 'Principal Currency',
      }),
      dataIndex: 'principalCurrency',
      key: 'principalCurrency',
      render: (principalCurrency: string) => <span>{principalCurrency}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_transactions.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <span>{status}</span>,
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.loan_collateral_transactions.col.type',
        defaultMessage: 'Type',
      }),
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => <span>{type}</span>,
      width: 20,
    },
  ];

  return (
    <TableSimple<CollateralTransactionsModel>
      collection={collection}
      columns={columns}
    />
  );
});

export default CollateralTransactionsTable;
