/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import ScopesFilter from './scopes-filter';

import { observer } from 'mobx-react-lite';
import {
  ProvideScopesPageStore,
  useScopesPageStore,
} from './provider/StoreProvider';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import UserScopesTable from 'components/tables/user-scopes-table';

const ScopesPageWithProvider = observer(() => {
  const intl = useIntl();
  const scopesPageStore = useScopesPageStore();

  const totalText = useMemo(
    () =>
      scopesPageStore.scopes.totalItems > 0
        ? intl.formatMessage(
            {
              id: 'page.scopes.total',
              defaultMessage: 'Total {scopes}',
            },
            {
              scopes: (
                <span className={styles.count}>
                  {intl.formatMessage(
                    {
                      id: 'page.scopes.total.count',
                      defaultMessage: '{count} scopes',
                    },
                    { count: scopesPageStore.scopes.totalItems }
                  )}
                </span>
              ),
            }
          )
        : null,
    [intl, scopesPageStore.scopes.totalItems]
  );

  const { layoutStore } = useBaseStores();

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.scopes.title',
        defaultMessage: 'Scopes',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  useEffect(() => {
    layoutStore.setNavbarContent(
      <div className={styles.total}>{totalText}</div>
    );

    return () => layoutStore.setNavbarContent(null);
  }, [intl, layoutStore, totalText]);

  return (
    <div className={styles.main}>
      <ScopesFilter collectData={scopesPageStore.scopes} />
      <UserScopesTable collection={scopesPageStore.scopes} />
    </div>
  );
});

const UserScopesPage = () => {
  return (
    <ProvideScopesPageStore>
      <ScopesPageWithProvider />
    </ProvideScopesPageStore>
  );
};

export default UserScopesPage;
